import { Divider } from "@/app/components/Divider/index.js";
import { AffiliationError } from "@/app/containers/AffiliationErrors/components/AffiliationErrorsType/AffiliationError/index.js";
import { AffiliationErrorsTypeProps } from "@/app/containers/AffiliationErrors/components/AffiliationErrorsType/types.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { getErrorTypeText } from "@/entities/AffiliationError/helpers/getErrorTypeText.js";
import { Flex, Icon, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { StyledFlex } from "./styles.js";

export const AffiliationErrorsType = (props: AffiliationErrorsTypeProps): JSX.Element => {
    const { t, translations } = useTranslation();
    const { errorType, affiliationErrors } = props;

    const errorTypeText = getErrorTypeText({ t, translations, errorType });

    return (
        <Flex direction="column" gap="16">
            <StyledFlex direction="row" gap="8" align="center">
                <Icon icon="essential/circle-bold" size="small" />
                <Text size={TextSize.MEDIUM} weight={TextWeight.BOLD}>
                    {errorTypeText}
                </Text>
            </StyledFlex>
            <Flex gap="16" direction="row">
                {affiliationErrors.map((affiliationError) => (
                    <AffiliationError affiliationError={affiliationError} key={affiliationError._id} />
                ))}
            </Flex>
            <Divider />
        </Flex>
    );
};
