import { ITheme } from "@waapi/waap-ui";
import { useState } from "react";
import { useTheme } from "styled-components";
import { BaseInput } from "../BaseInput/index.js";
import { ShowPasswordIcon } from "./style.js";
import { BasePasswordInputProps } from "./types.js";

export const BasePasswordInput = (props: BasePasswordInputProps): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme() as ITheme;

    return (
        <BaseInput
            {...props}
            type={showPassword ? "text" : "password"}
            appendElements={
                <ShowPasswordIcon
                    icon={showPassword ? "tech_security/eye-off" : "tech_security/eye-on-bold"}
                    iconColor={theme.grayscale[70]}
                    onClick={() => setShowPassword(!showPassword)}
                />
            }
        />
    );
};
