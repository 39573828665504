import { AllEnvironmentName, EnvironmentName, PALPATINE_BACK_HOSTS } from "@waapi/environments-repository";

export const getApiUrl = (selectedEnv: AllEnvironmentName = EnvironmentName.PROD): string => {
    return new URL(
        "/api",
        process.env.VITE_ADMIN_PALPATINE_API_URL ?? selectedEnv in PALPATINE_BACK_HOSTS
            ? PALPATINE_BACK_HOSTS[selectedEnv]
            : PALPATINE_BACK_HOSTS.prod,
    ).href;
};
