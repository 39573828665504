import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useWalletState } from "./types.js";

export const initialState: useWalletState = {
    wallet: null,
    isLoading: false,
    error: null,
};

const walletSlice = createSlice({
    name: "useWalletState",
    initialState,
    reducers: {
        updateWalletState(state, action: PayloadAction<Partial<useWalletState>>) {
            state.wallet = action.payload.wallet ?? state.wallet;
            state.isLoading = action.payload.isLoading ?? state.isLoading;
        },
        clear(state) {
            state.wallet = initialState.wallet;
        },
    },
});

export const { actions, reducer, name: sliceKey } = walletSlice;
