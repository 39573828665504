import useRequest from "@/customHooks/useRequest.js";
import useToasts from "@/customHooks/useToasts.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "redux-injectors";
import { isLoadingWallet, selectWalletState } from "./selectors.js";
import { actions, reducer, sliceKey } from "./slice.js";

export const useWalletState = () => {
    useInjectReducer({ key: sliceKey, reducer });
    const { addToast } = useToasts();
    const { t, translations } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingWallet);
    const wallet = useSelector(selectWalletState);

    const getWallet = useRequest<"getWallet">("getWallet");

    const fetchWallet = async (): Promise<void> => {
        if (isLoading) return;
        try {
            dispatch(actions.updateWalletState({ isLoading: true }));
            const res = await getWallet();
            if (res.hasFailed) {
                addToast(t(translations.pages.linkCreator.toasts.errorCreation()), { appearance: "error", autoDismiss: true });
                dispatch(
                    actions.updateWalletState({
                        isLoading: false,
                    }),
                );
                return;
            }
            dispatch(actions.updateWalletState({ wallet: res.data.ambassadorWallet, isLoading: false }));
        } catch {
            dispatch(
                actions.updateWalletState({
                    isLoading: false,
                }),
            );
        }
    };

    useEffect(() => {
        fetchWallet();
    }, []);

    return { isLoading, wallet, fetchWallet };
};
