import { IconButton } from "@waapi/waap-ui";
import { forwardRef } from "react";
import { StyledLink } from "./styles.js";

export const SmallCustomizableButton = forwardRef<HTMLDivElement>((_, ref): JSX.Element => {
    return (
        <StyledLink ref={ref}>
            <IconButton icon="messaging/send-message-bold" color="gradient" size="small" />
        </StyledLink>
    );
});
