import { getApiToken } from "@/entities/User/hooks/useUserState/selectors.js";
import { getApiUrl } from "@/utils/getApiUrl.js";
import { useInitApiClients as useInitGenericApiClients } from "@waapi/front-utils";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { createPalpatineClient } from "./clients/palpatine/index.js";
import { ApiClient, ApiStaffClient } from "./types.js";
import { useEnvironment } from "../useEnvironment.js";

const buildClients = (params: { apiUrl: string; apiToken?: string }) => [createPalpatineClient(params)];

export const useInitApiClients = () => {
    const { selectedEnv, detectedEnv } = useEnvironment();
    const apiUrl = getApiUrl(selectedEnv || detectedEnv);
    const apiToken = useSelector(getApiToken);

    const onBuildClients = useCallback(() => ({ clients: buildClients({ apiUrl, apiToken }) }), [apiToken, apiUrl]);

    const onBuildStaffClients = useCallback(() => ({ staffClients: [] }), []);

    return useInitGenericApiClients<ApiClient, ApiStaffClient>({
        buildClients: onBuildClients,
        buildStaffClients: onBuildStaffClients,
    });
};
