import { useEffect, useState } from "react";
import { EnvironmentName, PALPATINE_FRONT_URL_FROM_ENVIRONMENT_NAME, PALPATINE_BACK_HOSTS } from "@waapi/environments-repository";
import { getApiUrl } from "@/utils/getApiUrl.js";
import { useToasts } from "@waapi/waap-ui";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useEnvironment } from "../useEnvironment.js";

export const useEnvWarningToast = () => {
    const [warned, setWarned] = useState<boolean>(false);
    const { addToast } = useToasts();
    const { t, translations } = useTranslation();
    const { selectedEnv, detectedEnv } = useEnvironment();

    useEffect(() => {
        if (!warned) {
            if (
                !PALPATINE_FRONT_URL_FROM_ENVIRONMENT_NAME[EnvironmentName.PROD].includes(window.location.host) &&
                getApiUrl(selectedEnv || detectedEnv).includes(PALPATINE_BACK_HOSTS[EnvironmentName.PROD])
            ) {
                addToast(t(translations.toasts.warning_env_retrieving()), {
                    appearance: "warning",
                    autoDismiss: false,
                });
                setWarned(true);
            }
        }
    }, []);

    return { warned };
};
