import { getProfilePicture } from "@/entities/User/hooks/useUserState/selectors.js";
import { Badge } from "@waapi/waap-ui";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import baseAvatar from "../../../assets/blue-flat_waalien.svg";
import { Avatar } from "./style.js";
import { MyAvatarProps } from "./types.js";

export const MyAvatar = ({ hasNotification, ...props }: MyAvatarProps): JSX.Element => {
    const theme = useTheme();
    const profilePicture = useSelector(getProfilePicture);

    const avatar = (
        <Avatar
            {...props}
            src={profilePicture ?? baseAvatar}
            onError={(e: any) => {
                e.target.src = baseAvatar;
            }}
        />
    );
    if (hasNotification) {
        return (
            <span>
                <Badge color="error" variant="dark" xOffset={`-${theme.spacing[12]}`} yOffset={`-${theme.spacing[12]}`} count="1">
                    {avatar}
                </Badge>
            </span>
        );
    }
    return avatar;
};
