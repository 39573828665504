import { getApiToken } from "@/entities/User/hooks/useUserState/selectors.js";
import axiosReq from "@/services/axios/index.js";
import { getApiUrl } from "@/utils/getApiUrl.js";
import { useSelector } from "react-redux";
import { PalpatineClient } from "./useApiClients/clients/palpatine/types.js";
import { useApiClients } from "./useApiClients/context.js";
import { useEnvironment } from "./useEnvironment.js";

const requests = { ...axiosReq };

export type RequestTypes = {
    [P in keyof typeof requests]: ReturnType<(typeof requests)[P]>;
} & PalpatineClient;

const useRequest = <T extends keyof RequestTypes>(request: keyof RequestTypes): RequestTypes[T] => {
    const { detectedEnv, selectedEnv } = useEnvironment();
    const apiUrl = getApiUrl(selectedEnv || detectedEnv);
    const apiToken = useSelector(getApiToken);

    const { clients } = useApiClients();

    const clientMethod = clients.reduce<RequestTypes[T] | undefined>((res, client) => {
        if (client !== undefined && request in client) return client[request];
        return res;
    }, undefined);

    if (clientMethod !== undefined) return clientMethod;
    if (!apiToken) throw new Error(`Request ${request} not found`);
    return requests[request](apiUrl, apiToken) as RequestTypes[T];
};

export default useRequest;
