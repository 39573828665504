import { Button } from "@waapi/waap-ui";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { StyledContainer, StyledText, StyledTitle } from "./style.js";

export function NotFoundPage(): ReactElement {
    const { t, translations } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>{t(translations.pages.not_found.title())}</title>
                <meta name="description" content="Page not found" />
            </Helmet>

            <StyledContainer>
                <StyledTitle>404</StyledTitle>

                <StyledText>
                    <strong>{t(translations.pages.not_found.text_1())}</strong>
                    <br />
                    {t(translations.pages.not_found.text_2())}
                </StyledText>

                <Button iconLeft="arrows/alt-arrow-left" onClick={() => navigate(-1)}>
                    {t(translations.pages.button())}
                </Button>
            </StyledContainer>
        </>
    );
}
