import { getCurrentStagingEnvName } from "@/helpers/getCurrentStagingEnvName.js";
import {
    AllEnvironmentName,
    EnvironmentName,
    LocalEnvironmentName,
    PALPATINE_BACK_HOSTS,
    PALPATINE_FRONT_URL_FROM_ENVIRONMENT_NAME,
    isStagingEnvironment,
} from "@waapi/environments-repository";
import { useEffect } from "react";
import { TypedEmitter } from "tiny-typed-emitter";
import { useSessionStorage } from "./useSessionStorage.js";

const IS_PRODUCTION_FRONT = PALPATINE_FRONT_URL_FROM_ENVIRONMENT_NAME[EnvironmentName.PROD].includes(window.location.host);
const SESSION_KEY = "environment" as const;

type UseEnvironmentReturnType = {
    selectedEnv: AllEnvironmentName;
    detectedEnv: AllEnvironmentName;
    apiUrl: string;
    selectEnv: (env: AllEnvironmentName) => void;
    environments: AllEnvironmentName[];
};

if (!IS_PRODUCTION_FRONT) {
    // this allow to set environment using the browser console (window.environmentSelector.setEnvironment("alpha"))
    window.environmentSelector = {
        listeners: new Set<(env: AllEnvironmentName) => void>(),
        emitter: new TypedEmitter().on("change", (environment: AllEnvironmentName) => {
            console.log("Environment changed", environment);
            window.environmentSelector?.listeners?.forEach?.((listener) => listener(environment));
        }),
        setEnvironment(environment: string) {
            if (isStagingEnvironment(environment as EnvironmentName)) {
                this.emitter.emit("change", environment);
                this.selectedEnvironment = environment;
            } else {
                console.error(`Invalid environment name: ${environment}`);
            }
        },
        selectedEnvironment: window.sessionStorage.getItem(SESSION_KEY) || getCurrentStagingEnvName(),
    } as const;
}

export const useEnvironment = (): UseEnvironmentReturnType => {
    const currentEnv = getCurrentStagingEnvName();
    const [selectedEnv, setSelectedEnv] = useSessionStorage<AllEnvironmentName>(SESSION_KEY, currentEnv);

    useEffect(() => {
        const listener = (env: AllEnvironmentName) => {
            setSelectedEnv(env);
        };
        window?.environmentSelector?.listeners?.add?.(listener);

        return () => {
            window?.environmentSelector?.listeners?.delete?.(listener);
        };
    }, []);

    return {
        detectedEnv: currentEnv,
        selectedEnv: IS_PRODUCTION_FRONT ? EnvironmentName.PROD : selectedEnv,
        apiUrl: PALPATINE_BACK_HOSTS[selectedEnv],
        selectEnv: (env: AllEnvironmentName) => {
            setSelectedEnv(env);
        },
        environments: Object.values({
            ...EnvironmentName,
            ...LocalEnvironmentName,
        }),
    };
};
