import { createContext as gi, useState as ft, useEffect as mt, useContext as Fs, useRef as qr } from "react";
function go(n, e) {
  for (var t = 0; t < e.length; t++) {
    const r = e[t];
    if (typeof r != "string" && !Array.isArray(r)) {
      for (const i in r)
        if (i !== "default" && !(i in n)) {
          const s = Object.getOwnPropertyDescriptor(r, i);
          s && Object.defineProperty(n, i, s.get ? s : {
            enumerable: !0,
            get: () => r[i]
          });
        }
    }
  }
  return Object.freeze(Object.defineProperty(n, Symbol.toStringTag, { value: "Module" }));
}
const vd = "-1.-1.-1.-1", bd = (n) => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "), _d = () => gi({ clients: [], staffClients: [] }), Od = ({
  buildClients: n,
  buildStaffClients: e
}) => {
  const [t, r] = ft([]), [i, s] = ft([]), [a, o] = ft(!1);
  return mt(() => {
    const { clients: u, hasCorrectApiUrl: c } = n();
    r(u), c !== void 0 && o(c);
  }, [n]), mt(() => {
    const { staffClients: u, hasCorrectApiUrl: c } = e();
    s(u), c !== void 0 && o(c);
  }, [e]), { clients: t, staffClients: i, initialized: a };
}, yo = (n) => {
  switch (n.toUpperCase()) {
    case "BIF":
    case "CLP":
    case "DJF":
    case "GNF":
    case "JPY":
    case "KMF":
    case "KRW":
    case "MGA":
    case "PYG":
    case "RWF":
    case "UGX":
    case "VND":
    case "VUV":
    case "XAF":
    case "XOF":
    case "XPF":
      return 0;
    case "BHD":
    case "JOD":
    case "KWD":
    case "OMR":
    case "TND":
      return 3;
    default:
      return 2;
  }
};
function Ie(n) {
  return Ie = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(e) {
    return typeof e;
  } : function(e) {
    return e && typeof Symbol == "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, Ie(n);
}
function we(n, e) {
  if (!(n instanceof e))
    throw new TypeError("Cannot call a class as a function");
}
function vo(n, e) {
  if (Ie(n) != "object" || !n)
    return n;
  var t = n[Symbol.toPrimitive];
  if (t !== void 0) {
    var r = t.call(n, e || "default");
    if (Ie(r) != "object")
      return r;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (e === "string" ? String : Number)(n);
}
function Vs(n) {
  var e = vo(n, "string");
  return Ie(e) == "symbol" ? e : String(e);
}
function Hi(n, e) {
  for (var t = 0; t < e.length; t++) {
    var r = e[t];
    r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(n, Vs(r.key), r);
  }
}
function Se(n, e, t) {
  return e && Hi(n.prototype, e), t && Hi(n, t), Object.defineProperty(n, "prototype", {
    writable: !1
  }), n;
}
function dt(n) {
  if (n === void 0)
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return n;
}
function Wr(n, e) {
  return Wr = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(r, i) {
    return r.__proto__ = i, r;
  }, Wr(n, e);
}
function lr(n, e) {
  if (typeof e != "function" && e !== null)
    throw new TypeError("Super expression must either be null or a function");
  n.prototype = Object.create(e && e.prototype, {
    constructor: {
      value: n,
      writable: !0,
      configurable: !0
    }
  }), Object.defineProperty(n, "prototype", {
    writable: !1
  }), e && Wr(n, e);
}
function Tn(n, e) {
  if (e && (Ie(e) === "object" || typeof e == "function"))
    return e;
  if (e !== void 0)
    throw new TypeError("Derived constructors may only return object or undefined");
  return dt(n);
}
function Je(n) {
  return Je = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(t) {
    return t.__proto__ || Object.getPrototypeOf(t);
  }, Je(n);
}
function Be(n, e, t) {
  return e = Vs(e), e in n ? Object.defineProperty(n, e, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : n[e] = t, n;
}
function Gs(n) {
  if (Array.isArray(n))
    return n;
}
function bo(n) {
  if (typeof Symbol < "u" && n[Symbol.iterator] != null || n["@@iterator"] != null)
    return Array.from(n);
}
function Ui(n, e) {
  (e == null || e > n.length) && (e = n.length);
  for (var t = 0, r = new Array(e); t < e; t++)
    r[t] = n[t];
  return r;
}
function Ys(n, e) {
  if (n) {
    if (typeof n == "string")
      return Ui(n, e);
    var t = Object.prototype.toString.call(n).slice(8, -1);
    if (t === "Object" && n.constructor && (t = n.constructor.name), t === "Map" || t === "Set")
      return Array.from(n);
    if (t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t))
      return Ui(n, e);
  }
}
function Ks() {
  throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function _o(n) {
  return Gs(n) || bo(n) || Ys(n) || Ks();
}
function Zi(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function Fi(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? Zi(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : Zi(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
var Oo = {
  type: "logger",
  log: function(e) {
    this.output("log", e);
  },
  warn: function(e) {
    this.output("warn", e);
  },
  error: function(e) {
    this.output("error", e);
  },
  output: function(e, t) {
    console && console[e] && console[e].apply(console, t);
  }
}, Ao = function() {
  function n(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    we(this, n), this.init(e, t);
  }
  return Se(n, [{
    key: "init",
    value: function(t) {
      var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      this.prefix = r.prefix || "i18next:", this.logger = t || Oo, this.options = r, this.debug = r.debug;
    }
  }, {
    key: "setDebug",
    value: function(t) {
      this.debug = t;
    }
  }, {
    key: "log",
    value: function() {
      for (var t = arguments.length, r = new Array(t), i = 0; i < t; i++)
        r[i] = arguments[i];
      return this.forward(r, "log", "", !0);
    }
  }, {
    key: "warn",
    value: function() {
      for (var t = arguments.length, r = new Array(t), i = 0; i < t; i++)
        r[i] = arguments[i];
      return this.forward(r, "warn", "", !0);
    }
  }, {
    key: "error",
    value: function() {
      for (var t = arguments.length, r = new Array(t), i = 0; i < t; i++)
        r[i] = arguments[i];
      return this.forward(r, "error", "");
    }
  }, {
    key: "deprecate",
    value: function() {
      for (var t = arguments.length, r = new Array(t), i = 0; i < t; i++)
        r[i] = arguments[i];
      return this.forward(r, "warn", "WARNING DEPRECATED: ", !0);
    }
  }, {
    key: "forward",
    value: function(t, r, i, s) {
      return s && !this.debug ? null : (typeof t[0] == "string" && (t[0] = "".concat(i).concat(this.prefix, " ").concat(t[0])), this.logger[r](t));
    }
  }, {
    key: "create",
    value: function(t) {
      return new n(this.logger, Fi(Fi({}, {
        prefix: "".concat(this.prefix, ":").concat(t, ":")
      }), this.options));
    }
  }, {
    key: "clone",
    value: function(t) {
      return t = t || this.options, t.prefix = t.prefix || this.prefix, new n(this.logger, t);
    }
  }]), n;
}(), Ge = new Ao(), gt = function() {
  function n() {
    we(this, n), this.observers = {};
  }
  return Se(n, [{
    key: "on",
    value: function(t, r) {
      var i = this;
      return t.split(" ").forEach(function(s) {
        i.observers[s] = i.observers[s] || [], i.observers[s].push(r);
      }), this;
    }
  }, {
    key: "off",
    value: function(t, r) {
      if (this.observers[t]) {
        if (!r) {
          delete this.observers[t];
          return;
        }
        this.observers[t] = this.observers[t].filter(function(i) {
          return i !== r;
        });
      }
    }
  }, {
    key: "emit",
    value: function(t) {
      for (var r = arguments.length, i = new Array(r > 1 ? r - 1 : 0), s = 1; s < r; s++)
        i[s - 1] = arguments[s];
      if (this.observers[t]) {
        var a = [].concat(this.observers[t]);
        a.forEach(function(u) {
          u.apply(void 0, i);
        });
      }
      if (this.observers["*"]) {
        var o = [].concat(this.observers["*"]);
        o.forEach(function(u) {
          u.apply(u, [t].concat(i));
        });
      }
    }
  }]), n;
}();
function Jt() {
  var n, e, t = new Promise(function(r, i) {
    n = r, e = i;
  });
  return t.resolve = n, t.reject = e, t;
}
function Vi(n) {
  return n == null ? "" : "" + n;
}
function wo(n, e, t) {
  n.forEach(function(r) {
    e[r] && (t[r] = e[r]);
  });
}
function yi(n, e, t) {
  function r(o) {
    return o && o.indexOf("###") > -1 ? o.replace(/###/g, ".") : o;
  }
  function i() {
    return !n || typeof n == "string";
  }
  for (var s = typeof e != "string" ? [].concat(e) : e.split("."); s.length > 1; ) {
    if (i())
      return {};
    var a = r(s.shift());
    !n[a] && t && (n[a] = new t()), Object.prototype.hasOwnProperty.call(n, a) ? n = n[a] : n = {};
  }
  return i() ? {} : {
    obj: n,
    k: r(s.shift())
  };
}
function Gi(n, e, t) {
  var r = yi(n, e, Object), i = r.obj, s = r.k;
  i[s] = t;
}
function So(n, e, t, r) {
  var i = yi(n, e, Object), s = i.obj, a = i.k;
  s[a] = s[a] || [], r && (s[a] = s[a].concat(t)), r || s[a].push(t);
}
function Wn(n, e) {
  var t = yi(n, e), r = t.obj, i = t.k;
  if (r)
    return r[i];
}
function Yi(n, e, t) {
  var r = Wn(n, t);
  return r !== void 0 ? r : Wn(e, t);
}
function qs(n, e, t) {
  for (var r in e)
    r !== "__proto__" && r !== "constructor" && (r in n ? typeof n[r] == "string" || n[r] instanceof String || typeof e[r] == "string" || e[r] instanceof String ? t && (n[r] = e[r]) : qs(n[r], e[r], t) : n[r] = e[r]);
  return n;
}
function Pt(n) {
  return n.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}
var Eo = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;"
};
function ko(n) {
  return typeof n == "string" ? n.replace(/[&<>"'\/]/g, function(e) {
    return Eo[e];
  }) : n;
}
var fr = typeof window < "u" && window.navigator && typeof window.navigator.userAgentData > "u" && window.navigator.userAgent && window.navigator.userAgent.indexOf("MSIE") > -1, To = [" ", ",", "?", "!", ";"];
function Mo(n, e, t) {
  e = e || "", t = t || "";
  var r = To.filter(function(o) {
    return e.indexOf(o) < 0 && t.indexOf(o) < 0;
  });
  if (r.length === 0)
    return !0;
  var i = new RegExp("(".concat(r.map(function(o) {
    return o === "?" ? "\\?" : o;
  }).join("|"), ")")), s = !i.test(n);
  if (!s) {
    var a = n.indexOf(t);
    a > 0 && !i.test(n.substring(0, a)) && (s = !0);
  }
  return s;
}
function Ki(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function Ln(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? Ki(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : Ki(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
function Io(n) {
  var e = Po();
  return function() {
    var r = Je(n), i;
    if (e) {
      var s = Je(this).constructor;
      i = Reflect.construct(r, arguments, s);
    } else
      i = r.apply(this, arguments);
    return Tn(this, i);
  };
}
function Po() {
  if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham)
    return !1;
  if (typeof Proxy == "function")
    return !0;
  try {
    return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    })), !0;
  } catch {
    return !1;
  }
}
function Ws(n, e) {
  var t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : ".";
  if (n) {
    if (n[e])
      return n[e];
    for (var r = e.split(t), i = n, s = 0; s < r.length; ++s) {
      if (!i || typeof i[r[s]] == "string" && s + 1 < r.length)
        return;
      if (i[r[s]] === void 0) {
        for (var a = 2, o = r.slice(s, s + a).join(t), u = i[o]; u === void 0 && r.length > s + a; )
          a++, o = r.slice(s, s + a).join(t), u = i[o];
        if (u === void 0)
          return;
        if (u === null)
          return null;
        if (e.endsWith(o)) {
          if (typeof u == "string")
            return u;
          if (o && typeof u[o] == "string")
            return u[o];
        }
        var c = r.slice(s + a).join(t);
        return c ? Ws(u, c, t) : void 0;
      }
      i = i[r[s]];
    }
    return i;
  }
}
var No = function(n) {
  lr(t, n);
  var e = Io(t);
  function t(r) {
    var i, s = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
      ns: ["translation"],
      defaultNS: "translation"
    };
    return we(this, t), i = e.call(this), fr && gt.call(dt(i)), i.data = r || {}, i.options = s, i.options.keySeparator === void 0 && (i.options.keySeparator = "."), i.options.ignoreJSONStructure === void 0 && (i.options.ignoreJSONStructure = !0), i;
  }
  return Se(t, [{
    key: "addNamespaces",
    value: function(i) {
      this.options.ns.indexOf(i) < 0 && this.options.ns.push(i);
    }
  }, {
    key: "removeNamespaces",
    value: function(i) {
      var s = this.options.ns.indexOf(i);
      s > -1 && this.options.ns.splice(s, 1);
    }
  }, {
    key: "getResource",
    value: function(i, s, a) {
      var o = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {}, u = o.keySeparator !== void 0 ? o.keySeparator : this.options.keySeparator, c = o.ignoreJSONStructure !== void 0 ? o.ignoreJSONStructure : this.options.ignoreJSONStructure, d = [i, s];
      a && typeof a != "string" && (d = d.concat(a)), a && typeof a == "string" && (d = d.concat(u ? a.split(u) : a)), i.indexOf(".") > -1 && (d = i.split("."));
      var f = Wn(this.data, d);
      return f || !c || typeof a != "string" ? f : Ws(this.data && this.data[i] && this.data[i][s], a, u);
    }
  }, {
    key: "addResource",
    value: function(i, s, a, o) {
      var u = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : {
        silent: !1
      }, c = this.options.keySeparator;
      c === void 0 && (c = ".");
      var d = [i, s];
      a && (d = d.concat(c ? a.split(c) : a)), i.indexOf(".") > -1 && (d = i.split("."), o = s, s = d[1]), this.addNamespaces(s), Gi(this.data, d, o), u.silent || this.emit("added", i, s, a, o);
    }
  }, {
    key: "addResources",
    value: function(i, s, a) {
      var o = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {
        silent: !1
      };
      for (var u in a)
        (typeof a[u] == "string" || Object.prototype.toString.apply(a[u]) === "[object Array]") && this.addResource(i, s, u, a[u], {
          silent: !0
        });
      o.silent || this.emit("added", i, s, a);
    }
  }, {
    key: "addResourceBundle",
    value: function(i, s, a, o, u) {
      var c = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : {
        silent: !1
      }, d = [i, s];
      i.indexOf(".") > -1 && (d = i.split("."), o = a, a = s, s = d[1]), this.addNamespaces(s);
      var f = Wn(this.data, d) || {};
      o ? qs(f, a, u) : f = Ln(Ln({}, f), a), Gi(this.data, d, f), c.silent || this.emit("added", i, s, a);
    }
  }, {
    key: "removeResourceBundle",
    value: function(i, s) {
      this.hasResourceBundle(i, s) && delete this.data[i][s], this.removeNamespaces(s), this.emit("removed", i, s);
    }
  }, {
    key: "hasResourceBundle",
    value: function(i, s) {
      return this.getResource(i, s) !== void 0;
    }
  }, {
    key: "getResourceBundle",
    value: function(i, s) {
      return s || (s = this.options.defaultNS), this.options.compatibilityAPI === "v1" ? Ln(Ln({}, {}), this.getResource(i, s)) : this.getResource(i, s);
    }
  }, {
    key: "getDataByLanguage",
    value: function(i) {
      return this.data[i];
    }
  }, {
    key: "hasLanguageSomeTranslations",
    value: function(i) {
      var s = this.getDataByLanguage(i), a = s && Object.keys(s) || [];
      return !!a.find(function(o) {
        return s[o] && Object.keys(s[o]).length > 0;
      });
    }
  }, {
    key: "toJSON",
    value: function() {
      return this.data;
    }
  }]), t;
}(gt), Js = {
  processors: {},
  addPostProcessor: function(e) {
    this.processors[e.name] = e;
  },
  handle: function(e, t, r, i, s) {
    var a = this;
    return e.forEach(function(o) {
      a.processors[o] && (t = a.processors[o].process(t, r, i, s));
    }), t;
  }
};
function qi(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function be(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? qi(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : qi(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
function Lo(n) {
  var e = Do();
  return function() {
    var r = Je(n), i;
    if (e) {
      var s = Je(this).constructor;
      i = Reflect.construct(r, arguments, s);
    } else
      i = r.apply(this, arguments);
    return Tn(this, i);
  };
}
function Do() {
  if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham)
    return !1;
  if (typeof Proxy == "function")
    return !0;
  try {
    return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    })), !0;
  } catch {
    return !1;
  }
}
var Wi = {}, Ji = function(n) {
  lr(t, n);
  var e = Lo(t);
  function t(r) {
    var i, s = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    return we(this, t), i = e.call(this), fr && gt.call(dt(i)), wo(["resourceStore", "languageUtils", "pluralResolver", "interpolator", "backendConnector", "i18nFormat", "utils"], r, dt(i)), i.options = s, i.options.keySeparator === void 0 && (i.options.keySeparator = "."), i.logger = Ge.create("translator"), i;
  }
  return Se(t, [{
    key: "changeLanguage",
    value: function(i) {
      i && (this.language = i);
    }
  }, {
    key: "exists",
    value: function(i) {
      var s = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        interpolation: {}
      };
      if (i == null)
        return !1;
      var a = this.resolve(i, s);
      return a && a.res !== void 0;
    }
  }, {
    key: "extractFromKey",
    value: function(i, s) {
      var a = s.nsSeparator !== void 0 ? s.nsSeparator : this.options.nsSeparator;
      a === void 0 && (a = ":");
      var o = s.keySeparator !== void 0 ? s.keySeparator : this.options.keySeparator, u = s.ns || this.options.defaultNS || [], c = a && i.indexOf(a) > -1, d = !this.options.userDefinedKeySeparator && !s.keySeparator && !this.options.userDefinedNsSeparator && !s.nsSeparator && !Mo(i, a, o);
      if (c && !d) {
        var f = i.match(this.interpolator.nestingRegexp);
        if (f && f.length > 0)
          return {
            key: i,
            namespaces: u
          };
        var h = i.split(a);
        (a !== o || a === o && this.options.ns.indexOf(h[0]) > -1) && (u = h.shift()), i = h.join(o);
      }
      return typeof u == "string" && (u = [u]), {
        key: i,
        namespaces: u
      };
    }
  }, {
    key: "translate",
    value: function(i, s, a) {
      var o = this;
      if (Ie(s) !== "object" && this.options.overloadTranslationOptionHandler && (s = this.options.overloadTranslationOptionHandler(arguments)), s || (s = {}), i == null)
        return "";
      Array.isArray(i) || (i = [String(i)]);
      var u = s.returnDetails !== void 0 ? s.returnDetails : this.options.returnDetails, c = s.keySeparator !== void 0 ? s.keySeparator : this.options.keySeparator, d = this.extractFromKey(i[i.length - 1], s), f = d.key, h = d.namespaces, p = h[h.length - 1], g = s.lng || this.language, _ = s.appendNamespaceToCIMode || this.options.appendNamespaceToCIMode;
      if (g && g.toLowerCase() === "cimode") {
        if (_) {
          var A = s.nsSeparator || this.options.nsSeparator;
          return u ? {
            res: "".concat(p).concat(A).concat(f),
            usedKey: f,
            exactUsedKey: f,
            usedLng: g,
            usedNS: p
          } : "".concat(p).concat(A).concat(f);
        }
        return u ? {
          res: f,
          usedKey: f,
          exactUsedKey: f,
          usedLng: g,
          usedNS: p
        } : f;
      }
      var O = this.resolve(i, s), w = O && O.res, P = O && O.usedKey || f, C = O && O.exactUsedKey || f, j = Object.prototype.toString.apply(w), F = ["[object Number]", "[object Function]", "[object RegExp]"], re = s.joinArrays !== void 0 ? s.joinArrays : this.options.joinArrays, J = !this.i18nFormat || this.i18nFormat.handleAsObject, Y = typeof w != "string" && typeof w != "boolean" && typeof w != "number";
      if (J && w && Y && F.indexOf(j) < 0 && !(typeof re == "string" && j === "[object Array]")) {
        if (!s.returnObjects && !this.options.returnObjects) {
          this.options.returnedObjectHandler || this.logger.warn("accessing an object - but returnObjects options is not enabled!");
          var B = this.options.returnedObjectHandler ? this.options.returnedObjectHandler(P, w, be(be({}, s), {}, {
            ns: h
          })) : "key '".concat(f, " (").concat(this.language, ")' returned an object instead of string.");
          return u ? (O.res = B, O) : B;
        }
        if (c) {
          var ie = j === "[object Array]", pe = ie ? [] : {}, U = ie ? C : P;
          for (var m in w)
            if (Object.prototype.hasOwnProperty.call(w, m)) {
              var b = "".concat(U).concat(c).concat(m);
              pe[m] = this.translate(b, be(be({}, s), {
                joinArrays: !1,
                ns: h
              })), pe[m] === b && (pe[m] = w[m]);
            }
          w = pe;
        }
      } else if (J && typeof re == "string" && j === "[object Array]")
        w = w.join(re), w && (w = this.extendTranslation(w, i, s, a));
      else {
        var M = !1, T = !1, R = s.count !== void 0 && typeof s.count != "string", N = t.hasDefaultValue(s), K = R ? this.pluralResolver.getSuffix(g, s.count, s) : "", X = s["defaultValue".concat(K)] || s.defaultValue;
        !this.isValidLookup(w) && N && (M = !0, w = X), this.isValidLookup(w) || (T = !0, w = f);
        var te = s.missingKeyNoValueFallbackToKey || this.options.missingKeyNoValueFallbackToKey, de = te && T ? void 0 : w, Oe = N && X !== w && this.options.updateMissing;
        if (T || M || Oe) {
          if (this.logger.log(Oe ? "updateKey" : "missingKey", g, p, f, Oe ? X : w), c) {
            var ke = this.resolve(f, be(be({}, s), {}, {
              keySeparator: !1
            }));
            ke && ke.res && this.logger.warn("Seems the loaded translations were in flat JSON format instead of nested. Either set keySeparator: false on init or make sure your translations are published in nested format.");
          }
          var Le = [], Ue = this.languageUtils.getFallbackCodes(this.options.fallbackLng, s.lng || this.language);
          if (this.options.saveMissingTo === "fallback" && Ue && Ue[0])
            for (var Ze = 0; Ze < Ue.length; Ze++)
              Le.push(Ue[Ze]);
          else
            this.options.saveMissingTo === "all" ? Le = this.languageUtils.toResolveHierarchy(s.lng || this.language) : Le.push(s.lng || this.language);
          var me = function(Fe, ot, $i) {
            var Bi = N && $i !== w ? $i : de;
            o.options.missingKeyHandler ? o.options.missingKeyHandler(Fe, p, ot, Bi, Oe, s) : o.backendConnector && o.backendConnector.saveMissing && o.backendConnector.saveMissing(Fe, p, ot, Bi, Oe, s), o.emit("missingKey", Fe, p, ot, w);
          };
          this.options.saveMissing && (this.options.saveMissingPlurals && R ? Le.forEach(function(Te) {
            o.pluralResolver.getSuffixes(Te, s).forEach(function(Fe) {
              me([Te], f + Fe, s["defaultValue".concat(Fe)] || X);
            });
          }) : me(Le, f, X));
        }
        w = this.extendTranslation(w, i, s, O, a), T && w === f && this.options.appendNamespaceToMissingKey && (w = "".concat(p, ":").concat(f)), (T || M) && this.options.parseMissingKeyHandler && (this.options.compatibilityAPI !== "v1" ? w = this.options.parseMissingKeyHandler(this.options.appendNamespaceToMissingKey ? "".concat(p, ":").concat(f) : f, M ? w : void 0) : w = this.options.parseMissingKeyHandler(w));
      }
      return u ? (O.res = w, O) : w;
    }
  }, {
    key: "extendTranslation",
    value: function(i, s, a, o, u) {
      var c = this;
      if (this.i18nFormat && this.i18nFormat.parse)
        i = this.i18nFormat.parse(i, be(be({}, this.options.interpolation.defaultVariables), a), o.usedLng, o.usedNS, o.usedKey, {
          resolved: o
        });
      else if (!a.skipInterpolation) {
        a.interpolation && this.interpolator.init(be(be({}, a), {
          interpolation: be(be({}, this.options.interpolation), a.interpolation)
        }));
        var d = typeof i == "string" && (a && a.interpolation && a.interpolation.skipOnVariables !== void 0 ? a.interpolation.skipOnVariables : this.options.interpolation.skipOnVariables), f;
        if (d) {
          var h = i.match(this.interpolator.nestingRegexp);
          f = h && h.length;
        }
        var p = a.replace && typeof a.replace != "string" ? a.replace : a;
        if (this.options.interpolation.defaultVariables && (p = be(be({}, this.options.interpolation.defaultVariables), p)), i = this.interpolator.interpolate(i, p, a.lng || this.language, a), d) {
          var g = i.match(this.interpolator.nestingRegexp), _ = g && g.length;
          f < _ && (a.nest = !1);
        }
        a.nest !== !1 && (i = this.interpolator.nest(i, function() {
          for (var w = arguments.length, P = new Array(w), C = 0; C < w; C++)
            P[C] = arguments[C];
          return u && u[0] === P[0] && !a.context ? (c.logger.warn("It seems you are nesting recursively key: ".concat(P[0], " in key: ").concat(s[0])), null) : c.translate.apply(c, P.concat([s]));
        }, a)), a.interpolation && this.interpolator.reset();
      }
      var A = a.postProcess || this.options.postProcess, O = typeof A == "string" ? [A] : A;
      return i != null && O && O.length && a.applyPostProcessor !== !1 && (i = Js.handle(O, i, s, this.options && this.options.postProcessPassResolved ? be({
        i18nResolved: o
      }, a) : a, this)), i;
    }
  }, {
    key: "resolve",
    value: function(i) {
      var s = this, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, o, u, c, d, f;
      return typeof i == "string" && (i = [i]), i.forEach(function(h) {
        if (!s.isValidLookup(o)) {
          var p = s.extractFromKey(h, a), g = p.key;
          u = g;
          var _ = p.namespaces;
          s.options.fallbackNS && (_ = _.concat(s.options.fallbackNS));
          var A = a.count !== void 0 && typeof a.count != "string", O = A && !a.ordinal && a.count === 0 && s.pluralResolver.shouldUseIntlApi(), w = a.context !== void 0 && (typeof a.context == "string" || typeof a.context == "number") && a.context !== "", P = a.lngs ? a.lngs : s.languageUtils.toResolveHierarchy(a.lng || s.language, a.fallbackLng);
          _.forEach(function(C) {
            s.isValidLookup(o) || (f = C, !Wi["".concat(P[0], "-").concat(C)] && s.utils && s.utils.hasLoadedNamespace && !s.utils.hasLoadedNamespace(f) && (Wi["".concat(P[0], "-").concat(C)] = !0, s.logger.warn('key "'.concat(u, '" for languages "').concat(P.join(", "), `" won't get resolved as namespace "`).concat(f, '" was not yet loaded'), "This means something IS WRONG in your setup. You access the t function before i18next.init / i18next.loadNamespace / i18next.changeLanguage was done. Wait for the callback or Promise to resolve before accessing it!!!")), P.forEach(function(j) {
              if (!s.isValidLookup(o)) {
                d = j;
                var F = [g];
                if (s.i18nFormat && s.i18nFormat.addLookupKeys)
                  s.i18nFormat.addLookupKeys(F, g, j, C, a);
                else {
                  var re;
                  A && (re = s.pluralResolver.getSuffix(j, a.count, a));
                  var J = "".concat(s.options.pluralSeparator, "zero");
                  if (A && (F.push(g + re), O && F.push(g + J)), w) {
                    var Y = "".concat(g).concat(s.options.contextSeparator).concat(a.context);
                    F.push(Y), A && (F.push(Y + re), O && F.push(Y + J));
                  }
                }
                for (var B; B = F.pop(); )
                  s.isValidLookup(o) || (c = B, o = s.getResource(j, C, B, a));
              }
            }));
          });
        }
      }), {
        res: o,
        usedKey: u,
        exactUsedKey: c,
        usedLng: d,
        usedNS: f
      };
    }
  }, {
    key: "isValidLookup",
    value: function(i) {
      return i !== void 0 && !(!this.options.returnNull && i === null) && !(!this.options.returnEmptyString && i === "");
    }
  }, {
    key: "getResource",
    value: function(i, s, a) {
      var o = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
      return this.i18nFormat && this.i18nFormat.getResource ? this.i18nFormat.getResource(i, s, a, o) : this.resourceStore.getResource(i, s, a, o);
    }
  }], [{
    key: "hasDefaultValue",
    value: function(i) {
      var s = "defaultValue";
      for (var a in i)
        if (Object.prototype.hasOwnProperty.call(i, a) && s === a.substring(0, s.length) && i[a] !== void 0)
          return !0;
      return !1;
    }
  }]), t;
}(gt);
function Nr(n) {
  return n.charAt(0).toUpperCase() + n.slice(1);
}
var zi = function() {
  function n(e) {
    we(this, n), this.options = e, this.supportedLngs = this.options.supportedLngs || !1, this.logger = Ge.create("languageUtils");
  }
  return Se(n, [{
    key: "getScriptPartFromCode",
    value: function(t) {
      if (!t || t.indexOf("-") < 0)
        return null;
      var r = t.split("-");
      return r.length === 2 || (r.pop(), r[r.length - 1].toLowerCase() === "x") ? null : this.formatLanguageCode(r.join("-"));
    }
  }, {
    key: "getLanguagePartFromCode",
    value: function(t) {
      if (!t || t.indexOf("-") < 0)
        return t;
      var r = t.split("-");
      return this.formatLanguageCode(r[0]);
    }
  }, {
    key: "formatLanguageCode",
    value: function(t) {
      if (typeof t == "string" && t.indexOf("-") > -1) {
        var r = ["hans", "hant", "latn", "cyrl", "cans", "mong", "arab"], i = t.split("-");
        return this.options.lowerCaseLng ? i = i.map(function(s) {
          return s.toLowerCase();
        }) : i.length === 2 ? (i[0] = i[0].toLowerCase(), i[1] = i[1].toUpperCase(), r.indexOf(i[1].toLowerCase()) > -1 && (i[1] = Nr(i[1].toLowerCase()))) : i.length === 3 && (i[0] = i[0].toLowerCase(), i[1].length === 2 && (i[1] = i[1].toUpperCase()), i[0] !== "sgn" && i[2].length === 2 && (i[2] = i[2].toUpperCase()), r.indexOf(i[1].toLowerCase()) > -1 && (i[1] = Nr(i[1].toLowerCase())), r.indexOf(i[2].toLowerCase()) > -1 && (i[2] = Nr(i[2].toLowerCase()))), i.join("-");
      }
      return this.options.cleanCode || this.options.lowerCaseLng ? t.toLowerCase() : t;
    }
  }, {
    key: "isSupportedCode",
    value: function(t) {
      return (this.options.load === "languageOnly" || this.options.nonExplicitSupportedLngs) && (t = this.getLanguagePartFromCode(t)), !this.supportedLngs || !this.supportedLngs.length || this.supportedLngs.indexOf(t) > -1;
    }
  }, {
    key: "getBestMatchFromCodes",
    value: function(t) {
      var r = this;
      if (!t)
        return null;
      var i;
      return t.forEach(function(s) {
        if (!i) {
          var a = r.formatLanguageCode(s);
          (!r.options.supportedLngs || r.isSupportedCode(a)) && (i = a);
        }
      }), !i && this.options.supportedLngs && t.forEach(function(s) {
        if (!i) {
          var a = r.getLanguagePartFromCode(s);
          if (r.isSupportedCode(a))
            return i = a;
          i = r.options.supportedLngs.find(function(o) {
            if (o === a)
              return o;
            if (!(o.indexOf("-") < 0 && a.indexOf("-") < 0) && o.indexOf(a) === 0)
              return o;
          });
        }
      }), i || (i = this.getFallbackCodes(this.options.fallbackLng)[0]), i;
    }
  }, {
    key: "getFallbackCodes",
    value: function(t, r) {
      if (!t)
        return [];
      if (typeof t == "function" && (t = t(r)), typeof t == "string" && (t = [t]), Object.prototype.toString.apply(t) === "[object Array]")
        return t;
      if (!r)
        return t.default || [];
      var i = t[r];
      return i || (i = t[this.getScriptPartFromCode(r)]), i || (i = t[this.formatLanguageCode(r)]), i || (i = t[this.getLanguagePartFromCode(r)]), i || (i = t.default), i || [];
    }
  }, {
    key: "toResolveHierarchy",
    value: function(t, r) {
      var i = this, s = this.getFallbackCodes(r || this.options.fallbackLng || [], t), a = [], o = function(c) {
        c && (i.isSupportedCode(c) ? a.push(c) : i.logger.warn("rejecting language code not found in supportedLngs: ".concat(c)));
      };
      return typeof t == "string" && t.indexOf("-") > -1 ? (this.options.load !== "languageOnly" && o(this.formatLanguageCode(t)), this.options.load !== "languageOnly" && this.options.load !== "currentOnly" && o(this.getScriptPartFromCode(t)), this.options.load !== "currentOnly" && o(this.getLanguagePartFromCode(t))) : typeof t == "string" && o(this.formatLanguageCode(t)), s.forEach(function(u) {
        a.indexOf(u) < 0 && o(i.formatLanguageCode(u));
      }), a;
    }
  }]), n;
}(), Co = [{
  lngs: ["ach", "ak", "am", "arn", "br", "fil", "gun", "ln", "mfe", "mg", "mi", "oc", "pt", "pt-BR", "tg", "tl", "ti", "tr", "uz", "wa"],
  nr: [1, 2],
  fc: 1
}, {
  lngs: ["af", "an", "ast", "az", "bg", "bn", "ca", "da", "de", "dev", "el", "en", "eo", "es", "et", "eu", "fi", "fo", "fur", "fy", "gl", "gu", "ha", "hi", "hu", "hy", "ia", "it", "kk", "kn", "ku", "lb", "mai", "ml", "mn", "mr", "nah", "nap", "nb", "ne", "nl", "nn", "no", "nso", "pa", "pap", "pms", "ps", "pt-PT", "rm", "sco", "se", "si", "so", "son", "sq", "sv", "sw", "ta", "te", "tk", "ur", "yo"],
  nr: [1, 2],
  fc: 2
}, {
  lngs: ["ay", "bo", "cgg", "fa", "ht", "id", "ja", "jbo", "ka", "km", "ko", "ky", "lo", "ms", "sah", "su", "th", "tt", "ug", "vi", "wo", "zh"],
  nr: [1],
  fc: 3
}, {
  lngs: ["be", "bs", "cnr", "dz", "hr", "ru", "sr", "uk"],
  nr: [1, 2, 5],
  fc: 4
}, {
  lngs: ["ar"],
  nr: [0, 1, 2, 3, 11, 100],
  fc: 5
}, {
  lngs: ["cs", "sk"],
  nr: [1, 2, 5],
  fc: 6
}, {
  lngs: ["csb", "pl"],
  nr: [1, 2, 5],
  fc: 7
}, {
  lngs: ["cy"],
  nr: [1, 2, 3, 8],
  fc: 8
}, {
  lngs: ["fr"],
  nr: [1, 2],
  fc: 9
}, {
  lngs: ["ga"],
  nr: [1, 2, 3, 7, 11],
  fc: 10
}, {
  lngs: ["gd"],
  nr: [1, 2, 3, 20],
  fc: 11
}, {
  lngs: ["is"],
  nr: [1, 2],
  fc: 12
}, {
  lngs: ["jv"],
  nr: [0, 1],
  fc: 13
}, {
  lngs: ["kw"],
  nr: [1, 2, 3, 4],
  fc: 14
}, {
  lngs: ["lt"],
  nr: [1, 2, 10],
  fc: 15
}, {
  lngs: ["lv"],
  nr: [1, 2, 0],
  fc: 16
}, {
  lngs: ["mk"],
  nr: [1, 2],
  fc: 17
}, {
  lngs: ["mnk"],
  nr: [0, 1, 2],
  fc: 18
}, {
  lngs: ["mt"],
  nr: [1, 2, 11, 20],
  fc: 19
}, {
  lngs: ["or"],
  nr: [2, 1],
  fc: 2
}, {
  lngs: ["ro"],
  nr: [1, 2, 20],
  fc: 20
}, {
  lngs: ["sl"],
  nr: [5, 1, 2, 3],
  fc: 21
}, {
  lngs: ["he", "iw"],
  nr: [1, 2, 20, 21],
  fc: 22
}], Ro = {
  1: function(e) {
    return +(e > 1);
  },
  2: function(e) {
    return +(e != 1);
  },
  3: function(e) {
    return 0;
  },
  4: function(e) {
    return e % 10 == 1 && e % 100 != 11 ? 0 : e % 10 >= 2 && e % 10 <= 4 && (e % 100 < 10 || e % 100 >= 20) ? 1 : 2;
  },
  5: function(e) {
    return e == 0 ? 0 : e == 1 ? 1 : e == 2 ? 2 : e % 100 >= 3 && e % 100 <= 10 ? 3 : e % 100 >= 11 ? 4 : 5;
  },
  6: function(e) {
    return e == 1 ? 0 : e >= 2 && e <= 4 ? 1 : 2;
  },
  7: function(e) {
    return e == 1 ? 0 : e % 10 >= 2 && e % 10 <= 4 && (e % 100 < 10 || e % 100 >= 20) ? 1 : 2;
  },
  8: function(e) {
    return e == 1 ? 0 : e == 2 ? 1 : e != 8 && e != 11 ? 2 : 3;
  },
  9: function(e) {
    return +(e >= 2);
  },
  10: function(e) {
    return e == 1 ? 0 : e == 2 ? 1 : e < 7 ? 2 : e < 11 ? 3 : 4;
  },
  11: function(e) {
    return e == 1 || e == 11 ? 0 : e == 2 || e == 12 ? 1 : e > 2 && e < 20 ? 2 : 3;
  },
  12: function(e) {
    return +(e % 10 != 1 || e % 100 == 11);
  },
  13: function(e) {
    return +(e !== 0);
  },
  14: function(e) {
    return e == 1 ? 0 : e == 2 ? 1 : e == 3 ? 2 : 3;
  },
  15: function(e) {
    return e % 10 == 1 && e % 100 != 11 ? 0 : e % 10 >= 2 && (e % 100 < 10 || e % 100 >= 20) ? 1 : 2;
  },
  16: function(e) {
    return e % 10 == 1 && e % 100 != 11 ? 0 : e !== 0 ? 1 : 2;
  },
  17: function(e) {
    return e == 1 || e % 10 == 1 && e % 100 != 11 ? 0 : 1;
  },
  18: function(e) {
    return e == 0 ? 0 : e == 1 ? 1 : 2;
  },
  19: function(e) {
    return e == 1 ? 0 : e == 0 || e % 100 > 1 && e % 100 < 11 ? 1 : e % 100 > 10 && e % 100 < 20 ? 2 : 3;
  },
  20: function(e) {
    return e == 1 ? 0 : e == 0 || e % 100 > 0 && e % 100 < 20 ? 1 : 2;
  },
  21: function(e) {
    return e % 100 == 1 ? 1 : e % 100 == 2 ? 2 : e % 100 == 3 || e % 100 == 4 ? 3 : 0;
  },
  22: function(e) {
    return e == 1 ? 0 : e == 2 ? 1 : (e < 0 || e > 10) && e % 10 == 0 ? 2 : 3;
  }
}, xo = ["v1", "v2", "v3"], Xi = {
  zero: 0,
  one: 1,
  two: 2,
  few: 3,
  many: 4,
  other: 5
};
function jo() {
  var n = {};
  return Co.forEach(function(e) {
    e.lngs.forEach(function(t) {
      n[t] = {
        numbers: e.nr,
        plurals: Ro[e.fc]
      };
    });
  }), n;
}
var $o = function() {
  function n(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    we(this, n), this.languageUtils = e, this.options = t, this.logger = Ge.create("pluralResolver"), (!this.options.compatibilityJSON || this.options.compatibilityJSON === "v4") && (typeof Intl > "u" || !Intl.PluralRules) && (this.options.compatibilityJSON = "v3", this.logger.error("Your environment seems not to be Intl API compatible, use an Intl.PluralRules polyfill. Will fallback to the compatibilityJSON v3 format handling.")), this.rules = jo();
  }
  return Se(n, [{
    key: "addRule",
    value: function(t, r) {
      this.rules[t] = r;
    }
  }, {
    key: "getRule",
    value: function(t) {
      var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      if (this.shouldUseIntlApi())
        try {
          return new Intl.PluralRules(t, {
            type: r.ordinal ? "ordinal" : "cardinal"
          });
        } catch {
          return;
        }
      return this.rules[t] || this.rules[this.languageUtils.getLanguagePartFromCode(t)];
    }
  }, {
    key: "needsPlural",
    value: function(t) {
      var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, i = this.getRule(t, r);
      return this.shouldUseIntlApi() ? i && i.resolvedOptions().pluralCategories.length > 1 : i && i.numbers.length > 1;
    }
  }, {
    key: "getPluralFormsOfKey",
    value: function(t, r) {
      var i = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
      return this.getSuffixes(t, i).map(function(s) {
        return "".concat(r).concat(s);
      });
    }
  }, {
    key: "getSuffixes",
    value: function(t) {
      var r = this, i = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, s = this.getRule(t, i);
      return s ? this.shouldUseIntlApi() ? s.resolvedOptions().pluralCategories.sort(function(a, o) {
        return Xi[a] - Xi[o];
      }).map(function(a) {
        return "".concat(r.options.prepend).concat(a);
      }) : s.numbers.map(function(a) {
        return r.getSuffix(t, a, i);
      }) : [];
    }
  }, {
    key: "getSuffix",
    value: function(t, r) {
      var i = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, s = this.getRule(t, i);
      return s ? this.shouldUseIntlApi() ? "".concat(this.options.prepend).concat(s.select(r)) : this.getSuffixRetroCompatible(s, r) : (this.logger.warn("no plural rule found for: ".concat(t)), "");
    }
  }, {
    key: "getSuffixRetroCompatible",
    value: function(t, r) {
      var i = this, s = t.noAbs ? t.plurals(r) : t.plurals(Math.abs(r)), a = t.numbers[s];
      this.options.simplifyPluralSuffix && t.numbers.length === 2 && t.numbers[0] === 1 && (a === 2 ? a = "plural" : a === 1 && (a = ""));
      var o = function() {
        return i.options.prepend && a.toString() ? i.options.prepend + a.toString() : a.toString();
      };
      return this.options.compatibilityJSON === "v1" ? a === 1 ? "" : typeof a == "number" ? "_plural_".concat(a.toString()) : o() : this.options.compatibilityJSON === "v2" || this.options.simplifyPluralSuffix && t.numbers.length === 2 && t.numbers[0] === 1 ? o() : this.options.prepend && s.toString() ? this.options.prepend + s.toString() : s.toString();
    }
  }, {
    key: "shouldUseIntlApi",
    value: function() {
      return !xo.includes(this.options.compatibilityJSON);
    }
  }]), n;
}();
function Qi(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function De(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? Qi(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : Qi(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
var Bo = function() {
  function n() {
    var e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    we(this, n), this.logger = Ge.create("interpolator"), this.options = e, this.format = e.interpolation && e.interpolation.format || function(t) {
      return t;
    }, this.init(e);
  }
  return Se(n, [{
    key: "init",
    value: function() {
      var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
      t.interpolation || (t.interpolation = {
        escapeValue: !0
      });
      var r = t.interpolation;
      this.escape = r.escape !== void 0 ? r.escape : ko, this.escapeValue = r.escapeValue !== void 0 ? r.escapeValue : !0, this.useRawValueToEscape = r.useRawValueToEscape !== void 0 ? r.useRawValueToEscape : !1, this.prefix = r.prefix ? Pt(r.prefix) : r.prefixEscaped || "{{", this.suffix = r.suffix ? Pt(r.suffix) : r.suffixEscaped || "}}", this.formatSeparator = r.formatSeparator ? r.formatSeparator : r.formatSeparator || ",", this.unescapePrefix = r.unescapeSuffix ? "" : r.unescapePrefix || "-", this.unescapeSuffix = this.unescapePrefix ? "" : r.unescapeSuffix || "", this.nestingPrefix = r.nestingPrefix ? Pt(r.nestingPrefix) : r.nestingPrefixEscaped || Pt("$t("), this.nestingSuffix = r.nestingSuffix ? Pt(r.nestingSuffix) : r.nestingSuffixEscaped || Pt(")"), this.nestingOptionsSeparator = r.nestingOptionsSeparator ? r.nestingOptionsSeparator : r.nestingOptionsSeparator || ",", this.maxReplaces = r.maxReplaces ? r.maxReplaces : 1e3, this.alwaysFormat = r.alwaysFormat !== void 0 ? r.alwaysFormat : !1, this.resetRegExp();
    }
  }, {
    key: "reset",
    value: function() {
      this.options && this.init(this.options);
    }
  }, {
    key: "resetRegExp",
    value: function() {
      var t = "".concat(this.prefix, "(.+?)").concat(this.suffix);
      this.regexp = new RegExp(t, "g");
      var r = "".concat(this.prefix).concat(this.unescapePrefix, "(.+?)").concat(this.unescapeSuffix).concat(this.suffix);
      this.regexpUnescape = new RegExp(r, "g");
      var i = "".concat(this.nestingPrefix, "(.+?)").concat(this.nestingSuffix);
      this.nestingRegexp = new RegExp(i, "g");
    }
  }, {
    key: "interpolate",
    value: function(t, r, i, s) {
      var a = this, o, u, c, d = this.options && this.options.interpolation && this.options.interpolation.defaultVariables || {};
      function f(A) {
        return A.replace(/\$/g, "$$$$");
      }
      var h = function(O) {
        if (O.indexOf(a.formatSeparator) < 0) {
          var w = Yi(r, d, O);
          return a.alwaysFormat ? a.format(w, void 0, i, De(De(De({}, s), r), {}, {
            interpolationkey: O
          })) : w;
        }
        var P = O.split(a.formatSeparator), C = P.shift().trim(), j = P.join(a.formatSeparator).trim();
        return a.format(Yi(r, d, C), j, i, De(De(De({}, s), r), {}, {
          interpolationkey: C
        }));
      };
      this.resetRegExp();
      var p = s && s.missingInterpolationHandler || this.options.missingInterpolationHandler, g = s && s.interpolation && s.interpolation.skipOnVariables !== void 0 ? s.interpolation.skipOnVariables : this.options.interpolation.skipOnVariables, _ = [{
        regex: this.regexpUnescape,
        safeValue: function(O) {
          return f(O);
        }
      }, {
        regex: this.regexp,
        safeValue: function(O) {
          return a.escapeValue ? f(a.escape(O)) : f(O);
        }
      }];
      return _.forEach(function(A) {
        for (c = 0; o = A.regex.exec(t); ) {
          var O = o[1].trim();
          if (u = h(O), u === void 0)
            if (typeof p == "function") {
              var w = p(t, o, s);
              u = typeof w == "string" ? w : "";
            } else if (s && Object.prototype.hasOwnProperty.call(s, O))
              u = "";
            else if (g) {
              u = o[0];
              continue;
            } else
              a.logger.warn("missed to pass in variable ".concat(O, " for interpolating ").concat(t)), u = "";
          else
            typeof u != "string" && !a.useRawValueToEscape && (u = Vi(u));
          var P = A.safeValue(u);
          if (t = t.replace(o[0], P), g ? (A.regex.lastIndex += u.length, A.regex.lastIndex -= o[0].length) : A.regex.lastIndex = 0, c++, c >= a.maxReplaces)
            break;
        }
      }), t;
    }
  }, {
    key: "nest",
    value: function(t, r) {
      var i = this, s = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, a, o, u;
      function c(p, g) {
        var _ = this.nestingOptionsSeparator;
        if (p.indexOf(_) < 0)
          return p;
        var A = p.split(new RegExp("".concat(_, "[ ]*{"))), O = "{".concat(A[1]);
        p = A[0], O = this.interpolate(O, u);
        var w = O.match(/'/g), P = O.match(/"/g);
        (w && w.length % 2 === 0 && !P || P.length % 2 !== 0) && (O = O.replace(/'/g, '"'));
        try {
          u = JSON.parse(O), g && (u = De(De({}, g), u));
        } catch (C) {
          return this.logger.warn("failed parsing options string in nesting for key ".concat(p), C), "".concat(p).concat(_).concat(O);
        }
        return delete u.defaultValue, p;
      }
      for (; a = this.nestingRegexp.exec(t); ) {
        var d = [];
        u = De({}, s), u = u.replace && typeof u.replace != "string" ? u.replace : u, u.applyPostProcessor = !1, delete u.defaultValue;
        var f = !1;
        if (a[0].indexOf(this.formatSeparator) !== -1 && !/{.*}/.test(a[1])) {
          var h = a[1].split(this.formatSeparator).map(function(p) {
            return p.trim();
          });
          a[1] = h.shift(), d = h, f = !0;
        }
        if (o = r(c.call(this, a[1].trim(), u), u), o && a[0] === t && typeof o != "string")
          return o;
        typeof o != "string" && (o = Vi(o)), o || (this.logger.warn("missed to resolve ".concat(a[1], " for nesting ").concat(t)), o = ""), f && (o = d.reduce(function(p, g) {
          return i.format(p, g, s.lng, De(De({}, s), {}, {
            interpolationkey: a[1].trim()
          }));
        }, o.trim())), t = t.replace(a[0], o), this.regexp.lastIndex = 0;
      }
      return t;
    }
  }]), n;
}();
function es(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function tt(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? es(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : es(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
function Ho(n) {
  var e = n.toLowerCase().trim(), t = {};
  if (n.indexOf("(") > -1) {
    var r = n.split("(");
    e = r[0].toLowerCase().trim();
    var i = r[1].substring(0, r[1].length - 1);
    if (e === "currency" && i.indexOf(":") < 0)
      t.currency || (t.currency = i.trim());
    else if (e === "relativetime" && i.indexOf(":") < 0)
      t.range || (t.range = i.trim());
    else {
      var s = i.split(";");
      s.forEach(function(a) {
        if (a) {
          var o = a.split(":"), u = _o(o), c = u[0], d = u.slice(1), f = d.join(":").trim().replace(/^'+|'+$/g, "");
          t[c.trim()] || (t[c.trim()] = f), f === "false" && (t[c.trim()] = !1), f === "true" && (t[c.trim()] = !0), isNaN(f) || (t[c.trim()] = parseInt(f, 10));
        }
      });
    }
  }
  return {
    formatName: e,
    formatOptions: t
  };
}
function Nt(n) {
  var e = {};
  return function(r, i, s) {
    var a = i + JSON.stringify(s), o = e[a];
    return o || (o = n(i, s), e[a] = o), o(r);
  };
}
var Uo = function() {
  function n() {
    var e = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    we(this, n), this.logger = Ge.create("formatter"), this.options = e, this.formats = {
      number: Nt(function(t, r) {
        var i = new Intl.NumberFormat(t, tt({}, r));
        return function(s) {
          return i.format(s);
        };
      }),
      currency: Nt(function(t, r) {
        var i = new Intl.NumberFormat(t, tt(tt({}, r), {}, {
          style: "currency"
        }));
        return function(s) {
          return i.format(s);
        };
      }),
      datetime: Nt(function(t, r) {
        var i = new Intl.DateTimeFormat(t, tt({}, r));
        return function(s) {
          return i.format(s);
        };
      }),
      relativetime: Nt(function(t, r) {
        var i = new Intl.RelativeTimeFormat(t, tt({}, r));
        return function(s) {
          return i.format(s, r.range || "day");
        };
      }),
      list: Nt(function(t, r) {
        var i = new Intl.ListFormat(t, tt({}, r));
        return function(s) {
          return i.format(s);
        };
      })
    }, this.init(e);
  }
  return Se(n, [{
    key: "init",
    value: function(t) {
      var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
        interpolation: {}
      }, i = r.interpolation;
      this.formatSeparator = i.formatSeparator ? i.formatSeparator : i.formatSeparator || ",";
    }
  }, {
    key: "add",
    value: function(t, r) {
      this.formats[t.toLowerCase().trim()] = r;
    }
  }, {
    key: "addCached",
    value: function(t, r) {
      this.formats[t.toLowerCase().trim()] = Nt(r);
    }
  }, {
    key: "format",
    value: function(t, r, i) {
      var s = this, a = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {}, o = r.split(this.formatSeparator), u = o.reduce(function(c, d) {
        var f = Ho(d), h = f.formatName, p = f.formatOptions;
        if (s.formats[h]) {
          var g = c;
          try {
            var _ = a && a.formatParams && a.formatParams[a.interpolationkey] || {}, A = _.locale || _.lng || a.locale || a.lng || i;
            g = s.formats[h](c, A, tt(tt(tt({}, p), a), _));
          } catch (O) {
            s.logger.warn(O);
          }
          return g;
        } else
          s.logger.warn("there was no format function for ".concat(h));
        return c;
      }, t);
      return u;
    }
  }]), n;
}();
function ts(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function ns(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? ts(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : ts(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
function Zo(n) {
  var e = Fo();
  return function() {
    var r = Je(n), i;
    if (e) {
      var s = Je(this).constructor;
      i = Reflect.construct(r, arguments, s);
    } else
      i = r.apply(this, arguments);
    return Tn(this, i);
  };
}
function Fo() {
  if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham)
    return !1;
  if (typeof Proxy == "function")
    return !0;
  try {
    return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    })), !0;
  } catch {
    return !1;
  }
}
function Vo(n, e) {
  n.pending[e] !== void 0 && (delete n.pending[e], n.pendingCount--);
}
var Go = function(n) {
  lr(t, n);
  var e = Zo(t);
  function t(r, i, s) {
    var a, o = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    return we(this, t), a = e.call(this), fr && gt.call(dt(a)), a.backend = r, a.store = i, a.services = s, a.languageUtils = s.languageUtils, a.options = o, a.logger = Ge.create("backendConnector"), a.waitingReads = [], a.maxParallelReads = o.maxParallelReads || 10, a.readingCalls = 0, a.maxRetries = o.maxRetries >= 0 ? o.maxRetries : 5, a.retryTimeout = o.retryTimeout >= 1 ? o.retryTimeout : 350, a.state = {}, a.queue = [], a.backend && a.backend.init && a.backend.init(s, o.backend, o), a;
  }
  return Se(t, [{
    key: "queueLoad",
    value: function(i, s, a, o) {
      var u = this, c = {}, d = {}, f = {}, h = {};
      return i.forEach(function(p) {
        var g = !0;
        s.forEach(function(_) {
          var A = "".concat(p, "|").concat(_);
          !a.reload && u.store.hasResourceBundle(p, _) ? u.state[A] = 2 : u.state[A] < 0 || (u.state[A] === 1 ? d[A] === void 0 && (d[A] = !0) : (u.state[A] = 1, g = !1, d[A] === void 0 && (d[A] = !0), c[A] === void 0 && (c[A] = !0), h[_] === void 0 && (h[_] = !0)));
        }), g || (f[p] = !0);
      }), (Object.keys(c).length || Object.keys(d).length) && this.queue.push({
        pending: d,
        pendingCount: Object.keys(d).length,
        loaded: {},
        errors: [],
        callback: o
      }), {
        toLoad: Object.keys(c),
        pending: Object.keys(d),
        toLoadLanguages: Object.keys(f),
        toLoadNamespaces: Object.keys(h)
      };
    }
  }, {
    key: "loaded",
    value: function(i, s, a) {
      var o = i.split("|"), u = o[0], c = o[1];
      s && this.emit("failedLoading", u, c, s), a && this.store.addResourceBundle(u, c, a), this.state[i] = s ? -1 : 2;
      var d = {};
      this.queue.forEach(function(f) {
        So(f.loaded, [u], c), Vo(f, i), s && f.errors.push(s), f.pendingCount === 0 && !f.done && (Object.keys(f.loaded).forEach(function(h) {
          d[h] || (d[h] = {});
          var p = f.loaded[h];
          p.length && p.forEach(function(g) {
            d[h][g] === void 0 && (d[h][g] = !0);
          });
        }), f.done = !0, f.errors.length ? f.callback(f.errors) : f.callback());
      }), this.emit("loaded", d), this.queue = this.queue.filter(function(f) {
        return !f.done;
      });
    }
  }, {
    key: "read",
    value: function(i, s, a) {
      var o = this, u = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 0, c = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : this.retryTimeout, d = arguments.length > 5 ? arguments[5] : void 0;
      if (!i.length)
        return d(null, {});
      if (this.readingCalls >= this.maxParallelReads) {
        this.waitingReads.push({
          lng: i,
          ns: s,
          fcName: a,
          tried: u,
          wait: c,
          callback: d
        });
        return;
      }
      this.readingCalls++;
      var f = function(_, A) {
        if (o.readingCalls--, o.waitingReads.length > 0) {
          var O = o.waitingReads.shift();
          o.read(O.lng, O.ns, O.fcName, O.tried, O.wait, O.callback);
        }
        if (_ && A && u < o.maxRetries) {
          setTimeout(function() {
            o.read.call(o, i, s, a, u + 1, c * 2, d);
          }, c);
          return;
        }
        d(_, A);
      }, h = this.backend[a].bind(this.backend);
      if (h.length === 2) {
        try {
          var p = h(i, s);
          p && typeof p.then == "function" ? p.then(function(g) {
            return f(null, g);
          }).catch(f) : f(null, p);
        } catch (g) {
          f(g);
        }
        return;
      }
      return h(i, s, f);
    }
  }, {
    key: "prepareLoading",
    value: function(i, s) {
      var a = this, o = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, u = arguments.length > 3 ? arguments[3] : void 0;
      if (!this.backend)
        return this.logger.warn("No backend was added via i18next.use. Will not load resources."), u && u();
      typeof i == "string" && (i = this.languageUtils.toResolveHierarchy(i)), typeof s == "string" && (s = [s]);
      var c = this.queueLoad(i, s, o, u);
      if (!c.toLoad.length)
        return c.pending.length || u(), null;
      c.toLoad.forEach(function(d) {
        a.loadOne(d);
      });
    }
  }, {
    key: "load",
    value: function(i, s, a) {
      this.prepareLoading(i, s, {}, a);
    }
  }, {
    key: "reload",
    value: function(i, s, a) {
      this.prepareLoading(i, s, {
        reload: !0
      }, a);
    }
  }, {
    key: "loadOne",
    value: function(i) {
      var s = this, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "", o = i.split("|"), u = o[0], c = o[1];
      this.read(u, c, "read", void 0, void 0, function(d, f) {
        d && s.logger.warn("".concat(a, "loading namespace ").concat(c, " for language ").concat(u, " failed"), d), !d && f && s.logger.log("".concat(a, "loaded namespace ").concat(c, " for language ").concat(u), f), s.loaded(i, d, f);
      });
    }
  }, {
    key: "saveMissing",
    value: function(i, s, a, o, u) {
      var c = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : {}, d = arguments.length > 6 && arguments[6] !== void 0 ? arguments[6] : function() {
      };
      if (this.services.utils && this.services.utils.hasLoadedNamespace && !this.services.utils.hasLoadedNamespace(s)) {
        this.logger.warn('did not save key "'.concat(a, '" as the namespace "').concat(s, '" was not yet loaded'), "This means something IS WRONG in your setup. You access the t function before i18next.init / i18next.loadNamespace / i18next.changeLanguage was done. Wait for the callback or Promise to resolve before accessing it!!!");
        return;
      }
      if (!(a == null || a === "")) {
        if (this.backend && this.backend.create) {
          var f = ns(ns({}, c), {}, {
            isUpdate: u
          }), h = this.backend.create.bind(this.backend);
          if (h.length < 6)
            try {
              var p;
              h.length === 5 ? p = h(i, s, a, o, f) : p = h(i, s, a, o), p && typeof p.then == "function" ? p.then(function(g) {
                return d(null, g);
              }).catch(d) : d(null, p);
            } catch (g) {
              d(g);
            }
          else
            h(i, s, a, o, d, f);
        }
        !i || !i[0] || this.store.addResource(i[0], s, a, o);
      }
    }
  }]), t;
}(gt);
function rs() {
  return {
    debug: !1,
    initImmediate: !0,
    ns: ["translation"],
    defaultNS: ["translation"],
    fallbackLng: ["dev"],
    fallbackNS: !1,
    supportedLngs: !1,
    nonExplicitSupportedLngs: !1,
    load: "all",
    preload: !1,
    simplifyPluralSuffix: !0,
    keySeparator: ".",
    nsSeparator: ":",
    pluralSeparator: "_",
    contextSeparator: "_",
    partialBundledLanguages: !1,
    saveMissing: !1,
    updateMissing: !1,
    saveMissingTo: "fallback",
    saveMissingPlurals: !0,
    missingKeyHandler: !1,
    missingInterpolationHandler: !1,
    postProcess: !1,
    postProcessPassResolved: !1,
    returnNull: !0,
    returnEmptyString: !0,
    returnObjects: !1,
    joinArrays: !1,
    returnedObjectHandler: !1,
    parseMissingKeyHandler: !1,
    appendNamespaceToMissingKey: !1,
    appendNamespaceToCIMode: !1,
    overloadTranslationOptionHandler: function(e) {
      var t = {};
      if (Ie(e[1]) === "object" && (t = e[1]), typeof e[1] == "string" && (t.defaultValue = e[1]), typeof e[2] == "string" && (t.tDescription = e[2]), Ie(e[2]) === "object" || Ie(e[3]) === "object") {
        var r = e[3] || e[2];
        Object.keys(r).forEach(function(i) {
          t[i] = r[i];
        });
      }
      return t;
    },
    interpolation: {
      escapeValue: !0,
      format: function(e, t, r, i) {
        return e;
      },
      prefix: "{{",
      suffix: "}}",
      formatSeparator: ",",
      unescapePrefix: "-",
      nestingPrefix: "$t(",
      nestingSuffix: ")",
      nestingOptionsSeparator: ",",
      maxReplaces: 1e3,
      skipOnVariables: !0
    }
  };
}
function is(n) {
  return typeof n.ns == "string" && (n.ns = [n.ns]), typeof n.fallbackLng == "string" && (n.fallbackLng = [n.fallbackLng]), typeof n.fallbackNS == "string" && (n.fallbackNS = [n.fallbackNS]), n.supportedLngs && n.supportedLngs.indexOf("cimode") < 0 && (n.supportedLngs = n.supportedLngs.concat(["cimode"])), n;
}
function ss(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function Ve(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? ss(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : ss(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
function Yo(n) {
  var e = Ko();
  return function() {
    var r = Je(n), i;
    if (e) {
      var s = Je(this).constructor;
      i = Reflect.construct(r, arguments, s);
    } else
      i = r.apply(this, arguments);
    return Tn(this, i);
  };
}
function Ko() {
  if (typeof Reflect > "u" || !Reflect.construct || Reflect.construct.sham)
    return !1;
  if (typeof Proxy == "function")
    return !0;
  try {
    return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    })), !0;
  } catch {
    return !1;
  }
}
function Dn() {
}
function qo(n) {
  var e = Object.getOwnPropertyNames(Object.getPrototypeOf(n));
  e.forEach(function(t) {
    typeof n[t] == "function" && (n[t] = n[t].bind(n));
  });
}
var Jn = function(n) {
  lr(t, n);
  var e = Yo(t);
  function t() {
    var r, i = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, s = arguments.length > 1 ? arguments[1] : void 0;
    if (we(this, t), r = e.call(this), fr && gt.call(dt(r)), r.options = is(i), r.services = {}, r.logger = Ge, r.modules = {
      external: []
    }, qo(dt(r)), s && !r.isInitialized && !i.isClone) {
      if (!r.options.initImmediate)
        return r.init(i, s), Tn(r, dt(r));
      setTimeout(function() {
        r.init(i, s);
      }, 0);
    }
    return r;
  }
  return Se(t, [{
    key: "init",
    value: function() {
      var i = this, s = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = arguments.length > 1 ? arguments[1] : void 0;
      typeof s == "function" && (a = s, s = {}), !s.defaultNS && s.defaultNS !== !1 && s.ns && (typeof s.ns == "string" ? s.defaultNS = s.ns : s.ns.indexOf("translation") < 0 && (s.defaultNS = s.ns[0]));
      var o = rs();
      this.options = Ve(Ve(Ve({}, o), this.options), is(s)), this.options.compatibilityAPI !== "v1" && (this.options.interpolation = Ve(Ve({}, o.interpolation), this.options.interpolation)), s.keySeparator !== void 0 && (this.options.userDefinedKeySeparator = s.keySeparator), s.nsSeparator !== void 0 && (this.options.userDefinedNsSeparator = s.nsSeparator);
      function u(O) {
        return O ? typeof O == "function" ? new O() : O : null;
      }
      if (!this.options.isClone) {
        this.modules.logger ? Ge.init(u(this.modules.logger), this.options) : Ge.init(null, this.options);
        var c;
        this.modules.formatter ? c = this.modules.formatter : typeof Intl < "u" && (c = Uo);
        var d = new zi(this.options);
        this.store = new No(this.options.resources, this.options);
        var f = this.services;
        f.logger = Ge, f.resourceStore = this.store, f.languageUtils = d, f.pluralResolver = new $o(d, {
          prepend: this.options.pluralSeparator,
          compatibilityJSON: this.options.compatibilityJSON,
          simplifyPluralSuffix: this.options.simplifyPluralSuffix
        }), c && (!this.options.interpolation.format || this.options.interpolation.format === o.interpolation.format) && (f.formatter = u(c), f.formatter.init(f, this.options), this.options.interpolation.format = f.formatter.format.bind(f.formatter)), f.interpolator = new Bo(this.options), f.utils = {
          hasLoadedNamespace: this.hasLoadedNamespace.bind(this)
        }, f.backendConnector = new Go(u(this.modules.backend), f.resourceStore, f, this.options), f.backendConnector.on("*", function(O) {
          for (var w = arguments.length, P = new Array(w > 1 ? w - 1 : 0), C = 1; C < w; C++)
            P[C - 1] = arguments[C];
          i.emit.apply(i, [O].concat(P));
        }), this.modules.languageDetector && (f.languageDetector = u(this.modules.languageDetector), f.languageDetector.init && f.languageDetector.init(f, this.options.detection, this.options)), this.modules.i18nFormat && (f.i18nFormat = u(this.modules.i18nFormat), f.i18nFormat.init && f.i18nFormat.init(this)), this.translator = new Ji(this.services, this.options), this.translator.on("*", function(O) {
          for (var w = arguments.length, P = new Array(w > 1 ? w - 1 : 0), C = 1; C < w; C++)
            P[C - 1] = arguments[C];
          i.emit.apply(i, [O].concat(P));
        }), this.modules.external.forEach(function(O) {
          O.init && O.init(i);
        });
      }
      if (this.format = this.options.interpolation.format, a || (a = Dn), this.options.fallbackLng && !this.services.languageDetector && !this.options.lng) {
        var h = this.services.languageUtils.getFallbackCodes(this.options.fallbackLng);
        h.length > 0 && h[0] !== "dev" && (this.options.lng = h[0]);
      }
      !this.services.languageDetector && !this.options.lng && this.logger.warn("init: no languageDetector is used and no lng is defined");
      var p = ["getResource", "hasResourceBundle", "getResourceBundle", "getDataByLanguage"];
      p.forEach(function(O) {
        i[O] = function() {
          var w;
          return (w = i.store)[O].apply(w, arguments);
        };
      });
      var g = ["addResource", "addResources", "addResourceBundle", "removeResourceBundle"];
      g.forEach(function(O) {
        i[O] = function() {
          var w;
          return (w = i.store)[O].apply(w, arguments), i;
        };
      });
      var _ = Jt(), A = function() {
        var w = function(C, j) {
          i.isInitialized && !i.initializedStoreOnce && i.logger.warn("init: i18next is already initialized. You should call init just once!"), i.isInitialized = !0, i.options.isClone || i.logger.log("initialized", i.options), i.emit("initialized", i.options), _.resolve(j), a(C, j);
        };
        if (i.languages && i.options.compatibilityAPI !== "v1" && !i.isInitialized)
          return w(null, i.t.bind(i));
        i.changeLanguage(i.options.lng, w);
      };
      return this.options.resources || !this.options.initImmediate ? A() : setTimeout(A, 0), _;
    }
  }, {
    key: "loadResources",
    value: function(i) {
      var s = this, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : Dn, o = a, u = typeof i == "string" ? i : this.language;
      if (typeof i == "function" && (o = i), !this.options.resources || this.options.partialBundledLanguages) {
        if (u && u.toLowerCase() === "cimode")
          return o();
        var c = [], d = function(p) {
          if (p) {
            var g = s.services.languageUtils.toResolveHierarchy(p);
            g.forEach(function(_) {
              c.indexOf(_) < 0 && c.push(_);
            });
          }
        };
        if (u)
          d(u);
        else {
          var f = this.services.languageUtils.getFallbackCodes(this.options.fallbackLng);
          f.forEach(function(h) {
            return d(h);
          });
        }
        this.options.preload && this.options.preload.forEach(function(h) {
          return d(h);
        }), this.services.backendConnector.load(c, this.options.ns, function(h) {
          !h && !s.resolvedLanguage && s.language && s.setResolvedLanguage(s.language), o(h);
        });
      } else
        o(null);
    }
  }, {
    key: "reloadResources",
    value: function(i, s, a) {
      var o = Jt();
      return i || (i = this.languages), s || (s = this.options.ns), a || (a = Dn), this.services.backendConnector.reload(i, s, function(u) {
        o.resolve(), a(u);
      }), o;
    }
  }, {
    key: "use",
    value: function(i) {
      if (!i)
        throw new Error("You are passing an undefined module! Please check the object you are passing to i18next.use()");
      if (!i.type)
        throw new Error("You are passing a wrong module! Please check the object you are passing to i18next.use()");
      return i.type === "backend" && (this.modules.backend = i), (i.type === "logger" || i.log && i.warn && i.error) && (this.modules.logger = i), i.type === "languageDetector" && (this.modules.languageDetector = i), i.type === "i18nFormat" && (this.modules.i18nFormat = i), i.type === "postProcessor" && Js.addPostProcessor(i), i.type === "formatter" && (this.modules.formatter = i), i.type === "3rdParty" && this.modules.external.push(i), this;
    }
  }, {
    key: "setResolvedLanguage",
    value: function(i) {
      if (!(!i || !this.languages) && !(["cimode", "dev"].indexOf(i) > -1))
        for (var s = 0; s < this.languages.length; s++) {
          var a = this.languages[s];
          if (!(["cimode", "dev"].indexOf(a) > -1) && this.store.hasLanguageSomeTranslations(a)) {
            this.resolvedLanguage = a;
            break;
          }
        }
    }
  }, {
    key: "changeLanguage",
    value: function(i, s) {
      var a = this;
      this.isLanguageChangingTo = i;
      var o = Jt();
      this.emit("languageChanging", i);
      var u = function(h) {
        a.language = h, a.languages = a.services.languageUtils.toResolveHierarchy(h), a.resolvedLanguage = void 0, a.setResolvedLanguage(h);
      }, c = function(h, p) {
        p ? (u(p), a.translator.changeLanguage(p), a.isLanguageChangingTo = void 0, a.emit("languageChanged", p), a.logger.log("languageChanged", p)) : a.isLanguageChangingTo = void 0, o.resolve(function() {
          return a.t.apply(a, arguments);
        }), s && s(h, function() {
          return a.t.apply(a, arguments);
        });
      }, d = function(h) {
        !i && !h && a.services.languageDetector && (h = []);
        var p = typeof h == "string" ? h : a.services.languageUtils.getBestMatchFromCodes(h);
        p && (a.language || u(p), a.translator.language || a.translator.changeLanguage(p), a.services.languageDetector && a.services.languageDetector.cacheUserLanguage && a.services.languageDetector.cacheUserLanguage(p)), a.loadResources(p, function(g) {
          c(g, p);
        });
      };
      return !i && this.services.languageDetector && !this.services.languageDetector.async ? d(this.services.languageDetector.detect()) : !i && this.services.languageDetector && this.services.languageDetector.async ? this.services.languageDetector.detect.length === 0 ? this.services.languageDetector.detect().then(d) : this.services.languageDetector.detect(d) : d(i), o;
    }
  }, {
    key: "getFixedT",
    value: function(i, s, a) {
      var o = this, u = function c(d, f) {
        var h;
        if (Ie(f) !== "object") {
          for (var p = arguments.length, g = new Array(p > 2 ? p - 2 : 0), _ = 2; _ < p; _++)
            g[_ - 2] = arguments[_];
          h = o.options.overloadTranslationOptionHandler([d, f].concat(g));
        } else
          h = Ve({}, f);
        h.lng = h.lng || c.lng, h.lngs = h.lngs || c.lngs, h.ns = h.ns || c.ns, h.keyPrefix = h.keyPrefix || a || c.keyPrefix;
        var A = o.options.keySeparator || ".", O;
        return h.keyPrefix && Array.isArray(d) ? O = d.map(function(w) {
          return "".concat(h.keyPrefix).concat(A).concat(w);
        }) : O = h.keyPrefix ? "".concat(h.keyPrefix).concat(A).concat(d) : d, o.t(O, h);
      };
      return typeof i == "string" ? u.lng = i : u.lngs = i, u.ns = s, u.keyPrefix = a, u;
    }
  }, {
    key: "t",
    value: function() {
      var i;
      return this.translator && (i = this.translator).translate.apply(i, arguments);
    }
  }, {
    key: "exists",
    value: function() {
      var i;
      return this.translator && (i = this.translator).exists.apply(i, arguments);
    }
  }, {
    key: "setDefaultNamespace",
    value: function(i) {
      this.options.defaultNS = i;
    }
  }, {
    key: "hasLoadedNamespace",
    value: function(i) {
      var s = this, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      if (!this.isInitialized)
        return this.logger.warn("hasLoadedNamespace: i18next was not initialized", this.languages), !1;
      if (!this.languages || !this.languages.length)
        return this.logger.warn("hasLoadedNamespace: i18n.languages were undefined or empty", this.languages), !1;
      var o = this.resolvedLanguage || this.languages[0], u = this.options ? this.options.fallbackLng : !1, c = this.languages[this.languages.length - 1];
      if (o.toLowerCase() === "cimode")
        return !0;
      var d = function(p, g) {
        var _ = s.services.backendConnector.state["".concat(p, "|").concat(g)];
        return _ === -1 || _ === 2;
      };
      if (a.precheck) {
        var f = a.precheck(this, d);
        if (f !== void 0)
          return f;
      }
      return !!(this.hasResourceBundle(o, i) || !this.services.backendConnector.backend || this.options.resources && !this.options.partialBundledLanguages || d(o, i) && (!u || d(c, i)));
    }
  }, {
    key: "loadNamespaces",
    value: function(i, s) {
      var a = this, o = Jt();
      return this.options.ns ? (typeof i == "string" && (i = [i]), i.forEach(function(u) {
        a.options.ns.indexOf(u) < 0 && a.options.ns.push(u);
      }), this.loadResources(function(u) {
        o.resolve(), s && s(u);
      }), o) : (s && s(), Promise.resolve());
    }
  }, {
    key: "loadLanguages",
    value: function(i, s) {
      var a = Jt();
      typeof i == "string" && (i = [i]);
      var o = this.options.preload || [], u = i.filter(function(c) {
        return o.indexOf(c) < 0;
      });
      return u.length ? (this.options.preload = o.concat(u), this.loadResources(function(c) {
        a.resolve(), s && s(c);
      }), a) : (s && s(), Promise.resolve());
    }
  }, {
    key: "dir",
    value: function(i) {
      if (i || (i = this.resolvedLanguage || (this.languages && this.languages.length > 0 ? this.languages[0] : this.language)), !i)
        return "rtl";
      var s = ["ar", "shu", "sqr", "ssh", "xaa", "yhd", "yud", "aao", "abh", "abv", "acm", "acq", "acw", "acx", "acy", "adf", "ads", "aeb", "aec", "afb", "ajp", "apc", "apd", "arb", "arq", "ars", "ary", "arz", "auz", "avl", "ayh", "ayl", "ayn", "ayp", "bbz", "pga", "he", "iw", "ps", "pbt", "pbu", "pst", "prp", "prd", "ug", "ur", "ydd", "yds", "yih", "ji", "yi", "hbo", "men", "xmn", "fa", "jpr", "peo", "pes", "prs", "dv", "sam", "ckb"], a = this.services && this.services.languageUtils || new zi(rs());
      return s.indexOf(a.getLanguagePartFromCode(i)) > -1 || i.toLowerCase().indexOf("-arab") > 1 ? "rtl" : "ltr";
    }
  }, {
    key: "cloneInstance",
    value: function() {
      var i = this, s = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : Dn, o = Ve(Ve(Ve({}, this.options), s), {
        isClone: !0
      }), u = new t(o);
      (s.debug !== void 0 || s.prefix !== void 0) && (u.logger = u.logger.clone(s));
      var c = ["store", "services", "language"];
      return c.forEach(function(d) {
        u[d] = i[d];
      }), u.services = Ve({}, this.services), u.services.utils = {
        hasLoadedNamespace: u.hasLoadedNamespace.bind(u)
      }, u.translator = new Ji(u.services, u.options), u.translator.on("*", function(d) {
        for (var f = arguments.length, h = new Array(f > 1 ? f - 1 : 0), p = 1; p < f; p++)
          h[p - 1] = arguments[p];
        u.emit.apply(u, [d].concat(h));
      }), u.init(o, a), u.translator.options = u.options, u.translator.backendConnector.services.utils = {
        hasLoadedNamespace: u.hasLoadedNamespace.bind(u)
      }, u;
    }
  }, {
    key: "toJSON",
    value: function() {
      return {
        options: this.options,
        store: this.store,
        language: this.language,
        languages: this.languages,
        resolvedLanguage: this.resolvedLanguage
      };
    }
  }]), t;
}(gt);
Be(Jn, "createInstance", function() {
  var n = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, e = arguments.length > 1 ? arguments[1] : void 0;
  return new Jn(n, e);
});
var ye = Jn.createInstance();
ye.createInstance = Jn.createInstance;
ye.createInstance;
ye.dir;
ye.init;
ye.loadResources;
ye.reloadResources;
ye.use;
ye.changeLanguage;
ye.getFixedT;
ye.t;
ye.exists;
ye.setDefaultNamespace;
ye.hasLoadedNamespace;
ye.loadNamespaces;
ye.loadLanguages;
var zs = [], Wo = zs.forEach, Jo = zs.slice;
function zo(n) {
  return Wo.call(Jo.call(arguments, 1), function(e) {
    if (e)
      for (var t in e)
        n[t] === void 0 && (n[t] = e[t]);
  }), n;
}
function Xo(n) {
  return n ? typeof n == "function" ? new n() : n : null;
}
function Qo() {
  return {
    handleEmptyResourcesAsFailed: !0
  };
}
var Xs = /* @__PURE__ */ function() {
  function n(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    we(this, n), this.backends = [], this.type = "backend", this.init(e, t);
  }
  return Se(n, [{
    key: "init",
    value: function(t) {
      var r = this, i = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, s = arguments.length > 2 ? arguments[2] : void 0;
      this.services = t, this.options = zo(i, this.options || {}, Qo()), this.options.backends && this.options.backends.forEach(function(a, o) {
        r.backends[o] = r.backends[o] || Xo(a), r.backends[o].init(t, r.options.backendOptions && r.options.backendOptions[o] || {}, s);
      });
    }
  }, {
    key: "read",
    value: function(t, r, i) {
      var s = this, a = this.backends.length, o = function c(d) {
        if (d >= a)
          return i(new Error("non of the backend loaded data;", !0));
        var f = d === a - 1, h = s.options.handleEmptyResourcesAsFailed && !f ? 0 : -1, p = s.backends[d];
        if (p.read) {
          var g = function(w, P) {
            !w && P && Object.keys(P).length > h ? (i(null, P, d), u(d - 1, P)) : c(d + 1);
          }, _ = p.read.bind(p);
          if (_.length === 2) {
            try {
              var A = _(t, r);
              A && typeof A.then == "function" ? A.then(function(O) {
                return g(null, O);
              }).catch(g) : g(null, A);
            } catch (O) {
              g(O);
            }
            return;
          }
          _(t, r, g);
        } else
          c(d + 1);
      }, u = function c(d, f) {
        if (!(d < 0)) {
          var h = s.backends[d];
          h.save && h.save(t, r, f), c(d - 1, f);
        }
      };
      o(0);
    }
  }, {
    key: "create",
    value: function(t, r, i, s) {
      var a = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : function() {
      }, o = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : {};
      this.backends.forEach(function(u) {
        if (u.create) {
          var c = u.create.bind(u);
          if (c.length < 6) {
            try {
              var d;
              c.length === 5 ? d = c(t, r, i, s, o) : d = c(t, r, i, s), d && typeof d.then == "function" ? d.then(function(f) {
                return a(null, f);
              }).catch(a) : a(null, d);
            } catch (f) {
              a(f);
            }
            return;
          }
          c(t, r, i, s, a, o);
        }
      });
    }
  }]), n;
}();
Xs.type = "backend";
function Jr(n) {
  return Jr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(e) {
    return typeof e;
  } : function(e) {
    return e && typeof Symbol == "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, Jr(n);
}
var Qs = [], eu = Qs.forEach, tu = Qs.slice;
function zr(n) {
  return eu.call(tu.call(arguments, 1), function(e) {
    if (e)
      for (var t in e)
        n[t] === void 0 && (n[t] = e[t]);
  }), n;
}
function ea() {
  return typeof XMLHttpRequest == "function" || (typeof XMLHttpRequest > "u" ? "undefined" : Jr(XMLHttpRequest)) === "object";
}
function nu(n) {
  return !!n && typeof n.then == "function";
}
function ru(n) {
  return nu(n) ? n : Promise.resolve(n);
}
var ne = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function iu(n) {
  return n && n.__esModule && Object.prototype.hasOwnProperty.call(n, "default") ? n.default : n;
}
function su(n) {
  if (n.__esModule)
    return n;
  var e = n.default;
  if (typeof e == "function") {
    var t = function r() {
      if (this instanceof r) {
        var i = [null];
        i.push.apply(i, arguments);
        var s = Function.bind.apply(e, i);
        return new s();
      }
      return e.apply(this, arguments);
    };
    t.prototype = e.prototype;
  } else
    t = {};
  return Object.defineProperty(t, "__esModule", { value: !0 }), Object.keys(n).forEach(function(r) {
    var i = Object.getOwnPropertyDescriptor(n, r);
    Object.defineProperty(t, r, i.get ? i : {
      enumerable: !0,
      get: function() {
        return n[r];
      }
    });
  }), t;
}
function au(n) {
  throw new Error('Could not dynamically require "' + n + '". Please configure the dynamicRequireTargets or/and ignoreDynamicRequires option of @rollup/plugin-commonjs appropriately for this require call to work.');
}
var Xr = { exports: {} }, Cn = { exports: {} }, as;
function ou() {
  return as || (as = 1, function(n, e) {
    var t = typeof self < "u" ? self : ne, r = function() {
      function s() {
        this.fetch = !1, this.DOMException = t.DOMException;
      }
      return s.prototype = t, new s();
    }();
    (function(s) {
      (function(a) {
        var o = {
          searchParams: "URLSearchParams" in s,
          iterable: "Symbol" in s && "iterator" in Symbol,
          blob: "FileReader" in s && "Blob" in s && function() {
            try {
              return new Blob(), !0;
            } catch {
              return !1;
            }
          }(),
          formData: "FormData" in s,
          arrayBuffer: "ArrayBuffer" in s
        };
        function u(m) {
          return m && DataView.prototype.isPrototypeOf(m);
        }
        if (o.arrayBuffer)
          var c = [
            "[object Int8Array]",
            "[object Uint8Array]",
            "[object Uint8ClampedArray]",
            "[object Int16Array]",
            "[object Uint16Array]",
            "[object Int32Array]",
            "[object Uint32Array]",
            "[object Float32Array]",
            "[object Float64Array]"
          ], d = ArrayBuffer.isView || function(m) {
            return m && c.indexOf(Object.prototype.toString.call(m)) > -1;
          };
        function f(m) {
          if (typeof m != "string" && (m = String(m)), /[^a-z0-9\-#$%&'*+.^_`|~]/i.test(m))
            throw new TypeError("Invalid character in header field name");
          return m.toLowerCase();
        }
        function h(m) {
          return typeof m != "string" && (m = String(m)), m;
        }
        function p(m) {
          var b = {
            next: function() {
              var M = m.shift();
              return { done: M === void 0, value: M };
            }
          };
          return o.iterable && (b[Symbol.iterator] = function() {
            return b;
          }), b;
        }
        function g(m) {
          this.map = {}, m instanceof g ? m.forEach(function(b, M) {
            this.append(M, b);
          }, this) : Array.isArray(m) ? m.forEach(function(b) {
            this.append(b[0], b[1]);
          }, this) : m && Object.getOwnPropertyNames(m).forEach(function(b) {
            this.append(b, m[b]);
          }, this);
        }
        g.prototype.append = function(m, b) {
          m = f(m), b = h(b);
          var M = this.map[m];
          this.map[m] = M ? M + ", " + b : b;
        }, g.prototype.delete = function(m) {
          delete this.map[f(m)];
        }, g.prototype.get = function(m) {
          return m = f(m), this.has(m) ? this.map[m] : null;
        }, g.prototype.has = function(m) {
          return this.map.hasOwnProperty(f(m));
        }, g.prototype.set = function(m, b) {
          this.map[f(m)] = h(b);
        }, g.prototype.forEach = function(m, b) {
          for (var M in this.map)
            this.map.hasOwnProperty(M) && m.call(b, this.map[M], M, this);
        }, g.prototype.keys = function() {
          var m = [];
          return this.forEach(function(b, M) {
            m.push(M);
          }), p(m);
        }, g.prototype.values = function() {
          var m = [];
          return this.forEach(function(b) {
            m.push(b);
          }), p(m);
        }, g.prototype.entries = function() {
          var m = [];
          return this.forEach(function(b, M) {
            m.push([M, b]);
          }), p(m);
        }, o.iterable && (g.prototype[Symbol.iterator] = g.prototype.entries);
        function _(m) {
          if (m.bodyUsed)
            return Promise.reject(new TypeError("Already read"));
          m.bodyUsed = !0;
        }
        function A(m) {
          return new Promise(function(b, M) {
            m.onload = function() {
              b(m.result);
            }, m.onerror = function() {
              M(m.error);
            };
          });
        }
        function O(m) {
          var b = new FileReader(), M = A(b);
          return b.readAsArrayBuffer(m), M;
        }
        function w(m) {
          var b = new FileReader(), M = A(b);
          return b.readAsText(m), M;
        }
        function P(m) {
          for (var b = new Uint8Array(m), M = new Array(b.length), T = 0; T < b.length; T++)
            M[T] = String.fromCharCode(b[T]);
          return M.join("");
        }
        function C(m) {
          if (m.slice)
            return m.slice(0);
          var b = new Uint8Array(m.byteLength);
          return b.set(new Uint8Array(m)), b.buffer;
        }
        function j() {
          return this.bodyUsed = !1, this._initBody = function(m) {
            this._bodyInit = m, m ? typeof m == "string" ? this._bodyText = m : o.blob && Blob.prototype.isPrototypeOf(m) ? this._bodyBlob = m : o.formData && FormData.prototype.isPrototypeOf(m) ? this._bodyFormData = m : o.searchParams && URLSearchParams.prototype.isPrototypeOf(m) ? this._bodyText = m.toString() : o.arrayBuffer && o.blob && u(m) ? (this._bodyArrayBuffer = C(m.buffer), this._bodyInit = new Blob([this._bodyArrayBuffer])) : o.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(m) || d(m)) ? this._bodyArrayBuffer = C(m) : this._bodyText = m = Object.prototype.toString.call(m) : this._bodyText = "", this.headers.get("content-type") || (typeof m == "string" ? this.headers.set("content-type", "text/plain;charset=UTF-8") : this._bodyBlob && this._bodyBlob.type ? this.headers.set("content-type", this._bodyBlob.type) : o.searchParams && URLSearchParams.prototype.isPrototypeOf(m) && this.headers.set("content-type", "application/x-www-form-urlencoded;charset=UTF-8"));
          }, o.blob && (this.blob = function() {
            var m = _(this);
            if (m)
              return m;
            if (this._bodyBlob)
              return Promise.resolve(this._bodyBlob);
            if (this._bodyArrayBuffer)
              return Promise.resolve(new Blob([this._bodyArrayBuffer]));
            if (this._bodyFormData)
              throw new Error("could not read FormData body as blob");
            return Promise.resolve(new Blob([this._bodyText]));
          }, this.arrayBuffer = function() {
            return this._bodyArrayBuffer ? _(this) || Promise.resolve(this._bodyArrayBuffer) : this.blob().then(O);
          }), this.text = function() {
            var m = _(this);
            if (m)
              return m;
            if (this._bodyBlob)
              return w(this._bodyBlob);
            if (this._bodyArrayBuffer)
              return Promise.resolve(P(this._bodyArrayBuffer));
            if (this._bodyFormData)
              throw new Error("could not read FormData body as text");
            return Promise.resolve(this._bodyText);
          }, o.formData && (this.formData = function() {
            return this.text().then(Y);
          }), this.json = function() {
            return this.text().then(JSON.parse);
          }, this;
        }
        var F = ["DELETE", "GET", "HEAD", "OPTIONS", "POST", "PUT"];
        function re(m) {
          var b = m.toUpperCase();
          return F.indexOf(b) > -1 ? b : m;
        }
        function J(m, b) {
          b = b || {};
          var M = b.body;
          if (m instanceof J) {
            if (m.bodyUsed)
              throw new TypeError("Already read");
            this.url = m.url, this.credentials = m.credentials, b.headers || (this.headers = new g(m.headers)), this.method = m.method, this.mode = m.mode, this.signal = m.signal, !M && m._bodyInit != null && (M = m._bodyInit, m.bodyUsed = !0);
          } else
            this.url = String(m);
          if (this.credentials = b.credentials || this.credentials || "same-origin", (b.headers || !this.headers) && (this.headers = new g(b.headers)), this.method = re(b.method || this.method || "GET"), this.mode = b.mode || this.mode || null, this.signal = b.signal || this.signal, this.referrer = null, (this.method === "GET" || this.method === "HEAD") && M)
            throw new TypeError("Body not allowed for GET or HEAD requests");
          this._initBody(M);
        }
        J.prototype.clone = function() {
          return new J(this, { body: this._bodyInit });
        };
        function Y(m) {
          var b = new FormData();
          return m.trim().split("&").forEach(function(M) {
            if (M) {
              var T = M.split("="), R = T.shift().replace(/\+/g, " "), N = T.join("=").replace(/\+/g, " ");
              b.append(decodeURIComponent(R), decodeURIComponent(N));
            }
          }), b;
        }
        function B(m) {
          var b = new g(), M = m.replace(/\r?\n[\t ]+/g, " ");
          return M.split(/\r?\n/).forEach(function(T) {
            var R = T.split(":"), N = R.shift().trim();
            if (N) {
              var K = R.join(":").trim();
              b.append(N, K);
            }
          }), b;
        }
        j.call(J.prototype);
        function ie(m, b) {
          b || (b = {}), this.type = "default", this.status = b.status === void 0 ? 200 : b.status, this.ok = this.status >= 200 && this.status < 300, this.statusText = "statusText" in b ? b.statusText : "OK", this.headers = new g(b.headers), this.url = b.url || "", this._initBody(m);
        }
        j.call(ie.prototype), ie.prototype.clone = function() {
          return new ie(this._bodyInit, {
            status: this.status,
            statusText: this.statusText,
            headers: new g(this.headers),
            url: this.url
          });
        }, ie.error = function() {
          var m = new ie(null, { status: 0, statusText: "" });
          return m.type = "error", m;
        };
        var pe = [301, 302, 303, 307, 308];
        ie.redirect = function(m, b) {
          if (pe.indexOf(b) === -1)
            throw new RangeError("Invalid status code");
          return new ie(null, { status: b, headers: { location: m } });
        }, a.DOMException = s.DOMException;
        try {
          new a.DOMException();
        } catch {
          a.DOMException = function(b, M) {
            this.message = b, this.name = M;
            var T = Error(b);
            this.stack = T.stack;
          }, a.DOMException.prototype = Object.create(Error.prototype), a.DOMException.prototype.constructor = a.DOMException;
        }
        function U(m, b) {
          return new Promise(function(M, T) {
            var R = new J(m, b);
            if (R.signal && R.signal.aborted)
              return T(new a.DOMException("Aborted", "AbortError"));
            var N = new XMLHttpRequest();
            function K() {
              N.abort();
            }
            N.onload = function() {
              var X = {
                status: N.status,
                statusText: N.statusText,
                headers: B(N.getAllResponseHeaders() || "")
              };
              X.url = "responseURL" in N ? N.responseURL : X.headers.get("X-Request-URL");
              var te = "response" in N ? N.response : N.responseText;
              M(new ie(te, X));
            }, N.onerror = function() {
              T(new TypeError("Network request failed"));
            }, N.ontimeout = function() {
              T(new TypeError("Network request failed"));
            }, N.onabort = function() {
              T(new a.DOMException("Aborted", "AbortError"));
            }, N.open(R.method, R.url, !0), R.credentials === "include" ? N.withCredentials = !0 : R.credentials === "omit" && (N.withCredentials = !1), "responseType" in N && o.blob && (N.responseType = "blob"), R.headers.forEach(function(X, te) {
              N.setRequestHeader(te, X);
            }), R.signal && (R.signal.addEventListener("abort", K), N.onreadystatechange = function() {
              N.readyState === 4 && R.signal.removeEventListener("abort", K);
            }), N.send(typeof R._bodyInit > "u" ? null : R._bodyInit);
          });
        }
        return U.polyfill = !0, s.fetch || (s.fetch = U, s.Headers = g, s.Request = J, s.Response = ie), a.Headers = g, a.Request = J, a.Response = ie, a.fetch = U, Object.defineProperty(a, "__esModule", { value: !0 }), a;
      })({});
    })(r), r.fetch.ponyfill = !0, delete r.fetch.polyfill;
    var i = r;
    e = i.fetch, e.default = i.fetch, e.fetch = i.fetch, e.Headers = i.Headers, e.Request = i.Request, e.Response = i.Response, n.exports = e;
  }(Cn, Cn.exports)), Cn.exports;
}
(function(n, e) {
  var t;
  if (typeof fetch == "function" && (typeof ne < "u" && ne.fetch ? t = ne.fetch : typeof window < "u" && window.fetch ? t = window.fetch : t = fetch), typeof au < "u" && (typeof window > "u" || typeof window.document > "u")) {
    var r = t || ou();
    r.default && (r = r.default), e.default = r, n.exports = e.default;
  }
})(Xr, Xr.exports);
var ta = Xr.exports;
const na = /* @__PURE__ */ iu(ta), os = /* @__PURE__ */ go({
  __proto__: null,
  default: na
}, [ta]);
function zn(n) {
  return zn = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(e) {
    return typeof e;
  } : function(e) {
    return e && typeof Symbol == "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, zn(n);
}
var st;
typeof fetch == "function" && (typeof global < "u" && global.fetch ? st = global.fetch : typeof window < "u" && window.fetch ? st = window.fetch : st = fetch);
var ln;
ea() && (typeof global < "u" && global.XMLHttpRequest ? ln = global.XMLHttpRequest : typeof window < "u" && window.XMLHttpRequest && (ln = window.XMLHttpRequest));
var Xn;
typeof ActiveXObject == "function" && (typeof global < "u" && global.ActiveXObject ? Xn = global.ActiveXObject : typeof window < "u" && window.ActiveXObject && (Xn = window.ActiveXObject));
!st && os && !ln && !Xn && (st = na || os);
typeof st != "function" && (st = void 0);
var Qr = function(e, t) {
  if (t && zn(t) === "object") {
    var r = "";
    for (var i in t)
      r += "&" + encodeURIComponent(i) + "=" + encodeURIComponent(t[i]);
    if (!r)
      return e;
    e = e + (e.indexOf("?") !== -1 ? "&" : "?") + r.slice(1);
  }
  return e;
}, us = function(e, t, r) {
  st(e, t).then(function(i) {
    if (!i.ok)
      return r(i.statusText || "Error", {
        status: i.status
      });
    i.text().then(function(s) {
      r(null, {
        status: i.status,
        data: s
      });
    }).catch(r);
  }).catch(r);
}, cs = !1, uu = function(e, t, r, i) {
  e.queryStringParams && (t = Qr(t, e.queryStringParams));
  var s = zr({}, typeof e.customHeaders == "function" ? e.customHeaders() : e.customHeaders);
  r && (s["Content-Type"] = "application/json");
  var a = typeof e.requestOptions == "function" ? e.requestOptions(r) : e.requestOptions, o = zr({
    method: r ? "POST" : "GET",
    body: r ? e.stringify(r) : void 0,
    headers: s
  }, cs ? {} : a);
  try {
    us(t, o, i);
  } catch (u) {
    if (!a || Object.keys(a).length === 0 || !u.message || u.message.indexOf("not implemented") < 0)
      return i(u);
    try {
      Object.keys(a).forEach(function(c) {
        delete o[c];
      }), us(t, o, i), cs = !0;
    } catch (c) {
      i(c);
    }
  }
}, cu = function(e, t, r, i) {
  r && zn(r) === "object" && (r = Qr("", r).slice(1)), e.queryStringParams && (t = Qr(t, e.queryStringParams));
  try {
    var s;
    ln ? s = new ln() : s = new Xn("MSXML2.XMLHTTP.3.0"), s.open(r ? "POST" : "GET", t, 1), e.crossDomain || s.setRequestHeader("X-Requested-With", "XMLHttpRequest"), s.withCredentials = !!e.withCredentials, r && s.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"), s.overrideMimeType && s.overrideMimeType("application/json");
    var a = e.customHeaders;
    if (a = typeof a == "function" ? a() : a, a)
      for (var o in a)
        s.setRequestHeader(o, a[o]);
    s.onreadystatechange = function() {
      s.readyState > 3 && i(s.status >= 400 ? s.statusText : null, {
        status: s.status,
        data: s.responseText
      });
    }, s.send(r);
  } catch (u) {
    console && console.log(u);
  }
}, lu = function(e, t, r, i) {
  if (typeof r == "function" && (i = r, r = void 0), i = i || function() {
  }, st && t.indexOf("file:") !== 0)
    return uu(e, t, r, i);
  if (ea() || typeof ActiveXObject == "function")
    return cu(e, t, r, i);
  i(new Error("No fetch and no xhr implementation found!"));
};
function fn(n) {
  return fn = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(e) {
    return typeof e;
  } : function(e) {
    return e && typeof Symbol == "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
  }, fn(n);
}
function fu(n, e) {
  if (!(n instanceof e))
    throw new TypeError("Cannot call a class as a function");
}
function ls(n, e) {
  for (var t = 0; t < e.length; t++) {
    var r = e[t];
    r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(n, ra(r.key), r);
  }
}
function du(n, e, t) {
  return e && ls(n.prototype, e), t && ls(n, t), Object.defineProperty(n, "prototype", { writable: !1 }), n;
}
function hu(n, e, t) {
  return e = ra(e), e in n ? Object.defineProperty(n, e, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : n[e] = t, n;
}
function ra(n) {
  var e = pu(n, "string");
  return fn(e) === "symbol" ? e : String(e);
}
function pu(n, e) {
  if (fn(n) !== "object" || n === null)
    return n;
  var t = n[Symbol.toPrimitive];
  if (t !== void 0) {
    var r = t.call(n, e || "default");
    if (fn(r) !== "object")
      return r;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (e === "string" ? String : Number)(n);
}
var mu = function() {
  return {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
    addPath: "/locales/add/{{lng}}/{{ns}}",
    allowMultiLoading: !1,
    parse: function(t) {
      return JSON.parse(t);
    },
    stringify: JSON.stringify,
    parsePayload: function(t, r, i) {
      return hu({}, r, i || "");
    },
    parseLoadPayload: function(t, r) {
    },
    request: lu,
    reloadInterval: typeof window < "u" ? !1 : 60 * 60 * 1e3,
    customHeaders: {},
    queryStringParams: {},
    crossDomain: !1,
    withCredentials: !1,
    overrideMimeType: !1,
    requestOptions: {
      mode: "cors",
      credentials: "same-origin",
      cache: "default"
    }
  };
}, ia = function() {
  function n(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    fu(this, n), this.services = e, this.options = t, this.allOptions = r, this.type = "backend", this.init(e, t, r);
  }
  return du(n, [{
    key: "init",
    value: function(t) {
      var r = this, i = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, s = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
      this.services = t, this.options = zr(i, this.options || {}, mu()), this.allOptions = s, this.services && this.options.reloadInterval && setInterval(function() {
        return r.reload();
      }, this.options.reloadInterval);
    }
  }, {
    key: "readMulti",
    value: function(t, r, i) {
      this._readAny(t, t, r, r, i);
    }
  }, {
    key: "read",
    value: function(t, r, i) {
      this._readAny([t], t, [r], r, i);
    }
  }, {
    key: "_readAny",
    value: function(t, r, i, s, a) {
      var o = this, u = this.options.loadPath;
      typeof this.options.loadPath == "function" && (u = this.options.loadPath(t, i)), u = ru(u), u.then(function(c) {
        if (!c)
          return a(null, {});
        var d = o.services.interpolator.interpolate(c, {
          lng: t.join("+"),
          ns: i.join("+")
        });
        o.loadUrl(d, a, r, s);
      });
    }
  }, {
    key: "loadUrl",
    value: function(t, r, i, s) {
      var a = this, o = typeof i == "string" ? [i] : i, u = typeof s == "string" ? [s] : s, c = this.options.parseLoadPayload(o, u);
      this.options.request(this.options, t, c, function(d, f) {
        if (f && (f.status >= 500 && f.status < 600 || !f.status))
          return r("failed loading " + t + "; status code: " + f.status, !0);
        if (f && f.status >= 400 && f.status < 500)
          return r("failed loading " + t + "; status code: " + f.status, !1);
        if (!f && d && d.message && d.message.indexOf("Failed to fetch") > -1)
          return r("failed loading " + t + ": " + d.message, !0);
        if (d)
          return r(d, !1);
        var h, p;
        try {
          typeof f.data == "string" ? h = a.options.parse(f.data, i, s) : h = f.data;
        } catch {
          p = "failed parsing " + t + " to json";
        }
        if (p)
          return r(p, !1);
        r(null, h);
      });
    }
  }, {
    key: "create",
    value: function(t, r, i, s, a) {
      var o = this;
      if (this.options.addPath) {
        typeof t == "string" && (t = [t]);
        var u = this.options.parsePayload(r, i, s), c = 0, d = [], f = [];
        t.forEach(function(h) {
          var p = o.options.addPath;
          typeof o.options.addPath == "function" && (p = o.options.addPath(h, r));
          var g = o.services.interpolator.interpolate(p, {
            lng: h,
            ns: r
          });
          o.options.request(o.options, g, u, function(_, A) {
            c += 1, d.push(_), f.push(A), c === t.length && typeof a == "function" && a(d, f);
          });
        });
      }
    }
  }, {
    key: "reload",
    value: function() {
      var t = this, r = this.services, i = r.backendConnector, s = r.languageUtils, a = r.logger, o = i.language;
      if (!(o && o.toLowerCase() === "cimode")) {
        var u = [], c = function(f) {
          var h = s.toResolveHierarchy(f);
          h.forEach(function(p) {
            u.indexOf(p) < 0 && u.push(p);
          });
        };
        c(o), this.allOptions.preload && this.allOptions.preload.forEach(function(d) {
          return c(d);
        }), u.forEach(function(d) {
          t.allOptions.ns.forEach(function(f) {
            i.read(d, f, "read", null, null, function(h, p) {
              h && a.warn("loading namespace ".concat(f, " for language ").concat(d, " failed"), h), !h && p && a.log("loaded namespace ".concat(f, " for language ").concat(d), p), i.loaded("".concat(d, "|").concat(f), h, p);
            });
          });
        });
      }
    }
  }]), n;
}();
ia.type = "backend";
function fs(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function Lr(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? fs(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : fs(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
var gu = /* @__PURE__ */ function() {
  function n(e) {
    we(this, n), this.store = e.store;
  }
  return Se(n, [{
    key: "setItem",
    value: function(t, r) {
      if (this.store)
        try {
          this.store.setItem(t, r);
        } catch {
        }
    }
  }, {
    key: "getItem",
    value: function(t, r) {
      if (this.store)
        try {
          return this.store.getItem(t, r);
        } catch {
        }
    }
  }]), n;
}();
function yu() {
  return {
    prefix: "i18next_res_",
    expirationTime: 7 * 24 * 60 * 60 * 1e3,
    defaultVersion: void 0,
    versions: {},
    store: typeof window < "u" ? window.localStorage : null
  };
}
var sa = /* @__PURE__ */ function() {
  function n(e) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    we(this, n), this.init(e, t), this.type = "backend";
  }
  return Se(n, [{
    key: "init",
    value: function(t) {
      var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      this.services = t, this.options = Lr(Lr(Lr({}, yu()), this.options), r), this.storage = new gu(this.options);
    }
  }, {
    key: "read",
    value: function(t, r, i) {
      var s = (/* @__PURE__ */ new Date()).getTime();
      if (!this.storage.store)
        return i(null, null);
      var a = this.storage.getItem("".concat(this.options.prefix).concat(t, "-").concat(r));
      if (a) {
        a = JSON.parse(a);
        var o = this.getVersion(t);
        if (
          // expiration field is mandatory, and should not be expired
          a.i18nStamp && a.i18nStamp + this.options.expirationTime > s && // there should be no language version set, or if it is, it should match the one in translation
          o === a.i18nVersion
        )
          return delete a.i18nVersion, delete a.i18nStamp, i(null, a);
      }
      return i(null, null);
    }
  }, {
    key: "save",
    value: function(t, r, i) {
      if (this.storage.store) {
        i.i18nStamp = (/* @__PURE__ */ new Date()).getTime();
        var s = this.getVersion(t);
        s && (i.i18nVersion = s), this.storage.setItem("".concat(this.options.prefix).concat(t, "-").concat(r), JSON.stringify(i));
      }
    }
  }, {
    key: "getVersion",
    value: function(t) {
      return this.options.versions[t] || this.options.defaultVersion;
    }
  }]), n;
}();
sa.type = "backend";
function vu() {
  if (console && console.warn) {
    for (var n, e = arguments.length, t = new Array(e), r = 0; r < e; r++)
      t[r] = arguments[r];
    typeof t[0] == "string" && (t[0] = "react-i18next:: ".concat(t[0])), (n = console).warn.apply(n, t);
  }
}
var ds = {};
function ei() {
  for (var n = arguments.length, e = new Array(n), t = 0; t < n; t++)
    e[t] = arguments[t];
  typeof e[0] == "string" && ds[e[0]] || (typeof e[0] == "string" && (ds[e[0]] = /* @__PURE__ */ new Date()), vu.apply(void 0, e));
}
var aa = function(e, t) {
  return function() {
    if (e.isInitialized)
      t();
    else {
      var r = function i() {
        setTimeout(function() {
          e.off("initialized", i);
        }, 0), t();
      };
      e.on("initialized", r);
    }
  };
};
function hs(n, e, t) {
  n.loadNamespaces(e, aa(n, t));
}
function ps(n, e, t, r) {
  typeof t == "string" && (t = [t]), t.forEach(function(i) {
    n.options.ns.indexOf(i) < 0 && n.options.ns.push(i);
  }), n.loadLanguages(e, aa(n, r));
}
function bu(n, e) {
  var t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, r = e.languages[0], i = e.options ? e.options.fallbackLng : !1, s = e.languages[e.languages.length - 1];
  if (r.toLowerCase() === "cimode")
    return !0;
  var a = function(u, c) {
    var d = e.services.backendConnector.state["".concat(u, "|").concat(c)];
    return d === -1 || d === 2;
  };
  return t.bindI18n && t.bindI18n.indexOf("languageChanging") > -1 && e.services.backendConnector.backend && e.isLanguageChangingTo && !a(e.isLanguageChangingTo, n) ? !1 : !!(e.hasResourceBundle(r, n) || !e.services.backendConnector.backend || e.options.resources && !e.options.partialBundledLanguages || a(r, n) && (!i || a(s, n)));
}
function _u(n, e) {
  var t = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  if (!e.languages || !e.languages.length)
    return ei("i18n.languages were undefined or empty", e.languages), !0;
  var r = e.options.ignoreJSONStructure !== void 0;
  return r ? e.hasLoadedNamespace(n, {
    lng: t.lng,
    precheck: function(s, a) {
      if (t.bindI18n && t.bindI18n.indexOf("languageChanging") > -1 && s.services.backendConnector.backend && s.isLanguageChangingTo && !a(s.isLanguageChangingTo, n))
        return !1;
    }
  }) : bu(n, e, t);
}
var Ou = /&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34|nbsp|#160|copy|#169|reg|#174|hellip|#8230|#x2F|#47);/g, Au = {
  "&amp;": "&",
  "&#38;": "&",
  "&lt;": "<",
  "&#60;": "<",
  "&gt;": ">",
  "&#62;": ">",
  "&apos;": "'",
  "&#39;": "'",
  "&quot;": '"',
  "&#34;": '"',
  "&nbsp;": " ",
  "&#160;": " ",
  "&copy;": "©",
  "&#169;": "©",
  "&reg;": "®",
  "&#174;": "®",
  "&hellip;": "…",
  "&#8230;": "…",
  "&#x2F;": "/",
  "&#47;": "/"
}, wu = function(e) {
  return Au[e];
}, Su = function(e) {
  return e.replace(Ou, wu);
};
function ms(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function gs(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? ms(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : ms(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
var ti = {
  bindI18n: "languageChanged",
  bindI18nStore: "",
  transEmptyNodeValue: "",
  transSupportBasicHtmlNodes: !0,
  transWrapTextNodes: "",
  transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
  useSuspense: !0,
  unescape: Su
};
function Eu() {
  var n = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
  ti = gs(gs({}, ti), n);
}
function ku() {
  return ti;
}
var oa;
function Tu(n) {
  oa = n;
}
function Mu() {
  return oa;
}
var Iu = {
  type: "3rdParty",
  init: function(e) {
    Eu(e.options.react), Tu(e);
  }
}, Pu = gi(), Nu = function() {
  function n() {
    we(this, n), this.usedNamespaces = {};
  }
  return Se(n, [{
    key: "addUsedNamespaces",
    value: function(t) {
      var r = this;
      t.forEach(function(i) {
        r.usedNamespaces[i] || (r.usedNamespaces[i] = !0);
      });
    }
  }, {
    key: "getUsedNamespaces",
    value: function() {
      return Object.keys(this.usedNamespaces);
    }
  }]), n;
}();
function Lu(n, e) {
  var t = n == null ? null : typeof Symbol < "u" && n[Symbol.iterator] || n["@@iterator"];
  if (t != null) {
    var r, i, s, a, o = [], u = !0, c = !1;
    try {
      if (s = (t = t.call(n)).next, e === 0) {
        if (Object(t) !== t)
          return;
        u = !1;
      } else
        for (; !(u = (r = s.call(t)).done) && (o.push(r.value), o.length !== e); u = !0)
          ;
    } catch (d) {
      c = !0, i = d;
    } finally {
      try {
        if (!u && t.return != null && (a = t.return(), Object(a) !== a))
          return;
      } finally {
        if (c)
          throw i;
      }
    }
    return o;
  }
}
function Du(n, e) {
  return Gs(n) || Lu(n, e) || Ys(n, e) || Ks();
}
function ys(n, e) {
  var t = Object.keys(n);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(n);
    e && (r = r.filter(function(i) {
      return Object.getOwnPropertyDescriptor(n, i).enumerable;
    })), t.push.apply(t, r);
  }
  return t;
}
function Dr(n) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? ys(Object(t), !0).forEach(function(r) {
      Be(n, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(n, Object.getOwnPropertyDescriptors(t)) : ys(Object(t)).forEach(function(r) {
      Object.defineProperty(n, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return n;
}
var Cu = function(e, t) {
  var r = qr();
  return mt(function() {
    r.current = t ? r.current : e;
  }, [e, t]), r.current;
};
function Ru(n) {
  var e = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, t = e.i18n, r = Fs(Pu) || {}, i = r.i18n, s = r.defaultNS, a = t || i || Mu();
  if (a && !a.reportNamespaces && (a.reportNamespaces = new Nu()), !a) {
    ei("You will need to pass in an i18next instance by using initReactI18next");
    var o = function(Y, B) {
      return typeof B == "string" ? B : B && Ie(B) === "object" && typeof B.defaultValue == "string" ? B.defaultValue : Array.isArray(Y) ? Y[Y.length - 1] : Y;
    }, u = [o, {}, !1];
    return u.t = o, u.i18n = {}, u.ready = !1, u;
  }
  a.options.react && a.options.react.wait !== void 0 && ei("It seems you are still using the old wait option, you may migrate to the new useSuspense behaviour.");
  var c = Dr(Dr(Dr({}, ku()), a.options.react), e), d = c.useSuspense, f = c.keyPrefix, h = n || s || a.options && a.options.defaultNS;
  h = typeof h == "string" ? [h] : h || ["translation"], a.reportNamespaces.addUsedNamespaces && a.reportNamespaces.addUsedNamespaces(h);
  var p = (a.isInitialized || a.initializedStoreOnce) && h.every(function(J) {
    return _u(J, a, c);
  });
  function g() {
    return a.getFixedT(e.lng || null, c.nsMode === "fallback" ? h : h[0], f);
  }
  var _ = ft(g), A = Du(_, 2), O = A[0], w = A[1], P = h.join();
  e.lng && (P = "".concat(e.lng).concat(P));
  var C = Cu(P), j = qr(!0);
  mt(function() {
    var J = c.bindI18n, Y = c.bindI18nStore;
    j.current = !0, !p && !d && (e.lng ? ps(a, e.lng, h, function() {
      j.current && w(g);
    }) : hs(a, h, function() {
      j.current && w(g);
    })), p && C && C !== P && j.current && w(g);
    function B() {
      j.current && w(g);
    }
    return J && a && a.on(J, B), Y && a && a.store.on(Y, B), function() {
      j.current = !1, J && a && J.split(" ").forEach(function(ie) {
        return a.off(ie, B);
      }), Y && a && Y.split(" ").forEach(function(ie) {
        return a.store.off(ie, B);
      });
    };
  }, [a, P]);
  var F = qr(!0);
  mt(function() {
    j.current && !F.current && w(g), F.current = !1;
  }, [a, f]);
  var re = [O, a, p];
  if (re.t = O, re.i18n = a, re.ready = p, p || !p && !d)
    return re;
  throw new Promise(function(J) {
    e.lng ? ps(a, e.lng, h, function() {
      return J();
    }) : hs(a, h, function() {
      return J();
    });
  });
}
var ee;
(function(n) {
  n.PROD = "prod", n.STAGING = "staging";
})(ee || (ee = {}));
var ae;
(function(n) {
  n.LOCAL = "local", n.LOCAL2 = "local";
})(ae || (ae = {}));
var l;
(function(n) {
  n.PROD = "prod", n.ALPHA = "alpha", n.BETA = "beta", n.CHI = "chi", n.DELTA = "delta", n.EPSILON = "epsilon", n.FAKER = "faker", n.GAMMA = "gamma", n.IOTA = "iota", n.KAPPA = "kappa", n.LAMBDA = "lambda", n.MU = "mu", n.NU = "nu", n.OMEGA = "omega", n.OMICRON = "omicron", n.PSI = "psi", n.RHO = "rho", n.SIGMA = "sigma", n.THETA = "theta", n.ZETA = "zeta", n.UMI = "umi", n.HADES = "hades", n.VOLDEMORT = "voldemort";
})(l || (l = {}));
const ua = (n) => n && Object.values(l).includes(n) && n !== l.PROD;
Object.values(l).filter(ua);
var I;
(function(n) {
  n.PROD = "stargate-revenant.herokuapp.com", n.ALPHA = "alpha-stargate.herokuapp.com", n.BETA = "beta-stargate.herokuapp.com", n.CHI = "chi-stargate.herokuapp.com", n.DELTA = "delta-stargate.herokuapp.com", n.EPSILON = "epsilon-stargate.herokuapp.com", n.FAKER = "faker-stargate-6e0c42aadbf8.herokuapp.com", n.GAMMA = "gamma-stargate.herokuapp.com", n.IOTA = "iota-stargate.herokuapp.com", n.KAPPA = "kappa-stargate.herokuapp.com", n.LAMBDA = "lambda-stargate.herokuapp.com", n.LOCAL = "localhost:5000", n.LOCAL2 = "127.0.0.1:5000", n.MU = "mu-stargate.herokuapp.com", n.NU = "nu-stargate.herokuapp.com", n.OMEGA = "omega-stargate.herokuapp.com", n.OMICRON = "omicron-stargate-85115e3a8353.herokuapp.com", n.PSI = "psi-stargate.herokuapp.com", n.RHO = "rho-stargate.herokuapp.com", n.SIGMA = "sigma-stargate.herokuapp.com", n.STAGING = "staging-stargate.herokuapp.com", n.THETA = "theta-stargate.herokuapp.com", n.ZETA = "zeta-stargate.herokuapp.com", n.UMI = "umi-stargate-71d32dd84d9a.herokuapp.com", n.HADES = "hades-stargate-da7b30994215.herokuapp.com", n.VOLDEMORT = "voldemort-stargate-ba25b3b9d0dd.herokuapp.com";
})(I || (I = {}));
var y;
(function(n) {
  n.PROD = "app.waalaxy.com", n.ALPHA = "alpha-mystique.herokuapp.com", n.BETA = "beta-mystique.herokuapp.com", n.CHI = "chi-mystique.herokuapp.com", n.DELTA = "delta-mystique.herokuapp.com", n.EPSILON = "epsilon-mystique.herokuapp.com", n.FAKER = "faker-mystique-ed95cc6e4b08.herokuapp.com", n.GAMMA = "gamma-mystique.herokuapp.com", n.IOTA = "iota-mystique.herokuapp.com", n.KAPPA = "kappa-mystique.herokuapp.com", n.LAMBDA = "lambda-mystique.herokuapp.com", n.LOCAL = "localhost:3000", n.LOCAL2 = "127.0.0.1:3000", n.MU = "mu-mystique.herokuapp.com", n.NU = "nu-mystique.herokuapp.com", n.OMEGA = "omega-mystique.herokuapp.com", n.OMICRON = "omicron-mystique-3ff9a2f52f26.herokuapp.com", n.PSI = "psi-mystique.herokuapp.com", n.RHO = "rho-mystique.herokuapp.com", n.SIGMA = "sigma-mystique.herokuapp.com", n.THETA = "theta-mystique.herokuapp.com", n.ZETA = "zeta-mystique.herokuapp.com", n.UMI = "umi-mystique-2945a8a0147d.herokuapp.com", n.HADES = "hades-mystique-e6ed216cd0fd.herokuapp.com", n.VOLDEMORT = "voldemort-mystique-fc436e385c5f.herokuapp.com";
})(y || (y = {}));
var v;
(function(n) {
  n.PROD = "lifeline-front.herokuapp.com", n.ALPHA = "alpha-lifeline-front.herokuapp.com", n.BETA = "beta-lifeline-front.herokuapp.com", n.CHI = "chi-lifeline-front.herokuapp.com", n.DELTA = "delta-lifeline-front.herokuapp.com", n.EPSILON = "epsilon-lifeline-front.herokuapp.com", n.FAKER = "faker-lifeline-front-4500876fe776.herokuapp.com", n.GAMMA = "gamma-lifeline-front.herokuapp.com", n.IOTA = "iota-lifeline-front.herokuapp.com", n.KAPPA = "kappa-lifeline-front.herokuapp.com", n.LAMBDA = "lambda-lifeline-front.herokuapp.com", n.LOCAL = "localhost:3000", n.LOCAL2 = "127.0.0.1:3000", n.MU = "mu-lifeline-front.herokuapp.com", n.NU = "nu-lifeline-front.herokuapp.com", n.OMEGA = "omega-lifeline-front.herokuapp.com", n.OMICRON = "omicron-lifeline-front-7cd3a48b275b.herokuapp.com", n.PSI = "psi-lifeline-front.herokuapp.com", n.RHO = "rho-lifeline-front.herokuapp.com", n.SIGMA = "sigma-lifeline-front.herokuapp.com", n.THETA = "theta-lifeline-front.herokuapp.com", n.ZETA = "zeta-lifeline-front.herokuapp.com", n.UMI = "umi-lifeline-front-9d73acb379d5.herokuapp.com", n.HADES = "hades-lifeline-front-e6c7b22fc46d.herokuapp.com", n.VOLDEMORT = "voldemort-lifeline-front-b554107e87b6.herokuapp.com";
})(v || (v = {}));
var E;
(function(n) {
  n.PROD = "https://revenant-lifeline.herokuapp.com", n.ALPHA = "https://alpha-lifeline-back.herokuapp.com", n.BETA = "https://beta-lifeline-back.herokuapp.com", n.CHI = "https://chi-lifeline-back.herokuapp.com", n.DELTA = "https://delta-lifeline-back.herokuapp.com", n.EPSILON = "https://epsilon-lifeline-back.herokuapp.com", n.FAKER = "https://faker-lifeline-back-6593ccced273.herokuapp.com", n.GAMMA = "https://gamma-lifeline-back.herokuapp.com", n.IOTA = "https://iota-lifeline-back.herokuapp.com", n.KAPPA = "https://kappa-lifeline-back.herokuapp.com", n.LAMBDA = "https://lambda-lifeline-back.herokuapp.com", n.LOCAL = "https://revenant-lifeline.herokuapp.com", n.MU = "https://mu-lifeline-back.herokuapp.com", n.NU = "https://nu-lifeline-back.herokuapp.com", n.OMEGA = "https://omega-lifeline-back.herokuapp.com", n.OMICRON = "https://omicron-lifeline-back-7e32da17a28e.herokuapp.com/", n.PSI = "https://psi-lifeline-back.herokuapp.com", n.RHO = "https://rho-lifeline-back.herokuapp.com/", n.SIGMA = "https://sigma-lifeline-back.herokuapp.com", n.THETA = "https://theta-lifeline-back.herokuapp.com", n.ZETA = "https://zeta-lifeline-back.herokuapp.com/", n.UMI = "https://umi-lifeline-back-50fa06983a09.herokuapp.com/", n.HADES = "https://hades-lifeline-back-a694f9fa6b8e.herokuapp.com/", n.VOLDEMORT = "https://voldemort-lifeline-back-0e8e96167346.herokuapp.com/";
})(E || (E = {}));
var $;
(function(n) {
  n.PROD = "https://felindra-revenant.herokuapp.com", n.ALPHA = "https://alpha-felindra-back.herokuapp.com", n.BETA = "https://beta-felindra-back.herokuapp.com", n.CHI = "https://chi-felindra-back.herokuapp.com", n.DELTA = "https://delta-felindra-back.herokuapp.com", n.EPSILON = "https://epsilon-felindra-back.herokuapp.com", n.FAKER = "https://faker-felindra-back-35dcd5f67f92.herokuapp.com", n.GAMMA = "https://gamma-felindra-back.herokuapp.com", n.IOTA = "https://iota-felindra-back.herokuapp.com", n.KAPPA = "https://kappa-felindra-back.herokuapp.com", n.LAMBDA = "https://lambda-felindra-back.herokuapp.com", n.LOCAL = "https://felindra-revenant.herokuapp.com", n.MU = "https://mu-felindra-back.herokuapp.com", n.NU = "https://nu-felindra-back.herokuapp.com", n.OMEGA = "https://omega-felindra-back.herokuapp.com", n.OMICRON = "https://omicron-felindra-back-d07e405eae1f.herokuapp.com/", n.PSI = "https://psi-felindra-back.herokuapp.com", n.RHO = "https://rho-felindra-back.herokuapp.com/", n.SIGMA = "https://sigma-felindra-back.herokuapp.com", n.THETA = "https://theta-felindra-back.herokuapp.com", n.ZETA = "https://zeta-felindra-back.herokuapp.com/", n.UMI = "https://umi-felindra-back-3c014e715ec2.herokuapp.com/", n.HADES = "https://hades-felindra-back-0a3c889c7c9a.herokuapp.com/", n.VOLDEMORT = "https://voldemort-felindra-back-a930b066227c.herokuapp.com/";
})($ || ($ = {}));
l.PROD + "", l.ALPHA + "", l.BETA + "", l.CHI + "", l.DELTA + "", l.EPSILON + "", l.FAKER + "", l.GAMMA + "", l.IOTA + "", l.KAPPA + "", l.LAMBDA + "", l.MU + "", l.NU + "", l.OMEGA + "", l.OMICRON + "", l.PSI + "", l.RHO + "", l.SIGMA + "", l.THETA + "", l.ZETA + "", l.UMI + "", l.HADES + "", l.VOLDEMORT + "";
l.PROD + "", l.ALPHA + "", l.BETA + "", l.CHI + "", l.DELTA + "", l.EPSILON + "", l.FAKER + "", l.GAMMA + "", l.IOTA + "", l.KAPPA + "", l.LAMBDA + "", l.MU + "", l.NU + "", l.OMEGA + "", l.OMICRON + "", l.PSI + "", l.RHO + "", l.SIGMA + "", l.THETA + "", l.ZETA + "", l.UMI + "", l.HADES + "", l.VOLDEMORT + "";
l.PROD + "", l.ALPHA + "", l.BETA + "", l.CHI + "", l.DELTA + "", l.EPSILON + "", l.FAKER + "", l.GAMMA + "", l.IOTA + "", l.KAPPA + "", l.LAMBDA + "", l.MU + "", l.NU + "", l.OMEGA + "", l.OMICRON + "", l.PSI + "", l.RHO + "", l.SIGMA + "", l.THETA + "", l.ZETA + "", l.UMI + "", l.HADES + "", l.VOLDEMORT + "";
l.PROD + "", l.ALPHA + "", l.BETA + "", l.CHI + "", l.DELTA + "", l.EPSILON + "", l.FAKER + "", l.GAMMA + "", l.IOTA + "", l.KAPPA + "", l.LAMBDA + "", l.MU + "", l.NU + "", l.OMEGA + "", l.OMICRON + "", l.PSI + "", l.RHO + "", l.SIGMA + "", l.THETA + "", l.ZETA + "", l.UMI + "", l.HADES + "", l.VOLDEMORT + "";
const vs = {
  [l.PROD]: "hlkiignknimkfafapmgpbnbnmkajgljh",
  [ae.LOCAL]: "aapocclcgogkmnckokdopfmhonfmgoek",
  [ae.LOCAL2]: "aapocclcgogkmnckokdopfmhonfmgoek",
  [l.KAPPA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.ALPHA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.BETA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.GAMMA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.DELTA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.EPSILON]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.LAMBDA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.OMEGA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.ZETA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.SIGMA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.THETA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.IOTA]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.PSI]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.NU]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.MU]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.CHI]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.RHO]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.FAKER]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.OMICRON]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.UMI]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.HADES]: "feiclbomfllbkdmeibcljmfbdakimmdg",
  [l.VOLDEMORT]: "feiclbomfllbkdmeibcljmfbdakimmdg"
};
ee.PROD + "", y.PROD + "", vs[l.PROD], ee.STAGING + "";
ee.PROD + "", $.PROD + "", l.PROD, ee.STAGING + "", $.PROD + "", l.PROD, $.ALPHA + "", l.ALPHA, $.BETA + "", l.BETA, $.CHI + "", l.CHI, $.DELTA + "", l.DELTA, $.EPSILON + "", l.EPSILON, $.FAKER + "", l.FAKER, $.GAMMA + "", l.GAMMA, $.IOTA + "", l.IOTA, $.KAPPA + "", l.KAPPA, $.LAMBDA + "", l.LAMBDA, $.LOCAL + "", ae.LOCAL, $.MU + "", l.MU, $.NU + "", l.NU, $.OMEGA + "", l.OMEGA, $.OMICRON + "", l.OMICRON, $.PSI + "", l.PSI, $.RHO + "", l.RHO, $.SIGMA + "", l.SIGMA, $.THETA + "", l.THETA, $.ZETA + "", l.ZETA, $.UMI + "", l.UMI, $.HADES + "", l.HADES, $.VOLDEMORT + "", l.VOLDEMORT;
ee.PROD + "", l.PROD + "", $.PROD, ee.STAGING + "", l.PROD + "", $.PROD, l.ALPHA + "", $.ALPHA, l.BETA + "", $.BETA, l.CHI + "", $.CHI, l.DELTA + "", $.DELTA, l.EPSILON + "", $.EPSILON, l.FAKER + "", $.FAKER, l.GAMMA + "", $.GAMMA, l.IOTA + "", $.IOTA, l.KAPPA + "", $.KAPPA, l.LAMBDA + "", $.LAMBDA, ae.LOCAL + "", $.LOCAL, l.MU + "", $.MU, l.NU + "", $.NU, l.OMEGA + "", $.OMEGA, l.OMICRON + "", $.OMICRON, l.PSI + "", $.PSI, l.RHO + "", $.RHO, l.SIGMA + "", $.SIGMA, l.THETA + "", $.THETA, l.ZETA + "", $.ZETA, l.UMI + "", $.UMI, l.HADES + "", $.HADES, l.VOLDEMORT + "", $.VOLDEMORT;
ee.PROD + "", E.PROD + "", l.PROD, ee.STAGING + "", E.PROD + "", l.PROD, E.ALPHA + "", l.ALPHA, E.BETA + "", l.BETA, E.CHI + "", l.CHI, E.DELTA + "", l.DELTA, E.EPSILON + "", l.EPSILON, E.FAKER + "", l.FAKER, E.GAMMA + "", l.GAMMA, E.IOTA + "", l.IOTA, E.KAPPA + "", l.KAPPA, E.LAMBDA + "", l.LAMBDA, E.LOCAL + "", ae.LOCAL, E.MU + "", l.MU, E.NU + "", l.NU, E.OMEGA + "", l.OMEGA, E.OMICRON + "", l.OMICRON, E.PSI + "", l.PSI, E.RHO + "", l.RHO, E.SIGMA + "", l.SIGMA, E.THETA + "", l.THETA, E.ZETA + "", l.ZETA, E.UMI + "", l.UMI, E.HADES + "", l.HADES, E.VOLDEMORT + "", l.VOLDEMORT;
ee.PROD + "", l.PROD + "", E.PROD, ee.STAGING + "", l.PROD + "", E.PROD, l.ALPHA + "", E.ALPHA, l.BETA + "", E.BETA, l.CHI + "", E.CHI, l.DELTA + "", E.DELTA, l.EPSILON + "", E.EPSILON, l.FAKER + "", E.FAKER, l.GAMMA + "", E.GAMMA, l.IOTA + "", E.IOTA, l.KAPPA + "", E.KAPPA, l.LAMBDA + "", E.LAMBDA, ae.LOCAL + "", E.LOCAL, l.MU + "", E.MU, l.NU + "", E.NU, l.OMEGA + "", E.OMEGA, l.OMICRON + "", E.OMICRON, l.PSI + "", E.PSI, l.RHO + "", E.RHO, l.SIGMA + "", E.SIGMA, l.THETA + "", E.THETA, l.ZETA + "", E.ZETA, l.UMI + "", E.UMI, l.HADES + "", E.HADES, l.VOLDEMORT + "", E.VOLDEMORT;
ee.PROD + "", v.PROD + "", E.PROD, ee.STAGING + "", v.PROD + "", E.PROD, v.KAPPA + "", E.KAPPA, v.ALPHA + "", E.ALPHA, v.BETA + "", E.BETA, v.GAMMA + "", E.GAMMA, v.LOCAL + "", E.PROD, v.LOCAL2 + "", E.PROD, v.DELTA + "", E.DELTA, v.EPSILON + "", E.EPSILON, v.LAMBDA + "", E.LAMBDA, v.OMEGA + "", E.OMEGA, v.ZETA + "", E.ZETA, v.SIGMA + "", E.SIGMA, v.THETA + "", E.THETA, v.IOTA + "", E.IOTA, v.PSI + "", E.PSI, v.NU + "", E.NU, v.MU + "", E.MU, v.CHI + "", E.CHI, v.RHO + "", E.RHO, v.FAKER + "", E.FAKER, v.OMICRON + "", E.OMICRON, v.UMI + "", E.UMI, v.HADES + "", E.HADES, v.VOLDEMORT + "", E.VOLDEMORT;
ee.PROD + "", E.PROD + "", v.PROD, ee.STAGING + "", E.PROD + "", v.PROD, E.KAPPA + "", v.KAPPA, E.ALPHA + "", v.ALPHA, E.BETA + "", v.BETA, E.GAMMA + "", v.GAMMA, E.LOCAL + "", v.LOCAL, E.DELTA + "", v.DELTA, E.EPSILON + "", v.EPSILON, E.LAMBDA + "", v.LAMBDA, E.OMEGA + "", v.OMEGA, E.ZETA + "", v.ZETA, E.SIGMA + "", v.SIGMA, E.THETA + "", v.THETA, E.IOTA + "", v.IOTA, E.PSI + "", v.PSI, E.NU + "", v.NU, E.MU + "", v.MU, E.CHI + "", v.CHI, E.RHO + "", v.RHO, E.FAKER + "", v.FAKER, E.OMICRON + "", v.OMICRON, E.UMI + "", v.UMI, E.HADES + "", v.HADES, E.VOLDEMORT + "", v.VOLDEMORT;
ee.PROD + "", v.PROD + "", l.PROD, ee.STAGING + "", v.PROD + "", l.PROD, v.KAPPA + "", l.KAPPA, v.ALPHA + "", l.ALPHA, v.BETA + "", l.BETA, v.GAMMA + "", l.GAMMA, v.LOCAL + "", ae.LOCAL, v.LOCAL2 + "", ae.LOCAL2, v.DELTA + "", l.DELTA, v.EPSILON + "", l.EPSILON, v.LAMBDA + "", l.LAMBDA, v.OMEGA + "", l.OMEGA, v.ZETA + "", l.ZETA, v.SIGMA + "", l.SIGMA, v.THETA + "", l.THETA, v.IOTA + "", l.IOTA, v.PSI + "", l.PSI, v.NU + "", l.NU, v.MU + "", l.MU, v.CHI + "", l.CHI, v.RHO + "", l.RHO, v.FAKER + "", l.FAKER, v.OMICRON + "", l.OMICRON, v.UMI + "", l.UMI, v.HADES + "", l.HADES, v.VOLDEMORT + "", l.VOLDEMORT;
ee.PROD + "", l.PROD + "", v.PROD, ee.STAGING + "", l.PROD + "", v.PROD, l.KAPPA + "", v.KAPPA, l.ALPHA + "", v.ALPHA, l.BETA + "", v.BETA, l.GAMMA + "", v.GAMMA, ae.LOCAL + "", v.LOCAL, ae.LOCAL2 + "", v.LOCAL2, l.DELTA + "", v.DELTA, l.EPSILON + "", v.EPSILON, l.LAMBDA + "", v.LAMBDA, l.OMEGA + "", v.OMEGA, l.ZETA + "", v.ZETA, l.SIGMA + "", v.SIGMA, l.THETA + "", v.THETA, l.IOTA + "", v.IOTA, l.PSI + "", v.PSI, l.NU + "", v.NU, l.MU + "", v.MU, l.CHI + "", v.CHI, l.RHO + "", v.RHO, l.FAKER + "", v.FAKER, l.OMICRON + "", v.OMICRON, l.UMI + "", v.UMI, l.HADES + "", v.HADES, l.VOLDEMORT + "", v.VOLDEMORT;
ee.PROD + "", v.PROD + "", y.PROD, ee.STAGING + "", v.PROD + "", y.PROD, v.KAPPA + "", y.KAPPA, v.ALPHA + "", y.ALPHA, v.BETA + "", y.BETA, v.GAMMA + "", y.GAMMA, v.LOCAL + "", y.LOCAL, v.LOCAL2 + "", y.LOCAL2, v.DELTA + "", y.DELTA, v.EPSILON + "", y.EPSILON, v.LAMBDA + "", y.LAMBDA, v.OMEGA + "", y.OMEGA, v.ZETA + "", y.ZETA, v.SIGMA + "", y.SIGMA, v.THETA + "", y.THETA, v.IOTA + "", y.IOTA, v.PSI + "", y.PSI, v.NU + "", y.NU, v.MU + "", y.MU, v.CHI + "", y.CHI, v.RHO + "", y.RHO, v.FAKER + "", y.FAKER, v.OMICRON + "", y.OMICRON, v.UMI + "", y.UMI, v.HADES + "", y.HADES, v.VOLDEMORT + "", y.VOLDEMORT;
ee.PROD + "", y.PROD + "", v.PROD, ee.STAGING + "", y.PROD + "", v.PROD, y.KAPPA + "", v.KAPPA, y.ALPHA + "", v.ALPHA, y.BETA + "", v.BETA, y.GAMMA + "", v.GAMMA, y.LOCAL + "", v.LOCAL, y.LOCAL2 + "", v.LOCAL2, y.DELTA + "", v.DELTA, y.EPSILON + "", v.EPSILON, y.LAMBDA + "", v.LAMBDA, y.OMEGA + "", v.OMEGA, y.ZETA + "", v.ZETA, y.SIGMA + "", v.SIGMA, y.THETA + "", v.THETA, y.IOTA + "", v.IOTA, y.PSI + "", v.PSI, y.NU + "", v.NU, y.MU + "", v.MU, y.CHI + "", v.CHI, y.RHO + "", v.RHO, y.FAKER + "", v.FAKER, y.OMICRON + "", v.OMICRON, y.UMI + "", v.UMI, y.HADES + "", v.HADES, y.VOLDEMORT + "", v.VOLDEMORT;
ee.PROD + "", y.PROD + "", l.PROD, ee.STAGING + "", y.PROD + "", l.PROD, y.KAPPA + "", l.KAPPA, y.ALPHA + "", l.ALPHA, y.BETA + "", l.BETA, y.GAMMA + "", l.GAMMA, y.LOCAL + "", ae.LOCAL, y.LOCAL2 + "", ae.LOCAL2, y.DELTA + "", l.DELTA, y.EPSILON + "", l.EPSILON, y.LAMBDA + "", l.LAMBDA, y.OMEGA + "", l.OMEGA, y.ZETA + "", l.ZETA, y.SIGMA + "", l.SIGMA, y.THETA + "", l.THETA, y.IOTA + "", l.IOTA, y.PSI + "", l.PSI, y.NU + "", l.NU, y.MU + "", l.MU, y.CHI + "", l.CHI, y.RHO + "", l.RHO, y.FAKER + "", l.FAKER, y.OMICRON + "", l.OMICRON, y.UMI + "", l.UMI, y.HADES + "", l.HADES, y.VOLDEMORT + "", l.VOLDEMORT;
ee.PROD + "", l.PROD + "", y.PROD, ee.STAGING + "", l.PROD + "", y.PROD, l.KAPPA + "", y.KAPPA, l.ALPHA + "", y.ALPHA, l.BETA + "", y.BETA, l.GAMMA + "", y.GAMMA, ae.LOCAL + "", y.LOCAL, ae.LOCAL2 + "", y.LOCAL2, l.DELTA + "", y.DELTA, l.EPSILON + "", y.EPSILON, l.LAMBDA + "", y.LAMBDA, l.OMEGA + "", y.OMEGA, l.ZETA + "", y.ZETA, l.SIGMA + "", y.SIGMA, l.THETA + "", y.THETA, l.IOTA + "", y.IOTA, l.PSI + "", y.PSI, l.NU + "", y.NU, l.MU + "", y.MU, l.CHI + "", y.CHI, l.RHO + "", y.RHO, l.FAKER + "", y.FAKER, l.OMICRON + "", y.OMICRON, l.UMI + "", y.UMI, l.HADES + "", y.HADES, l.VOLDEMORT + "", y.VOLDEMORT;
ee.PROD + "", I.PROD + "", l.PROD, ee.STAGING + "", I.PROD + "", l.PROD, I.ALPHA + "", l.ALPHA, I.BETA + "", l.BETA, I.CHI + "", l.CHI, I.DELTA + "", l.DELTA, I.EPSILON + "", l.EPSILON, I.FAKER + "", l.FAKER, I.GAMMA + "", l.GAMMA, I.IOTA + "", l.IOTA, I.KAPPA + "", l.KAPPA, I.LAMBDA + "", l.LAMBDA, I.LOCAL + "", ae.LOCAL, I.LOCAL2 + "", ae.LOCAL2, I.MU + "", l.MU, I.NU + "", l.NU, I.OMEGA + "", l.OMEGA, I.OMICRON + "", l.OMICRON, I.PSI + "", l.PSI, I.STAGING + "", l.PROD, I.RHO + "", l.RHO, I.SIGMA + "", l.SIGMA, I.THETA + "", l.THETA, I.ZETA + "", l.ZETA, I.UMI + "", l.UMI, I.HADES + "", l.HADES, I.VOLDEMORT + "", l.VOLDEMORT;
ee.PROD + "", l.PROD + "", I.PROD, ee.STAGING + "", l.PROD + "", I.PROD, l.ALPHA + "", I.ALPHA, l.BETA + "", I.BETA, l.CHI + "", I.CHI, l.DELTA + "", I.DELTA, l.EPSILON + "", I.EPSILON, l.FAKER + "", I.FAKER, l.GAMMA + "", I.GAMMA, l.IOTA + "", I.IOTA, l.KAPPA + "", I.KAPPA, l.LAMBDA + "", I.LAMBDA, ae.LOCAL + "", I.LOCAL, ae.LOCAL2 + "", I.LOCAL2, l.MU + "", I.MU, l.NU + "", I.NU, l.OMEGA + "", I.OMEGA, l.OMICRON + "", I.OMICRON, l.PSI + "", I.PSI, l.RHO + "", I.RHO, l.SIGMA + "", I.SIGMA, l.THETA + "", I.THETA, l.ZETA + "", I.ZETA, l.UMI + "", I.UMI, l.HADES + "", I.HADES, l.VOLDEMORT + "", I.VOLDEMORT;
const xu = {
  [y.PROD]: l.PROD,
  [y.KAPPA]: l.KAPPA,
  [y.ALPHA]: l.ALPHA,
  [y.BETA]: l.BETA,
  [y.GAMMA]: l.GAMMA,
  [y.LOCAL]: ae.LOCAL,
  [y.LOCAL2]: ae.LOCAL2,
  [y.DELTA]: l.DELTA,
  [y.EPSILON]: l.EPSILON,
  [y.LAMBDA]: l.LAMBDA,
  [y.OMEGA]: l.OMEGA,
  [y.ZETA]: l.ZETA,
  [y.SIGMA]: l.SIGMA,
  [y.THETA]: l.THETA,
  [y.IOTA]: l.IOTA,
  [y.PSI]: l.PSI,
  [y.NU]: l.NU,
  [y.MU]: l.MU,
  [y.CHI]: l.CHI,
  [y.RHO]: l.RHO,
  [y.FAKER]: l.FAKER,
  [y.OMICRON]: l.OMICRON,
  [y.UMI]: l.UMI,
  [y.HADES]: l.HADES,
  [y.VOLDEMORT]: l.VOLDEMORT
}, ju = {
  [y.PROD]: I.PROD,
  [y.KAPPA]: I.KAPPA,
  [y.ALPHA]: I.ALPHA,
  [y.BETA]: I.BETA,
  [y.GAMMA]: I.GAMMA,
  [y.LOCAL]: I.LOCAL,
  [y.LOCAL2]: I.LOCAL,
  [y.DELTA]: I.DELTA,
  [y.EPSILON]: I.EPSILON,
  [y.LAMBDA]: I.LAMBDA,
  [y.OMEGA]: I.OMEGA,
  [y.ZETA]: I.ZETA,
  [y.SIGMA]: I.SIGMA,
  [y.THETA]: I.THETA,
  [y.IOTA]: I.IOTA,
  [y.PSI]: I.PSI,
  [y.NU]: I.NU,
  [y.MU]: I.MU,
  [y.CHI]: I.CHI,
  [y.RHO]: I.RHO,
  [y.FAKER]: I.FAKER,
  [y.OMICRON]: I.OMICRON,
  [y.UMI]: I.UMI,
  [y.HADES]: I.HADES,
  [y.VOLDEMORT]: I.VOLDEMORT
}, $u = I.KAPPA, Bu = ({ fragment: n, pathSuffix: e = "" }) => {
  const { host: t } = window.location;
  return ua(xu[t]) ? `https://${ju[t] || $u}/api/shakespeare/translations${e}?fragment=${n}&language={{lng}}` : `https://stargate-revenant.herokuapp.com/api/shakespeare/translations${e}?fragment=${n}&language={{lng}}`;
};
var Hu = { exports: {} }, Cr = { exports: {} }, bs;
function et() {
  return bs || (bs = 1, function(n, e) {
    (function(t, r) {
      n.exports = r();
    })(ne, function() {
      var t = 1e3, r = 6e4, i = 36e5, s = "millisecond", a = "second", o = "minute", u = "hour", c = "day", d = "week", f = "month", h = "quarter", p = "year", g = "date", _ = "Invalid Date", A = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, O = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, w = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(U) {
        var m = ["th", "st", "nd", "rd"], b = U % 100;
        return "[" + U + (m[(b - 20) % 10] || m[b] || m[0]) + "]";
      } }, P = function(U, m, b) {
        var M = String(U);
        return !M || M.length >= m ? U : "" + Array(m + 1 - M.length).join(b) + U;
      }, C = { s: P, z: function(U) {
        var m = -U.utcOffset(), b = Math.abs(m), M = Math.floor(b / 60), T = b % 60;
        return (m <= 0 ? "+" : "-") + P(M, 2, "0") + ":" + P(T, 2, "0");
      }, m: function U(m, b) {
        if (m.date() < b.date())
          return -U(b, m);
        var M = 12 * (b.year() - m.year()) + (b.month() - m.month()), T = m.clone().add(M, f), R = b - T < 0, N = m.clone().add(M + (R ? -1 : 1), f);
        return +(-(M + (b - T) / (R ? T - N : N - T)) || 0);
      }, a: function(U) {
        return U < 0 ? Math.ceil(U) || 0 : Math.floor(U);
      }, p: function(U) {
        return { M: f, y: p, w: d, d: c, D: g, h: u, m: o, s: a, ms: s, Q: h }[U] || String(U || "").toLowerCase().replace(/s$/, "");
      }, u: function(U) {
        return U === void 0;
      } }, j = "en", F = {};
      F[j] = w;
      var re = function(U) {
        return U instanceof ie;
      }, J = function U(m, b, M) {
        var T;
        if (!m)
          return j;
        if (typeof m == "string") {
          var R = m.toLowerCase();
          F[R] && (T = R), b && (F[R] = b, T = R);
          var N = m.split("-");
          if (!T && N.length > 1)
            return U(N[0]);
        } else {
          var K = m.name;
          F[K] = m, T = K;
        }
        return !M && T && (j = T), T || !M && j;
      }, Y = function(U, m) {
        if (re(U))
          return U.clone();
        var b = typeof m == "object" ? m : {};
        return b.date = U, b.args = arguments, new ie(b);
      }, B = C;
      B.l = J, B.i = re, B.w = function(U, m) {
        return Y(U, { locale: m.$L, utc: m.$u, x: m.$x, $offset: m.$offset });
      };
      var ie = function() {
        function U(b) {
          this.$L = J(b.locale, null, !0), this.parse(b);
        }
        var m = U.prototype;
        return m.parse = function(b) {
          this.$d = function(M) {
            var T = M.date, R = M.utc;
            if (T === null)
              return /* @__PURE__ */ new Date(NaN);
            if (B.u(T))
              return /* @__PURE__ */ new Date();
            if (T instanceof Date)
              return new Date(T);
            if (typeof T == "string" && !/Z$/i.test(T)) {
              var N = T.match(A);
              if (N) {
                var K = N[2] - 1 || 0, X = (N[7] || "0").substring(0, 3);
                return R ? new Date(Date.UTC(N[1], K, N[3] || 1, N[4] || 0, N[5] || 0, N[6] || 0, X)) : new Date(N[1], K, N[3] || 1, N[4] || 0, N[5] || 0, N[6] || 0, X);
              }
            }
            return new Date(T);
          }(b), this.$x = b.x || {}, this.init();
        }, m.init = function() {
          var b = this.$d;
          this.$y = b.getFullYear(), this.$M = b.getMonth(), this.$D = b.getDate(), this.$W = b.getDay(), this.$H = b.getHours(), this.$m = b.getMinutes(), this.$s = b.getSeconds(), this.$ms = b.getMilliseconds();
        }, m.$utils = function() {
          return B;
        }, m.isValid = function() {
          return this.$d.toString() !== _;
        }, m.isSame = function(b, M) {
          var T = Y(b);
          return this.startOf(M) <= T && T <= this.endOf(M);
        }, m.isAfter = function(b, M) {
          return Y(b) < this.startOf(M);
        }, m.isBefore = function(b, M) {
          return this.endOf(M) < Y(b);
        }, m.$g = function(b, M, T) {
          return B.u(b) ? this[M] : this.set(T, b);
        }, m.unix = function() {
          return Math.floor(this.valueOf() / 1e3);
        }, m.valueOf = function() {
          return this.$d.getTime();
        }, m.startOf = function(b, M) {
          var T = this, R = !!B.u(M) || M, N = B.p(b), K = function(Ze, me) {
            var Te = B.w(T.$u ? Date.UTC(T.$y, me, Ze) : new Date(T.$y, me, Ze), T);
            return R ? Te : Te.endOf(c);
          }, X = function(Ze, me) {
            return B.w(T.toDate()[Ze].apply(T.toDate("s"), (R ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(me)), T);
          }, te = this.$W, de = this.$M, Oe = this.$D, ke = "set" + (this.$u ? "UTC" : "");
          switch (N) {
            case p:
              return R ? K(1, 0) : K(31, 11);
            case f:
              return R ? K(1, de) : K(0, de + 1);
            case d:
              var Le = this.$locale().weekStart || 0, Ue = (te < Le ? te + 7 : te) - Le;
              return K(R ? Oe - Ue : Oe + (6 - Ue), de);
            case c:
            case g:
              return X(ke + "Hours", 0);
            case u:
              return X(ke + "Minutes", 1);
            case o:
              return X(ke + "Seconds", 2);
            case a:
              return X(ke + "Milliseconds", 3);
            default:
              return this.clone();
          }
        }, m.endOf = function(b) {
          return this.startOf(b, !1);
        }, m.$set = function(b, M) {
          var T, R = B.p(b), N = "set" + (this.$u ? "UTC" : ""), K = (T = {}, T[c] = N + "Date", T[g] = N + "Date", T[f] = N + "Month", T[p] = N + "FullYear", T[u] = N + "Hours", T[o] = N + "Minutes", T[a] = N + "Seconds", T[s] = N + "Milliseconds", T)[R], X = R === c ? this.$D + (M - this.$W) : M;
          if (R === f || R === p) {
            var te = this.clone().set(g, 1);
            te.$d[K](X), te.init(), this.$d = te.set(g, Math.min(this.$D, te.daysInMonth())).$d;
          } else
            K && this.$d[K](X);
          return this.init(), this;
        }, m.set = function(b, M) {
          return this.clone().$set(b, M);
        }, m.get = function(b) {
          return this[B.p(b)]();
        }, m.add = function(b, M) {
          var T, R = this;
          b = Number(b);
          var N = B.p(M), K = function(de) {
            var Oe = Y(R);
            return B.w(Oe.date(Oe.date() + Math.round(de * b)), R);
          };
          if (N === f)
            return this.set(f, this.$M + b);
          if (N === p)
            return this.set(p, this.$y + b);
          if (N === c)
            return K(1);
          if (N === d)
            return K(7);
          var X = (T = {}, T[o] = r, T[u] = i, T[a] = t, T)[N] || 1, te = this.$d.getTime() + b * X;
          return B.w(te, this);
        }, m.subtract = function(b, M) {
          return this.add(-1 * b, M);
        }, m.format = function(b) {
          var M = this, T = this.$locale();
          if (!this.isValid())
            return T.invalidDate || _;
          var R = b || "YYYY-MM-DDTHH:mm:ssZ", N = B.z(this), K = this.$H, X = this.$m, te = this.$M, de = T.weekdays, Oe = T.months, ke = function(me, Te, Fe, ot) {
            return me && (me[Te] || me(M, R)) || Fe[Te].slice(0, ot);
          }, Le = function(me) {
            return B.s(K % 12 || 12, me, "0");
          }, Ue = T.meridiem || function(me, Te, Fe) {
            var ot = me < 12 ? "AM" : "PM";
            return Fe ? ot.toLowerCase() : ot;
          }, Ze = { YY: String(this.$y).slice(-2), YYYY: this.$y, M: te + 1, MM: B.s(te + 1, 2, "0"), MMM: ke(T.monthsShort, te, Oe, 3), MMMM: ke(Oe, te), D: this.$D, DD: B.s(this.$D, 2, "0"), d: String(this.$W), dd: ke(T.weekdaysMin, this.$W, de, 2), ddd: ke(T.weekdaysShort, this.$W, de, 3), dddd: de[this.$W], H: String(K), HH: B.s(K, 2, "0"), h: Le(1), hh: Le(2), a: Ue(K, X, !0), A: Ue(K, X, !1), m: String(X), mm: B.s(X, 2, "0"), s: String(this.$s), ss: B.s(this.$s, 2, "0"), SSS: B.s(this.$ms, 3, "0"), Z: N };
          return R.replace(O, function(me, Te) {
            return Te || Ze[me] || N.replace(":", "");
          });
        }, m.utcOffset = function() {
          return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
        }, m.diff = function(b, M, T) {
          var R, N = B.p(M), K = Y(b), X = (K.utcOffset() - this.utcOffset()) * r, te = this - K, de = B.m(this, K);
          return de = (R = {}, R[p] = de / 12, R[f] = de, R[h] = de / 3, R[d] = (te - X) / 6048e5, R[c] = (te - X) / 864e5, R[u] = te / i, R[o] = te / r, R[a] = te / t, R)[N] || te, T ? de : B.a(de);
        }, m.daysInMonth = function() {
          return this.endOf(f).$D;
        }, m.$locale = function() {
          return F[this.$L];
        }, m.locale = function(b, M) {
          if (!b)
            return this.$L;
          var T = this.clone(), R = J(b, M, !0);
          return R && (T.$L = R), T;
        }, m.clone = function() {
          return B.w(this.$d, this);
        }, m.toDate = function() {
          return new Date(this.valueOf());
        }, m.toJSON = function() {
          return this.isValid() ? this.toISOString() : null;
        }, m.toISOString = function() {
          return this.$d.toISOString();
        }, m.toString = function() {
          return this.$d.toUTCString();
        }, U;
      }(), pe = ie.prototype;
      return Y.prototype = pe, [["$ms", s], ["$s", a], ["$m", o], ["$H", u], ["$W", c], ["$M", f], ["$y", p], ["$D", g]].forEach(function(U) {
        pe[U[1]] = function(m) {
          return this.$g(m, U[0], U[1]);
        };
      }), Y.extend = function(U, m) {
        return U.$i || (U(m, ie, Y), U.$i = !0), Y;
      }, Y.locale = J, Y.isDayjs = re, Y.unix = function(U) {
        return Y(1e3 * U);
      }, Y.en = F[j], Y.Ls = F, Y.p = {}, Y;
    });
  }(Cr)), Cr.exports;
}
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(u) {
      return u && typeof u == "object" && "default" in u ? u : { default: u };
    }
    var i = r(t), s = { s: "ein paar Sekunden", m: ["eine Minute", "einer Minute"], mm: "%d Minuten", h: ["eine Stunde", "einer Stunde"], hh: "%d Stunden", d: ["ein Tag", "einem Tag"], dd: ["%d Tage", "%d Tagen"], M: ["ein Monat", "einem Monat"], MM: ["%d Monate", "%d Monaten"], y: ["ein Jahr", "einem Jahr"], yy: ["%d Jahre", "%d Jahren"] };
    function a(u, c, d) {
      var f = s[d];
      return Array.isArray(f) && (f = f[c ? 0 : 1]), f.replace("%d", u);
    }
    var o = { name: "de", weekdays: "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split("_"), weekdaysShort: "So._Mo._Di._Mi._Do._Fr._Sa.".split("_"), weekdaysMin: "So_Mo_Di_Mi_Do_Fr_Sa".split("_"), months: "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split("_"), monthsShort: "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sept._Okt._Nov._Dez.".split("_"), ordinal: function(u) {
      return u + ".";
    }, weekStart: 1, yearStart: 4, formats: { LTS: "HH:mm:ss", LT: "HH:mm", L: "DD.MM.YYYY", LL: "D. MMMM YYYY", LLL: "D. MMMM YYYY HH:mm", LLLL: "dddd, D. MMMM YYYY HH:mm" }, relativeTime: { future: "in %s", past: "vor %s", s: a, m: a, mm: a, h: a, hh: a, d: a, dd: a, M: a, MM: a, y: a, yy: a } };
    return i.default.locale(o, null, !0), o;
  });
})(Hu);
var Uu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r();
  })(ne, function() {
    return { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(t) {
      var r = ["th", "st", "nd", "rd"], i = t % 100;
      return "[" + t + (r[(i - 20) % 10] || r[i] || r[0]) + "]";
    } };
  });
})(Uu);
var Zu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "es", monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"), weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"), weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"), weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"), months: "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split("_"), weekStart: 1, formats: { LT: "H:mm", LTS: "H:mm:ss", L: "DD/MM/YYYY", LL: "D [de] MMMM [de] YYYY", LLL: "D [de] MMMM [de] YYYY H:mm", LLLL: "dddd, D [de] MMMM [de] YYYY H:mm" }, relativeTime: { future: "en %s", past: "hace %s", s: "unos segundos", m: "un minuto", mm: "%d minutos", h: "una hora", hh: "%d horas", d: "un día", dd: "%d días", M: "un mes", MM: "%d meses", y: "un año", yy: "%d años" }, ordinal: function(a) {
      return a + "º";
    } };
    return i.default.locale(s, null, !0), s;
  });
})(Zu);
var Fu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "fr", weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"), weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"), weekdaysMin: "di_lu_ma_me_je_ve_sa".split("_"), months: "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split("_"), monthsShort: "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"), weekStart: 1, yearStart: 4, formats: { LT: "HH:mm", LTS: "HH:mm:ss", L: "DD/MM/YYYY", LL: "D MMMM YYYY", LLL: "D MMMM YYYY HH:mm", LLLL: "dddd D MMMM YYYY HH:mm" }, relativeTime: { future: "dans %s", past: "il y a %s", s: "quelques secondes", m: "une minute", mm: "%d minutes", h: "une heure", hh: "%d heures", d: "un jour", dd: "%d jours", M: "un mois", MM: "%d mois", y: "un an", yy: "%d ans" }, ordinal: function(a) {
      return "" + a + (a === 1 ? "er" : "");
    } };
    return i.default.locale(s, null, !0), s;
  });
})(Fu);
var Vu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "id", weekdays: "Minggu_Senin_Selasa_Rabu_Kamis_Jumat_Sabtu".split("_"), months: "Januari_Februari_Maret_April_Mei_Juni_Juli_Agustus_September_Oktober_November_Desember".split("_"), weekdaysShort: "Min_Sen_Sel_Rab_Kam_Jum_Sab".split("_"), monthsShort: "Jan_Feb_Mar_Apr_Mei_Jun_Jul_Agt_Sep_Okt_Nov_Des".split("_"), weekdaysMin: "Mg_Sn_Sl_Rb_Km_Jm_Sb".split("_"), weekStart: 1, formats: { LT: "HH.mm", LTS: "HH.mm.ss", L: "DD/MM/YYYY", LL: "D MMMM YYYY", LLL: "D MMMM YYYY [pukul] HH.mm", LLLL: "dddd, D MMMM YYYY [pukul] HH.mm" }, relativeTime: { future: "dalam %s", past: "%s yang lalu", s: "beberapa detik", m: "semenit", mm: "%d menit", h: "sejam", hh: "%d jam", d: "sehari", dd: "%d hari", M: "sebulan", MM: "%d bulan", y: "setahun", yy: "%d tahun" }, ordinal: function(a) {
      return a + ".";
    } };
    return i.default.locale(s, null, !0), s;
  });
})(Vu);
var Gu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "it", weekdays: "domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato".split("_"), weekdaysShort: "dom_lun_mar_mer_gio_ven_sab".split("_"), weekdaysMin: "do_lu_ma_me_gi_ve_sa".split("_"), months: "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split("_"), weekStart: 1, monthsShort: "gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic".split("_"), formats: { LT: "HH:mm", LTS: "HH:mm:ss", L: "DD/MM/YYYY", LL: "D MMMM YYYY", LLL: "D MMMM YYYY HH:mm", LLLL: "dddd D MMMM YYYY HH:mm" }, relativeTime: { future: "tra %s", past: "%s fa", s: "qualche secondo", m: "un minuto", mm: "%d minuti", h: "un' ora", hh: "%d ore", d: "un giorno", dd: "%d giorni", M: "un mese", MM: "%d mesi", y: "un anno", yy: "%d anni" }, ordinal: function(a) {
      return a + "º";
    } };
    return i.default.locale(s, null, !0), s;
  });
})(Gu);
var Yu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "ja", weekdays: "日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日".split("_"), weekdaysShort: "日_月_火_水_木_金_土".split("_"), weekdaysMin: "日_月_火_水_木_金_土".split("_"), months: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"), monthsShort: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"), ordinal: function(a) {
      return a + "日";
    }, formats: { LT: "HH:mm", LTS: "HH:mm:ss", L: "YYYY/MM/DD", LL: "YYYY年M月D日", LLL: "YYYY年M月D日 HH:mm", LLLL: "YYYY年M月D日 dddd HH:mm", l: "YYYY/MM/DD", ll: "YYYY年M月D日", lll: "YYYY年M月D日 HH:mm", llll: "YYYY年M月D日(ddd) HH:mm" }, meridiem: function(a) {
      return a < 12 ? "午前" : "午後";
    }, relativeTime: { future: "%s後", past: "%s前", s: "数秒", m: "1分", mm: "%d分", h: "1時間", hh: "%d時間", d: "1日", dd: "%d日", M: "1ヶ月", MM: "%dヶ月", y: "1年", yy: "%d年" } };
    return i.default.locale(s, null, !0), s;
  });
})(Yu);
var Ku = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "nl", weekdays: "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split("_"), weekdaysShort: "zo._ma._di._wo._do._vr._za.".split("_"), weekdaysMin: "zo_ma_di_wo_do_vr_za".split("_"), months: "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split("_"), monthsShort: "jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec".split("_"), ordinal: function(a) {
      return "[" + a + (a === 1 || a === 8 || a >= 20 ? "ste" : "de") + "]";
    }, weekStart: 1, yearStart: 4, formats: { LT: "HH:mm", LTS: "HH:mm:ss", L: "DD-MM-YYYY", LL: "D MMMM YYYY", LLL: "D MMMM YYYY HH:mm", LLLL: "dddd D MMMM YYYY HH:mm" }, relativeTime: { future: "over %s", past: "%s geleden", s: "een paar seconden", m: "een minuut", mm: "%d minuten", h: "een uur", hh: "%d uur", d: "een dag", dd: "%d dagen", M: "een maand", MM: "%d maanden", y: "een jaar", yy: "%d jaar" } };
    return i.default.locale(s, null, !0), s;
  });
})(Ku);
var qu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(h) {
      return h && typeof h == "object" && "default" in h ? h : { default: h };
    }
    var i = r(t);
    function s(h) {
      return h % 10 < 5 && h % 10 > 1 && ~~(h / 10) % 10 != 1;
    }
    function a(h, p, g) {
      var _ = h + " ";
      switch (g) {
        case "m":
          return p ? "minuta" : "minutę";
        case "mm":
          return _ + (s(h) ? "minuty" : "minut");
        case "h":
          return p ? "godzina" : "godzinę";
        case "hh":
          return _ + (s(h) ? "godziny" : "godzin");
        case "MM":
          return _ + (s(h) ? "miesiące" : "miesięcy");
        case "yy":
          return _ + (s(h) ? "lata" : "lat");
      }
    }
    var o = "stycznia_lutego_marca_kwietnia_maja_czerwca_lipca_sierpnia_września_października_listopada_grudnia".split("_"), u = "styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień".split("_"), c = /D MMMM/, d = function(h, p) {
      return c.test(p) ? o[h.month()] : u[h.month()];
    };
    d.s = u, d.f = o;
    var f = { name: "pl", weekdays: "niedziela_poniedziałek_wtorek_środa_czwartek_piątek_sobota".split("_"), weekdaysShort: "ndz_pon_wt_śr_czw_pt_sob".split("_"), weekdaysMin: "Nd_Pn_Wt_Śr_Cz_Pt_So".split("_"), months: d, monthsShort: "sty_lut_mar_kwi_maj_cze_lip_sie_wrz_paź_lis_gru".split("_"), ordinal: function(h) {
      return h + ".";
    }, weekStart: 1, yearStart: 4, relativeTime: { future: "za %s", past: "%s temu", s: "kilka sekund", m: a, mm: a, h: a, hh: a, d: "1 dzień", dd: "%d dni", M: "miesiąc", MM: a, y: "rok", yy: a }, formats: { LT: "HH:mm", LTS: "HH:mm:ss", L: "DD.MM.YYYY", LL: "D MMMM YYYY", LLL: "D MMMM YYYY HH:mm", LLLL: "dddd, D MMMM YYYY HH:mm" } };
    return i.default.locale(f, null, !0), f;
  });
})(qu);
var Wu = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(a) {
      return a && typeof a == "object" && "default" in a ? a : { default: a };
    }
    var i = r(t), s = { name: "pt", weekdays: "domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado".split("_"), weekdaysShort: "dom_seg_ter_qua_qui_sex_sab".split("_"), weekdaysMin: "Do_2ª_3ª_4ª_5ª_6ª_Sa".split("_"), months: "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split("_"), monthsShort: "jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez".split("_"), ordinal: function(a) {
      return a + "º";
    }, weekStart: 1, yearStart: 4, formats: { LT: "HH:mm", LTS: "HH:mm:ss", L: "DD/MM/YYYY", LL: "D [de] MMMM [de] YYYY", LLL: "D [de] MMMM [de] YYYY [às] HH:mm", LLLL: "dddd, D [de] MMMM [de] YYYY [às] HH:mm" }, relativeTime: { future: "em %s", past: "há %s", s: "alguns segundos", m: "um minuto", mm: "%d minutos", h: "uma hora", hh: "%d horas", d: "um dia", dd: "%d dias", M: "um mês", MM: "%d meses", y: "um ano", yy: "%d anos" } };
    return i.default.locale(s, null, !0), s;
  });
})(Wu);
var Ju = { exports: {} };
(function(n, e) {
  (function(t, r) {
    n.exports = r(et());
  })(ne, function(t) {
    function r(g) {
      return g && typeof g == "object" && "default" in g ? g : { default: g };
    }
    var i = r(t), s = "января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря".split("_"), a = "январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь".split("_"), o = "янв._февр._мар._апр._мая_июня_июля_авг._сент._окт._нояб._дек.".split("_"), u = "янв._февр._март_апр._май_июнь_июль_авг._сент._окт._нояб._дек.".split("_"), c = /D[oD]?(\[[^[\]]*\]|\s)+MMMM?/;
    function d(g, _, A) {
      var O, w;
      return A === "m" ? _ ? "минута" : "минуту" : g + " " + (O = +g, w = { mm: _ ? "минута_минуты_минут" : "минуту_минуты_минут", hh: "час_часа_часов", dd: "день_дня_дней", MM: "месяц_месяца_месяцев", yy: "год_года_лет" }[A].split("_"), O % 10 == 1 && O % 100 != 11 ? w[0] : O % 10 >= 2 && O % 10 <= 4 && (O % 100 < 10 || O % 100 >= 20) ? w[1] : w[2]);
    }
    var f = function(g, _) {
      return c.test(_) ? s[g.month()] : a[g.month()];
    };
    f.s = a, f.f = s;
    var h = function(g, _) {
      return c.test(_) ? o[g.month()] : u[g.month()];
    };
    h.s = u, h.f = o;
    var p = { name: "ru", weekdays: "воскресенье_понедельник_вторник_среда_четверг_пятница_суббота".split("_"), weekdaysShort: "вск_пнд_втр_срд_чтв_птн_сбт".split("_"), weekdaysMin: "вс_пн_вт_ср_чт_пт_сб".split("_"), months: f, monthsShort: h, weekStart: 1, yearStart: 4, formats: { LT: "H:mm", LTS: "H:mm:ss", L: "DD.MM.YYYY", LL: "D MMMM YYYY г.", LLL: "D MMMM YYYY г., H:mm", LLLL: "dddd, D MMMM YYYY г., H:mm" }, relativeTime: { future: "через %s", past: "%s назад", s: "несколько секунд", m: d, mm: d, h: "час", hh: d, d: "день", dd: d, M: "месяц", MM: d, y: "год", yy: d }, ordinal: function(g) {
      return g;
    }, meridiem: function(g) {
      return g < 4 ? "ночи" : g < 12 ? "утра" : g < 17 ? "дня" : "вечера";
    } };
    return i.default.locale(p, null, !0), p;
  });
})(Ju);
var Re = /* @__PURE__ */ ((n) => (n.FR = "fr", n.EN = "en", n.ES = "es", n.JA = "ja", n.RU = "ru", n.DE = "de", n.ID = "id", n.IT = "it", n.NL = "nl", n.PL = "pl", n.PT = "pt", n))(Re || {});
const zu = (n) => Object.values(Re).includes(n), Xu = (n) => zu(n) ? n : Re.EN, rn = (n, e = "") => new Proxy(n, {
  get: (t, r) => {
    const i = `${e ? `${e}.` : ""}${r.toString()}`;
    try {
      return r in t && t[r] ? t[r] : rn(() => i);
    } catch {
      return rn(() => i);
    }
  },
  set: (t, r, i) => (t[r] = i, !0)
}), ca = (n, e, t, r = "") => {
  Object.keys(n).forEach((i) => {
    typeof n[i] == "object" && ca(n[i], e, t, `${r ? `${r}.` : ""}${i}`), n[i] = e(n[i], `${r ? `${r}.` : ""}${i}`);
  });
}, la = (n, e = "") => {
  const t = {};
  return Object.keys(n).forEach((r) => {
    const i = e ? `${e}.${r}` : r;
    typeof n[r] == "object" ? t[r] = la(n[r], i) : t[r] = () => i;
  }), t;
}, fa = (n, e) => {
  const t = e || n.language || Re.EN;
  if (n.translationsData[t])
    return n.translationsData[t];
  const r = n.getDataByLanguage(t);
  let i;
  return r ? (i = la(r.translation), ca(i, rn, n), i = rn(i), n.translationsData[t] = i) : i = rn({}), i;
};
let da;
const ha = Object.defineProperty(ye, "translationsData", {
  value: {},
  writable: !0
}), Qu = async (n) => {
  const { fragment: e, localStoragePrefix: t, pathSuffix: r } = n;
  await ye.use(Xs).use(Iu).init({
    supportedLngs: Object.values(Re),
    returnNull: !1,
    backend: {
      backends: [sa, ia],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: t,
          // expiration
          expirationTime: 60 * 60 * 1e3,
          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.localStorage
        },
        {
          loadPath: Bu({ fragment: e, pathSuffix: r }),
          parse: (i) => typeof i == "string" ? JSON.parse(i).data : i.data
        }
      ]
    },
    fallbackLng: Re.EN,
    debug: !1,
    react: {
      useSuspense: !1
    },
    interpolation: {
      escapeValue: !1
      // not needed for react as it escapes by default
    }
  }), da = fa(ha);
}, Ad = (n) => {
  const [e, t] = ft(!0);
  return mt(() => {
    Qu(n).finally(() => t(!1));
  }, [JSON.stringify(n)]), { loading: e };
}, ec = () => {
  const [n, e] = ft(da), [t, r] = ft(!1), { t: i, i18n: s } = Ru(), a = (d) => {
    s.changeLanguage(d, () => e(fa(ha)));
  }, o = (d, f) => {
    const h = typeof f == "string" ? i(d, f, { fallbackLng: Re.EN }) : i(d, { ...f, fallbackLng: Re.EN });
    return h !== "" ? h : typeof f == "string" ? i(d, f, { lng: Re.EN }) : i(d, { lng: Re.EN, ...f });
  }, u = async (d) => {
    r(!0);
    try {
      return await s.loadLanguages(d), { hasFailed: !1 };
    } catch {
      return { hasFailed: !0 };
    } finally {
      r(!1);
    }
  }, c = Xu(s.language);
  return {
    t: o,
    changeLanguage: a,
    currentLanguage: c,
    fetchLanguageData: u,
    translations: n,
    languages: Object.values(Re),
    i18n: s,
    isLoadingLanguage: t
  };
}, wd = () => {
  const { currentLanguage: n } = ec(), e = n ?? navigator.language;
  return { formatAmountWithCurrency: ({ amount: r, currencyCode: i, displayFractionDigits: s = !1 }) => new Intl.NumberFormat(e, {
    style: "currency",
    currency: i,
    currencyDisplay: "symbol",
    maximumFractionDigits: s ? yo(i) : 0
  }).format(r) };
}, tc = gi({ ioClient: void 0, loading: !0 });
var vi = {}, dr = {}, Et = {};
Object.defineProperty(Et, "__esModule", { value: !0 });
Et.isAnyZodType = Et.isZodType = void 0;
function nc(n, e) {
  return n.constructor.name === e;
}
Et.isZodType = nc;
function rc(n) {
  return "_def" in n;
}
Et.isAnyZodType = rc;
var ic = ne && ne.__rest || function(n, e) {
  var t = {};
  for (var r in n)
    Object.prototype.hasOwnProperty.call(n, r) && e.indexOf(r) < 0 && (t[r] = n[r]);
  if (n != null && typeof Object.getOwnPropertySymbols == "function")
    for (var i = 0, r = Object.getOwnPropertySymbols(n); i < r.length; i++)
      e.indexOf(r[i]) < 0 && Object.prototype.propertyIsEnumerable.call(n, r[i]) && (t[r[i]] = n[r[i]]);
  return t;
};
Object.defineProperty(dr, "__esModule", { value: !0 });
dr.extendZodWithOpenApi = void 0;
const _s = Et;
function sc(n) {
  if (typeof n.ZodSchema.prototype.openapi < "u")
    return;
  n.ZodSchema.prototype.openapi = function(o) {
    var u, c, d, f, h, p;
    const g = o ?? {}, { param: _ } = g, A = ic(g, ["param"]), O = new this.constructor(Object.assign(Object.assign({}, this._def), { openapi: {
      _internal: (u = this._def.openapi) === null || u === void 0 ? void 0 : u._internal,
      metadata: Object.assign(Object.assign(Object.assign({}, (c = this._def.openapi) === null || c === void 0 ? void 0 : c.metadata), A), { param: !((f = (d = this._def.openapi) === null || d === void 0 ? void 0 : d.metadata) === null || f === void 0) && f.param || _ ? Object.assign(Object.assign({}, (p = (h = this._def.openapi) === null || h === void 0 ? void 0 : h.metadata) === null || p === void 0 ? void 0 : p.param), _) : void 0 })
    } }));
    return (0, _s.isZodType)(this, "ZodObject") && (O.extend = this.extend), O;
  }, n.ZodSchema.prototype.internal_openapi = function(o) {
    var u, c;
    const d = new this.constructor(Object.assign(Object.assign({}, this._def), { openapi: {
      _internal: Object.assign(Object.assign({}, (u = this._def.openapi) === null || u === void 0 ? void 0 : u._internal), o),
      metadata: (c = this._def.openapi) === null || c === void 0 ? void 0 : c.metadata
    } }));
    if ((0, _s.isZodType)(this, "ZodObject")) {
      const f = this.extend;
      d.extend = function(...h) {
        var p, g, _, A, O;
        const w = f.apply(this, h);
        return w._def.openapi = {
          _internal: {
            extendedFrom: !((g = (p = this._def.openapi) === null || p === void 0 ? void 0 : p._internal) === null || g === void 0) && g.refId ? { refId: (A = (_ = this._def.openapi) === null || _ === void 0 ? void 0 : _._internal) === null || A === void 0 ? void 0 : A.refId, schema: this } : (O = this._def.openapi) === null || O === void 0 ? void 0 : O._internal.extendedFrom
          },
          metadata: {}
        }, w;
      };
    }
    return d;
  };
  const e = n.ZodSchema.prototype.optional;
  n.ZodSchema.prototype.optional = function(...o) {
    const u = e.apply(this, o);
    return u._def.openapi = this._def.openapi, u;
  };
  const t = n.ZodSchema.prototype.nullable;
  n.ZodSchema.prototype.nullable = function(...o) {
    const u = t.apply(this, o);
    return u._def.openapi = this._def.openapi, u;
  };
  const r = n.ZodSchema.prototype.default;
  n.ZodSchema.prototype.default = function(...o) {
    const u = r.apply(this, o);
    return u._def.openapi = this._def.openapi, u;
  };
  const i = n.ZodSchema.prototype.describe;
  n.ZodSchema.prototype.describe = function(...o) {
    const [u] = o;
    return i.apply(this, o).openapi({ description: u });
  };
  const s = n.ZodObject.prototype.pick;
  n.ZodObject.prototype.pick = function(...o) {
    const u = s.apply(this, o);
    return u._def.openapi = void 0, u;
  };
  const a = n.ZodObject.prototype.omit;
  n.ZodObject.prototype.omit = function(...o) {
    const u = a.apply(this, o);
    return u._def.openapi = void 0, u;
  };
}
dr.extendZodWithOpenApi = sc;
var hr = {}, ge = {};
Object.defineProperty(ge, "__esModule", { value: !0 });
ge.objectEquals = ge.compact = ge.omitBy = ge.omit = ge.mapValues = ge.isNil = ge.isUndefined = void 0;
function ac(n) {
  return n === void 0;
}
ge.isUndefined = ac;
function pa(n) {
  return n == null;
}
ge.isNil = pa;
function oc(n, e) {
  const t = {};
  return Object.entries(n).forEach(([r, i]) => {
    t[r] = e(i);
  }), t;
}
ge.mapValues = oc;
function uc(n, e) {
  const t = {};
  return Object.entries(n).forEach(([r, i]) => {
    e.some((s) => s === r) || (t[r] = i);
  }), t;
}
ge.omit = uc;
function cc(n, e) {
  const t = {};
  return Object.entries(n).forEach(([r, i]) => {
    e(i, r) || (t[r] = i);
  }), t;
}
ge.omitBy = cc;
function lc(n) {
  return n.filter((e) => !pa(e));
}
ge.compact = lc;
function ma(n, e) {
  if (n == null || e === null || e === void 0)
    return n === e;
  if (n === e || n.valueOf() === e.valueOf())
    return !0;
  if (Array.isArray(n) && (!Array.isArray(e) || n.length !== e.length) || !(n instanceof Object) || !(e instanceof Object))
    return !1;
  const t = Object.keys(n);
  return Object.keys(e).every((r) => t.indexOf(r) !== -1) && t.every((r) => ma(n[r], e[r]));
}
ge.objectEquals = ma;
Object.defineProperty(hr, "__esModule", { value: !0 });
hr.getOpenApiMetadata = void 0;
const Os = ge;
function fc(n) {
  var e, t;
  return (0, Os.omitBy)((t = (e = n._def.openapi) === null || e === void 0 ? void 0 : e.metadata) !== null && t !== void 0 ? t : {}, Os.isNil);
}
hr.getOpenApiMetadata = fc;
var pr = {}, Ye = {};
Object.defineProperty(Ye, "__esModule", { value: !0 });
Ye.UnknownZodTypeError = Ye.MissingParameterDataError = Ye.ConflictError = Ye.ZodToOpenAPIError = void 0;
class mr {
  constructor(e) {
    this.message = e;
  }
}
Ye.ZodToOpenAPIError = mr;
class dc extends mr {
  constructor(e, t) {
    super(e), this.data = t;
  }
}
Ye.ConflictError = dc;
class hc extends mr {
  constructor(e) {
    super(`Missing parameter data, please specify \`${e.missingField}\` and other OpenAPI parameter props using the \`param\` field of \`ZodSchema.openapi\``), this.data = e;
  }
}
Ye.MissingParameterDataError = hc;
class pc extends mr {
  constructor(e) {
    super("Unknown zod object type, please specify `type` and other OpenAPI props using `ZodSchema.openapi`."), this.data = e;
  }
}
Ye.UnknownZodTypeError = pc;
var gr = {};
Object.defineProperty(gr, "__esModule", { value: !0 });
gr.enumInfo = void 0;
function mc(n) {
  const t = Object.keys(n).filter((s) => typeof n[n[s]] != "number").map((s) => n[s]), r = t.filter((s) => typeof s == "number").length, i = r === 0 ? "string" : r === t.length ? "numeric" : "mixed";
  return { values: t, type: i };
}
gr.enumInfo = mc;
var Rn = ne && ne.__rest || function(n, e) {
  var t = {};
  for (var r in n)
    Object.prototype.hasOwnProperty.call(n, r) && e.indexOf(r) < 0 && (t[r] = n[r]);
  if (n != null && typeof Object.getOwnPropertySymbols == "function")
    for (var i = 0, r = Object.getOwnPropertySymbols(n); i < r.length; i++)
      e.indexOf(r[i]) < 0 && Object.prototype.propertyIsEnumerable.call(n, r[i]) && (t[r[i]] = n[r[i]]);
  return t;
};
Object.defineProperty(pr, "__esModule", { value: !0 });
pr.OpenAPIGenerator = void 0;
const ue = ge, nt = Ye, z = Et, gc = gr, As = ["3.0.0", "3.0.1", "3.0.2", "3.0.3", "3.1.0"];
class yc {
  constructor(e, t) {
    this.definitions = e, this.openAPIVersion = t, this.schemaRefs = {}, this.paramRefs = {}, this.pathRefs = {}, this.webhookRefs = {}, this.rawComponents = [], this.openApiVersionSatisfies = (r, i) => As.indexOf(r) >= As.indexOf(i), this.sortDefinitions();
  }
  generateDocument(e) {
    return this.definitions.forEach((t) => this.generateSingle(t)), Object.assign(Object.assign(Object.assign({}, e), { openapi: this.openAPIVersion, components: this.buildComponents(), paths: this.pathRefs }), Object.keys(this.webhookRefs).length && {
      webhooks: this.webhookRefs
    });
  }
  generateComponents() {
    return this.definitions.forEach((e) => this.generateSingle(e)), {
      components: this.buildComponents()
    };
  }
  buildComponents() {
    var e, t;
    const r = {};
    return this.rawComponents.forEach(({ componentType: i, name: s, component: a }) => {
      var o;
      (o = r[i]) !== null && o !== void 0 || (r[i] = {}), r[i][s] = a;
    }), Object.assign(Object.assign({}, r), { schemas: Object.assign(Object.assign({}, (e = r.schemas) !== null && e !== void 0 ? e : {}), this.schemaRefs), parameters: Object.assign(Object.assign({}, (t = r.parameters) !== null && t !== void 0 ? t : {}), this.paramRefs) });
  }
  sortDefinitions() {
    const e = [
      "schema",
      "parameter",
      "component",
      "route",
      "webhook"
    ];
    this.definitions.sort((t, r) => {
      const i = e.findIndex((a) => a === t.type), s = e.findIndex((a) => a === r.type);
      return i - s;
    });
  }
  generateSingle(e) {
    switch (e.type) {
      case "parameter":
        this.generateParameterDefinition(e.schema);
        return;
      case "schema":
        this.generateSchemaDefinition(e.schema);
        return;
      case "route":
        this.generateSingleRoute(e.route);
        return;
      case "webhook":
        this.generateSingleWebhook(e.webhook);
        return;
      case "component":
        this.rawComponents.push(e);
        return;
    }
  }
  generateParameterDefinition(e) {
    const t = this.getInternalMetadata(e), r = this.generateParameter(e);
    return t != null && t.refId && (this.paramRefs[t.refId] = r), r;
  }
  getParameterRef(e, t) {
    var r, i, s, a, o;
    const u = (r = e == null ? void 0 : e.metadata) === null || r === void 0 ? void 0 : r.param, c = !((i = e == null ? void 0 : e._internal) === null || i === void 0) && i.refId ? this.paramRefs[(s = e._internal) === null || s === void 0 ? void 0 : s.refId] : void 0;
    if (!(!(!((a = e == null ? void 0 : e._internal) === null || a === void 0) && a.refId) || !c)) {
      if (u && c.in !== u.in || t != null && t.in && c.in !== t.in)
        throw new nt.ConflictError(`Conflicting location for parameter ${c.name}`, {
          key: "in",
          values: (0, ue.compact)([
            c.in,
            t == null ? void 0 : t.in,
            u == null ? void 0 : u.in
          ])
        });
      if (u && c.name !== u.name || t != null && t.name && c.name !== (t == null ? void 0 : t.name))
        throw new nt.ConflictError("Conflicting names for parameter", {
          key: "name",
          values: (0, ue.compact)([
            c.name,
            t == null ? void 0 : t.name,
            u == null ? void 0 : u.name
          ])
        });
      return {
        $ref: `#/components/parameters/${(o = e._internal) === null || o === void 0 ? void 0 : o.refId}`
      };
    }
  }
  generateInlineParameters(e, t) {
    var r;
    const i = this.getMetadata(e), s = (r = i == null ? void 0 : i.metadata) === null || r === void 0 ? void 0 : r.param, a = this.getParameterRef(i, { in: t });
    if (a)
      return [a];
    if ((0, z.isZodType)(e, "ZodObject")) {
      const o = e._def.shape();
      return Object.entries(o).map(([c, d]) => {
        var f, h;
        const p = this.getMetadata(d), g = this.getParameterRef(p, {
          in: t,
          name: c
        });
        if (g)
          return g;
        const _ = (f = p == null ? void 0 : p.metadata) === null || f === void 0 ? void 0 : f.param;
        if (_ != null && _.name && _.name !== c)
          throw new nt.ConflictError("Conflicting names for parameter", {
            key: "name",
            values: [c, _.name]
          });
        if (_ != null && _.in && _.in !== t)
          throw new nt.ConflictError(`Conflicting location for parameter ${(h = _.name) !== null && h !== void 0 ? h : c}`, {
            key: "in",
            values: [t, _.in]
          });
        return this.generateParameter(d.openapi({ param: { name: c, in: t } }));
      });
    }
    if (s != null && s.in && s.in !== t)
      throw new nt.ConflictError(`Conflicting location for parameter ${s.name}`, {
        key: "in",
        values: [t, s.in]
      });
    return [
      this.generateParameter(e.openapi({ param: { in: t } }))
    ];
  }
  generateParameter(e) {
    var t;
    const r = this.getMetadata(e), i = (t = r == null ? void 0 : r.metadata) === null || t === void 0 ? void 0 : t.param, s = i == null ? void 0 : i.name, a = i == null ? void 0 : i.in;
    if (!s)
      throw new nt.MissingParameterDataError({ missingField: "name" });
    if (!a)
      throw new nt.MissingParameterDataError({
        missingField: "in",
        paramName: s
      });
    const o = !this.isOptionalSchema(e) && !e.isNullable(), u = this.generateSimpleSchema(e);
    return Object.assign({
      in: a,
      name: s,
      schema: u,
      required: o
    }, i ? this.buildParameterMetadata(i) : {});
  }
  /**
   * Generates an OpenAPI SchemaObject or a ReferenceObject with all the provided metadata applied
   */
  generateSimpleSchema(e) {
    var t, r, i, s;
    const a = this.unwrapChained(e), o = (t = e._def.openapi) !== null && t !== void 0 ? t : a._def.openapi, u = this.getDefaultValue(e), c = (r = o == null ? void 0 : o._internal) === null || r === void 0 ? void 0 : r.refId;
    if (c && this.schemaRefs[c]) {
      const f = this.schemaRefs[c], h = {
        $ref: this.generateSchemaRef(c)
      }, p = (0, ue.omitBy)(
        // We do not want to check our "custom" metadata fields. We only want
        // the plain metadata for a SchemaObject.
        this.buildSchemaMetadata((i = o == null ? void 0 : o.metadata) !== null && i !== void 0 ? i : {}),
        (A, O) => A === void 0 || (0, ue.objectEquals)(A, f[O])
      ), g = p.type ? {} : (0, ue.omitBy)(this.constructReferencedOpenAPISchema(e, a, u), (A, O) => A === void 0 || (0, ue.objectEquals)(A, f[O])), _ = this.applySchemaMetadata(g, p);
      return Object.keys(_).length > 0 ? {
        allOf: [h, _]
      } : h;
    }
    const d = !((s = o == null ? void 0 : o.metadata) === null || s === void 0) && s.type ? {
      type: o == null ? void 0 : o.metadata.type
    } : this.toOpenAPISchema(a, e.isNullable(), u);
    return o != null && o.metadata ? this.applySchemaMetadata(d, o.metadata) : (0, ue.omitBy)(d, ue.isNil);
  }
  generateInnerSchema(e, t) {
    const r = this.generateSimpleSchema(e);
    return "$ref" in r && r.$ref ? r : t ? this.applySchemaMetadata(r, t) : r;
  }
  generateSchemaDefinition(e) {
    var t;
    const r = this.getMetadata(e), i = (t = r == null ? void 0 : r._internal) === null || t === void 0 ? void 0 : t.refId, s = this.generateSimpleSchema(e), a = r != null && r.metadata ? this.applySchemaMetadata(s, r.metadata) : s;
    return i && (this.schemaRefs[i] = a), a;
  }
  generateSchemaRef(e) {
    return `#/components/schemas/${e}`;
  }
  getRequestBody(e) {
    if (!e)
      return;
    const { content: t } = e, r = Rn(e, ["content"]), i = this.getBodyContent(t);
    return Object.assign(Object.assign({}, r), { content: i });
  }
  getParameters(e) {
    if (!e)
      return [];
    const t = e.query ? this.generateInlineParameters(e.query, "query") : [], r = e.params ? this.generateInlineParameters(e.params, "path") : [], { headers: i } = e, s = i ? (0, z.isZodType)(i, "ZodObject") ? this.generateInlineParameters(i, "header") : i.flatMap((a) => this.generateInlineParameters(a, "header")) : [];
    return [...r, ...t, ...s];
  }
  generatePath(e) {
    const { method: t, path: r, request: i, responses: s } = e, a = Rn(e, ["method", "path", "request", "responses"]), o = (0, ue.mapValues)(s, (f) => this.getResponse(f)), u = this.getParameters(i), c = this.getRequestBody(i == null ? void 0 : i.body);
    return {
      [t]: Object.assign(Object.assign(Object.assign(Object.assign({}, a), u.length > 0 ? { parameters: u } : {}), c ? { requestBody: c } : {}), { responses: o })
    };
  }
  generateSingleRoute(e) {
    const t = this.generatePath(e);
    return this.pathRefs[e.path] = Object.assign(Object.assign({}, this.pathRefs[e.path]), t), t;
  }
  generateSingleWebhook(e) {
    const t = this.generatePath(e);
    return this.webhookRefs[e.path] = Object.assign(Object.assign({}, this.webhookRefs[e.path]), t), t;
  }
  getResponse(e) {
    var { content: t } = e, r = Rn(e, ["content"]);
    const i = t ? { content: this.getBodyContent(t) } : {};
    return Object.assign(Object.assign({}, r), i);
  }
  getBodyContent(e) {
    return (0, ue.mapValues)(e, (t) => {
      if (!(0, z.isAnyZodType)(t.schema))
        return t;
      const { schema: r } = t, i = Rn(t, ["schema"]), s = this.generateInnerSchema(r);
      return Object.assign({ schema: s }, i);
    });
  }
  getZodStringCheck(e, t) {
    return e._def.checks.find((r) => r.kind === t);
  }
  /**
   * Attempts to map Zod strings to known formats
   * https://json-schema.org/understanding-json-schema/reference/string.html#built-in-formats
   */
  mapStringFormat(e) {
    if (e.isUUID)
      return "uuid";
    if (e.isEmail)
      return "email";
    if (e.isURL)
      return "uri";
    if (e.isDatetime)
      return "date-time";
  }
  mapDiscriminator(e, t) {
    if (e.some((i) => {
      var s, a;
      return ((a = (s = i._def.openapi) === null || s === void 0 ? void 0 : s._internal) === null || a === void 0 ? void 0 : a.refId) === void 0;
    }))
      return;
    const r = {};
    return e.forEach((i) => {
      var s, a, o;
      const u = (a = (s = i._def.openapi) === null || s === void 0 ? void 0 : s._internal) === null || a === void 0 ? void 0 : a.refId, c = (o = i.shape) === null || o === void 0 ? void 0 : o[t];
      if ((0, z.isZodType)(c, "ZodEnum")) {
        c._def.values.forEach((f) => {
          r[f] = this.generateSchemaRef(u);
        });
        return;
      }
      const d = c == null ? void 0 : c._def.value;
      if (typeof d != "string")
        throw new Error(`Discriminator ${t} could not be found in one of the values of a discriminated union`);
      r[d] = this.generateSchemaRef(u);
    }), {
      propertyName: t,
      mapping: r
    };
  }
  mapNullableOfArray(e, t) {
    return t ? this.openApiVersionSatisfies(this.openAPIVersion, "3.1.0") ? [...e, { type: "null" }] : [...e, { nullable: !0 }] : e;
  }
  mapNullableType(e, t) {
    return t && this.openApiVersionSatisfies(this.openAPIVersion, "3.1.0") ? {
      type: Array.isArray(e) ? [...e, "null"] : [e, "null"]
    } : {
      type: e,
      nullable: t ? !0 : void 0
    };
  }
  getNumberChecks(e) {
    return Object.assign({}, ...e.map((t) => {
      switch (t.kind) {
        case "min":
          return t.inclusive ? { minimum: t.value } : this.openApiVersionSatisfies(this.openAPIVersion, "3.1.0") ? { exclusiveMinimum: t.value } : { minimum: t.value, exclusiveMinimum: !0 };
        case "max":
          return t.inclusive ? { maximum: t.value } : this.openApiVersionSatisfies(this.openAPIVersion, "3.1.0") ? { exclusiveMaximum: t.value } : { maximum: t.value, exclusiveMaximum: !0 };
        default:
          return {};
      }
    }));
  }
  constructReferencedOpenAPISchema(e, t, r) {
    var i, s;
    const a = e.isNullable(), o = (i = e._def.openapi) !== null && i !== void 0 ? i : t._def.openapi;
    return !((s = o == null ? void 0 : o.metadata) === null || s === void 0) && s.type ? this.mapNullableType(o.metadata.type, a) : this.toOpenAPISchema(t, a, r);
  }
  toOpenAPISchema(e, t, r) {
    var i, s, a, o, u, c;
    if ((0, z.isZodType)(e, "ZodNull"))
      return { type: "null" };
    if ((0, z.isZodType)(e, "ZodString")) {
      const f = this.getZodStringCheck(e, "regex");
      return Object.assign(Object.assign({}, this.mapNullableType("string", t)), {
        // FIXME: https://github.com/colinhacks/zod/commit/d78047e9f44596a96d637abb0ce209cd2732d88c
        minLength: Number.isFinite(e.minLength) && (i = e.minLength) !== null && i !== void 0 ? i : void 0,
        maxLength: Number.isFinite(e.maxLength) && (s = e.maxLength) !== null && s !== void 0 ? s : void 0,
        format: this.mapStringFormat(e),
        pattern: f == null ? void 0 : f.regex.source,
        default: r
      });
    }
    if ((0, z.isZodType)(e, "ZodNumber"))
      return Object.assign(Object.assign(Object.assign({}, this.mapNullableType(e.isInt ? "integer" : "number", t)), this.getNumberChecks(e._def.checks)), { default: r });
    if ((0, z.isZodType)(e, "ZodBoolean"))
      return Object.assign(Object.assign({}, this.mapNullableType("boolean", t)), { default: r });
    if ((0, z.isZodType)(e, "ZodEffects") && (e._def.effect.type === "refinement" || e._def.effect.type === "preprocess")) {
      const f = e._def.schema;
      return this.generateInnerSchema(f);
    }
    if ((0, z.isZodType)(e, "ZodLiteral"))
      return Object.assign(Object.assign({}, this.mapNullableType(typeof e._def.value, t)), { enum: [e._def.value], default: r });
    if ((0, z.isZodType)(e, "ZodEnum"))
      return Object.assign(Object.assign({}, this.mapNullableType("string", t)), { enum: e._def.values, default: r });
    if ((0, z.isZodType)(e, "ZodNativeEnum")) {
      const { type: f, values: h } = (0, gc.enumInfo)(e._def.values);
      if (f === "mixed")
        throw new nt.ZodToOpenAPIError("Enum has mixed string and number values, please specify the OpenAPI type manually");
      return Object.assign(Object.assign({}, this.mapNullableType(f === "numeric" ? "integer" : "string", t)), { enum: h, default: r });
    }
    if ((0, z.isZodType)(e, "ZodObject"))
      return this.toOpenAPIObjectSchema(e, t, r);
    if ((0, z.isZodType)(e, "ZodArray")) {
      const f = e._def.type;
      return Object.assign(Object.assign({}, this.mapNullableType("array", t)), { items: this.generateInnerSchema(f), minItems: (a = e._def.minLength) === null || a === void 0 ? void 0 : a.value, maxItems: (o = e._def.maxLength) === null || o === void 0 ? void 0 : o.value, default: r });
    }
    if ((0, z.isZodType)(e, "ZodUnion")) {
      const f = this.flattenUnionTypes(e);
      return {
        anyOf: this.mapNullableOfArray(f.map((h) => this.generateInnerSchema(h)), t),
        default: r
      };
    }
    if ((0, z.isZodType)(e, "ZodDiscriminatedUnion")) {
      const f = [...e.options.values()], h = f.map((p) => this.generateInnerSchema(p));
      return t ? {
        oneOf: this.mapNullableOfArray(h, t),
        default: r
      } : {
        oneOf: h,
        discriminator: this.mapDiscriminator(f, e.discriminator),
        default: r
      };
    }
    if ((0, z.isZodType)(e, "ZodIntersection")) {
      const h = {
        allOf: this.flattenIntersectionTypes(e).map((p) => this.generateInnerSchema(p))
      };
      return t ? {
        anyOf: this.mapNullableOfArray([h], t),
        default: r
      } : Object.assign(Object.assign({}, h), { default: r });
    }
    if ((0, z.isZodType)(e, "ZodRecord")) {
      const f = e._def.valueType;
      return Object.assign(Object.assign({}, this.mapNullableType("object", t)), { additionalProperties: this.generateInnerSchema(f), default: r });
    }
    if ((0, z.isZodType)(e, "ZodUnknown"))
      return {};
    if ((0, z.isZodType)(e, "ZodDate"))
      return Object.assign(Object.assign({}, this.mapNullableType("string", t)), { default: r });
    const d = (c = (u = this.getMetadata(e)) === null || u === void 0 ? void 0 : u._internal) === null || c === void 0 ? void 0 : c.refId;
    throw new nt.UnknownZodTypeError({
      currentSchema: e._def,
      schemaName: d
    });
  }
  isOptionalSchema(e) {
    return (0, z.isZodType)(e, "ZodEffects") ? this.isOptionalSchema(e._def.schema) : (0, z.isZodType)(e, "ZodDefault") ? this.isOptionalSchema(e._def.innerType) : e.isOptional();
  }
  getDefaultValue(e) {
    if ((0, z.isZodType)(e, "ZodOptional") || (0, z.isZodType)(e, "ZodNullable"))
      return this.getDefaultValue(e.unwrap());
    if ((0, z.isZodType)(e, "ZodEffects"))
      return this.getDefaultValue(e._def.schema);
    if ((0, z.isZodType)(e, "ZodDefault"))
      return e._def.defaultValue();
  }
  requiredKeysOf(e) {
    return Object.entries(e._def.shape()).filter(([t, r]) => !this.isOptionalSchema(r)).map(([t, r]) => t);
  }
  toOpenAPIObjectSchema(e, t, r) {
    var i, s;
    const a = (s = (i = e._def.openapi) === null || i === void 0 ? void 0 : i._internal) === null || s === void 0 ? void 0 : s.extendedFrom, o = a == null ? void 0 : a.schema._def.shape(), u = e._def.shape(), c = a ? this.requiredKeysOf(a.schema) : [], d = this.requiredKeysOf(e), f = o ? (0, ue.mapValues)(o, (O) => this.generateInnerSchema(O)) : {}, h = (0, ue.mapValues)(u, (O) => this.generateInnerSchema(O)), p = Object.fromEntries(Object.entries(h).filter(([O, w]) => !(0, ue.objectEquals)(f[O], w))), g = d.filter((O) => !c.includes(O)), _ = e._def.unknownKeys, A = Object.assign(Object.assign(Object.assign(Object.assign({}, this.mapNullableType("object", t)), { default: r, properties: p }), g.length > 0 ? { required: g } : {}), _ === "strict" ? { additionalProperties: !1 } : {});
    return a ? {
      allOf: [
        { $ref: `#/components/schemas/${a.refId}` },
        A
      ]
    } : A;
  }
  flattenUnionTypes(e) {
    return (0, z.isZodType)(e, "ZodUnion") ? e._def.options.flatMap((r) => this.flattenUnionTypes(r)) : [e];
  }
  flattenIntersectionTypes(e) {
    if (!(0, z.isZodType)(e, "ZodIntersection"))
      return [e];
    const t = this.flattenIntersectionTypes(e._def.left), r = this.flattenIntersectionTypes(e._def.right);
    return [...t, ...r];
  }
  unwrapChained(e) {
    return (0, z.isZodType)(e, "ZodOptional") || (0, z.isZodType)(e, "ZodNullable") || (0, z.isZodType)(e, "ZodBranded") ? this.unwrapChained(e.unwrap()) : (0, z.isZodType)(e, "ZodDefault") ? this.unwrapChained(e._def.innerType) : (0, z.isZodType)(e, "ZodEffects") && e._def.effect.type === "refinement" ? this.unwrapChained(e._def.schema) : e;
  }
  /**
   * A method that omits all custom keys added to the regular OpenAPI
   * metadata properties
   */
  buildSchemaMetadata(e) {
    return (0, ue.omitBy)((0, ue.omit)(e, ["param"]), ue.isNil);
  }
  buildParameterMetadata(e) {
    return (0, ue.omitBy)(e, ue.isNil);
  }
  getMetadata(e) {
    const t = this.unwrapChained(e);
    return e._def.openapi ? e._def.openapi : t._def.openapi;
  }
  getInternalMetadata(e) {
    const t = this.unwrapChained(e), r = e._def.openapi ? e._def.openapi : t._def.openapi;
    return r == null ? void 0 : r._internal;
  }
  applySchemaMetadata(e, t) {
    return (0, ue.omitBy)(Object.assign(Object.assign({}, e), this.buildSchemaMetadata(t)), ue.isNil);
  }
}
pr.OpenAPIGenerator = yc;
var yr = {};
Object.defineProperty(yr, "__esModule", { value: !0 });
yr.OpenAPIRegistry = void 0;
class vc {
  constructor(e) {
    this.parents = e, this._definitions = [];
  }
  get definitions() {
    var e, t;
    return [...(t = (e = this.parents) === null || e === void 0 ? void 0 : e.flatMap((i) => i.definitions)) !== null && t !== void 0 ? t : [], ...this._definitions];
  }
  /**
   * Registers a new component schema under /components/schemas/${name}
   */
  register(e, t) {
    const r = this.schemaWithRefId(e, t);
    return this._definitions.push({ type: "schema", schema: r }), r;
  }
  /**
   * Registers a new parameter schema under /components/parameters/${name}
   */
  registerParameter(e, t) {
    var r, i, s;
    const a = this.schemaWithRefId(e, t), o = (r = a._def.openapi) === null || r === void 0 ? void 0 : r.metadata, u = a.openapi(Object.assign(Object.assign({}, o), { param: Object.assign(Object.assign({}, o == null ? void 0 : o.param), { name: (s = (i = o == null ? void 0 : o.param) === null || i === void 0 ? void 0 : i.name) !== null && s !== void 0 ? s : e }) }));
    return this._definitions.push({
      type: "parameter",
      schema: u
    }), u;
  }
  /**
   * Registers a new path that would be generated under paths:
   */
  registerPath(e) {
    this._definitions.push({
      type: "route",
      route: e
    });
  }
  /**
   * Registers a new webhook that would be generated under webhooks:
   */
  registerWebhook(e) {
    this._definitions.push({
      type: "webhook",
      webhook: e
    });
  }
  /**
   * Registers a raw OpenAPI component. Use this if you have a simple object instead of a Zod schema.
   *
   * @param type The component type, e.g. `schemas`, `responses`, `securitySchemes`, etc.
   * @param name The name of the object, it is the key under the component
   *             type in the resulting OpenAPI document
   * @param component The actual object to put there
   */
  registerComponent(e, t, r) {
    return this._definitions.push({
      type: "component",
      componentType: e,
      name: t,
      component: r
    }), {
      name: t,
      ref: { $ref: `#/components/${e}/${t}` }
    };
  }
  schemaWithRefId(e, t) {
    return t.internal_openapi({ refId: e });
  }
}
yr.OpenAPIRegistry = vc;
class ht {
  static isValidExtension(e) {
    return /^x-/.test(e);
  }
  getExtension(e) {
    if (!ht.isValidExtension(e))
      throw new Error(`Invalid specification extension: '${e}'. Extensions must start with prefix 'x-`);
    return this[e] ? this[e] : null;
  }
  addExtension(e, t) {
    if (!ht.isValidExtension(e))
      throw new Error(`Invalid specification extension: '${e}'. Extensions must start with prefix 'x-`);
    this[e] = t;
  }
  listExtensions() {
    const e = [];
    for (const t in this)
      Object.prototype.hasOwnProperty.call(this, t) && ht.isValidExtension(t) && e.push(t);
    return e;
  }
}
function bc(n, e) {
  if (ht.isValidExtension(e))
    return n[e];
}
function _c(n, e, t) {
  ht.isValidExtension(e) && (n[e] = t);
}
function Oc(n, e) {
  if (!ht.isValidExtension(e))
    return n[e];
}
function Ac(n) {
  return Object.prototype.hasOwnProperty.call(n, "$ref");
}
function wc(n) {
  return !Object.prototype.hasOwnProperty.call(n, "$ref");
}
class Sc {
  constructor(e, t) {
    this.url = e, this.description = t, this.variables = {};
  }
  addVariable(e, t) {
    this.variables[e] = t;
  }
}
class Ec {
  constructor(e, t, r) {
    this.default = e, this.enum = t, this.description = r;
  }
}
const bi = Symbol.for("yaml.alias"), ni = Symbol.for("yaml.document"), pt = Symbol.for("yaml.map"), ga = Symbol.for("yaml.pair"), vr = Symbol.for("yaml.scalar"), Gt = Symbol.for("yaml.seq"), Ne = Symbol.for("yaml.node.type"), Yt = (n) => !!n && typeof n == "object" && n[Ne] === bi, _i = (n) => !!n && typeof n == "object" && n[Ne] === ni, Mn = (n) => !!n && typeof n == "object" && n[Ne] === pt, fe = (n) => !!n && typeof n == "object" && n[Ne] === ga, oe = (n) => !!n && typeof n == "object" && n[Ne] === vr, In = (n) => !!n && typeof n == "object" && n[Ne] === Gt;
function ce(n) {
  if (n && typeof n == "object")
    switch (n[Ne]) {
      case pt:
      case Gt:
        return !0;
    }
  return !1;
}
function he(n) {
  if (n && typeof n == "object")
    switch (n[Ne]) {
      case bi:
      case pt:
      case vr:
      case Gt:
        return !0;
    }
  return !1;
}
const kc = (n) => (oe(n) || ce(n)) && !!n.anchor;
class Oi {
  constructor(e) {
    Object.defineProperty(this, Ne, { value: e });
  }
  /** Create a copy of this node.  */
  clone() {
    const e = Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this));
    return this.range && (e.range = this.range.slice()), e;
  }
}
const At = Symbol("break visit"), Tc = Symbol("skip children"), sn = Symbol("remove node");
function kt(n, e) {
  const t = Mc(e);
  _i(n) ? Rt(null, n.contents, t, Object.freeze([n])) === sn && (n.contents = null) : Rt(null, n, t, Object.freeze([]));
}
kt.BREAK = At;
kt.SKIP = Tc;
kt.REMOVE = sn;
function Rt(n, e, t, r) {
  const i = Ic(n, e, t, r);
  if (he(i) || fe(i))
    return Pc(n, r, i), Rt(n, i, t, r);
  if (typeof i != "symbol") {
    if (ce(e)) {
      r = Object.freeze(r.concat(e));
      for (let s = 0; s < e.items.length; ++s) {
        const a = Rt(s, e.items[s], t, r);
        if (typeof a == "number")
          s = a - 1;
        else {
          if (a === At)
            return At;
          a === sn && (e.items.splice(s, 1), s -= 1);
        }
      }
    } else if (fe(e)) {
      r = Object.freeze(r.concat(e));
      const s = Rt("key", e.key, t, r);
      if (s === At)
        return At;
      s === sn && (e.key = null);
      const a = Rt("value", e.value, t, r);
      if (a === At)
        return At;
      a === sn && (e.value = null);
    }
  }
  return i;
}
function Mc(n) {
  return typeof n == "object" && (n.Collection || n.Node || n.Value) ? Object.assign({
    Alias: n.Node,
    Map: n.Node,
    Scalar: n.Node,
    Seq: n.Node
  }, n.Value && {
    Map: n.Value,
    Scalar: n.Value,
    Seq: n.Value
  }, n.Collection && {
    Map: n.Collection,
    Seq: n.Collection
  }, n) : n;
}
function Ic(n, e, t, r) {
  var i, s, a, o, u;
  if (typeof t == "function")
    return t(n, e, r);
  if (Mn(e))
    return (i = t.Map) == null ? void 0 : i.call(t, n, e, r);
  if (In(e))
    return (s = t.Seq) == null ? void 0 : s.call(t, n, e, r);
  if (fe(e))
    return (a = t.Pair) == null ? void 0 : a.call(t, n, e, r);
  if (oe(e))
    return (o = t.Scalar) == null ? void 0 : o.call(t, n, e, r);
  if (Yt(e))
    return (u = t.Alias) == null ? void 0 : u.call(t, n, e, r);
}
function Pc(n, e, t) {
  const r = e[e.length - 1];
  if (ce(r))
    r.items[n] = t;
  else if (fe(r))
    n === "key" ? r.key = t : r.value = t;
  else if (_i(r))
    r.contents = t;
  else {
    const i = Yt(r) ? "alias" : "scalar";
    throw new Error(`Cannot replace node with ${i} parent`);
  }
}
const Nc = {
  "!": "%21",
  ",": "%2C",
  "[": "%5B",
  "]": "%5D",
  "{": "%7B",
  "}": "%7D"
}, Lc = (n) => n.replace(/[!,[\]{}]/g, (e) => Nc[e]);
class Ae {
  constructor(e, t) {
    this.docStart = null, this.docEnd = !1, this.yaml = Object.assign({}, Ae.defaultYaml, e), this.tags = Object.assign({}, Ae.defaultTags, t);
  }
  clone() {
    const e = new Ae(this.yaml, this.tags);
    return e.docStart = this.docStart, e;
  }
  /**
   * During parsing, get a Directives instance for the current document and
   * update the stream state according to the current version's spec.
   */
  atDocument() {
    const e = new Ae(this.yaml, this.tags);
    switch (this.yaml.version) {
      case "1.1":
        this.atNextDocument = !0;
        break;
      case "1.2":
        this.atNextDocument = !1, this.yaml = {
          explicit: Ae.defaultYaml.explicit,
          version: "1.2"
        }, this.tags = Object.assign({}, Ae.defaultTags);
        break;
    }
    return e;
  }
  /**
   * @param onError - May be called even if the action was successful
   * @returns `true` on success
   */
  add(e, t) {
    this.atNextDocument && (this.yaml = { explicit: Ae.defaultYaml.explicit, version: "1.1" }, this.tags = Object.assign({}, Ae.defaultTags), this.atNextDocument = !1);
    const r = e.trim().split(/[ \t]+/), i = r.shift();
    switch (i) {
      case "%TAG": {
        if (r.length !== 2 && (t(0, "%TAG directive should contain exactly two parts"), r.length < 2))
          return !1;
        const [s, a] = r;
        return this.tags[s] = a, !0;
      }
      case "%YAML": {
        if (this.yaml.explicit = !0, r.length !== 1)
          return t(0, "%YAML directive should contain exactly one part"), !1;
        const [s] = r;
        if (s === "1.1" || s === "1.2")
          return this.yaml.version = s, !0;
        {
          const a = /^\d+\.\d+$/.test(s);
          return t(6, `Unsupported YAML version ${s}`, a), !1;
        }
      }
      default:
        return t(0, `Unknown directive ${i}`, !0), !1;
    }
  }
  /**
   * Resolves a tag, matching handles to those defined in %TAG directives.
   *
   * @returns Resolved tag, which may also be the non-specific tag `'!'` or a
   *   `'!local'` tag, or `null` if unresolvable.
   */
  tagName(e, t) {
    if (e === "!")
      return "!";
    if (e[0] !== "!")
      return t(`Not a valid tag: ${e}`), null;
    if (e[1] === "<") {
      const a = e.slice(2, -1);
      return a === "!" || a === "!!" ? (t(`Verbatim tags aren't resolved, so ${e} is invalid.`), null) : (e[e.length - 1] !== ">" && t("Verbatim tags must end with a >"), a);
    }
    const [, r, i] = e.match(/^(.*!)([^!]*)$/);
    i || t(`The ${e} tag has no suffix`);
    const s = this.tags[r];
    return s ? s + decodeURIComponent(i) : r === "!" ? e : (t(`Could not resolve tag: ${e}`), null);
  }
  /**
   * Given a fully resolved tag, returns its printable string form,
   * taking into account current tag prefixes and defaults.
   */
  tagString(e) {
    for (const [t, r] of Object.entries(this.tags))
      if (e.startsWith(r))
        return t + Lc(e.substring(r.length));
    return e[0] === "!" ? e : `!<${e}>`;
  }
  toString(e) {
    const t = this.yaml.explicit ? [`%YAML ${this.yaml.version || "1.2"}`] : [], r = Object.entries(this.tags);
    let i;
    if (e && r.length > 0 && he(e.contents)) {
      const s = {};
      kt(e.contents, (a, o) => {
        he(o) && o.tag && (s[o.tag] = !0);
      }), i = Object.keys(s);
    } else
      i = [];
    for (const [s, a] of r)
      s === "!!" && a === "tag:yaml.org,2002:" || (!e || i.some((o) => o.startsWith(a))) && t.push(`%TAG ${s} ${a}`);
    return t.join(`
`);
  }
}
Ae.defaultYaml = { explicit: !1, version: "1.2" };
Ae.defaultTags = { "!!": "tag:yaml.org,2002:" };
function ya(n) {
  if (/[\x00-\x19\s,[\]{}]/.test(n)) {
    const t = `Anchor must not contain whitespace or control characters: ${JSON.stringify(n)}`;
    throw new Error(t);
  }
  return !0;
}
function va(n) {
  const e = /* @__PURE__ */ new Set();
  return kt(n, {
    Value(t, r) {
      r.anchor && e.add(r.anchor);
    }
  }), e;
}
function ba(n, e) {
  for (let t = 1; ; ++t) {
    const r = `${n}${t}`;
    if (!e.has(r))
      return r;
  }
}
function Dc(n, e) {
  const t = [], r = /* @__PURE__ */ new Map();
  let i = null;
  return {
    onAnchor: (s) => {
      t.push(s), i || (i = va(n));
      const a = ba(e, i);
      return i.add(a), a;
    },
    /**
     * With circular references, the source node is only resolved after all
     * of its child nodes are. This is why anchors are set only after all of
     * the nodes have been created.
     */
    setAnchors: () => {
      for (const s of t) {
        const a = r.get(s);
        if (typeof a == "object" && a.anchor && (oe(a.node) || ce(a.node)))
          a.node.anchor = a.anchor;
        else {
          const o = new Error("Failed to resolve repeated object (this should not happen)");
          throw o.source = s, o;
        }
      }
    },
    sourceObjects: r
  };
}
class _a extends Oi {
  constructor(e) {
    super(bi), this.source = e, Object.defineProperty(this, "tag", {
      set() {
        throw new Error("Alias nodes cannot have tags");
      }
    });
  }
  /**
   * Resolve the value of this alias within `doc`, finding the last
   * instance of the `source` anchor before this node.
   */
  resolve(e) {
    let t;
    return kt(e, {
      Node: (r, i) => {
        if (i === this)
          return kt.BREAK;
        i.anchor === this.source && (t = i);
      }
    }), t;
  }
  toJSON(e, t) {
    if (!t)
      return { source: this.source };
    const { anchors: r, doc: i, maxAliasCount: s } = t, a = this.resolve(i);
    if (!a) {
      const u = `Unresolved alias (the anchor must be set before the alias): ${this.source}`;
      throw new ReferenceError(u);
    }
    const o = r.get(a);
    if (!o || o.res === void 0) {
      const u = "This should not happen: Alias anchor was not resolved?";
      throw new ReferenceError(u);
    }
    if (s >= 0 && (o.count += 1, o.aliasCount === 0 && (o.aliasCount = Hn(i, a, r)), o.count * o.aliasCount > s)) {
      const u = "Excessive alias count indicates a resource exhaustion attack";
      throw new ReferenceError(u);
    }
    return o.res;
  }
  toString(e, t, r) {
    const i = `*${this.source}`;
    if (e) {
      if (ya(this.source), e.options.verifyAliasOrder && !e.anchors.has(this.source)) {
        const s = `Unresolved alias (the anchor must be set before the alias): ${this.source}`;
        throw new Error(s);
      }
      if (e.implicitKey)
        return `${i} `;
    }
    return i;
  }
}
function Hn(n, e, t) {
  if (Yt(e)) {
    const r = e.resolve(n), i = t && r && t.get(r);
    return i ? i.count * i.aliasCount : 0;
  } else if (ce(e)) {
    let r = 0;
    for (const i of e.items) {
      const s = Hn(n, i, t);
      s > r && (r = s);
    }
    return r;
  } else if (fe(e)) {
    const r = Hn(n, e.key, t), i = Hn(n, e.value, t);
    return Math.max(r, i);
  }
  return 1;
}
function Ke(n, e, t) {
  if (Array.isArray(n))
    return n.map((r, i) => Ke(r, String(i), t));
  if (n && typeof n.toJSON == "function") {
    if (!t || !kc(n))
      return n.toJSON(e, t);
    const r = { aliasCount: 0, count: 1, res: void 0 };
    t.anchors.set(n, r), t.onCreate = (s) => {
      r.res = s, delete t.onCreate;
    };
    const i = n.toJSON(e, t);
    return t.onCreate && t.onCreate(i), i;
  }
  return typeof n == "bigint" && !(t != null && t.keep) ? Number(n) : n;
}
const Oa = (n) => !n || typeof n != "function" && typeof n != "object";
class Q extends Oi {
  constructor(e) {
    super(vr), this.value = e;
  }
  toJSON(e, t) {
    return t != null && t.keep ? this.value : Ke(this.value, e, t);
  }
  toString() {
    return String(this.value);
  }
}
Q.BLOCK_FOLDED = "BLOCK_FOLDED";
Q.BLOCK_LITERAL = "BLOCK_LITERAL";
Q.PLAIN = "PLAIN";
Q.QUOTE_DOUBLE = "QUOTE_DOUBLE";
Q.QUOTE_SINGLE = "QUOTE_SINGLE";
const Cc = "tag:yaml.org,2002:";
function Rc(n, e, t) {
  if (e) {
    const r = t.filter((s) => s.tag === e), i = r.find((s) => !s.format) ?? r[0];
    if (!i)
      throw new Error(`Tag ${e} not found`);
    return i;
  }
  return t.find((r) => {
    var i;
    return ((i = r.identify) == null ? void 0 : i.call(r, n)) && !r.format;
  });
}
function dn(n, e, t) {
  var f, h;
  if (_i(n) && (n = n.contents), he(n))
    return n;
  if (fe(n)) {
    const p = (h = (f = t.schema[pt]).createNode) == null ? void 0 : h.call(f, t.schema, null, t);
    return p.items.push(n), p;
  }
  (n instanceof String || n instanceof Number || n instanceof Boolean || typeof BigInt < "u" && n instanceof BigInt) && (n = n.valueOf());
  const { aliasDuplicateObjects: r, onAnchor: i, onTagObj: s, schema: a, sourceObjects: o } = t;
  let u;
  if (r && n && typeof n == "object") {
    if (u = o.get(n), u)
      return u.anchor || (u.anchor = i(n)), new _a(u.anchor);
    u = { anchor: null, node: null }, o.set(n, u);
  }
  e != null && e.startsWith("!!") && (e = Cc + e.slice(2));
  let c = Rc(n, e, a.tags);
  if (!c) {
    if (n && typeof n.toJSON == "function" && (n = n.toJSON()), !n || typeof n != "object") {
      const p = new Q(n);
      return u && (u.node = p), p;
    }
    c = n instanceof Map ? a[pt] : Symbol.iterator in Object(n) ? a[Gt] : a[pt];
  }
  s && (s(c), delete t.onTagObj);
  const d = c != null && c.createNode ? c.createNode(t.schema, n, t) : new Q(n);
  return e && (d.tag = e), u && (u.node = d), d;
}
function Qn(n, e, t) {
  let r = t;
  for (let i = e.length - 1; i >= 0; --i) {
    const s = e[i];
    if (typeof s == "number" && Number.isInteger(s) && s >= 0) {
      const a = [];
      a[s] = r, r = a;
    } else
      r = /* @__PURE__ */ new Map([[s, r]]);
  }
  return dn(r, void 0, {
    aliasDuplicateObjects: !1,
    keepUndefined: !1,
    onAnchor: () => {
      throw new Error("This should not happen, please report a bug.");
    },
    schema: n,
    sourceObjects: /* @__PURE__ */ new Map()
  });
}
const Xt = (n) => n == null || typeof n == "object" && !!n[Symbol.iterator]().next().done;
class br extends Oi {
  constructor(e, t) {
    super(e), Object.defineProperty(this, "schema", {
      value: t,
      configurable: !0,
      enumerable: !1,
      writable: !0
    });
  }
  /**
   * Create a copy of this collection.
   *
   * @param schema - If defined, overwrites the original's schema
   */
  clone(e) {
    const t = Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this));
    return e && (t.schema = e), t.items = t.items.map((r) => he(r) || fe(r) ? r.clone(e) : r), this.range && (t.range = this.range.slice()), t;
  }
  /**
   * Adds a value to the collection. For `!!map` and `!!omap` the value must
   * be a Pair instance or a `{ key, value }` object, which may not have a key
   * that already exists in the map.
   */
  addIn(e, t) {
    if (Xt(e))
      this.add(t);
    else {
      const [r, ...i] = e, s = this.get(r, !0);
      if (ce(s))
        s.addIn(i, t);
      else if (s === void 0 && this.schema)
        this.set(r, Qn(this.schema, i, t));
      else
        throw new Error(`Expected YAML collection at ${r}. Remaining path: ${i}`);
    }
  }
  /**
   * Removes a value from the collection.
   * @returns `true` if the item was found and removed.
   */
  deleteIn(e) {
    const [t, ...r] = e;
    if (r.length === 0)
      return this.delete(t);
    const i = this.get(t, !0);
    if (ce(i))
      return i.deleteIn(r);
    throw new Error(`Expected YAML collection at ${t}. Remaining path: ${r}`);
  }
  /**
   * Returns item at `key`, or `undefined` if not found. By default unwraps
   * scalar values from their surrounding node; to disable set `keepScalar` to
   * `true` (collections are always returned intact).
   */
  getIn(e, t) {
    const [r, ...i] = e, s = this.get(r, !0);
    return i.length === 0 ? !t && oe(s) ? s.value : s : ce(s) ? s.getIn(i, t) : void 0;
  }
  hasAllNullValues(e) {
    return this.items.every((t) => {
      if (!fe(t))
        return !1;
      const r = t.value;
      return r == null || e && oe(r) && r.value == null && !r.commentBefore && !r.comment && !r.tag;
    });
  }
  /**
   * Checks if the collection includes a value with the key `key`.
   */
  hasIn(e) {
    const [t, ...r] = e;
    if (r.length === 0)
      return this.has(t);
    const i = this.get(t, !0);
    return ce(i) ? i.hasIn(r) : !1;
  }
  /**
   * Sets a value in this collection. For `!!set`, `value` needs to be a
   * boolean to add/remove the item from the set.
   */
  setIn(e, t) {
    const [r, ...i] = e;
    if (i.length === 0)
      this.set(r, t);
    else {
      const s = this.get(r, !0);
      if (ce(s))
        s.setIn(i, t);
      else if (s === void 0 && this.schema)
        this.set(r, Qn(this.schema, i, t));
      else
        throw new Error(`Expected YAML collection at ${r}. Remaining path: ${i}`);
    }
  }
}
br.maxFlowStringSingleLineLength = 60;
const xc = (n) => n.replace(/^(?!$)(?: $)?/gm, "#");
function it(n, e) {
  return /^\n+$/.test(n) ? n.substring(1) : e ? n.replace(/^(?! *$)/gm, e) : n;
}
const lt = (n, e, t) => n.endsWith(`
`) ? it(t, e) : t.includes(`
`) ? `
` + it(t, e) : (n.endsWith(" ") ? "" : " ") + t, Aa = "flow", ri = "block", Un = "quoted";
function _r(n, e, t = "flow", { indentAtStart: r, lineWidth: i = 80, minContentWidth: s = 20, onFold: a, onOverflow: o } = {}) {
  if (!i || i < 0)
    return n;
  const u = Math.max(1 + s, 1 + i - e.length);
  if (n.length <= u)
    return n;
  const c = [], d = {};
  let f = i - e.length;
  typeof r == "number" && (r > i - Math.max(2, s) ? c.push(0) : f = i - r);
  let h, p, g = !1, _ = -1, A = -1, O = -1;
  t === ri && (_ = ws(n, _), _ !== -1 && (f = _ + u));
  for (let P; P = n[_ += 1]; ) {
    if (t === Un && P === "\\") {
      switch (A = _, n[_ + 1]) {
        case "x":
          _ += 3;
          break;
        case "u":
          _ += 5;
          break;
        case "U":
          _ += 9;
          break;
        default:
          _ += 1;
      }
      O = _;
    }
    if (P === `
`)
      t === ri && (_ = ws(n, _)), f = _ + u, h = void 0;
    else {
      if (P === " " && p && p !== " " && p !== `
` && p !== "	") {
        const C = n[_ + 1];
        C && C !== " " && C !== `
` && C !== "	" && (h = _);
      }
      if (_ >= f)
        if (h)
          c.push(h), f = h + u, h = void 0;
        else if (t === Un) {
          for (; p === " " || p === "	"; )
            p = P, P = n[_ += 1], g = !0;
          const C = _ > O + 1 ? _ - 2 : A - 1;
          if (d[C])
            return n;
          c.push(C), d[C] = !0, f = C + u, h = void 0;
        } else
          g = !0;
    }
    p = P;
  }
  if (g && o && o(), c.length === 0)
    return n;
  a && a();
  let w = n.slice(0, c[0]);
  for (let P = 0; P < c.length; ++P) {
    const C = c[P], j = c[P + 1] || n.length;
    C === 0 ? w = `
${e}${n.slice(0, j)}` : (t === Un && d[C] && (w += `${n[C]}\\`), w += `
${e}${n.slice(C + 1, j)}`);
  }
  return w;
}
function ws(n, e) {
  let t = n[e + 1];
  for (; t === " " || t === "	"; ) {
    do
      t = n[e += 1];
    while (t && t !== `
`);
    t = n[e + 1];
  }
  return e;
}
const Or = (n) => ({
  indentAtStart: n.indentAtStart,
  lineWidth: n.options.lineWidth,
  minContentWidth: n.options.minContentWidth
}), Ar = (n) => /^(%|---|\.\.\.)/m.test(n);
function jc(n, e, t) {
  if (!e || e < 0)
    return !1;
  const r = e - t, i = n.length;
  if (i <= r)
    return !1;
  for (let s = 0, a = 0; s < i; ++s)
    if (n[s] === `
`) {
      if (s - a > r)
        return !0;
      if (a = s + 1, i - a <= r)
        return !1;
    }
  return !0;
}
function an(n, e) {
  const t = JSON.stringify(n);
  if (e.options.doubleQuotedAsJSON)
    return t;
  const { implicitKey: r } = e, i = e.options.doubleQuotedMinMultiLineLength, s = e.indent || (Ar(n) ? "  " : "");
  let a = "", o = 0;
  for (let u = 0, c = t[u]; c; c = t[++u])
    if (c === " " && t[u + 1] === "\\" && t[u + 2] === "n" && (a += t.slice(o, u) + "\\ ", u += 1, o = u, c = "\\"), c === "\\")
      switch (t[u + 1]) {
        case "u":
          {
            a += t.slice(o, u);
            const d = t.substr(u + 2, 4);
            switch (d) {
              case "0000":
                a += "\\0";
                break;
              case "0007":
                a += "\\a";
                break;
              case "000b":
                a += "\\v";
                break;
              case "001b":
                a += "\\e";
                break;
              case "0085":
                a += "\\N";
                break;
              case "00a0":
                a += "\\_";
                break;
              case "2028":
                a += "\\L";
                break;
              case "2029":
                a += "\\P";
                break;
              default:
                d.substr(0, 2) === "00" ? a += "\\x" + d.substr(2) : a += t.substr(u, 6);
            }
            u += 5, o = u + 1;
          }
          break;
        case "n":
          if (r || t[u + 2] === '"' || t.length < i)
            u += 1;
          else {
            for (a += t.slice(o, u) + `

`; t[u + 2] === "\\" && t[u + 3] === "n" && t[u + 4] !== '"'; )
              a += `
`, u += 2;
            a += s, t[u + 2] === " " && (a += "\\"), u += 1, o = u + 1;
          }
          break;
        default:
          u += 1;
      }
  return a = o ? a + t.slice(o) : t, r ? a : _r(a, s, Un, Or(e));
}
function ii(n, e) {
  if (e.options.singleQuote === !1 || e.implicitKey && n.includes(`
`) || /[ \t]\n|\n[ \t]/.test(n))
    return an(n, e);
  const t = e.indent || (Ar(n) ? "  " : ""), r = "'" + n.replace(/'/g, "''").replace(/\n+/g, `$&
${t}`) + "'";
  return e.implicitKey ? r : _r(r, t, Aa, Or(e));
}
function xt(n, e) {
  const { singleQuote: t } = e.options;
  let r;
  if (t === !1)
    r = an;
  else {
    const i = n.includes('"'), s = n.includes("'");
    i && !s ? r = ii : s && !i ? r = an : r = t ? ii : an;
  }
  return r(n, e);
}
function Zn({ comment: n, type: e, value: t }, r, i, s) {
  const { blockQuote: a, commentString: o, lineWidth: u } = r.options;
  if (!a || /\n[\t ]+$/.test(t) || /^\s*$/.test(t))
    return xt(t, r);
  const c = r.indent || (r.forceBlockIndent || Ar(t) ? "  " : ""), d = a === "literal" ? !0 : a === "folded" || e === Q.BLOCK_FOLDED ? !1 : e === Q.BLOCK_LITERAL ? !0 : !jc(t, u, c.length);
  if (!t)
    return d ? `|
` : `>
`;
  let f, h;
  for (h = t.length; h > 0; --h) {
    const F = t[h - 1];
    if (F !== `
` && F !== "	" && F !== " ")
      break;
  }
  let p = t.substring(h);
  const g = p.indexOf(`
`);
  g === -1 ? f = "-" : t === p || g !== p.length - 1 ? (f = "+", s && s()) : f = "", p && (t = t.slice(0, -p.length), p[p.length - 1] === `
` && (p = p.slice(0, -1)), p = p.replace(/\n+(?!\n|$)/g, `$&${c}`));
  let _ = !1, A, O = -1;
  for (A = 0; A < t.length; ++A) {
    const F = t[A];
    if (F === " ")
      _ = !0;
    else if (F === `
`)
      O = A;
    else
      break;
  }
  let w = t.substring(0, O < A ? O + 1 : A);
  w && (t = t.substring(w.length), w = w.replace(/\n+/g, `$&${c}`));
  let C = (d ? "|" : ">") + (_ ? c ? "2" : "1" : "") + f;
  if (n && (C += " " + o(n.replace(/ ?[\r\n]+/g, " ")), i && i()), d)
    return t = t.replace(/\n+/g, `$&${c}`), `${C}
${c}${w}${t}${p}`;
  t = t.replace(/\n+/g, `
$&`).replace(/(?:^|\n)([\t ].*)(?:([\n\t ]*)\n(?![\n\t ]))?/g, "$1$2").replace(/\n+/g, `$&${c}`);
  const j = _r(`${w}${t}${p}`, c, ri, Or(r));
  return `${C}
${c}${j}`;
}
function $c(n, e, t, r) {
  const { type: i, value: s } = n, { actualString: a, implicitKey: o, indent: u, indentStep: c, inFlow: d } = e;
  if (o && /[\n[\]{},]/.test(s) || d && /[[\]{},]/.test(s))
    return xt(s, e);
  if (!s || /^[\n\t ,[\]{}#&*!|>'"%@`]|^[?-]$|^[?-][ \t]|[\n:][ \t]|[ \t]\n|[\n\t ]#|[\n\t :]$/.test(s))
    return o || d || !s.includes(`
`) ? xt(s, e) : Zn(n, e, t, r);
  if (!o && !d && i !== Q.PLAIN && s.includes(`
`))
    return Zn(n, e, t, r);
  if (Ar(s)) {
    if (u === "")
      return e.forceBlockIndent = !0, Zn(n, e, t, r);
    if (o && u === c)
      return xt(s, e);
  }
  const f = s.replace(/\n+/g, `$&
${u}`);
  if (a) {
    const h = (_) => {
      var A;
      return _.default && _.tag !== "tag:yaml.org,2002:str" && ((A = _.test) == null ? void 0 : A.test(f));
    }, { compat: p, tags: g } = e.doc.schema;
    if (g.some(h) || p != null && p.some(h))
      return xt(s, e);
  }
  return o ? f : _r(f, u, Aa, Or(e));
}
function Ai(n, e, t, r) {
  const { implicitKey: i, inFlow: s } = e, a = typeof n.value == "string" ? n : Object.assign({}, n, { value: String(n.value) });
  let { type: o } = n;
  o !== Q.QUOTE_DOUBLE && /[\x00-\x08\x0b-\x1f\x7f-\x9f\u{D800}-\u{DFFF}]/u.test(a.value) && (o = Q.QUOTE_DOUBLE);
  const u = (d) => {
    switch (d) {
      case Q.BLOCK_FOLDED:
      case Q.BLOCK_LITERAL:
        return i || s ? xt(a.value, e) : Zn(a, e, t, r);
      case Q.QUOTE_DOUBLE:
        return an(a.value, e);
      case Q.QUOTE_SINGLE:
        return ii(a.value, e);
      case Q.PLAIN:
        return $c(a, e, t, r);
      default:
        return null;
    }
  };
  let c = u(o);
  if (c === null) {
    const { defaultKeyType: d, defaultStringType: f } = e.options, h = i && d || f;
    if (c = u(h), c === null)
      throw new Error(`Unsupported default string type ${h}`);
  }
  return c;
}
function wa(n, e) {
  const t = Object.assign({
    blockQuote: !0,
    commentString: xc,
    defaultKeyType: null,
    defaultStringType: "PLAIN",
    directives: null,
    doubleQuotedAsJSON: !1,
    doubleQuotedMinMultiLineLength: 40,
    falseStr: "false",
    flowCollectionPadding: !0,
    indentSeq: !0,
    lineWidth: 80,
    minContentWidth: 20,
    nullStr: "null",
    simpleKeys: !1,
    singleQuote: null,
    trueStr: "true",
    verifyAliasOrder: !0
  }, n.schema.toStringOptions, e);
  let r;
  switch (t.collectionStyle) {
    case "block":
      r = !1;
      break;
    case "flow":
      r = !0;
      break;
    default:
      r = null;
  }
  return {
    anchors: /* @__PURE__ */ new Set(),
    doc: n,
    flowCollectionPadding: t.flowCollectionPadding ? " " : "",
    indent: "",
    indentStep: typeof t.indent == "number" ? " ".repeat(t.indent) : "  ",
    inFlow: r,
    options: t
  };
}
function Bc(n, e) {
  var i;
  if (e.tag) {
    const s = n.filter((a) => a.tag === e.tag);
    if (s.length > 0)
      return s.find((a) => a.format === e.format) ?? s[0];
  }
  let t, r;
  if (oe(e)) {
    r = e.value;
    const s = n.filter((a) => {
      var o;
      return (o = a.identify) == null ? void 0 : o.call(a, r);
    });
    t = s.find((a) => a.format === e.format) ?? s.find((a) => !a.format);
  } else
    r = e, t = n.find((s) => s.nodeClass && r instanceof s.nodeClass);
  if (!t) {
    const s = ((i = r == null ? void 0 : r.constructor) == null ? void 0 : i.name) ?? typeof r;
    throw new Error(`Tag not resolved for ${s} value`);
  }
  return t;
}
function Hc(n, e, { anchors: t, doc: r }) {
  if (!r.directives)
    return "";
  const i = [], s = (oe(n) || ce(n)) && n.anchor;
  s && ya(s) && (t.add(s), i.push(`&${s}`));
  const a = n.tag ? n.tag : e.default ? null : e.tag;
  return a && i.push(r.directives.tagString(a)), i.join(" ");
}
function Tt(n, e, t, r) {
  var u;
  if (fe(n))
    return n.toString(e, t, r);
  if (Yt(n)) {
    if (e.doc.directives)
      return n.toString(e);
    if ((u = e.resolvedAliases) != null && u.has(n))
      throw new TypeError("Cannot stringify circular structure without alias nodes");
    e.resolvedAliases ? e.resolvedAliases.add(n) : e.resolvedAliases = /* @__PURE__ */ new Set([n]), n = n.resolve(e.doc);
  }
  let i;
  const s = he(n) ? n : e.doc.createNode(n, { onTagObj: (c) => i = c });
  i || (i = Bc(e.doc.schema.tags, s));
  const a = Hc(s, i, e);
  a.length > 0 && (e.indentAtStart = (e.indentAtStart ?? 0) + a.length + 1);
  const o = typeof i.stringify == "function" ? i.stringify(s, e, t, r) : oe(s) ? Ai(s, e, t, r) : s.toString(e, t, r);
  return a ? oe(s) || o[0] === "{" || o[0] === "[" ? `${a} ${o}` : `${a}
${e.indent}${o}` : o;
}
function Uc({ key: n, value: e }, t, r, i) {
  const { allNullValues: s, doc: a, indent: o, indentStep: u, options: { commentString: c, indentSeq: d, simpleKeys: f } } = t;
  let h = he(n) && n.comment || null;
  if (f) {
    if (h)
      throw new Error("With simple keys, key nodes cannot have comments");
    if (ce(n)) {
      const re = "With simple keys, collection cannot be used as a key value";
      throw new Error(re);
    }
  }
  let p = !f && (!n || h && e == null && !t.inFlow || ce(n) || (oe(n) ? n.type === Q.BLOCK_FOLDED || n.type === Q.BLOCK_LITERAL : typeof n == "object"));
  t = Object.assign({}, t, {
    allNullValues: !1,
    implicitKey: !p && (f || !s),
    indent: o + u
  });
  let g = !1, _ = !1, A = Tt(n, t, () => g = !0, () => _ = !0);
  if (!p && !t.inFlow && A.length > 1024) {
    if (f)
      throw new Error("With simple keys, single line scalar must not span more than 1024 characters");
    p = !0;
  }
  if (t.inFlow) {
    if (s || e == null)
      return g && r && r(), A === "" ? "?" : p ? `? ${A}` : A;
  } else if (s && !f || e == null && p)
    return A = `? ${A}`, h && !g ? A += lt(A, t.indent, c(h)) : _ && i && i(), A;
  g && (h = null), p ? (h && (A += lt(A, t.indent, c(h))), A = `? ${A}
${o}:`) : (A = `${A}:`, h && (A += lt(A, t.indent, c(h))));
  let O, w, P;
  he(e) ? (O = !!e.spaceBefore, w = e.commentBefore, P = e.comment) : (O = !1, w = null, P = null, e && typeof e == "object" && (e = a.createNode(e))), t.implicitKey = !1, !p && !h && oe(e) && (t.indentAtStart = A.length + 1), _ = !1, !d && u.length >= 2 && !t.inFlow && !p && In(e) && !e.flow && !e.tag && !e.anchor && (t.indent = t.indent.substring(2));
  let C = !1;
  const j = Tt(e, t, () => C = !0, () => _ = !0);
  let F = " ";
  if (h || O || w) {
    if (F = O ? `
` : "", w) {
      const re = c(w);
      F += `
${it(re, t.indent)}`;
    }
    j === "" && !t.inFlow ? F === `
` && (F = `

`) : F += `
${t.indent}`;
  } else if (!p && ce(e)) {
    const re = j[0], J = j.indexOf(`
`), Y = J !== -1, B = t.inFlow ?? e.flow ?? e.items.length === 0;
    if (Y || !B) {
      let ie = !1;
      if (Y && (re === "&" || re === "!")) {
        let pe = j.indexOf(" ");
        re === "&" && pe !== -1 && pe < J && j[pe + 1] === "!" && (pe = j.indexOf(" ", pe + 1)), (pe === -1 || J < pe) && (ie = !0);
      }
      ie || (F = `
${t.indent}`);
    }
  } else
    (j === "" || j[0] === `
`) && (F = "");
  return A += F + j, t.inFlow ? C && r && r() : P && !C ? A += lt(A, t.indent, c(P)) : _ && i && i(), A;
}
function Zc(n, e) {
  (n === "debug" || n === "warn") && (typeof process < "u" && process.emitWarning ? process.emitWarning(e) : console.warn(e));
}
const Ss = "<<";
function Sa(n, e, { key: t, value: r }) {
  if (n != null && n.doc.schema.merge && Fc(t))
    if (r = Yt(r) ? r.resolve(n.doc) : r, In(r))
      for (const i of r.items)
        Rr(n, e, i);
    else if (Array.isArray(r))
      for (const i of r)
        Rr(n, e, i);
    else
      Rr(n, e, r);
  else {
    const i = Ke(t, "", n);
    if (e instanceof Map)
      e.set(i, Ke(r, i, n));
    else if (e instanceof Set)
      e.add(i);
    else {
      const s = Vc(t, i, n), a = Ke(r, s, n);
      s in e ? Object.defineProperty(e, s, {
        value: a,
        writable: !0,
        enumerable: !0,
        configurable: !0
      }) : e[s] = a;
    }
  }
  return e;
}
const Fc = (n) => n === Ss || oe(n) && n.value === Ss && (!n.type || n.type === Q.PLAIN);
function Rr(n, e, t) {
  const r = n && Yt(t) ? t.resolve(n.doc) : t;
  if (!Mn(r))
    throw new Error("Merge sources must be maps or map aliases");
  const i = r.toJSON(null, n, Map);
  for (const [s, a] of i)
    e instanceof Map ? e.has(s) || e.set(s, a) : e instanceof Set ? e.add(s) : Object.prototype.hasOwnProperty.call(e, s) || Object.defineProperty(e, s, {
      value: a,
      writable: !0,
      enumerable: !0,
      configurable: !0
    });
  return e;
}
function Vc(n, e, t) {
  if (e === null)
    return "";
  if (typeof e != "object")
    return String(e);
  if (he(n) && t && t.doc) {
    const r = wa(t.doc, {});
    r.anchors = /* @__PURE__ */ new Set();
    for (const s of t.anchors.keys())
      r.anchors.add(s.anchor);
    r.inFlow = !0, r.inStringifyKey = !0;
    const i = n.toString(r);
    if (!t.mapKeyWarned) {
      let s = JSON.stringify(i);
      s.length > 40 && (s = s.substring(0, 36) + '..."'), Zc(t.doc.options.logLevel, `Keys with collection values will be stringified due to JS Object restrictions: ${s}. Set mapAsMap: true to use object keys.`), t.mapKeyWarned = !0;
    }
    return i;
  }
  return JSON.stringify(e);
}
function wi(n, e, t) {
  const r = dn(n, void 0, t), i = dn(e, void 0, t);
  return new Pe(r, i);
}
class Pe {
  constructor(e, t = null) {
    Object.defineProperty(this, Ne, { value: ga }), this.key = e, this.value = t;
  }
  clone(e) {
    let { key: t, value: r } = this;
    return he(t) && (t = t.clone(e)), he(r) && (r = r.clone(e)), new Pe(t, r);
  }
  toJSON(e, t) {
    const r = t != null && t.mapAsMap ? /* @__PURE__ */ new Map() : {};
    return Sa(t, r, this);
  }
  toString(e, t, r) {
    return e != null && e.doc ? Uc(this, e, t, r) : JSON.stringify(this);
  }
}
function Ea(n, e, t) {
  return (e.inFlow ?? n.flow ? Yc : Gc)(n, e, t);
}
function Gc({ comment: n, items: e }, t, { blockItemPrefix: r, flowChars: i, itemIndent: s, onChompKeep: a, onComment: o }) {
  const { indent: u, options: { commentString: c } } = t, d = Object.assign({}, t, { indent: s, type: null });
  let f = !1;
  const h = [];
  for (let g = 0; g < e.length; ++g) {
    const _ = e[g];
    let A = null;
    if (he(_))
      !f && _.spaceBefore && h.push(""), er(t, h, _.commentBefore, f), _.comment && (A = _.comment);
    else if (fe(_)) {
      const w = he(_.key) ? _.key : null;
      w && (!f && w.spaceBefore && h.push(""), er(t, h, w.commentBefore, f));
    }
    f = !1;
    let O = Tt(_, d, () => A = null, () => f = !0);
    A && (O += lt(O, s, c(A))), f && A && (f = !1), h.push(r + O);
  }
  let p;
  if (h.length === 0)
    p = i.start + i.end;
  else {
    p = h[0];
    for (let g = 1; g < h.length; ++g) {
      const _ = h[g];
      p += _ ? `
${u}${_}` : `
`;
    }
  }
  return n ? (p += `
` + it(c(n), u), o && o()) : f && a && a(), p;
}
function Yc({ comment: n, items: e }, t, { flowChars: r, itemIndent: i, onComment: s }) {
  const { indent: a, indentStep: o, flowCollectionPadding: u, options: { commentString: c } } = t;
  i += o;
  const d = Object.assign({}, t, {
    indent: i,
    inFlow: !0,
    type: null
  });
  let f = !1, h = 0;
  const p = [];
  for (let O = 0; O < e.length; ++O) {
    const w = e[O];
    let P = null;
    if (he(w))
      w.spaceBefore && p.push(""), er(t, p, w.commentBefore, !1), w.comment && (P = w.comment);
    else if (fe(w)) {
      const j = he(w.key) ? w.key : null;
      j && (j.spaceBefore && p.push(""), er(t, p, j.commentBefore, !1), j.comment && (f = !0));
      const F = he(w.value) ? w.value : null;
      F ? (F.comment && (P = F.comment), F.commentBefore && (f = !0)) : w.value == null && j && j.comment && (P = j.comment);
    }
    P && (f = !0);
    let C = Tt(w, d, () => P = null);
    O < e.length - 1 && (C += ","), P && (C += lt(C, i, c(P))), !f && (p.length > h || C.includes(`
`)) && (f = !0), p.push(C), h = p.length;
  }
  let g;
  const { start: _, end: A } = r;
  if (p.length === 0)
    g = _ + A;
  else if (f || (f = p.reduce((w, P) => w + P.length + 2, 2) > br.maxFlowStringSingleLineLength), f) {
    g = _;
    for (const O of p)
      g += O ? `
${o}${a}${O}` : `
`;
    g += `
${a}${A}`;
  } else
    g = `${_}${u}${p.join(" ")}${u}${A}`;
  return n && (g += lt(g, c(n), a), s && s()), g;
}
function er({ indent: n, options: { commentString: e } }, t, r, i) {
  if (r && i && (r = r.replace(/^\n+/, "")), r) {
    const s = it(e(r), n);
    t.push(s.trimStart());
  }
}
function wt(n, e) {
  const t = oe(e) ? e.value : e;
  for (const r of n)
    if (fe(r) && (r.key === e || r.key === t || oe(r.key) && r.key.value === t))
      return r;
}
class ut extends br {
  static get tagName() {
    return "tag:yaml.org,2002:map";
  }
  constructor(e) {
    super(pt, e), this.items = [];
  }
  /**
   * Adds a value to the collection.
   *
   * @param overwrite - If not set `true`, using a key that is already in the
   *   collection will throw. Otherwise, overwrites the previous value.
   */
  add(e, t) {
    var a;
    let r;
    fe(e) ? r = e : !e || typeof e != "object" || !("key" in e) ? r = new Pe(e, e == null ? void 0 : e.value) : r = new Pe(e.key, e.value);
    const i = wt(this.items, r.key), s = (a = this.schema) == null ? void 0 : a.sortMapEntries;
    if (i) {
      if (!t)
        throw new Error(`Key ${r.key} already set`);
      oe(i.value) && Oa(r.value) ? i.value.value = r.value : i.value = r.value;
    } else if (s) {
      const o = this.items.findIndex((u) => s(r, u) < 0);
      o === -1 ? this.items.push(r) : this.items.splice(o, 0, r);
    } else
      this.items.push(r);
  }
  delete(e) {
    const t = wt(this.items, e);
    return t ? this.items.splice(this.items.indexOf(t), 1).length > 0 : !1;
  }
  get(e, t) {
    const r = wt(this.items, e), i = r == null ? void 0 : r.value;
    return (!t && oe(i) ? i.value : i) ?? void 0;
  }
  has(e) {
    return !!wt(this.items, e);
  }
  set(e, t) {
    this.add(new Pe(e, t), !0);
  }
  /**
   * @param ctx - Conversion context, originally set in Document#toJS()
   * @param {Class} Type - If set, forces the returned collection type
   * @returns Instance of Type, Map, or Object
   */
  toJSON(e, t, r) {
    const i = r ? new r() : t != null && t.mapAsMap ? /* @__PURE__ */ new Map() : {};
    t != null && t.onCreate && t.onCreate(i);
    for (const s of this.items)
      Sa(t, i, s);
    return i;
  }
  toString(e, t, r) {
    if (!e)
      return JSON.stringify(this);
    for (const i of this.items)
      if (!fe(i))
        throw new Error(`Map items must all be pairs; found ${JSON.stringify(i)} instead`);
    return !e.allNullValues && this.hasAllNullValues(!1) && (e = Object.assign({}, e, { allNullValues: !0 })), Ea(this, e, {
      blockItemPrefix: "",
      flowChars: { start: "{", end: "}" },
      itemIndent: e.indent || "",
      onChompKeep: r,
      onComment: t
    });
  }
}
function Kc(n, e, t) {
  const { keepUndefined: r, replacer: i } = t, s = new ut(n), a = (o, u) => {
    if (typeof i == "function")
      u = i.call(e, o, u);
    else if (Array.isArray(i) && !i.includes(o))
      return;
    (u !== void 0 || r) && s.items.push(wi(o, u, t));
  };
  if (e instanceof Map)
    for (const [o, u] of e)
      a(o, u);
  else if (e && typeof e == "object")
    for (const o of Object.keys(e))
      a(o, e[o]);
  return typeof n.sortMapEntries == "function" && s.items.sort(n.sortMapEntries), s;
}
const Kt = {
  collection: "map",
  createNode: Kc,
  default: !0,
  nodeClass: ut,
  tag: "tag:yaml.org,2002:map",
  resolve(n, e) {
    return Mn(n) || e("Expected a mapping for this tag"), n;
  }
};
class wr extends br {
  static get tagName() {
    return "tag:yaml.org,2002:seq";
  }
  constructor(e) {
    super(Gt, e), this.items = [];
  }
  add(e) {
    this.items.push(e);
  }
  /**
   * Removes a value from the collection.
   *
   * `key` must contain a representation of an integer for this to succeed.
   * It may be wrapped in a `Scalar`.
   *
   * @returns `true` if the item was found and removed.
   */
  delete(e) {
    const t = xn(e);
    return typeof t != "number" ? !1 : this.items.splice(t, 1).length > 0;
  }
  get(e, t) {
    const r = xn(e);
    if (typeof r != "number")
      return;
    const i = this.items[r];
    return !t && oe(i) ? i.value : i;
  }
  /**
   * Checks if the collection includes a value with the key `key`.
   *
   * `key` must contain a representation of an integer for this to succeed.
   * It may be wrapped in a `Scalar`.
   */
  has(e) {
    const t = xn(e);
    return typeof t == "number" && t < this.items.length;
  }
  /**
   * Sets a value in this collection. For `!!set`, `value` needs to be a
   * boolean to add/remove the item from the set.
   *
   * If `key` does not contain a representation of an integer, this will throw.
   * It may be wrapped in a `Scalar`.
   */
  set(e, t) {
    const r = xn(e);
    if (typeof r != "number")
      throw new Error(`Expected a valid index, not ${e}.`);
    const i = this.items[r];
    oe(i) && Oa(t) ? i.value = t : this.items[r] = t;
  }
  toJSON(e, t) {
    const r = [];
    t != null && t.onCreate && t.onCreate(r);
    let i = 0;
    for (const s of this.items)
      r.push(Ke(s, String(i++), t));
    return r;
  }
  toString(e, t, r) {
    return e ? Ea(this, e, {
      blockItemPrefix: "- ",
      flowChars: { start: "[", end: "]" },
      itemIndent: (e.indent || "") + "  ",
      onChompKeep: r,
      onComment: t
    }) : JSON.stringify(this);
  }
}
function xn(n) {
  let e = oe(n) ? n.value : n;
  return e && typeof e == "string" && (e = Number(e)), typeof e == "number" && Number.isInteger(e) && e >= 0 ? e : null;
}
function qc(n, e, t) {
  const { replacer: r } = t, i = new wr(n);
  if (e && Symbol.iterator in Object(e)) {
    let s = 0;
    for (let a of e) {
      if (typeof r == "function") {
        const o = e instanceof Set ? a : String(s++);
        a = r.call(e, o, a);
      }
      i.items.push(dn(a, void 0, t));
    }
  }
  return i;
}
const qt = {
  collection: "seq",
  createNode: qc,
  default: !0,
  nodeClass: wr,
  tag: "tag:yaml.org,2002:seq",
  resolve(n, e) {
    return In(n) || e("Expected a sequence for this tag"), n;
  }
}, Sr = {
  identify: (n) => typeof n == "string",
  default: !0,
  tag: "tag:yaml.org,2002:str",
  resolve: (n) => n,
  stringify(n, e, t, r) {
    return e = Object.assign({ actualString: !0 }, e), Ai(n, e, t, r);
  }
}, Er = {
  identify: (n) => n == null,
  createNode: () => new Q(null),
  default: !0,
  tag: "tag:yaml.org,2002:null",
  test: /^(?:~|[Nn]ull|NULL)?$/,
  resolve: () => new Q(null),
  stringify: ({ source: n }, e) => typeof n == "string" && Er.test.test(n) ? n : e.options.nullStr
}, Si = {
  identify: (n) => typeof n == "boolean",
  default: !0,
  tag: "tag:yaml.org,2002:bool",
  test: /^(?:[Tt]rue|TRUE|[Ff]alse|FALSE)$/,
  resolve: (n) => new Q(n[0] === "t" || n[0] === "T"),
  stringify({ source: n, value: e }, t) {
    if (n && Si.test.test(n)) {
      const r = n[0] === "t" || n[0] === "T";
      if (e === r)
        return n;
    }
    return e ? t.options.trueStr : t.options.falseStr;
  }
};
function He({ format: n, minFractionDigits: e, tag: t, value: r }) {
  if (typeof r == "bigint")
    return String(r);
  const i = typeof r == "number" ? r : Number(r);
  if (!isFinite(i))
    return isNaN(i) ? ".nan" : i < 0 ? "-.inf" : ".inf";
  let s = JSON.stringify(r);
  if (!n && e && (!t || t === "tag:yaml.org,2002:float") && /^\d/.test(s)) {
    let a = s.indexOf(".");
    a < 0 && (a = s.length, s += ".");
    let o = e - (s.length - a - 1);
    for (; o-- > 0; )
      s += "0";
  }
  return s;
}
const ka = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  test: /^(?:[-+]?\.(?:inf|Inf|INF|nan|NaN|NAN))$/,
  resolve: (n) => n.slice(-3).toLowerCase() === "nan" ? NaN : n[0] === "-" ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY,
  stringify: He
}, Ta = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  format: "EXP",
  test: /^[-+]?(?:\.[0-9]+|[0-9]+(?:\.[0-9]*)?)[eE][-+]?[0-9]+$/,
  resolve: (n) => parseFloat(n),
  stringify(n) {
    const e = Number(n.value);
    return isFinite(e) ? e.toExponential() : He(n);
  }
}, Ma = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  test: /^[-+]?(?:\.[0-9]+|[0-9]+\.[0-9]*)$/,
  resolve(n) {
    const e = new Q(parseFloat(n)), t = n.indexOf(".");
    return t !== -1 && n[n.length - 1] === "0" && (e.minFractionDigits = n.length - t - 1), e;
  },
  stringify: He
}, kr = (n) => typeof n == "bigint" || Number.isInteger(n), Ei = (n, e, t, { intAsBigInt: r }) => r ? BigInt(n) : parseInt(n.substring(e), t);
function Ia(n, e, t) {
  const { value: r } = n;
  return kr(r) && r >= 0 ? t + r.toString(e) : He(n);
}
const Pa = {
  identify: (n) => kr(n) && n >= 0,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  format: "OCT",
  test: /^0o[0-7]+$/,
  resolve: (n, e, t) => Ei(n, 2, 8, t),
  stringify: (n) => Ia(n, 8, "0o")
}, Na = {
  identify: kr,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  test: /^[-+]?[0-9]+$/,
  resolve: (n, e, t) => Ei(n, 0, 10, t),
  stringify: He
}, La = {
  identify: (n) => kr(n) && n >= 0,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  format: "HEX",
  test: /^0x[0-9a-fA-F]+$/,
  resolve: (n, e, t) => Ei(n, 2, 16, t),
  stringify: (n) => Ia(n, 16, "0x")
}, Wc = [
  Kt,
  qt,
  Sr,
  Er,
  Si,
  Pa,
  Na,
  La,
  ka,
  Ta,
  Ma
];
function Es(n) {
  return typeof n == "bigint" || Number.isInteger(n);
}
const jn = ({ value: n }) => JSON.stringify(n), Jc = [
  {
    identify: (n) => typeof n == "string",
    default: !0,
    tag: "tag:yaml.org,2002:str",
    resolve: (n) => n,
    stringify: jn
  },
  {
    identify: (n) => n == null,
    createNode: () => new Q(null),
    default: !0,
    tag: "tag:yaml.org,2002:null",
    test: /^null$/,
    resolve: () => null,
    stringify: jn
  },
  {
    identify: (n) => typeof n == "boolean",
    default: !0,
    tag: "tag:yaml.org,2002:bool",
    test: /^true|false$/,
    resolve: (n) => n === "true",
    stringify: jn
  },
  {
    identify: Es,
    default: !0,
    tag: "tag:yaml.org,2002:int",
    test: /^-?(?:0|[1-9][0-9]*)$/,
    resolve: (n, e, { intAsBigInt: t }) => t ? BigInt(n) : parseInt(n, 10),
    stringify: ({ value: n }) => Es(n) ? n.toString() : JSON.stringify(n)
  },
  {
    identify: (n) => typeof n == "number",
    default: !0,
    tag: "tag:yaml.org,2002:float",
    test: /^-?(?:0|[1-9][0-9]*)(?:\.[0-9]*)?(?:[eE][-+]?[0-9]+)?$/,
    resolve: (n) => parseFloat(n),
    stringify: jn
  }
], zc = {
  default: !0,
  tag: "",
  test: /^/,
  resolve(n, e) {
    return e(`Unresolved plain scalar ${JSON.stringify(n)}`), n;
  }
}, Xc = [Kt, qt].concat(Jc, zc), ki = {
  identify: (n) => n instanceof Uint8Array,
  default: !1,
  tag: "tag:yaml.org,2002:binary",
  /**
   * Returns a Buffer in node and an Uint8Array in browsers
   *
   * To use the resulting buffer as an image, you'll want to do something like:
   *
   *   const blob = new Blob([buffer], { type: 'image/jpeg' })
   *   document.querySelector('#photo').src = URL.createObjectURL(blob)
   */
  resolve(n, e) {
    if (typeof Buffer == "function")
      return Buffer.from(n, "base64");
    if (typeof atob == "function") {
      const t = atob(n.replace(/[\n\r]/g, "")), r = new Uint8Array(t.length);
      for (let i = 0; i < t.length; ++i)
        r[i] = t.charCodeAt(i);
      return r;
    } else
      return e("This environment does not support reading binary tags; either Buffer or atob is required"), n;
  },
  stringify({ comment: n, type: e, value: t }, r, i, s) {
    const a = t;
    let o;
    if (typeof Buffer == "function")
      o = a instanceof Buffer ? a.toString("base64") : Buffer.from(a.buffer).toString("base64");
    else if (typeof btoa == "function") {
      let u = "";
      for (let c = 0; c < a.length; ++c)
        u += String.fromCharCode(a[c]);
      o = btoa(u);
    } else
      throw new Error("This environment does not support writing binary tags; either Buffer or btoa is required");
    if (e || (e = Q.BLOCK_LITERAL), e !== Q.QUOTE_DOUBLE) {
      const u = Math.max(r.options.lineWidth - r.indent.length, r.options.minContentWidth), c = Math.ceil(o.length / u), d = new Array(c);
      for (let f = 0, h = 0; f < c; ++f, h += u)
        d[f] = o.substr(h, u);
      o = d.join(e === Q.BLOCK_LITERAL ? `
` : " ");
    }
    return Ai({ comment: n, type: e, value: o }, r, i, s);
  }
};
function Da(n, e) {
  if (In(n))
    for (let t = 0; t < n.items.length; ++t) {
      let r = n.items[t];
      if (!fe(r)) {
        if (Mn(r)) {
          r.items.length > 1 && e("Each pair must have its own sequence indicator");
          const i = r.items[0] || new Pe(new Q(null));
          if (r.commentBefore && (i.key.commentBefore = i.key.commentBefore ? `${r.commentBefore}
${i.key.commentBefore}` : r.commentBefore), r.comment) {
            const s = i.value ?? i.key;
            s.comment = s.comment ? `${r.comment}
${s.comment}` : r.comment;
          }
          r = i;
        }
        n.items[t] = fe(r) ? r : new Pe(r);
      }
    }
  else
    e("Expected a sequence for this tag");
  return n;
}
function Ca(n, e, t) {
  const { replacer: r } = t, i = new wr(n);
  i.tag = "tag:yaml.org,2002:pairs";
  let s = 0;
  if (e && Symbol.iterator in Object(e))
    for (let a of e) {
      typeof r == "function" && (a = r.call(e, String(s++), a));
      let o, u;
      if (Array.isArray(a))
        if (a.length === 2)
          o = a[0], u = a[1];
        else
          throw new TypeError(`Expected [key, value] tuple: ${a}`);
      else if (a && a instanceof Object) {
        const c = Object.keys(a);
        if (c.length === 1)
          o = c[0], u = a[o];
        else
          throw new TypeError(`Expected { key: value } tuple: ${a}`);
      } else
        o = a;
      i.items.push(wi(o, u, t));
    }
  return i;
}
const Ti = {
  collection: "seq",
  default: !1,
  tag: "tag:yaml.org,2002:pairs",
  resolve: Da,
  createNode: Ca
};
class $t extends wr {
  constructor() {
    super(), this.add = ut.prototype.add.bind(this), this.delete = ut.prototype.delete.bind(this), this.get = ut.prototype.get.bind(this), this.has = ut.prototype.has.bind(this), this.set = ut.prototype.set.bind(this), this.tag = $t.tag;
  }
  /**
   * If `ctx` is given, the return type is actually `Map<unknown, unknown>`,
   * but TypeScript won't allow widening the signature of a child method.
   */
  toJSON(e, t) {
    if (!t)
      return super.toJSON(e);
    const r = /* @__PURE__ */ new Map();
    t != null && t.onCreate && t.onCreate(r);
    for (const i of this.items) {
      let s, a;
      if (fe(i) ? (s = Ke(i.key, "", t), a = Ke(i.value, s, t)) : s = Ke(i, "", t), r.has(s))
        throw new Error("Ordered maps must not include duplicate keys");
      r.set(s, a);
    }
    return r;
  }
}
$t.tag = "tag:yaml.org,2002:omap";
const Mi = {
  collection: "seq",
  identify: (n) => n instanceof Map,
  nodeClass: $t,
  default: !1,
  tag: "tag:yaml.org,2002:omap",
  resolve(n, e) {
    const t = Da(n, e), r = [];
    for (const { key: i } of t.items)
      oe(i) && (r.includes(i.value) ? e(`Ordered maps must not include duplicate keys: ${i.value}`) : r.push(i.value));
    return Object.assign(new $t(), t);
  },
  createNode(n, e, t) {
    const r = Ca(n, e, t), i = new $t();
    return i.items = r.items, i;
  }
};
function Ra({ value: n, source: e }, t) {
  return e && (n ? xa : ja).test.test(e) ? e : n ? t.options.trueStr : t.options.falseStr;
}
const xa = {
  identify: (n) => n === !0,
  default: !0,
  tag: "tag:yaml.org,2002:bool",
  test: /^(?:Y|y|[Yy]es|YES|[Tt]rue|TRUE|[Oo]n|ON)$/,
  resolve: () => new Q(!0),
  stringify: Ra
}, ja = {
  identify: (n) => n === !1,
  default: !0,
  tag: "tag:yaml.org,2002:bool",
  test: /^(?:N|n|[Nn]o|NO|[Ff]alse|FALSE|[Oo]ff|OFF)$/i,
  resolve: () => new Q(!1),
  stringify: Ra
}, Qc = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  test: /^[-+]?\.(?:inf|Inf|INF|nan|NaN|NAN)$/,
  resolve: (n) => n.slice(-3).toLowerCase() === "nan" ? NaN : n[0] === "-" ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY,
  stringify: He
}, el = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  format: "EXP",
  test: /^[-+]?(?:[0-9][0-9_]*)?(?:\.[0-9_]*)?[eE][-+]?[0-9]+$/,
  resolve: (n) => parseFloat(n.replace(/_/g, "")),
  stringify(n) {
    const e = Number(n.value);
    return isFinite(e) ? e.toExponential() : He(n);
  }
}, tl = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  test: /^[-+]?(?:[0-9][0-9_]*)?\.[0-9_]*$/,
  resolve(n) {
    const e = new Q(parseFloat(n.replace(/_/g, ""))), t = n.indexOf(".");
    if (t !== -1) {
      const r = n.substring(t + 1).replace(/_/g, "");
      r[r.length - 1] === "0" && (e.minFractionDigits = r.length);
    }
    return e;
  },
  stringify: He
}, Pn = (n) => typeof n == "bigint" || Number.isInteger(n);
function Tr(n, e, t, { intAsBigInt: r }) {
  const i = n[0];
  if ((i === "-" || i === "+") && (e += 1), n = n.substring(e).replace(/_/g, ""), r) {
    switch (t) {
      case 2:
        n = `0b${n}`;
        break;
      case 8:
        n = `0o${n}`;
        break;
      case 16:
        n = `0x${n}`;
        break;
    }
    const a = BigInt(n);
    return i === "-" ? BigInt(-1) * a : a;
  }
  const s = parseInt(n, t);
  return i === "-" ? -1 * s : s;
}
function Ii(n, e, t) {
  const { value: r } = n;
  if (Pn(r)) {
    const i = r.toString(e);
    return r < 0 ? "-" + t + i.substr(1) : t + i;
  }
  return He(n);
}
const nl = {
  identify: Pn,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  format: "BIN",
  test: /^[-+]?0b[0-1_]+$/,
  resolve: (n, e, t) => Tr(n, 2, 2, t),
  stringify: (n) => Ii(n, 2, "0b")
}, rl = {
  identify: Pn,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  format: "OCT",
  test: /^[-+]?0[0-7_]+$/,
  resolve: (n, e, t) => Tr(n, 1, 8, t),
  stringify: (n) => Ii(n, 8, "0")
}, il = {
  identify: Pn,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  test: /^[-+]?[0-9][0-9_]*$/,
  resolve: (n, e, t) => Tr(n, 0, 10, t),
  stringify: He
}, sl = {
  identify: Pn,
  default: !0,
  tag: "tag:yaml.org,2002:int",
  format: "HEX",
  test: /^[-+]?0x[0-9a-fA-F_]+$/,
  resolve: (n, e, t) => Tr(n, 2, 16, t),
  stringify: (n) => Ii(n, 16, "0x")
};
class Bt extends ut {
  constructor(e) {
    super(e), this.tag = Bt.tag;
  }
  add(e) {
    let t;
    fe(e) ? t = e : e && typeof e == "object" && "key" in e && "value" in e && e.value === null ? t = new Pe(e.key, null) : t = new Pe(e, null), wt(this.items, t.key) || this.items.push(t);
  }
  /**
   * If `keepPair` is `true`, returns the Pair matching `key`.
   * Otherwise, returns the value of that Pair's key.
   */
  get(e, t) {
    const r = wt(this.items, e);
    return !t && fe(r) ? oe(r.key) ? r.key.value : r.key : r;
  }
  set(e, t) {
    if (typeof t != "boolean")
      throw new Error(`Expected boolean value for set(key, value) in a YAML set, not ${typeof t}`);
    const r = wt(this.items, e);
    r && !t ? this.items.splice(this.items.indexOf(r), 1) : !r && t && this.items.push(new Pe(e));
  }
  toJSON(e, t) {
    return super.toJSON(e, t, Set);
  }
  toString(e, t, r) {
    if (!e)
      return JSON.stringify(this);
    if (this.hasAllNullValues(!0))
      return super.toString(Object.assign({}, e, { allNullValues: !0 }), t, r);
    throw new Error("Set items must all have null values");
  }
}
Bt.tag = "tag:yaml.org,2002:set";
const Pi = {
  collection: "map",
  identify: (n) => n instanceof Set,
  nodeClass: Bt,
  default: !1,
  tag: "tag:yaml.org,2002:set",
  resolve(n, e) {
    if (Mn(n)) {
      if (n.hasAllNullValues(!0))
        return Object.assign(new Bt(), n);
      e("Set items must all have null values");
    } else
      e("Expected a mapping for this tag");
    return n;
  },
  createNode(n, e, t) {
    const { replacer: r } = t, i = new Bt(n);
    if (e && Symbol.iterator in Object(e))
      for (let s of e)
        typeof r == "function" && (s = r.call(e, s, s)), i.items.push(wi(s, null, t));
    return i;
  }
};
function Ni(n, e) {
  const t = n[0], r = t === "-" || t === "+" ? n.substring(1) : n, i = (a) => e ? BigInt(a) : Number(a), s = r.replace(/_/g, "").split(":").reduce((a, o) => a * i(60) + i(o), i(0));
  return t === "-" ? i(-1) * s : s;
}
function $a(n) {
  let { value: e } = n, t = (a) => a;
  if (typeof e == "bigint")
    t = (a) => BigInt(a);
  else if (isNaN(e) || !isFinite(e))
    return He(n);
  let r = "";
  e < 0 && (r = "-", e *= t(-1));
  const i = t(60), s = [e % i];
  return e < 60 ? s.unshift(0) : (e = (e - s[0]) / i, s.unshift(e % i), e >= 60 && (e = (e - s[0]) / i, s.unshift(e))), r + s.map((a) => a < 10 ? "0" + String(a) : String(a)).join(":").replace(/000000\d*$/, "");
}
const Ba = {
  identify: (n) => typeof n == "bigint" || Number.isInteger(n),
  default: !0,
  tag: "tag:yaml.org,2002:int",
  format: "TIME",
  test: /^[-+]?[0-9][0-9_]*(?::[0-5]?[0-9])+$/,
  resolve: (n, e, { intAsBigInt: t }) => Ni(n, t),
  stringify: $a
}, Ha = {
  identify: (n) => typeof n == "number",
  default: !0,
  tag: "tag:yaml.org,2002:float",
  format: "TIME",
  test: /^[-+]?[0-9][0-9_]*(?::[0-5]?[0-9])+\.[0-9_]*$/,
  resolve: (n) => Ni(n, !1),
  stringify: $a
}, Mr = {
  identify: (n) => n instanceof Date,
  default: !0,
  tag: "tag:yaml.org,2002:timestamp",
  // If the time zone is omitted, the timestamp is assumed to be specified in UTC. The time part
  // may be omitted altogether, resulting in a date format. In such a case, the time part is
  // assumed to be 00:00:00Z (start of day, UTC).
  test: RegExp("^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})(?:(?:t|T|[ \\t]+)([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2}(\\.[0-9]+)?)(?:[ \\t]*(Z|[-+][012]?[0-9](?::[0-9]{2})?))?)?$"),
  resolve(n) {
    const e = n.match(Mr.test);
    if (!e)
      throw new Error("!!timestamp expects a date, starting with yyyy-mm-dd");
    const [, t, r, i, s, a, o] = e.map(Number), u = e[7] ? Number((e[7] + "00").substr(1, 3)) : 0;
    let c = Date.UTC(t, r - 1, i, s || 0, a || 0, o || 0, u);
    const d = e[8];
    if (d && d !== "Z") {
      let f = Ni(d, !1);
      Math.abs(f) < 30 && (f *= 60), c -= 6e4 * f;
    }
    return new Date(c);
  },
  stringify: ({ value: n }) => n.toISOString().replace(/((T00:00)?:00)?\.000Z$/, "")
}, ks = [
  Kt,
  qt,
  Sr,
  Er,
  xa,
  ja,
  nl,
  rl,
  il,
  sl,
  Qc,
  el,
  tl,
  ki,
  Mi,
  Ti,
  Pi,
  Ba,
  Ha,
  Mr
], Ts = /* @__PURE__ */ new Map([
  ["core", Wc],
  ["failsafe", [Kt, qt, Sr]],
  ["json", Xc],
  ["yaml11", ks],
  ["yaml-1.1", ks]
]), Ms = {
  binary: ki,
  bool: Si,
  float: Ma,
  floatExp: Ta,
  floatNaN: ka,
  floatTime: Ha,
  int: Na,
  intHex: La,
  intOct: Pa,
  intTime: Ba,
  map: Kt,
  null: Er,
  omap: Mi,
  pairs: Ti,
  seq: qt,
  set: Pi,
  timestamp: Mr
}, al = {
  "tag:yaml.org,2002:binary": ki,
  "tag:yaml.org,2002:omap": Mi,
  "tag:yaml.org,2002:pairs": Ti,
  "tag:yaml.org,2002:set": Pi,
  "tag:yaml.org,2002:timestamp": Mr
};
function xr(n, e) {
  let t = Ts.get(e);
  if (!t)
    if (Array.isArray(n))
      t = [];
    else {
      const r = Array.from(Ts.keys()).filter((i) => i !== "yaml11").map((i) => JSON.stringify(i)).join(", ");
      throw new Error(`Unknown schema "${e}"; use one of ${r} or define customTags array`);
    }
  if (Array.isArray(n))
    for (const r of n)
      t = t.concat(r);
  else
    typeof n == "function" && (t = n(t.slice()));
  return t.map((r) => {
    if (typeof r != "string")
      return r;
    const i = Ms[r];
    if (i)
      return i;
    const s = Object.keys(Ms).map((a) => JSON.stringify(a)).join(", ");
    throw new Error(`Unknown custom tag "${r}"; use one of ${s}`);
  });
}
const ol = (n, e) => n.key < e.key ? -1 : n.key > e.key ? 1 : 0;
class Li {
  constructor({ compat: e, customTags: t, merge: r, resolveKnownTags: i, schema: s, sortMapEntries: a, toStringDefaults: o }) {
    this.compat = Array.isArray(e) ? xr(e, "compat") : e ? xr(null, e) : null, this.merge = !!r, this.name = typeof s == "string" && s || "core", this.knownTags = i ? al : {}, this.tags = xr(t, this.name), this.toStringOptions = o ?? null, Object.defineProperty(this, pt, { value: Kt }), Object.defineProperty(this, vr, { value: Sr }), Object.defineProperty(this, Gt, { value: qt }), this.sortMapEntries = typeof a == "function" ? a : a === !0 ? ol : null;
  }
  clone() {
    const e = Object.create(Li.prototype, Object.getOwnPropertyDescriptors(this));
    return e.tags = this.tags.slice(), e;
  }
}
function ul(n, e) {
  var u;
  const t = [];
  let r = e.directives === !0;
  if (e.directives !== !1 && n.directives) {
    const c = n.directives.toString(n);
    c ? (t.push(c), r = !0) : n.directives.docStart && (r = !0);
  }
  r && t.push("---");
  const i = wa(n, e), { commentString: s } = i.options;
  if (n.commentBefore) {
    t.length !== 1 && t.unshift("");
    const c = s(n.commentBefore);
    t.unshift(it(c, ""));
  }
  let a = !1, o = null;
  if (n.contents) {
    if (he(n.contents)) {
      if (n.contents.spaceBefore && r && t.push(""), n.contents.commentBefore) {
        const f = s(n.contents.commentBefore);
        t.push(it(f, ""));
      }
      i.forceBlockIndent = !!n.comment, o = n.contents.comment;
    }
    const c = o ? void 0 : () => a = !0;
    let d = Tt(n.contents, i, () => o = null, c);
    o && (d += lt(d, "", s(o))), (d[0] === "|" || d[0] === ">") && t[t.length - 1] === "---" ? t[t.length - 1] = `--- ${d}` : t.push(d);
  } else
    t.push(Tt(n.contents, i));
  if ((u = n.directives) != null && u.docEnd)
    if (n.comment) {
      const c = s(n.comment);
      c.includes(`
`) ? (t.push("..."), t.push(it(c, ""))) : t.push(`... ${c}`);
    } else
      t.push("...");
  else {
    let c = n.comment;
    c && a && (c = c.replace(/^\n+/, "")), c && ((!a || o) && t[t.length - 1] !== "" && t.push(""), t.push(it(s(c), "")));
  }
  return t.join(`
`) + `
`;
}
function Qt(n, e, t, r) {
  if (r && typeof r == "object")
    if (Array.isArray(r))
      for (let i = 0, s = r.length; i < s; ++i) {
        const a = r[i], o = Qt(n, r, String(i), a);
        o === void 0 ? delete r[i] : o !== a && (r[i] = o);
      }
    else if (r instanceof Map)
      for (const i of Array.from(r.keys())) {
        const s = r.get(i), a = Qt(n, r, i, s);
        a === void 0 ? r.delete(i) : a !== s && r.set(i, a);
      }
    else if (r instanceof Set)
      for (const i of Array.from(r)) {
        const s = Qt(n, r, i, i);
        s === void 0 ? r.delete(i) : s !== i && (r.delete(i), r.add(s));
      }
    else
      for (const [i, s] of Object.entries(r)) {
        const a = Qt(n, r, i, s);
        a === void 0 ? delete r[i] : a !== s && (r[i] = a);
      }
  return n.call(e, t, r);
}
class Di {
  constructor(e, t, r) {
    this.commentBefore = null, this.comment = null, this.errors = [], this.warnings = [], Object.defineProperty(this, Ne, { value: ni });
    let i = null;
    typeof t == "function" || Array.isArray(t) ? i = t : r === void 0 && t && (r = t, t = void 0);
    const s = Object.assign({
      intAsBigInt: !1,
      keepSourceTokens: !1,
      logLevel: "warn",
      prettyErrors: !0,
      strict: !0,
      uniqueKeys: !0,
      version: "1.2"
    }, r);
    this.options = s;
    let { version: a } = s;
    r != null && r._directives ? (this.directives = r._directives.atDocument(), this.directives.yaml.explicit && (a = this.directives.yaml.version)) : this.directives = new Ae({ version: a }), this.setSchema(a, r), e === void 0 ? this.contents = null : this.contents = this.createNode(e, i, r);
  }
  /**
   * Create a deep copy of this Document and its contents.
   *
   * Custom Node values that inherit from `Object` still refer to their original instances.
   */
  clone() {
    const e = Object.create(Di.prototype, {
      [Ne]: { value: ni }
    });
    return e.commentBefore = this.commentBefore, e.comment = this.comment, e.errors = this.errors.slice(), e.warnings = this.warnings.slice(), e.options = Object.assign({}, this.options), this.directives && (e.directives = this.directives.clone()), e.schema = this.schema.clone(), e.contents = he(this.contents) ? this.contents.clone(e.schema) : this.contents, this.range && (e.range = this.range.slice()), e;
  }
  /** Adds a value to the document. */
  add(e) {
    Lt(this.contents) && this.contents.add(e);
  }
  /** Adds a value to the document. */
  addIn(e, t) {
    Lt(this.contents) && this.contents.addIn(e, t);
  }
  /**
   * Create a new `Alias` node, ensuring that the target `node` has the required anchor.
   *
   * If `node` already has an anchor, `name` is ignored.
   * Otherwise, the `node.anchor` value will be set to `name`,
   * or if an anchor with that name is already present in the document,
   * `name` will be used as a prefix for a new unique anchor.
   * If `name` is undefined, the generated anchor will use 'a' as a prefix.
   */
  createAlias(e, t) {
    if (!e.anchor) {
      const r = va(this);
      e.anchor = // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      !t || r.has(t) ? ba(t || "a", r) : t;
    }
    return new _a(e.anchor);
  }
  createNode(e, t, r) {
    let i;
    if (typeof t == "function")
      e = t.call({ "": e }, "", e), i = t;
    else if (Array.isArray(t)) {
      const A = (w) => typeof w == "number" || w instanceof String || w instanceof Number, O = t.filter(A).map(String);
      O.length > 0 && (t = t.concat(O)), i = t;
    } else
      r === void 0 && t && (r = t, t = void 0);
    const { aliasDuplicateObjects: s, anchorPrefix: a, flow: o, keepUndefined: u, onTagObj: c, tag: d } = r ?? {}, { onAnchor: f, setAnchors: h, sourceObjects: p } = Dc(
      this,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      a || "a"
    ), g = {
      aliasDuplicateObjects: s ?? !0,
      keepUndefined: u ?? !1,
      onAnchor: f,
      onTagObj: c,
      replacer: i,
      schema: this.schema,
      sourceObjects: p
    }, _ = dn(e, d, g);
    return o && ce(_) && (_.flow = !0), h(), _;
  }
  /**
   * Convert a key and a value into a `Pair` using the current schema,
   * recursively wrapping all values as `Scalar` or `Collection` nodes.
   */
  createPair(e, t, r = {}) {
    const i = this.createNode(e, null, r), s = this.createNode(t, null, r);
    return new Pe(i, s);
  }
  /**
   * Removes a value from the document.
   * @returns `true` if the item was found and removed.
   */
  delete(e) {
    return Lt(this.contents) ? this.contents.delete(e) : !1;
  }
  /**
   * Removes a value from the document.
   * @returns `true` if the item was found and removed.
   */
  deleteIn(e) {
    return Xt(e) ? this.contents == null ? !1 : (this.contents = null, !0) : Lt(this.contents) ? this.contents.deleteIn(e) : !1;
  }
  /**
   * Returns item at `key`, or `undefined` if not found. By default unwraps
   * scalar values from their surrounding node; to disable set `keepScalar` to
   * `true` (collections are always returned intact).
   */
  get(e, t) {
    return ce(this.contents) ? this.contents.get(e, t) : void 0;
  }
  /**
   * Returns item at `path`, or `undefined` if not found. By default unwraps
   * scalar values from their surrounding node; to disable set `keepScalar` to
   * `true` (collections are always returned intact).
   */
  getIn(e, t) {
    return Xt(e) ? !t && oe(this.contents) ? this.contents.value : this.contents : ce(this.contents) ? this.contents.getIn(e, t) : void 0;
  }
  /**
   * Checks if the document includes a value with the key `key`.
   */
  has(e) {
    return ce(this.contents) ? this.contents.has(e) : !1;
  }
  /**
   * Checks if the document includes a value at `path`.
   */
  hasIn(e) {
    return Xt(e) ? this.contents !== void 0 : ce(this.contents) ? this.contents.hasIn(e) : !1;
  }
  /**
   * Sets a value in this document. For `!!set`, `value` needs to be a
   * boolean to add/remove the item from the set.
   */
  set(e, t) {
    this.contents == null ? this.contents = Qn(this.schema, [e], t) : Lt(this.contents) && this.contents.set(e, t);
  }
  /**
   * Sets a value in this document. For `!!set`, `value` needs to be a
   * boolean to add/remove the item from the set.
   */
  setIn(e, t) {
    Xt(e) ? this.contents = t : this.contents == null ? this.contents = Qn(this.schema, Array.from(e), t) : Lt(this.contents) && this.contents.setIn(e, t);
  }
  /**
   * Change the YAML version and schema used by the document.
   * A `null` version disables support for directives, explicit tags, anchors, and aliases.
   * It also requires the `schema` option to be given as a `Schema` instance value.
   *
   * Overrides all previously set schema options.
   */
  setSchema(e, t = {}) {
    typeof e == "number" && (e = String(e));
    let r;
    switch (e) {
      case "1.1":
        this.directives ? this.directives.yaml.version = "1.1" : this.directives = new Ae({ version: "1.1" }), r = { merge: !0, resolveKnownTags: !1, schema: "yaml-1.1" };
        break;
      case "1.2":
      case "next":
        this.directives ? this.directives.yaml.version = e : this.directives = new Ae({ version: e }), r = { merge: !1, resolveKnownTags: !0, schema: "core" };
        break;
      case null:
        this.directives && delete this.directives, r = null;
        break;
      default: {
        const i = JSON.stringify(e);
        throw new Error(`Expected '1.1', '1.2' or null as first argument, but found: ${i}`);
      }
    }
    if (t.schema instanceof Object)
      this.schema = t.schema;
    else if (r)
      this.schema = new Li(Object.assign(r, t));
    else
      throw new Error("With a null YAML version, the { schema: Schema } option is required");
  }
  // json & jsonArg are only used from toJSON()
  toJS({ json: e, jsonArg: t, mapAsMap: r, maxAliasCount: i, onAnchor: s, reviver: a } = {}) {
    const o = {
      anchors: /* @__PURE__ */ new Map(),
      doc: this,
      keep: !e,
      mapAsMap: r === !0,
      mapKeyWarned: !1,
      maxAliasCount: typeof i == "number" ? i : 100,
      stringify: Tt
    }, u = Ke(this.contents, t ?? "", o);
    if (typeof s == "function")
      for (const { count: c, res: d } of o.anchors.values())
        s(d, c);
    return typeof a == "function" ? Qt(a, { "": u }, "", u) : u;
  }
  /**
   * A JSON representation of the document `contents`.
   *
   * @param jsonArg Used by `JSON.stringify` to indicate the array index or
   *   property name.
   */
  toJSON(e, t) {
    return this.toJS({ json: !0, jsonArg: e, mapAsMap: !1, onAnchor: t });
  }
  /** A YAML representation of the document. */
  toString(e = {}) {
    if (this.errors.length > 0)
      throw new Error("Document with errors cannot be stringified");
    if ("indent" in e && (!Number.isInteger(e.indent) || Number(e.indent) <= 0)) {
      const t = JSON.stringify(e.indent);
      throw new Error(`"indent" option must be a positive integer, not ${t}`);
    }
    return ul(this, e);
  }
}
function Lt(n) {
  if (ce(n))
    return !0;
  throw new Error("Expected a YAML collection as document contents");
}
function cl(n, e, t) {
  let r = null;
  if (typeof e == "function" || Array.isArray(e) ? r = e : t === void 0 && e && (t = e), typeof t == "string" && (t = t.length), typeof t == "number") {
    const i = Math.round(t);
    t = i < 1 ? void 0 : i > 8 ? { indent: 8 } : { indent: i };
  }
  if (n === void 0) {
    const { keepUndefined: i } = t ?? e ?? {};
    if (!i)
      return;
  }
  return new Di(n, r, t).toString(t);
}
class tr {
  static create(e) {
    return new tr(e);
  }
  constructor(e) {
    this.rootDoc = e || {
      openapi: "3.0.0",
      info: {
        title: "app",
        version: "version"
      },
      paths: {},
      components: {
        schemas: {},
        responses: {},
        parameters: {},
        examples: {},
        requestBodies: {},
        headers: {},
        securitySchemes: {},
        links: {},
        callbacks: {}
      },
      tags: [],
      servers: []
    };
  }
  getSpec() {
    return this.rootDoc;
  }
  getSpecAsJson(e, t) {
    return JSON.stringify(this.rootDoc, e, t);
  }
  getSpecAsYaml() {
    return cl(this.rootDoc);
  }
  static isValidOpenApiVersion(e) {
    e = e || "";
    const t = /(\d+)\.(\d+).(\d+)/.exec(e);
    return !!(t && parseInt(t[1], 10) >= 3);
  }
  addOpenApiVersion(e) {
    if (!tr.isValidOpenApiVersion(e))
      throw new Error("Invalid OpenApi version: " + e + ". Follow convention: 3.x.y");
    return this.rootDoc.openapi = e, this;
  }
  addInfo(e) {
    return this.rootDoc.info = e, this;
  }
  addContact(e) {
    return this.rootDoc.info.contact = e, this;
  }
  addLicense(e) {
    return this.rootDoc.info.license = e, this;
  }
  addTitle(e) {
    return this.rootDoc.info.title = e, this;
  }
  addDescription(e) {
    return this.rootDoc.info.description = e, this;
  }
  addTermsOfService(e) {
    return this.rootDoc.info.termsOfService = e, this;
  }
  addVersion(e) {
    return this.rootDoc.info.version = e, this;
  }
  addPath(e, t) {
    return this.rootDoc.paths[e] = { ...this.rootDoc.paths[e] || {}, ...t }, this;
  }
  addSchema(e, t) {
    return this.rootDoc.components.schemas[e] = t, this;
  }
  addResponse(e, t) {
    return this.rootDoc.components.responses[e] = t, this;
  }
  addParameter(e, t) {
    return this.rootDoc.components.parameters[e] = t, this;
  }
  addExample(e, t) {
    return this.rootDoc.components.examples[e] = t, this;
  }
  addRequestBody(e, t) {
    return this.rootDoc.components.requestBodies[e] = t, this;
  }
  addHeader(e, t) {
    return this.rootDoc.components.headers[e] = t, this;
  }
  addSecurityScheme(e, t) {
    return this.rootDoc.components.securitySchemes[e] = t, this;
  }
  addLink(e, t) {
    return this.rootDoc.components.links[e] = t, this;
  }
  addCallback(e, t) {
    return this.rootDoc.components.callbacks[e] = t, this;
  }
  addServer(e) {
    return this.rootDoc.servers.push(e), this;
  }
  addTag(e) {
    return this.rootDoc.tags.push(e), this;
  }
  addExternalDocs(e) {
    return this.rootDoc.externalDocs = e, this;
  }
  addWebhook(e, t) {
    var r;
    return (r = this.rootDoc).webhooks ?? (r.webhooks = {}), this.rootDoc.webhooks[e] = t, this;
  }
}
const ll = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  OpenApiBuilder: tr,
  Server: Sc,
  ServerVariable: Ec,
  SpecificationExtension: ht,
  addExtension: _c,
  getExtension: bc,
  getPath: Oc,
  isReferenceObject: Ac,
  isSchemaObject: wc
}, Symbol.toStringTag, { value: "Module" })), fl = /* @__PURE__ */ su(ll);
(function(n) {
  var e = ne && ne.__createBinding || (Object.create ? function(u, c, d, f) {
    f === void 0 && (f = d);
    var h = Object.getOwnPropertyDescriptor(c, d);
    (!h || ("get" in h ? !c.__esModule : h.writable || h.configurable)) && (h = { enumerable: !0, get: function() {
      return c[d];
    } }), Object.defineProperty(u, f, h);
  } : function(u, c, d, f) {
    f === void 0 && (f = d), u[f] = c[d];
  }), t = ne && ne.__setModuleDefault || (Object.create ? function(u, c) {
    Object.defineProperty(u, "default", { enumerable: !0, value: c });
  } : function(u, c) {
    u.default = c;
  }), r = ne && ne.__exportStar || function(u, c) {
    for (var d in u)
      d !== "default" && !Object.prototype.hasOwnProperty.call(c, d) && e(c, u, d);
  }, i = ne && ne.__importStar || function(u) {
    if (u && u.__esModule)
      return u;
    var c = {};
    if (u != null)
      for (var d in u)
        d !== "default" && Object.prototype.hasOwnProperty.call(u, d) && e(c, u, d);
    return t(c, u), c;
  };
  Object.defineProperty(n, "__esModule", { value: !0 }), n.OpenAPI = n.OpenAPIRegistry = n.OpenAPIGenerator = n.extendZodWithOpenApi = void 0;
  var s = dr;
  Object.defineProperty(n, "extendZodWithOpenApi", { enumerable: !0, get: function() {
    return s.extendZodWithOpenApi;
  } }), r(hr, n);
  var a = pr;
  Object.defineProperty(n, "OpenAPIGenerator", { enumerable: !0, get: function() {
    return a.OpenAPIGenerator;
  } });
  var o = yr;
  Object.defineProperty(n, "OpenAPIRegistry", { enumerable: !0, get: function() {
    return o.OpenAPIRegistry;
  } }), n.OpenAPI = i(fl);
})(vi);
var W;
(function(n) {
  n.assertEqual = (i) => i;
  function e(i) {
  }
  n.assertIs = e;
  function t(i) {
    throw new Error();
  }
  n.assertNever = t, n.arrayToEnum = (i) => {
    const s = {};
    for (const a of i)
      s[a] = a;
    return s;
  }, n.getValidEnumValues = (i) => {
    const s = n.objectKeys(i).filter((o) => typeof i[i[o]] != "number"), a = {};
    for (const o of s)
      a[o] = i[o];
    return n.objectValues(a);
  }, n.objectValues = (i) => n.objectKeys(i).map(function(s) {
    return i[s];
  }), n.objectKeys = typeof Object.keys == "function" ? (i) => Object.keys(i) : (i) => {
    const s = [];
    for (const a in i)
      Object.prototype.hasOwnProperty.call(i, a) && s.push(a);
    return s;
  }, n.find = (i, s) => {
    for (const a of i)
      if (s(a))
        return a;
  }, n.isInteger = typeof Number.isInteger == "function" ? (i) => Number.isInteger(i) : (i) => typeof i == "number" && isFinite(i) && Math.floor(i) === i;
  function r(i, s = " | ") {
    return i.map((a) => typeof a == "string" ? `'${a}'` : a).join(s);
  }
  n.joinValues = r, n.jsonStringifyReplacer = (i, s) => typeof s == "bigint" ? s.toString() : s;
})(W || (W = {}));
var si;
(function(n) {
  n.mergeShapes = (e, t) => ({
    ...e,
    ...t
    // second overwrites first
  });
})(si || (si = {}));
const D = W.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), ct = (n) => {
  switch (typeof n) {
    case "undefined":
      return D.undefined;
    case "string":
      return D.string;
    case "number":
      return isNaN(n) ? D.nan : D.number;
    case "boolean":
      return D.boolean;
    case "function":
      return D.function;
    case "bigint":
      return D.bigint;
    case "symbol":
      return D.symbol;
    case "object":
      return Array.isArray(n) ? D.array : n === null ? D.null : n.then && typeof n.then == "function" && n.catch && typeof n.catch == "function" ? D.promise : typeof Map < "u" && n instanceof Map ? D.map : typeof Set < "u" && n instanceof Set ? D.set : typeof Date < "u" && n instanceof Date ? D.date : D.object;
    default:
      return D.unknown;
  }
}, k = W.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), dl = (n) => JSON.stringify(n, null, 2).replace(/"([^"]+)":/g, "$1:");
class Ee extends Error {
  constructor(e) {
    super(), this.issues = [], this.addIssue = (r) => {
      this.issues = [...this.issues, r];
    }, this.addIssues = (r = []) => {
      this.issues = [...this.issues, ...r];
    };
    const t = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, t) : this.__proto__ = t, this.name = "ZodError", this.issues = e;
  }
  get errors() {
    return this.issues;
  }
  format(e) {
    const t = e || function(s) {
      return s.message;
    }, r = { _errors: [] }, i = (s) => {
      for (const a of s.issues)
        if (a.code === "invalid_union")
          a.unionErrors.map(i);
        else if (a.code === "invalid_return_type")
          i(a.returnTypeError);
        else if (a.code === "invalid_arguments")
          i(a.argumentsError);
        else if (a.path.length === 0)
          r._errors.push(t(a));
        else {
          let o = r, u = 0;
          for (; u < a.path.length; ) {
            const c = a.path[u];
            u === a.path.length - 1 ? (o[c] = o[c] || { _errors: [] }, o[c]._errors.push(t(a))) : o[c] = o[c] || { _errors: [] }, o = o[c], u++;
          }
        }
    };
    return i(this), r;
  }
  static assert(e) {
    if (!(e instanceof Ee))
      throw new Error(`Not a ZodError: ${e}`);
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, W.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(e = (t) => t.message) {
    const t = {}, r = [];
    for (const i of this.issues)
      i.path.length > 0 ? (t[i.path[0]] = t[i.path[0]] || [], t[i.path[0]].push(e(i))) : r.push(e(i));
    return { formErrors: r, fieldErrors: t };
  }
  get formErrors() {
    return this.flatten();
  }
}
Ee.create = (n) => new Ee(n);
const Zt = (n, e) => {
  let t;
  switch (n.code) {
    case k.invalid_type:
      n.received === D.undefined ? t = "Required" : t = `Expected ${n.expected}, received ${n.received}`;
      break;
    case k.invalid_literal:
      t = `Invalid literal value, expected ${JSON.stringify(n.expected, W.jsonStringifyReplacer)}`;
      break;
    case k.unrecognized_keys:
      t = `Unrecognized key(s) in object: ${W.joinValues(n.keys, ", ")}`;
      break;
    case k.invalid_union:
      t = "Invalid input";
      break;
    case k.invalid_union_discriminator:
      t = `Invalid discriminator value. Expected ${W.joinValues(n.options)}`;
      break;
    case k.invalid_enum_value:
      t = `Invalid enum value. Expected ${W.joinValues(n.options)}, received '${n.received}'`;
      break;
    case k.invalid_arguments:
      t = "Invalid function arguments";
      break;
    case k.invalid_return_type:
      t = "Invalid function return type";
      break;
    case k.invalid_date:
      t = "Invalid date";
      break;
    case k.invalid_string:
      typeof n.validation == "object" ? "includes" in n.validation ? (t = `Invalid input: must include "${n.validation.includes}"`, typeof n.validation.position == "number" && (t = `${t} at one or more positions greater than or equal to ${n.validation.position}`)) : "startsWith" in n.validation ? t = `Invalid input: must start with "${n.validation.startsWith}"` : "endsWith" in n.validation ? t = `Invalid input: must end with "${n.validation.endsWith}"` : W.assertNever(n.validation) : n.validation !== "regex" ? t = `Invalid ${n.validation}` : t = "Invalid";
      break;
    case k.too_small:
      n.type === "array" ? t = `Array must contain ${n.exact ? "exactly" : n.inclusive ? "at least" : "more than"} ${n.minimum} element(s)` : n.type === "string" ? t = `String must contain ${n.exact ? "exactly" : n.inclusive ? "at least" : "over"} ${n.minimum} character(s)` : n.type === "number" ? t = `Number must be ${n.exact ? "exactly equal to " : n.inclusive ? "greater than or equal to " : "greater than "}${n.minimum}` : n.type === "date" ? t = `Date must be ${n.exact ? "exactly equal to " : n.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(n.minimum))}` : t = "Invalid input";
      break;
    case k.too_big:
      n.type === "array" ? t = `Array must contain ${n.exact ? "exactly" : n.inclusive ? "at most" : "less than"} ${n.maximum} element(s)` : n.type === "string" ? t = `String must contain ${n.exact ? "exactly" : n.inclusive ? "at most" : "under"} ${n.maximum} character(s)` : n.type === "number" ? t = `Number must be ${n.exact ? "exactly" : n.inclusive ? "less than or equal to" : "less than"} ${n.maximum}` : n.type === "bigint" ? t = `BigInt must be ${n.exact ? "exactly" : n.inclusive ? "less than or equal to" : "less than"} ${n.maximum}` : n.type === "date" ? t = `Date must be ${n.exact ? "exactly" : n.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(n.maximum))}` : t = "Invalid input";
      break;
    case k.custom:
      t = "Invalid input";
      break;
    case k.invalid_intersection_types:
      t = "Intersection results could not be merged";
      break;
    case k.not_multiple_of:
      t = `Number must be a multiple of ${n.multipleOf}`;
      break;
    case k.not_finite:
      t = "Number must be finite";
      break;
    default:
      t = e.defaultError, W.assertNever(n);
  }
  return { message: t };
};
let Ua = Zt;
function hl(n) {
  Ua = n;
}
function nr() {
  return Ua;
}
const rr = (n) => {
  const { data: e, path: t, errorMaps: r, issueData: i } = n, s = [...t, ...i.path || []], a = {
    ...i,
    path: s
  };
  if (i.message !== void 0)
    return {
      ...i,
      path: s,
      message: i.message
    };
  let o = "";
  const u = r.filter((c) => !!c).slice().reverse();
  for (const c of u)
    o = c(a, { data: e, defaultError: o }).message;
  return {
    ...i,
    path: s,
    message: o
  };
}, pl = [];
function L(n, e) {
  const t = nr(), r = rr({
    issueData: e,
    data: n.data,
    path: n.path,
    errorMaps: [
      n.common.contextualErrorMap,
      n.schemaErrorMap,
      t,
      t === Zt ? void 0 : Zt
      // then global default map
    ].filter((i) => !!i)
  });
  n.common.issues.push(r);
}
class ve {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(e, t) {
    const r = [];
    for (const i of t) {
      if (i.status === "aborted")
        return Z;
      i.status === "dirty" && e.dirty(), r.push(i.value);
    }
    return { status: e.value, value: r };
  }
  static async mergeObjectAsync(e, t) {
    const r = [];
    for (const i of t) {
      const s = await i.key, a = await i.value;
      r.push({
        key: s,
        value: a
      });
    }
    return ve.mergeObjectSync(e, r);
  }
  static mergeObjectSync(e, t) {
    const r = {};
    for (const i of t) {
      const { key: s, value: a } = i;
      if (s.status === "aborted" || a.status === "aborted")
        return Z;
      s.status === "dirty" && e.dirty(), a.status === "dirty" && e.dirty(), s.value !== "__proto__" && (typeof a.value < "u" || i.alwaysSet) && (r[s.value] = a.value);
    }
    return { status: e.value, value: r };
  }
}
const Z = Object.freeze({
  status: "aborted"
}), jt = (n) => ({ status: "dirty", value: n }), _e = (n) => ({ status: "valid", value: n }), ai = (n) => n.status === "aborted", oi = (n) => n.status === "dirty", hn = (n) => n.status === "valid", pn = (n) => typeof Promise < "u" && n instanceof Promise;
function ir(n, e, t, r) {
  if (t === "a" && !r)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof e == "function" ? n !== e || !r : !e.has(n))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return t === "m" ? r : t === "a" ? r.call(n) : r ? r.value : e.get(n);
}
function Za(n, e, t, r, i) {
  if (r === "m")
    throw new TypeError("Private method is not writable");
  if (r === "a" && !i)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof e == "function" ? n !== e || !i : !e.has(n))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return r === "a" ? i.call(n, t) : i ? i.value = t : e.set(n, t), t;
}
var x;
(function(n) {
  n.errToObj = (e) => typeof e == "string" ? { message: e } : e || {}, n.toString = (e) => typeof e == "string" ? e : e == null ? void 0 : e.message;
})(x || (x = {}));
var en, tn;
class ze {
  constructor(e, t, r, i) {
    this._cachedPath = [], this.parent = e, this.data = t, this._path = r, this._key = i;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const Is = (n, e) => {
  if (hn(e))
    return { success: !0, data: e.value };
  if (!n.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const t = new Ee(n.common.issues);
      return this._error = t, this._error;
    }
  };
};
function V(n) {
  if (!n)
    return {};
  const { errorMap: e, invalid_type_error: t, required_error: r, description: i } = n;
  if (e && (t || r))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return e ? { errorMap: e, description: i } : { errorMap: (a, o) => {
    var u, c;
    const { message: d } = n;
    return a.code === "invalid_enum_value" ? { message: d ?? o.defaultError } : typeof o.data > "u" ? { message: (u = d ?? r) !== null && u !== void 0 ? u : o.defaultError } : a.code !== "invalid_type" ? { message: o.defaultError } : { message: (c = d ?? t) !== null && c !== void 0 ? c : o.defaultError };
  }, description: i };
}
class G {
  constructor(e) {
    this.spa = this.safeParseAsync, this._def = e, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(e) {
    return ct(e.data);
  }
  _getOrReturnCtx(e, t) {
    return t || {
      common: e.parent.common,
      data: e.data,
      parsedType: ct(e.data),
      schemaErrorMap: this._def.errorMap,
      path: e.path,
      parent: e.parent
    };
  }
  _processInputParams(e) {
    return {
      status: new ve(),
      ctx: {
        common: e.parent.common,
        data: e.data,
        parsedType: ct(e.data),
        schemaErrorMap: this._def.errorMap,
        path: e.path,
        parent: e.parent
      }
    };
  }
  _parseSync(e) {
    const t = this._parse(e);
    if (pn(t))
      throw new Error("Synchronous parse encountered promise.");
    return t;
  }
  _parseAsync(e) {
    const t = this._parse(e);
    return Promise.resolve(t);
  }
  parse(e, t) {
    const r = this.safeParse(e, t);
    if (r.success)
      return r.data;
    throw r.error;
  }
  safeParse(e, t) {
    var r;
    const i = {
      common: {
        issues: [],
        async: (r = t == null ? void 0 : t.async) !== null && r !== void 0 ? r : !1,
        contextualErrorMap: t == null ? void 0 : t.errorMap
      },
      path: (t == null ? void 0 : t.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: e,
      parsedType: ct(e)
    }, s = this._parseSync({ data: e, path: i.path, parent: i });
    return Is(i, s);
  }
  async parseAsync(e, t) {
    const r = await this.safeParseAsync(e, t);
    if (r.success)
      return r.data;
    throw r.error;
  }
  async safeParseAsync(e, t) {
    const r = {
      common: {
        issues: [],
        contextualErrorMap: t == null ? void 0 : t.errorMap,
        async: !0
      },
      path: (t == null ? void 0 : t.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: e,
      parsedType: ct(e)
    }, i = this._parse({ data: e, path: r.path, parent: r }), s = await (pn(i) ? i : Promise.resolve(i));
    return Is(r, s);
  }
  refine(e, t) {
    const r = (i) => typeof t == "string" || typeof t > "u" ? { message: t } : typeof t == "function" ? t(i) : t;
    return this._refinement((i, s) => {
      const a = e(i), o = () => s.addIssue({
        code: k.custom,
        ...r(i)
      });
      return typeof Promise < "u" && a instanceof Promise ? a.then((u) => u ? !0 : (o(), !1)) : a ? !0 : (o(), !1);
    });
  }
  refinement(e, t) {
    return this._refinement((r, i) => e(r) ? !0 : (i.addIssue(typeof t == "function" ? t(r, i) : t), !1));
  }
  _refinement(e) {
    return new $e({
      schema: this,
      typeName: H.ZodEffects,
      effect: { type: "refinement", refinement: e }
    });
  }
  superRefine(e) {
    return this._refinement(e);
  }
  optional() {
    return qe.create(this, this._def);
  }
  nullable() {
    return _t.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return je.create(this, this._def);
  }
  promise() {
    return Vt.create(this, this._def);
  }
  or(e) {
    return vn.create([this, e], this._def);
  }
  and(e) {
    return bn.create(this, e, this._def);
  }
  transform(e) {
    return new $e({
      ...V(this._def),
      schema: this,
      typeName: H.ZodEffects,
      effect: { type: "transform", transform: e }
    });
  }
  default(e) {
    const t = typeof e == "function" ? e : () => e;
    return new Sn({
      ...V(this._def),
      innerType: this,
      defaultValue: t,
      typeName: H.ZodDefault
    });
  }
  brand() {
    return new Ci({
      typeName: H.ZodBranded,
      type: this,
      ...V(this._def)
    });
  }
  catch(e) {
    const t = typeof e == "function" ? e : () => e;
    return new En({
      ...V(this._def),
      innerType: this,
      catchValue: t,
      typeName: H.ZodCatch
    });
  }
  describe(e) {
    const t = this.constructor;
    return new t({
      ...this._def,
      description: e
    });
  }
  pipe(e) {
    return Nn.create(this, e);
  }
  readonly() {
    return kn.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const ml = /^c[^\s-]{8,}$/i, gl = /^[0-9a-z]+$/, yl = /^[0-9A-HJKMNP-TV-Z]{26}$/, vl = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, bl = /^[a-z0-9_-]{21}$/i, _l = /^[-+]?P(?!$)(?:(?:[-+]?\d+Y)|(?:[-+]?\d+[.,]\d+Y$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:(?:[-+]?\d+W)|(?:[-+]?\d+[.,]\d+W$))?(?:(?:[-+]?\d+D)|(?:[-+]?\d+[.,]\d+D$))?(?:T(?=[\d+-])(?:(?:[-+]?\d+H)|(?:[-+]?\d+[.,]\d+H$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:[-+]?\d+(?:[.,]\d+)?S)?)??$/, Ol = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, Al = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let jr;
const wl = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/, Sl = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, El = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/, Fa = "((\\d\\d[2468][048]|\\d\\d[13579][26]|\\d\\d0[48]|[02468][048]00|[13579][26]00)-02-29|\\d{4}-((0[13578]|1[02])-(0[1-9]|[12]\\d|3[01])|(0[469]|11)-(0[1-9]|[12]\\d|30)|(02)-(0[1-9]|1\\d|2[0-8])))", kl = new RegExp(`^${Fa}$`);
function Va(n) {
  let e = "([01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d";
  return n.precision ? e = `${e}\\.\\d{${n.precision}}` : n.precision == null && (e = `${e}(\\.\\d+)?`), e;
}
function Tl(n) {
  return new RegExp(`^${Va(n)}$`);
}
function Ga(n) {
  let e = `${Fa}T${Va(n)}`;
  const t = [];
  return t.push(n.local ? "Z?" : "Z"), n.offset && t.push("([+-]\\d{2}:?\\d{2})"), e = `${e}(${t.join("|")})`, new RegExp(`^${e}$`);
}
function Ml(n, e) {
  return !!((e === "v4" || !e) && wl.test(n) || (e === "v6" || !e) && Sl.test(n));
}
class xe extends G {
  _parse(e) {
    if (this._def.coerce && (e.data = String(e.data)), this._getType(e) !== D.string) {
      const s = this._getOrReturnCtx(e);
      return L(s, {
        code: k.invalid_type,
        expected: D.string,
        received: s.parsedType
      }), Z;
    }
    const r = new ve();
    let i;
    for (const s of this._def.checks)
      if (s.kind === "min")
        e.data.length < s.value && (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), r.dirty());
      else if (s.kind === "max")
        e.data.length > s.value && (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), r.dirty());
      else if (s.kind === "length") {
        const a = e.data.length > s.value, o = e.data.length < s.value;
        (a || o) && (i = this._getOrReturnCtx(e, i), a ? L(i, {
          code: k.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }) : o && L(i, {
          code: k.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }), r.dirty());
      } else if (s.kind === "email")
        Ol.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "email",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "emoji")
        jr || (jr = new RegExp(Al, "u")), jr.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "emoji",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "uuid")
        vl.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "uuid",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "nanoid")
        bl.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "nanoid",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "cuid")
        ml.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "cuid",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "cuid2")
        gl.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "cuid2",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "ulid")
        yl.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "ulid",
          code: k.invalid_string,
          message: s.message
        }), r.dirty());
      else if (s.kind === "url")
        try {
          new URL(e.data);
        } catch {
          i = this._getOrReturnCtx(e, i), L(i, {
            validation: "url",
            code: k.invalid_string,
            message: s.message
          }), r.dirty();
        }
      else
        s.kind === "regex" ? (s.regex.lastIndex = 0, s.regex.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "regex",
          code: k.invalid_string,
          message: s.message
        }), r.dirty())) : s.kind === "trim" ? e.data = e.data.trim() : s.kind === "includes" ? e.data.includes(s.value, s.position) || (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.invalid_string,
          validation: { includes: s.value, position: s.position },
          message: s.message
        }), r.dirty()) : s.kind === "toLowerCase" ? e.data = e.data.toLowerCase() : s.kind === "toUpperCase" ? e.data = e.data.toUpperCase() : s.kind === "startsWith" ? e.data.startsWith(s.value) || (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.invalid_string,
          validation: { startsWith: s.value },
          message: s.message
        }), r.dirty()) : s.kind === "endsWith" ? e.data.endsWith(s.value) || (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.invalid_string,
          validation: { endsWith: s.value },
          message: s.message
        }), r.dirty()) : s.kind === "datetime" ? Ga(s).test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.invalid_string,
          validation: "datetime",
          message: s.message
        }), r.dirty()) : s.kind === "date" ? kl.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.invalid_string,
          validation: "date",
          message: s.message
        }), r.dirty()) : s.kind === "time" ? Tl(s).test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          code: k.invalid_string,
          validation: "time",
          message: s.message
        }), r.dirty()) : s.kind === "duration" ? _l.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "duration",
          code: k.invalid_string,
          message: s.message
        }), r.dirty()) : s.kind === "ip" ? Ml(e.data, s.version) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "ip",
          code: k.invalid_string,
          message: s.message
        }), r.dirty()) : s.kind === "base64" ? El.test(e.data) || (i = this._getOrReturnCtx(e, i), L(i, {
          validation: "base64",
          code: k.invalid_string,
          message: s.message
        }), r.dirty()) : W.assertNever(s);
    return { status: r.value, value: e.data };
  }
  _regex(e, t, r) {
    return this.refinement((i) => e.test(i), {
      validation: t,
      code: k.invalid_string,
      ...x.errToObj(r)
    });
  }
  _addCheck(e) {
    return new xe({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  email(e) {
    return this._addCheck({ kind: "email", ...x.errToObj(e) });
  }
  url(e) {
    return this._addCheck({ kind: "url", ...x.errToObj(e) });
  }
  emoji(e) {
    return this._addCheck({ kind: "emoji", ...x.errToObj(e) });
  }
  uuid(e) {
    return this._addCheck({ kind: "uuid", ...x.errToObj(e) });
  }
  nanoid(e) {
    return this._addCheck({ kind: "nanoid", ...x.errToObj(e) });
  }
  cuid(e) {
    return this._addCheck({ kind: "cuid", ...x.errToObj(e) });
  }
  cuid2(e) {
    return this._addCheck({ kind: "cuid2", ...x.errToObj(e) });
  }
  ulid(e) {
    return this._addCheck({ kind: "ulid", ...x.errToObj(e) });
  }
  base64(e) {
    return this._addCheck({ kind: "base64", ...x.errToObj(e) });
  }
  ip(e) {
    return this._addCheck({ kind: "ip", ...x.errToObj(e) });
  }
  datetime(e) {
    var t, r;
    return typeof e == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      local: !1,
      message: e
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (e == null ? void 0 : e.precision) > "u" ? null : e == null ? void 0 : e.precision,
      offset: (t = e == null ? void 0 : e.offset) !== null && t !== void 0 ? t : !1,
      local: (r = e == null ? void 0 : e.local) !== null && r !== void 0 ? r : !1,
      ...x.errToObj(e == null ? void 0 : e.message)
    });
  }
  date(e) {
    return this._addCheck({ kind: "date", message: e });
  }
  time(e) {
    return typeof e == "string" ? this._addCheck({
      kind: "time",
      precision: null,
      message: e
    }) : this._addCheck({
      kind: "time",
      precision: typeof (e == null ? void 0 : e.precision) > "u" ? null : e == null ? void 0 : e.precision,
      ...x.errToObj(e == null ? void 0 : e.message)
    });
  }
  duration(e) {
    return this._addCheck({ kind: "duration", ...x.errToObj(e) });
  }
  regex(e, t) {
    return this._addCheck({
      kind: "regex",
      regex: e,
      ...x.errToObj(t)
    });
  }
  includes(e, t) {
    return this._addCheck({
      kind: "includes",
      value: e,
      position: t == null ? void 0 : t.position,
      ...x.errToObj(t == null ? void 0 : t.message)
    });
  }
  startsWith(e, t) {
    return this._addCheck({
      kind: "startsWith",
      value: e,
      ...x.errToObj(t)
    });
  }
  endsWith(e, t) {
    return this._addCheck({
      kind: "endsWith",
      value: e,
      ...x.errToObj(t)
    });
  }
  min(e, t) {
    return this._addCheck({
      kind: "min",
      value: e,
      ...x.errToObj(t)
    });
  }
  max(e, t) {
    return this._addCheck({
      kind: "max",
      value: e,
      ...x.errToObj(t)
    });
  }
  length(e, t) {
    return this._addCheck({
      kind: "length",
      value: e,
      ...x.errToObj(t)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(e) {
    return this.min(1, x.errToObj(e));
  }
  trim() {
    return new xe({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new xe({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new xe({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((e) => e.kind === "datetime");
  }
  get isDate() {
    return !!this._def.checks.find((e) => e.kind === "date");
  }
  get isTime() {
    return !!this._def.checks.find((e) => e.kind === "time");
  }
  get isDuration() {
    return !!this._def.checks.find((e) => e.kind === "duration");
  }
  get isEmail() {
    return !!this._def.checks.find((e) => e.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((e) => e.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((e) => e.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((e) => e.kind === "uuid");
  }
  get isNANOID() {
    return !!this._def.checks.find((e) => e.kind === "nanoid");
  }
  get isCUID() {
    return !!this._def.checks.find((e) => e.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((e) => e.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((e) => e.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((e) => e.kind === "ip");
  }
  get isBase64() {
    return !!this._def.checks.find((e) => e.kind === "base64");
  }
  get minLength() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "min" && (e === null || t.value > e) && (e = t.value);
    return e;
  }
  get maxLength() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "max" && (e === null || t.value < e) && (e = t.value);
    return e;
  }
}
xe.create = (n) => {
  var e;
  return new xe({
    checks: [],
    typeName: H.ZodString,
    coerce: (e = n == null ? void 0 : n.coerce) !== null && e !== void 0 ? e : !1,
    ...V(n)
  });
};
function Il(n, e) {
  const t = (n.toString().split(".")[1] || "").length, r = (e.toString().split(".")[1] || "").length, i = t > r ? t : r, s = parseInt(n.toFixed(i).replace(".", "")), a = parseInt(e.toFixed(i).replace(".", ""));
  return s % a / Math.pow(10, i);
}
class yt extends G {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(e) {
    if (this._def.coerce && (e.data = Number(e.data)), this._getType(e) !== D.number) {
      const s = this._getOrReturnCtx(e);
      return L(s, {
        code: k.invalid_type,
        expected: D.number,
        received: s.parsedType
      }), Z;
    }
    let r;
    const i = new ve();
    for (const s of this._def.checks)
      s.kind === "int" ? W.isInteger(e.data) || (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.invalid_type,
        expected: "integer",
        received: "float",
        message: s.message
      }), i.dirty()) : s.kind === "min" ? (s.inclusive ? e.data < s.value : e.data <= s.value) && (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.too_small,
        minimum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), i.dirty()) : s.kind === "max" ? (s.inclusive ? e.data > s.value : e.data >= s.value) && (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.too_big,
        maximum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), i.dirty()) : s.kind === "multipleOf" ? Il(e.data, s.value) !== 0 && (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), i.dirty()) : s.kind === "finite" ? Number.isFinite(e.data) || (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.not_finite,
        message: s.message
      }), i.dirty()) : W.assertNever(s);
    return { status: i.value, value: e.data };
  }
  gte(e, t) {
    return this.setLimit("min", e, !0, x.toString(t));
  }
  gt(e, t) {
    return this.setLimit("min", e, !1, x.toString(t));
  }
  lte(e, t) {
    return this.setLimit("max", e, !0, x.toString(t));
  }
  lt(e, t) {
    return this.setLimit("max", e, !1, x.toString(t));
  }
  setLimit(e, t, r, i) {
    return new yt({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: e,
          value: t,
          inclusive: r,
          message: x.toString(i)
        }
      ]
    });
  }
  _addCheck(e) {
    return new yt({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  int(e) {
    return this._addCheck({
      kind: "int",
      message: x.toString(e)
    });
  }
  positive(e) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: x.toString(e)
    });
  }
  negative(e) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: x.toString(e)
    });
  }
  nonpositive(e) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: x.toString(e)
    });
  }
  nonnegative(e) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: x.toString(e)
    });
  }
  multipleOf(e, t) {
    return this._addCheck({
      kind: "multipleOf",
      value: e,
      message: x.toString(t)
    });
  }
  finite(e) {
    return this._addCheck({
      kind: "finite",
      message: x.toString(e)
    });
  }
  safe(e) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: x.toString(e)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: x.toString(e)
    });
  }
  get minValue() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "min" && (e === null || t.value > e) && (e = t.value);
    return e;
  }
  get maxValue() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "max" && (e === null || t.value < e) && (e = t.value);
    return e;
  }
  get isInt() {
    return !!this._def.checks.find((e) => e.kind === "int" || e.kind === "multipleOf" && W.isInteger(e.value));
  }
  get isFinite() {
    let e = null, t = null;
    for (const r of this._def.checks) {
      if (r.kind === "finite" || r.kind === "int" || r.kind === "multipleOf")
        return !0;
      r.kind === "min" ? (t === null || r.value > t) && (t = r.value) : r.kind === "max" && (e === null || r.value < e) && (e = r.value);
    }
    return Number.isFinite(t) && Number.isFinite(e);
  }
}
yt.create = (n) => new yt({
  checks: [],
  typeName: H.ZodNumber,
  coerce: (n == null ? void 0 : n.coerce) || !1,
  ...V(n)
});
class vt extends G {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(e) {
    if (this._def.coerce && (e.data = BigInt(e.data)), this._getType(e) !== D.bigint) {
      const s = this._getOrReturnCtx(e);
      return L(s, {
        code: k.invalid_type,
        expected: D.bigint,
        received: s.parsedType
      }), Z;
    }
    let r;
    const i = new ve();
    for (const s of this._def.checks)
      s.kind === "min" ? (s.inclusive ? e.data < s.value : e.data <= s.value) && (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.too_small,
        type: "bigint",
        minimum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), i.dirty()) : s.kind === "max" ? (s.inclusive ? e.data > s.value : e.data >= s.value) && (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.too_big,
        type: "bigint",
        maximum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), i.dirty()) : s.kind === "multipleOf" ? e.data % s.value !== BigInt(0) && (r = this._getOrReturnCtx(e, r), L(r, {
        code: k.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), i.dirty()) : W.assertNever(s);
    return { status: i.value, value: e.data };
  }
  gte(e, t) {
    return this.setLimit("min", e, !0, x.toString(t));
  }
  gt(e, t) {
    return this.setLimit("min", e, !1, x.toString(t));
  }
  lte(e, t) {
    return this.setLimit("max", e, !0, x.toString(t));
  }
  lt(e, t) {
    return this.setLimit("max", e, !1, x.toString(t));
  }
  setLimit(e, t, r, i) {
    return new vt({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: e,
          value: t,
          inclusive: r,
          message: x.toString(i)
        }
      ]
    });
  }
  _addCheck(e) {
    return new vt({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  positive(e) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: x.toString(e)
    });
  }
  negative(e) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: x.toString(e)
    });
  }
  nonpositive(e) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: x.toString(e)
    });
  }
  nonnegative(e) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: x.toString(e)
    });
  }
  multipleOf(e, t) {
    return this._addCheck({
      kind: "multipleOf",
      value: e,
      message: x.toString(t)
    });
  }
  get minValue() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "min" && (e === null || t.value > e) && (e = t.value);
    return e;
  }
  get maxValue() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "max" && (e === null || t.value < e) && (e = t.value);
    return e;
  }
}
vt.create = (n) => {
  var e;
  return new vt({
    checks: [],
    typeName: H.ZodBigInt,
    coerce: (e = n == null ? void 0 : n.coerce) !== null && e !== void 0 ? e : !1,
    ...V(n)
  });
};
class mn extends G {
  _parse(e) {
    if (this._def.coerce && (e.data = !!e.data), this._getType(e) !== D.boolean) {
      const r = this._getOrReturnCtx(e);
      return L(r, {
        code: k.invalid_type,
        expected: D.boolean,
        received: r.parsedType
      }), Z;
    }
    return _e(e.data);
  }
}
mn.create = (n) => new mn({
  typeName: H.ZodBoolean,
  coerce: (n == null ? void 0 : n.coerce) || !1,
  ...V(n)
});
class Mt extends G {
  _parse(e) {
    if (this._def.coerce && (e.data = new Date(e.data)), this._getType(e) !== D.date) {
      const s = this._getOrReturnCtx(e);
      return L(s, {
        code: k.invalid_type,
        expected: D.date,
        received: s.parsedType
      }), Z;
    }
    if (isNaN(e.data.getTime())) {
      const s = this._getOrReturnCtx(e);
      return L(s, {
        code: k.invalid_date
      }), Z;
    }
    const r = new ve();
    let i;
    for (const s of this._def.checks)
      s.kind === "min" ? e.data.getTime() < s.value && (i = this._getOrReturnCtx(e, i), L(i, {
        code: k.too_small,
        message: s.message,
        inclusive: !0,
        exact: !1,
        minimum: s.value,
        type: "date"
      }), r.dirty()) : s.kind === "max" ? e.data.getTime() > s.value && (i = this._getOrReturnCtx(e, i), L(i, {
        code: k.too_big,
        message: s.message,
        inclusive: !0,
        exact: !1,
        maximum: s.value,
        type: "date"
      }), r.dirty()) : W.assertNever(s);
    return {
      status: r.value,
      value: new Date(e.data.getTime())
    };
  }
  _addCheck(e) {
    return new Mt({
      ...this._def,
      checks: [...this._def.checks, e]
    });
  }
  min(e, t) {
    return this._addCheck({
      kind: "min",
      value: e.getTime(),
      message: x.toString(t)
    });
  }
  max(e, t) {
    return this._addCheck({
      kind: "max",
      value: e.getTime(),
      message: x.toString(t)
    });
  }
  get minDate() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "min" && (e === null || t.value > e) && (e = t.value);
    return e != null ? new Date(e) : null;
  }
  get maxDate() {
    let e = null;
    for (const t of this._def.checks)
      t.kind === "max" && (e === null || t.value < e) && (e = t.value);
    return e != null ? new Date(e) : null;
  }
}
Mt.create = (n) => new Mt({
  checks: [],
  coerce: (n == null ? void 0 : n.coerce) || !1,
  typeName: H.ZodDate,
  ...V(n)
});
class sr extends G {
  _parse(e) {
    if (this._getType(e) !== D.symbol) {
      const r = this._getOrReturnCtx(e);
      return L(r, {
        code: k.invalid_type,
        expected: D.symbol,
        received: r.parsedType
      }), Z;
    }
    return _e(e.data);
  }
}
sr.create = (n) => new sr({
  typeName: H.ZodSymbol,
  ...V(n)
});
class gn extends G {
  _parse(e) {
    if (this._getType(e) !== D.undefined) {
      const r = this._getOrReturnCtx(e);
      return L(r, {
        code: k.invalid_type,
        expected: D.undefined,
        received: r.parsedType
      }), Z;
    }
    return _e(e.data);
  }
}
gn.create = (n) => new gn({
  typeName: H.ZodUndefined,
  ...V(n)
});
class yn extends G {
  _parse(e) {
    if (this._getType(e) !== D.null) {
      const r = this._getOrReturnCtx(e);
      return L(r, {
        code: k.invalid_type,
        expected: D.null,
        received: r.parsedType
      }), Z;
    }
    return _e(e.data);
  }
}
yn.create = (n) => new yn({
  typeName: H.ZodNull,
  ...V(n)
});
class Ft extends G {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(e) {
    return _e(e.data);
  }
}
Ft.create = (n) => new Ft({
  typeName: H.ZodAny,
  ...V(n)
});
class St extends G {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(e) {
    return _e(e.data);
  }
}
St.create = (n) => new St({
  typeName: H.ZodUnknown,
  ...V(n)
});
class at extends G {
  _parse(e) {
    const t = this._getOrReturnCtx(e);
    return L(t, {
      code: k.invalid_type,
      expected: D.never,
      received: t.parsedType
    }), Z;
  }
}
at.create = (n) => new at({
  typeName: H.ZodNever,
  ...V(n)
});
class ar extends G {
  _parse(e) {
    if (this._getType(e) !== D.undefined) {
      const r = this._getOrReturnCtx(e);
      return L(r, {
        code: k.invalid_type,
        expected: D.void,
        received: r.parsedType
      }), Z;
    }
    return _e(e.data);
  }
}
ar.create = (n) => new ar({
  typeName: H.ZodVoid,
  ...V(n)
});
class je extends G {
  _parse(e) {
    const { ctx: t, status: r } = this._processInputParams(e), i = this._def;
    if (t.parsedType !== D.array)
      return L(t, {
        code: k.invalid_type,
        expected: D.array,
        received: t.parsedType
      }), Z;
    if (i.exactLength !== null) {
      const a = t.data.length > i.exactLength.value, o = t.data.length < i.exactLength.value;
      (a || o) && (L(t, {
        code: a ? k.too_big : k.too_small,
        minimum: o ? i.exactLength.value : void 0,
        maximum: a ? i.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: i.exactLength.message
      }), r.dirty());
    }
    if (i.minLength !== null && t.data.length < i.minLength.value && (L(t, {
      code: k.too_small,
      minimum: i.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: i.minLength.message
    }), r.dirty()), i.maxLength !== null && t.data.length > i.maxLength.value && (L(t, {
      code: k.too_big,
      maximum: i.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: i.maxLength.message
    }), r.dirty()), t.common.async)
      return Promise.all([...t.data].map((a, o) => i.type._parseAsync(new ze(t, a, t.path, o)))).then((a) => ve.mergeArray(r, a));
    const s = [...t.data].map((a, o) => i.type._parseSync(new ze(t, a, t.path, o)));
    return ve.mergeArray(r, s);
  }
  get element() {
    return this._def.type;
  }
  min(e, t) {
    return new je({
      ...this._def,
      minLength: { value: e, message: x.toString(t) }
    });
  }
  max(e, t) {
    return new je({
      ...this._def,
      maxLength: { value: e, message: x.toString(t) }
    });
  }
  length(e, t) {
    return new je({
      ...this._def,
      exactLength: { value: e, message: x.toString(t) }
    });
  }
  nonempty(e) {
    return this.min(1, e);
  }
}
je.create = (n, e) => new je({
  type: n,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: H.ZodArray,
  ...V(e)
});
function Dt(n) {
  if (n instanceof se) {
    const e = {};
    for (const t in n.shape) {
      const r = n.shape[t];
      e[t] = qe.create(Dt(r));
    }
    return new se({
      ...n._def,
      shape: () => e
    });
  } else
    return n instanceof je ? new je({
      ...n._def,
      type: Dt(n.element)
    }) : n instanceof qe ? qe.create(Dt(n.unwrap())) : n instanceof _t ? _t.create(Dt(n.unwrap())) : n instanceof Xe ? Xe.create(n.items.map((e) => Dt(e))) : n;
}
class se extends G {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const e = this._def.shape(), t = W.objectKeys(e);
    return this._cached = { shape: e, keys: t };
  }
  _parse(e) {
    if (this._getType(e) !== D.object) {
      const c = this._getOrReturnCtx(e);
      return L(c, {
        code: k.invalid_type,
        expected: D.object,
        received: c.parsedType
      }), Z;
    }
    const { status: r, ctx: i } = this._processInputParams(e), { shape: s, keys: a } = this._getCached(), o = [];
    if (!(this._def.catchall instanceof at && this._def.unknownKeys === "strip"))
      for (const c in i.data)
        a.includes(c) || o.push(c);
    const u = [];
    for (const c of a) {
      const d = s[c], f = i.data[c];
      u.push({
        key: { status: "valid", value: c },
        value: d._parse(new ze(i, f, i.path, c)),
        alwaysSet: c in i.data
      });
    }
    if (this._def.catchall instanceof at) {
      const c = this._def.unknownKeys;
      if (c === "passthrough")
        for (const d of o)
          u.push({
            key: { status: "valid", value: d },
            value: { status: "valid", value: i.data[d] }
          });
      else if (c === "strict")
        o.length > 0 && (L(i, {
          code: k.unrecognized_keys,
          keys: o
        }), r.dirty());
      else if (c !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const c = this._def.catchall;
      for (const d of o) {
        const f = i.data[d];
        u.push({
          key: { status: "valid", value: d },
          value: c._parse(
            new ze(i, f, i.path, d)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: d in i.data
        });
      }
    }
    return i.common.async ? Promise.resolve().then(async () => {
      const c = [];
      for (const d of u) {
        const f = await d.key, h = await d.value;
        c.push({
          key: f,
          value: h,
          alwaysSet: d.alwaysSet
        });
      }
      return c;
    }).then((c) => ve.mergeObjectSync(r, c)) : ve.mergeObjectSync(r, u);
  }
  get shape() {
    return this._def.shape();
  }
  strict(e) {
    return x.errToObj, new se({
      ...this._def,
      unknownKeys: "strict",
      ...e !== void 0 ? {
        errorMap: (t, r) => {
          var i, s, a, o;
          const u = (a = (s = (i = this._def).errorMap) === null || s === void 0 ? void 0 : s.call(i, t, r).message) !== null && a !== void 0 ? a : r.defaultError;
          return t.code === "unrecognized_keys" ? {
            message: (o = x.errToObj(e).message) !== null && o !== void 0 ? o : u
          } : {
            message: u
          };
        }
      } : {}
    });
  }
  strip() {
    return new se({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new se({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(e) {
    return new se({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...e
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(e) {
    return new se({
      unknownKeys: e._def.unknownKeys,
      catchall: e._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...e._def.shape()
      }),
      typeName: H.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(e, t) {
    return this.augment({ [e]: t });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(e) {
    return new se({
      ...this._def,
      catchall: e
    });
  }
  pick(e) {
    const t = {};
    return W.objectKeys(e).forEach((r) => {
      e[r] && this.shape[r] && (t[r] = this.shape[r]);
    }), new se({
      ...this._def,
      shape: () => t
    });
  }
  omit(e) {
    const t = {};
    return W.objectKeys(this.shape).forEach((r) => {
      e[r] || (t[r] = this.shape[r]);
    }), new se({
      ...this._def,
      shape: () => t
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return Dt(this);
  }
  partial(e) {
    const t = {};
    return W.objectKeys(this.shape).forEach((r) => {
      const i = this.shape[r];
      e && !e[r] ? t[r] = i : t[r] = i.optional();
    }), new se({
      ...this._def,
      shape: () => t
    });
  }
  required(e) {
    const t = {};
    return W.objectKeys(this.shape).forEach((r) => {
      if (e && !e[r])
        t[r] = this.shape[r];
      else {
        let s = this.shape[r];
        for (; s instanceof qe; )
          s = s._def.innerType;
        t[r] = s;
      }
    }), new se({
      ...this._def,
      shape: () => t
    });
  }
  keyof() {
    return Ya(W.objectKeys(this.shape));
  }
}
se.create = (n, e) => new se({
  shape: () => n,
  unknownKeys: "strip",
  catchall: at.create(),
  typeName: H.ZodObject,
  ...V(e)
});
se.strictCreate = (n, e) => new se({
  shape: () => n,
  unknownKeys: "strict",
  catchall: at.create(),
  typeName: H.ZodObject,
  ...V(e)
});
se.lazycreate = (n, e) => new se({
  shape: n,
  unknownKeys: "strip",
  catchall: at.create(),
  typeName: H.ZodObject,
  ...V(e)
});
class vn extends G {
  _parse(e) {
    const { ctx: t } = this._processInputParams(e), r = this._def.options;
    function i(s) {
      for (const o of s)
        if (o.result.status === "valid")
          return o.result;
      for (const o of s)
        if (o.result.status === "dirty")
          return t.common.issues.push(...o.ctx.common.issues), o.result;
      const a = s.map((o) => new Ee(o.ctx.common.issues));
      return L(t, {
        code: k.invalid_union,
        unionErrors: a
      }), Z;
    }
    if (t.common.async)
      return Promise.all(r.map(async (s) => {
        const a = {
          ...t,
          common: {
            ...t.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await s._parseAsync({
            data: t.data,
            path: t.path,
            parent: a
          }),
          ctx: a
        };
      })).then(i);
    {
      let s;
      const a = [];
      for (const u of r) {
        const c = {
          ...t,
          common: {
            ...t.common,
            issues: []
          },
          parent: null
        }, d = u._parseSync({
          data: t.data,
          path: t.path,
          parent: c
        });
        if (d.status === "valid")
          return d;
        d.status === "dirty" && !s && (s = { result: d, ctx: c }), c.common.issues.length && a.push(c.common.issues);
      }
      if (s)
        return t.common.issues.push(...s.ctx.common.issues), s.result;
      const o = a.map((u) => new Ee(u));
      return L(t, {
        code: k.invalid_union,
        unionErrors: o
      }), Z;
    }
  }
  get options() {
    return this._def.options;
  }
}
vn.create = (n, e) => new vn({
  options: n,
  typeName: H.ZodUnion,
  ...V(e)
});
const rt = (n) => n instanceof On ? rt(n.schema) : n instanceof $e ? rt(n.innerType()) : n instanceof An ? [n.value] : n instanceof bt ? n.options : n instanceof wn ? W.objectValues(n.enum) : n instanceof Sn ? rt(n._def.innerType) : n instanceof gn ? [void 0] : n instanceof yn ? [null] : n instanceof qe ? [void 0, ...rt(n.unwrap())] : n instanceof _t ? [null, ...rt(n.unwrap())] : n instanceof Ci || n instanceof kn ? rt(n.unwrap()) : n instanceof En ? rt(n._def.innerType) : [];
class Ir extends G {
  _parse(e) {
    const { ctx: t } = this._processInputParams(e);
    if (t.parsedType !== D.object)
      return L(t, {
        code: k.invalid_type,
        expected: D.object,
        received: t.parsedType
      }), Z;
    const r = this.discriminator, i = t.data[r], s = this.optionsMap.get(i);
    return s ? t.common.async ? s._parseAsync({
      data: t.data,
      path: t.path,
      parent: t
    }) : s._parseSync({
      data: t.data,
      path: t.path,
      parent: t
    }) : (L(t, {
      code: k.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [r]
    }), Z);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(e, t, r) {
    const i = /* @__PURE__ */ new Map();
    for (const s of t) {
      const a = rt(s.shape[e]);
      if (!a.length)
        throw new Error(`A discriminator value for key \`${e}\` could not be extracted from all schema options`);
      for (const o of a) {
        if (i.has(o))
          throw new Error(`Discriminator property ${String(e)} has duplicate value ${String(o)}`);
        i.set(o, s);
      }
    }
    return new Ir({
      typeName: H.ZodDiscriminatedUnion,
      discriminator: e,
      options: t,
      optionsMap: i,
      ...V(r)
    });
  }
}
function ui(n, e) {
  const t = ct(n), r = ct(e);
  if (n === e)
    return { valid: !0, data: n };
  if (t === D.object && r === D.object) {
    const i = W.objectKeys(e), s = W.objectKeys(n).filter((o) => i.indexOf(o) !== -1), a = { ...n, ...e };
    for (const o of s) {
      const u = ui(n[o], e[o]);
      if (!u.valid)
        return { valid: !1 };
      a[o] = u.data;
    }
    return { valid: !0, data: a };
  } else if (t === D.array && r === D.array) {
    if (n.length !== e.length)
      return { valid: !1 };
    const i = [];
    for (let s = 0; s < n.length; s++) {
      const a = n[s], o = e[s], u = ui(a, o);
      if (!u.valid)
        return { valid: !1 };
      i.push(u.data);
    }
    return { valid: !0, data: i };
  } else
    return t === D.date && r === D.date && +n == +e ? { valid: !0, data: n } : { valid: !1 };
}
class bn extends G {
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e), i = (s, a) => {
      if (ai(s) || ai(a))
        return Z;
      const o = ui(s.value, a.value);
      return o.valid ? ((oi(s) || oi(a)) && t.dirty(), { status: t.value, value: o.data }) : (L(r, {
        code: k.invalid_intersection_types
      }), Z);
    };
    return r.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: r.data,
        path: r.path,
        parent: r
      }),
      this._def.right._parseAsync({
        data: r.data,
        path: r.path,
        parent: r
      })
    ]).then(([s, a]) => i(s, a)) : i(this._def.left._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }), this._def.right._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }));
  }
}
bn.create = (n, e, t) => new bn({
  left: n,
  right: e,
  typeName: H.ZodIntersection,
  ...V(t)
});
class Xe extends G {
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e);
    if (r.parsedType !== D.array)
      return L(r, {
        code: k.invalid_type,
        expected: D.array,
        received: r.parsedType
      }), Z;
    if (r.data.length < this._def.items.length)
      return L(r, {
        code: k.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), Z;
    !this._def.rest && r.data.length > this._def.items.length && (L(r, {
      code: k.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), t.dirty());
    const s = [...r.data].map((a, o) => {
      const u = this._def.items[o] || this._def.rest;
      return u ? u._parse(new ze(r, a, r.path, o)) : null;
    }).filter((a) => !!a);
    return r.common.async ? Promise.all(s).then((a) => ve.mergeArray(t, a)) : ve.mergeArray(t, s);
  }
  get items() {
    return this._def.items;
  }
  rest(e) {
    return new Xe({
      ...this._def,
      rest: e
    });
  }
}
Xe.create = (n, e) => {
  if (!Array.isArray(n))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new Xe({
    items: n,
    typeName: H.ZodTuple,
    rest: null,
    ...V(e)
  });
};
class _n extends G {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e);
    if (r.parsedType !== D.object)
      return L(r, {
        code: k.invalid_type,
        expected: D.object,
        received: r.parsedType
      }), Z;
    const i = [], s = this._def.keyType, a = this._def.valueType;
    for (const o in r.data)
      i.push({
        key: s._parse(new ze(r, o, r.path, o)),
        value: a._parse(new ze(r, r.data[o], r.path, o)),
        alwaysSet: o in r.data
      });
    return r.common.async ? ve.mergeObjectAsync(t, i) : ve.mergeObjectSync(t, i);
  }
  get element() {
    return this._def.valueType;
  }
  static create(e, t, r) {
    return t instanceof G ? new _n({
      keyType: e,
      valueType: t,
      typeName: H.ZodRecord,
      ...V(r)
    }) : new _n({
      keyType: xe.create(),
      valueType: e,
      typeName: H.ZodRecord,
      ...V(t)
    });
  }
}
class or extends G {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e);
    if (r.parsedType !== D.map)
      return L(r, {
        code: k.invalid_type,
        expected: D.map,
        received: r.parsedType
      }), Z;
    const i = this._def.keyType, s = this._def.valueType, a = [...r.data.entries()].map(([o, u], c) => ({
      key: i._parse(new ze(r, o, r.path, [c, "key"])),
      value: s._parse(new ze(r, u, r.path, [c, "value"]))
    }));
    if (r.common.async) {
      const o = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const u of a) {
          const c = await u.key, d = await u.value;
          if (c.status === "aborted" || d.status === "aborted")
            return Z;
          (c.status === "dirty" || d.status === "dirty") && t.dirty(), o.set(c.value, d.value);
        }
        return { status: t.value, value: o };
      });
    } else {
      const o = /* @__PURE__ */ new Map();
      for (const u of a) {
        const c = u.key, d = u.value;
        if (c.status === "aborted" || d.status === "aborted")
          return Z;
        (c.status === "dirty" || d.status === "dirty") && t.dirty(), o.set(c.value, d.value);
      }
      return { status: t.value, value: o };
    }
  }
}
or.create = (n, e, t) => new or({
  valueType: e,
  keyType: n,
  typeName: H.ZodMap,
  ...V(t)
});
class It extends G {
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e);
    if (r.parsedType !== D.set)
      return L(r, {
        code: k.invalid_type,
        expected: D.set,
        received: r.parsedType
      }), Z;
    const i = this._def;
    i.minSize !== null && r.data.size < i.minSize.value && (L(r, {
      code: k.too_small,
      minimum: i.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: i.minSize.message
    }), t.dirty()), i.maxSize !== null && r.data.size > i.maxSize.value && (L(r, {
      code: k.too_big,
      maximum: i.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: i.maxSize.message
    }), t.dirty());
    const s = this._def.valueType;
    function a(u) {
      const c = /* @__PURE__ */ new Set();
      for (const d of u) {
        if (d.status === "aborted")
          return Z;
        d.status === "dirty" && t.dirty(), c.add(d.value);
      }
      return { status: t.value, value: c };
    }
    const o = [...r.data.values()].map((u, c) => s._parse(new ze(r, u, r.path, c)));
    return r.common.async ? Promise.all(o).then((u) => a(u)) : a(o);
  }
  min(e, t) {
    return new It({
      ...this._def,
      minSize: { value: e, message: x.toString(t) }
    });
  }
  max(e, t) {
    return new It({
      ...this._def,
      maxSize: { value: e, message: x.toString(t) }
    });
  }
  size(e, t) {
    return this.min(e, t).max(e, t);
  }
  nonempty(e) {
    return this.min(1, e);
  }
}
It.create = (n, e) => new It({
  valueType: n,
  minSize: null,
  maxSize: null,
  typeName: H.ZodSet,
  ...V(e)
});
class Ht extends G {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(e) {
    const { ctx: t } = this._processInputParams(e);
    if (t.parsedType !== D.function)
      return L(t, {
        code: k.invalid_type,
        expected: D.function,
        received: t.parsedType
      }), Z;
    function r(o, u) {
      return rr({
        data: o,
        path: t.path,
        errorMaps: [
          t.common.contextualErrorMap,
          t.schemaErrorMap,
          nr(),
          Zt
        ].filter((c) => !!c),
        issueData: {
          code: k.invalid_arguments,
          argumentsError: u
        }
      });
    }
    function i(o, u) {
      return rr({
        data: o,
        path: t.path,
        errorMaps: [
          t.common.contextualErrorMap,
          t.schemaErrorMap,
          nr(),
          Zt
        ].filter((c) => !!c),
        issueData: {
          code: k.invalid_return_type,
          returnTypeError: u
        }
      });
    }
    const s = { errorMap: t.common.contextualErrorMap }, a = t.data;
    if (this._def.returns instanceof Vt) {
      const o = this;
      return _e(async function(...u) {
        const c = new Ee([]), d = await o._def.args.parseAsync(u, s).catch((p) => {
          throw c.addIssue(r(u, p)), c;
        }), f = await Reflect.apply(a, this, d);
        return await o._def.returns._def.type.parseAsync(f, s).catch((p) => {
          throw c.addIssue(i(f, p)), c;
        });
      });
    } else {
      const o = this;
      return _e(function(...u) {
        const c = o._def.args.safeParse(u, s);
        if (!c.success)
          throw new Ee([r(u, c.error)]);
        const d = Reflect.apply(a, this, c.data), f = o._def.returns.safeParse(d, s);
        if (!f.success)
          throw new Ee([i(d, f.error)]);
        return f.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...e) {
    return new Ht({
      ...this._def,
      args: Xe.create(e).rest(St.create())
    });
  }
  returns(e) {
    return new Ht({
      ...this._def,
      returns: e
    });
  }
  implement(e) {
    return this.parse(e);
  }
  strictImplement(e) {
    return this.parse(e);
  }
  static create(e, t, r) {
    return new Ht({
      args: e || Xe.create([]).rest(St.create()),
      returns: t || St.create(),
      typeName: H.ZodFunction,
      ...V(r)
    });
  }
}
class On extends G {
  get schema() {
    return this._def.getter();
  }
  _parse(e) {
    const { ctx: t } = this._processInputParams(e);
    return this._def.getter()._parse({ data: t.data, path: t.path, parent: t });
  }
}
On.create = (n, e) => new On({
  getter: n,
  typeName: H.ZodLazy,
  ...V(e)
});
class An extends G {
  _parse(e) {
    if (e.data !== this._def.value) {
      const t = this._getOrReturnCtx(e);
      return L(t, {
        received: t.data,
        code: k.invalid_literal,
        expected: this._def.value
      }), Z;
    }
    return { status: "valid", value: e.data };
  }
  get value() {
    return this._def.value;
  }
}
An.create = (n, e) => new An({
  value: n,
  typeName: H.ZodLiteral,
  ...V(e)
});
function Ya(n, e) {
  return new bt({
    values: n,
    typeName: H.ZodEnum,
    ...V(e)
  });
}
class bt extends G {
  constructor() {
    super(...arguments), en.set(this, void 0);
  }
  _parse(e) {
    if (typeof e.data != "string") {
      const t = this._getOrReturnCtx(e), r = this._def.values;
      return L(t, {
        expected: W.joinValues(r),
        received: t.parsedType,
        code: k.invalid_type
      }), Z;
    }
    if (ir(this, en, "f") || Za(this, en, new Set(this._def.values), "f"), !ir(this, en, "f").has(e.data)) {
      const t = this._getOrReturnCtx(e), r = this._def.values;
      return L(t, {
        received: t.data,
        code: k.invalid_enum_value,
        options: r
      }), Z;
    }
    return _e(e.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const e = {};
    for (const t of this._def.values)
      e[t] = t;
    return e;
  }
  get Values() {
    const e = {};
    for (const t of this._def.values)
      e[t] = t;
    return e;
  }
  get Enum() {
    const e = {};
    for (const t of this._def.values)
      e[t] = t;
    return e;
  }
  extract(e, t = this._def) {
    return bt.create(e, {
      ...this._def,
      ...t
    });
  }
  exclude(e, t = this._def) {
    return bt.create(this.options.filter((r) => !e.includes(r)), {
      ...this._def,
      ...t
    });
  }
}
en = /* @__PURE__ */ new WeakMap();
bt.create = Ya;
class wn extends G {
  constructor() {
    super(...arguments), tn.set(this, void 0);
  }
  _parse(e) {
    const t = W.getValidEnumValues(this._def.values), r = this._getOrReturnCtx(e);
    if (r.parsedType !== D.string && r.parsedType !== D.number) {
      const i = W.objectValues(t);
      return L(r, {
        expected: W.joinValues(i),
        received: r.parsedType,
        code: k.invalid_type
      }), Z;
    }
    if (ir(this, tn, "f") || Za(this, tn, new Set(W.getValidEnumValues(this._def.values)), "f"), !ir(this, tn, "f").has(e.data)) {
      const i = W.objectValues(t);
      return L(r, {
        received: r.data,
        code: k.invalid_enum_value,
        options: i
      }), Z;
    }
    return _e(e.data);
  }
  get enum() {
    return this._def.values;
  }
}
tn = /* @__PURE__ */ new WeakMap();
wn.create = (n, e) => new wn({
  values: n,
  typeName: H.ZodNativeEnum,
  ...V(e)
});
class Vt extends G {
  unwrap() {
    return this._def.type;
  }
  _parse(e) {
    const { ctx: t } = this._processInputParams(e);
    if (t.parsedType !== D.promise && t.common.async === !1)
      return L(t, {
        code: k.invalid_type,
        expected: D.promise,
        received: t.parsedType
      }), Z;
    const r = t.parsedType === D.promise ? t.data : Promise.resolve(t.data);
    return _e(r.then((i) => this._def.type.parseAsync(i, {
      path: t.path,
      errorMap: t.common.contextualErrorMap
    })));
  }
}
Vt.create = (n, e) => new Vt({
  type: n,
  typeName: H.ZodPromise,
  ...V(e)
});
class $e extends G {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === H.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e), i = this._def.effect || null, s = {
      addIssue: (a) => {
        L(r, a), a.fatal ? t.abort() : t.dirty();
      },
      get path() {
        return r.path;
      }
    };
    if (s.addIssue = s.addIssue.bind(s), i.type === "preprocess") {
      const a = i.transform(r.data, s);
      if (r.common.async)
        return Promise.resolve(a).then(async (o) => {
          if (t.value === "aborted")
            return Z;
          const u = await this._def.schema._parseAsync({
            data: o,
            path: r.path,
            parent: r
          });
          return u.status === "aborted" ? Z : u.status === "dirty" || t.value === "dirty" ? jt(u.value) : u;
        });
      {
        if (t.value === "aborted")
          return Z;
        const o = this._def.schema._parseSync({
          data: a,
          path: r.path,
          parent: r
        });
        return o.status === "aborted" ? Z : o.status === "dirty" || t.value === "dirty" ? jt(o.value) : o;
      }
    }
    if (i.type === "refinement") {
      const a = (o) => {
        const u = i.refinement(o, s);
        if (r.common.async)
          return Promise.resolve(u);
        if (u instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return o;
      };
      if (r.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: r.data,
          path: r.path,
          parent: r
        });
        return o.status === "aborted" ? Z : (o.status === "dirty" && t.dirty(), a(o.value), { status: t.value, value: o.value });
      } else
        return this._def.schema._parseAsync({ data: r.data, path: r.path, parent: r }).then((o) => o.status === "aborted" ? Z : (o.status === "dirty" && t.dirty(), a(o.value).then(() => ({ status: t.value, value: o.value }))));
    }
    if (i.type === "transform")
      if (r.common.async === !1) {
        const a = this._def.schema._parseSync({
          data: r.data,
          path: r.path,
          parent: r
        });
        if (!hn(a))
          return a;
        const o = i.transform(a.value, s);
        if (o instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: t.value, value: o };
      } else
        return this._def.schema._parseAsync({ data: r.data, path: r.path, parent: r }).then((a) => hn(a) ? Promise.resolve(i.transform(a.value, s)).then((o) => ({ status: t.value, value: o })) : a);
    W.assertNever(i);
  }
}
$e.create = (n, e, t) => new $e({
  schema: n,
  typeName: H.ZodEffects,
  effect: e,
  ...V(t)
});
$e.createWithPreprocess = (n, e, t) => new $e({
  schema: e,
  effect: { type: "preprocess", transform: n },
  typeName: H.ZodEffects,
  ...V(t)
});
class qe extends G {
  _parse(e) {
    return this._getType(e) === D.undefined ? _e(void 0) : this._def.innerType._parse(e);
  }
  unwrap() {
    return this._def.innerType;
  }
}
qe.create = (n, e) => new qe({
  innerType: n,
  typeName: H.ZodOptional,
  ...V(e)
});
class _t extends G {
  _parse(e) {
    return this._getType(e) === D.null ? _e(null) : this._def.innerType._parse(e);
  }
  unwrap() {
    return this._def.innerType;
  }
}
_t.create = (n, e) => new _t({
  innerType: n,
  typeName: H.ZodNullable,
  ...V(e)
});
class Sn extends G {
  _parse(e) {
    const { ctx: t } = this._processInputParams(e);
    let r = t.data;
    return t.parsedType === D.undefined && (r = this._def.defaultValue()), this._def.innerType._parse({
      data: r,
      path: t.path,
      parent: t
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Sn.create = (n, e) => new Sn({
  innerType: n,
  typeName: H.ZodDefault,
  defaultValue: typeof e.default == "function" ? e.default : () => e.default,
  ...V(e)
});
class En extends G {
  _parse(e) {
    const { ctx: t } = this._processInputParams(e), r = {
      ...t,
      common: {
        ...t.common,
        issues: []
      }
    }, i = this._def.innerType._parse({
      data: r.data,
      path: r.path,
      parent: {
        ...r
      }
    });
    return pn(i) ? i.then((s) => ({
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new Ee(r.common.issues);
        },
        input: r.data
      })
    })) : {
      status: "valid",
      value: i.status === "valid" ? i.value : this._def.catchValue({
        get error() {
          return new Ee(r.common.issues);
        },
        input: r.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
En.create = (n, e) => new En({
  innerType: n,
  typeName: H.ZodCatch,
  catchValue: typeof e.catch == "function" ? e.catch : () => e.catch,
  ...V(e)
});
class ur extends G {
  _parse(e) {
    if (this._getType(e) !== D.nan) {
      const r = this._getOrReturnCtx(e);
      return L(r, {
        code: k.invalid_type,
        expected: D.nan,
        received: r.parsedType
      }), Z;
    }
    return { status: "valid", value: e.data };
  }
}
ur.create = (n) => new ur({
  typeName: H.ZodNaN,
  ...V(n)
});
const Pl = Symbol("zod_brand");
class Ci extends G {
  _parse(e) {
    const { ctx: t } = this._processInputParams(e), r = t.data;
    return this._def.type._parse({
      data: r,
      path: t.path,
      parent: t
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class Nn extends G {
  _parse(e) {
    const { status: t, ctx: r } = this._processInputParams(e);
    if (r.common.async)
      return (async () => {
        const s = await this._def.in._parseAsync({
          data: r.data,
          path: r.path,
          parent: r
        });
        return s.status === "aborted" ? Z : s.status === "dirty" ? (t.dirty(), jt(s.value)) : this._def.out._parseAsync({
          data: s.value,
          path: r.path,
          parent: r
        });
      })();
    {
      const i = this._def.in._parseSync({
        data: r.data,
        path: r.path,
        parent: r
      });
      return i.status === "aborted" ? Z : i.status === "dirty" ? (t.dirty(), {
        status: "dirty",
        value: i.value
      }) : this._def.out._parseSync({
        data: i.value,
        path: r.path,
        parent: r
      });
    }
  }
  static create(e, t) {
    return new Nn({
      in: e,
      out: t,
      typeName: H.ZodPipeline
    });
  }
}
class kn extends G {
  _parse(e) {
    const t = this._def.innerType._parse(e), r = (i) => (hn(i) && (i.value = Object.freeze(i.value)), i);
    return pn(t) ? t.then((i) => r(i)) : r(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
kn.create = (n, e) => new kn({
  innerType: n,
  typeName: H.ZodReadonly,
  ...V(e)
});
function Ka(n, e = {}, t) {
  return n ? Ft.create().superRefine((r, i) => {
    var s, a;
    if (!n(r)) {
      const o = typeof e == "function" ? e(r) : typeof e == "string" ? { message: e } : e, u = (a = (s = o.fatal) !== null && s !== void 0 ? s : t) !== null && a !== void 0 ? a : !0, c = typeof o == "string" ? { message: o } : o;
      i.addIssue({ code: "custom", ...c, fatal: u });
    }
  }) : Ft.create();
}
const Nl = {
  object: se.lazycreate
};
var H;
(function(n) {
  n.ZodString = "ZodString", n.ZodNumber = "ZodNumber", n.ZodNaN = "ZodNaN", n.ZodBigInt = "ZodBigInt", n.ZodBoolean = "ZodBoolean", n.ZodDate = "ZodDate", n.ZodSymbol = "ZodSymbol", n.ZodUndefined = "ZodUndefined", n.ZodNull = "ZodNull", n.ZodAny = "ZodAny", n.ZodUnknown = "ZodUnknown", n.ZodNever = "ZodNever", n.ZodVoid = "ZodVoid", n.ZodArray = "ZodArray", n.ZodObject = "ZodObject", n.ZodUnion = "ZodUnion", n.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", n.ZodIntersection = "ZodIntersection", n.ZodTuple = "ZodTuple", n.ZodRecord = "ZodRecord", n.ZodMap = "ZodMap", n.ZodSet = "ZodSet", n.ZodFunction = "ZodFunction", n.ZodLazy = "ZodLazy", n.ZodLiteral = "ZodLiteral", n.ZodEnum = "ZodEnum", n.ZodEffects = "ZodEffects", n.ZodNativeEnum = "ZodNativeEnum", n.ZodOptional = "ZodOptional", n.ZodNullable = "ZodNullable", n.ZodDefault = "ZodDefault", n.ZodCatch = "ZodCatch", n.ZodPromise = "ZodPromise", n.ZodBranded = "ZodBranded", n.ZodPipeline = "ZodPipeline", n.ZodReadonly = "ZodReadonly";
})(H || (H = {}));
const Ll = (n, e = {
  message: `Input not instance of ${n.name}`
}) => Ka((t) => t instanceof n, e), qa = xe.create, Wa = yt.create, Dl = ur.create, Cl = vt.create, Ja = mn.create, Rl = Mt.create, xl = sr.create, jl = gn.create, $l = yn.create, Bl = Ft.create, Hl = St.create, Ul = at.create, Zl = ar.create, Fl = je.create, Vl = se.create, Gl = se.strictCreate, Yl = vn.create, za = Ir.create, Kl = bn.create, ql = Xe.create, Wl = _n.create, Jl = or.create, zl = It.create, Xl = Ht.create, Ql = On.create, ef = An.create, tf = bt.create, nf = wn.create, rf = Vt.create, Ps = $e.create, sf = qe.create, af = _t.create, of = $e.createWithPreprocess, uf = Nn.create, cf = () => qa().optional(), lf = () => Wa().optional(), ff = () => Ja().optional(), df = {
  string: (n) => xe.create({ ...n, coerce: !0 }),
  number: (n) => yt.create({ ...n, coerce: !0 }),
  boolean: (n) => mn.create({
    ...n,
    coerce: !0
  }),
  bigint: (n) => vt.create({ ...n, coerce: !0 }),
  date: (n) => Mt.create({ ...n, coerce: !0 })
}, hf = Z;
var S = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: Zt,
  setErrorMap: hl,
  getErrorMap: nr,
  makeIssue: rr,
  EMPTY_PATH: pl,
  addIssueToContext: L,
  ParseStatus: ve,
  INVALID: Z,
  DIRTY: jt,
  OK: _e,
  isAborted: ai,
  isDirty: oi,
  isValid: hn,
  isAsync: pn,
  get util() {
    return W;
  },
  get objectUtil() {
    return si;
  },
  ZodParsedType: D,
  getParsedType: ct,
  ZodType: G,
  datetimeRegex: Ga,
  ZodString: xe,
  ZodNumber: yt,
  ZodBigInt: vt,
  ZodBoolean: mn,
  ZodDate: Mt,
  ZodSymbol: sr,
  ZodUndefined: gn,
  ZodNull: yn,
  ZodAny: Ft,
  ZodUnknown: St,
  ZodNever: at,
  ZodVoid: ar,
  ZodArray: je,
  ZodObject: se,
  ZodUnion: vn,
  ZodDiscriminatedUnion: Ir,
  ZodIntersection: bn,
  ZodTuple: Xe,
  ZodRecord: _n,
  ZodMap: or,
  ZodSet: It,
  ZodFunction: Ht,
  ZodLazy: On,
  ZodLiteral: An,
  ZodEnum: bt,
  ZodNativeEnum: wn,
  ZodPromise: Vt,
  ZodEffects: $e,
  ZodTransformer: $e,
  ZodOptional: qe,
  ZodNullable: _t,
  ZodDefault: Sn,
  ZodCatch: En,
  ZodNaN: ur,
  BRAND: Pl,
  ZodBranded: Ci,
  ZodPipeline: Nn,
  ZodReadonly: kn,
  custom: Ka,
  Schema: G,
  ZodSchema: G,
  late: Nl,
  get ZodFirstPartyTypeKind() {
    return H;
  },
  coerce: df,
  any: Bl,
  array: Fl,
  bigint: Cl,
  boolean: Ja,
  date: Rl,
  discriminatedUnion: za,
  effect: Ps,
  enum: tf,
  function: Xl,
  instanceof: Ll,
  intersection: Kl,
  lazy: Ql,
  literal: ef,
  map: Jl,
  nan: Dl,
  nativeEnum: nf,
  never: Ul,
  null: $l,
  nullable: af,
  number: Wa,
  object: Vl,
  oboolean: ff,
  onumber: lf,
  optional: sf,
  ostring: cf,
  pipeline: uf,
  preprocess: of,
  promise: rf,
  record: Wl,
  set: zl,
  strictObject: Gl,
  string: qa,
  symbol: xl,
  transformer: Ps,
  tuple: ql,
  undefined: jl,
  union: Yl,
  unknown: Hl,
  void: Zl,
  NEVER: hf,
  ZodIssueCode: k,
  quotelessJson: dl,
  ZodError: Ee
});
vi.extendZodWithOpenApi(S);
const pf = S.object({
  topicName: S.string().openapi({ description: "topic name" }),
  count: S.number().openapi({ description: "count" }),
  sizeInKb: S.number().openapi({ description: "size in kb" })
}), mf = pf;
mf.extend({
  _id: S.string({ description: "SocketStat" }),
  createdAt: S.string().optional(),
  updatedAt: S.string().optional()
});
const Ns = S.union([S.string(), S.number(), S.boolean(), S.null()]);
S.record(S.string(), S.union([Ns, S.array(Ns).transform((n) => n.length > 0 ? n[0] : null)]));
function Ls(n, e) {
  return e = Xa(e), e.preserve || (n = n.toLowerCase()), n.charAt(0).toUpperCase() + n.substring(1);
}
var Ds = /['"`’]/, gf = /[0-9a-zA-Z\u00C0-\u017F\u0400-\u04FF]/;
Ls.words = function(n, e) {
  e = Xa(e), e.preserve || (n = n.toLowerCase());
  for (var t = 0, r = /[^0-9a-zA-Z\u00C0-\u017F\u0400-\u04FF]+|$/g, i, s = "", a = 0; i = r.exec(n); ) {
    var o = i[0], u = r.lastIndex - o.length;
    if (!(Ds.test(n[u]) && gf.test(n[u + 1]))) {
      var c = n.substring(t, r.lastIndex - o.length);
      if (Ds.test(c[0]) && (s += c[0], c = c.substring(1)), typeof e.skipWord == "function" && e.skipWord(c, a) ? s += c : s += Ls(c, e), s += o, t = r.lastIndex, a++, t == n.length)
        break;
    }
  }
  return s;
};
function Xa(n) {
  if (!n)
    return { preserve: !1 };
  if (typeof n == "boolean")
    return { preserve: n };
  if (n.skipWord instanceof RegExp) {
    const e = n.skipWord;
    n.skipWord = function(t, r) {
      return r > 0 && e.test(t);
    };
  }
  return n || {};
}
var ci;
(function(n) {
  n.FIRST_NAME = "firstName", n.LAST_NAME = "lastName", n.COMPANY = "company";
})(ci || (ci = {}));
var li;
(function(n) {
  n.FIRST_NAME = "firstName", n.LAST_NAME = "lastName", n.COMPANY = "company", n.LINKEDIN_URL = "linkedinUrl", n.EMAIL = "email", n.PHONE = "phone", n.JOB_TITLE = "jobTitle", n.LOCATION = "location", n.LINKEDIN_CONVERSATION_LINK = "linkedInConversationLink";
})(li || (li = {}));
Object.values(ci);
Object.values(li);
var cr;
(function(n) {
  n.ENTITY = "entityId", n.PARENT = "parentId", n.USER = "userId";
})(cr || (cr = {}));
const yf = S.object({
  type: S.nativeEnum(cr),
  value: S.string()
}), vf = S.object({
  subTopics: S.array(yf),
  payload: S.array(S.unknown())
}), Cs = (n) => {
  const { topic: e, target: t } = n;
  return t ? `${e}_${t.type}_${t.id}` : e;
}, bf = S.object({
  type: S.nativeEnum(cr),
  id: S.string()
}).nullable(), _f = S.object({
  target: bf,
  topic: S.string()
});
S.array(_f);
const Qe = /* @__PURE__ */ Object.create(null);
Qe.open = "0";
Qe.close = "1";
Qe.ping = "2";
Qe.pong = "3";
Qe.message = "4";
Qe.upgrade = "5";
Qe.noop = "6";
const Fn = /* @__PURE__ */ Object.create(null);
Object.keys(Qe).forEach((n) => {
  Fn[Qe[n]] = n;
});
const Of = { type: "error", data: "parser error" }, Af = typeof Blob == "function" || typeof Blob < "u" && Object.prototype.toString.call(Blob) === "[object BlobConstructor]", wf = typeof ArrayBuffer == "function", Sf = (n) => typeof ArrayBuffer.isView == "function" ? ArrayBuffer.isView(n) : n && n.buffer instanceof ArrayBuffer, Qa = ({ type: n, data: e }, t, r) => Af && e instanceof Blob ? t ? r(e) : Rs(e, r) : wf && (e instanceof ArrayBuffer || Sf(e)) ? t ? r(e) : Rs(new Blob([e]), r) : r(Qe[n] + (e || "")), Rs = (n, e) => {
  const t = new FileReader();
  return t.onload = function() {
    const r = t.result.split(",")[1];
    e("b" + (r || ""));
  }, t.readAsDataURL(n);
}, xs = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/", nn = typeof Uint8Array > "u" ? [] : new Uint8Array(256);
for (let n = 0; n < xs.length; n++)
  nn[xs.charCodeAt(n)] = n;
const Ef = (n) => {
  let e = n.length * 0.75, t = n.length, r, i = 0, s, a, o, u;
  n[n.length - 1] === "=" && (e--, n[n.length - 2] === "=" && e--);
  const c = new ArrayBuffer(e), d = new Uint8Array(c);
  for (r = 0; r < t; r += 4)
    s = nn[n.charCodeAt(r)], a = nn[n.charCodeAt(r + 1)], o = nn[n.charCodeAt(r + 2)], u = nn[n.charCodeAt(r + 3)], d[i++] = s << 2 | a >> 4, d[i++] = (a & 15) << 4 | o >> 2, d[i++] = (o & 3) << 6 | u & 63;
  return c;
}, kf = typeof ArrayBuffer == "function", eo = (n, e) => {
  if (typeof n != "string")
    return {
      type: "message",
      data: to(n, e)
    };
  const t = n.charAt(0);
  return t === "b" ? {
    type: "message",
    data: Tf(n.substring(1), e)
  } : Fn[t] ? n.length > 1 ? {
    type: Fn[t],
    data: n.substring(1)
  } : {
    type: Fn[t]
  } : Of;
}, Tf = (n, e) => {
  if (kf) {
    const t = Ef(n);
    return to(t, e);
  } else
    return { base64: !0, data: n };
}, to = (n, e) => {
  switch (e) {
    case "blob":
      return n instanceof ArrayBuffer ? new Blob([n]) : n;
    case "arraybuffer":
    default:
      return n;
  }
}, no = String.fromCharCode(30), Mf = (n, e) => {
  const t = n.length, r = new Array(t);
  let i = 0;
  n.forEach((s, a) => {
    Qa(s, !1, (o) => {
      r[a] = o, ++i === t && e(r.join(no));
    });
  });
}, If = (n, e) => {
  const t = n.split(no), r = [];
  for (let i = 0; i < t.length; i++) {
    const s = eo(t[i], e);
    if (r.push(s), s.type === "error")
      break;
  }
  return r;
}, ro = 4;
function le(n) {
  if (n)
    return Pf(n);
}
function Pf(n) {
  for (var e in le.prototype)
    n[e] = le.prototype[e];
  return n;
}
le.prototype.on = le.prototype.addEventListener = function(n, e) {
  return this._callbacks = this._callbacks || {}, (this._callbacks["$" + n] = this._callbacks["$" + n] || []).push(e), this;
};
le.prototype.once = function(n, e) {
  function t() {
    this.off(n, t), e.apply(this, arguments);
  }
  return t.fn = e, this.on(n, t), this;
};
le.prototype.off = le.prototype.removeListener = le.prototype.removeAllListeners = le.prototype.removeEventListener = function(n, e) {
  if (this._callbacks = this._callbacks || {}, arguments.length == 0)
    return this._callbacks = {}, this;
  var t = this._callbacks["$" + n];
  if (!t)
    return this;
  if (arguments.length == 1)
    return delete this._callbacks["$" + n], this;
  for (var r, i = 0; i < t.length; i++)
    if (r = t[i], r === e || r.fn === e) {
      t.splice(i, 1);
      break;
    }
  return t.length === 0 && delete this._callbacks["$" + n], this;
};
le.prototype.emit = function(n) {
  this._callbacks = this._callbacks || {};
  for (var e = new Array(arguments.length - 1), t = this._callbacks["$" + n], r = 1; r < arguments.length; r++)
    e[r - 1] = arguments[r];
  if (t) {
    t = t.slice(0);
    for (var r = 0, i = t.length; r < i; ++r)
      t[r].apply(this, e);
  }
  return this;
};
le.prototype.emitReserved = le.prototype.emit;
le.prototype.listeners = function(n) {
  return this._callbacks = this._callbacks || {}, this._callbacks["$" + n] || [];
};
le.prototype.hasListeners = function(n) {
  return !!this.listeners(n).length;
};
const Me = (() => typeof self < "u" ? self : typeof window < "u" ? window : Function("return this")())();
function io(n, ...e) {
  return e.reduce((t, r) => (n.hasOwnProperty(r) && (t[r] = n[r]), t), {});
}
const Nf = Me.setTimeout, Lf = Me.clearTimeout;
function Pr(n, e) {
  e.useNativeTimers ? (n.setTimeoutFn = Nf.bind(Me), n.clearTimeoutFn = Lf.bind(Me)) : (n.setTimeoutFn = Me.setTimeout.bind(Me), n.clearTimeoutFn = Me.clearTimeout.bind(Me));
}
const Df = 1.33;
function Cf(n) {
  return typeof n == "string" ? Rf(n) : Math.ceil((n.byteLength || n.size) * Df);
}
function Rf(n) {
  let e = 0, t = 0;
  for (let r = 0, i = n.length; r < i; r++)
    e = n.charCodeAt(r), e < 128 ? t += 1 : e < 2048 ? t += 2 : e < 55296 || e >= 57344 ? t += 3 : (r++, t += 4);
  return t;
}
class xf extends Error {
  constructor(e, t, r) {
    super(e), this.description = t, this.context = r, this.type = "TransportError";
  }
}
class so extends le {
  /**
   * Transport abstract constructor.
   *
   * @param {Object} opts - options
   * @protected
   */
  constructor(e) {
    super(), this.writable = !1, Pr(this, e), this.opts = e, this.query = e.query, this.socket = e.socket;
  }
  /**
   * Emits an error.
   *
   * @param {String} reason
   * @param description
   * @param context - the error context
   * @return {Transport} for chaining
   * @protected
   */
  onError(e, t, r) {
    return super.emitReserved("error", new xf(e, t, r)), this;
  }
  /**
   * Opens the transport.
   */
  open() {
    return this.readyState = "opening", this.doOpen(), this;
  }
  /**
   * Closes the transport.
   */
  close() {
    return (this.readyState === "opening" || this.readyState === "open") && (this.doClose(), this.onClose()), this;
  }
  /**
   * Sends multiple packets.
   *
   * @param {Array} packets
   */
  send(e) {
    this.readyState === "open" && this.write(e);
  }
  /**
   * Called upon open
   *
   * @protected
   */
  onOpen() {
    this.readyState = "open", this.writable = !0, super.emitReserved("open");
  }
  /**
   * Called with data.
   *
   * @param {String} data
   * @protected
   */
  onData(e) {
    const t = eo(e, this.socket.binaryType);
    this.onPacket(t);
  }
  /**
   * Called with a decoded packet.
   *
   * @protected
   */
  onPacket(e) {
    super.emitReserved("packet", e);
  }
  /**
   * Called upon close.
   *
   * @protected
   */
  onClose(e) {
    this.readyState = "closed", super.emitReserved("close", e);
  }
  /**
   * Pauses the transport, in order not to lose packets during an upgrade.
   *
   * @param onPause
   */
  pause(e) {
  }
}
const ao = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_".split(""), fi = 64, jf = {};
let js = 0, $n = 0, $s;
function Bs(n) {
  let e = "";
  do
    e = ao[n % fi] + e, n = Math.floor(n / fi);
  while (n > 0);
  return e;
}
function oo() {
  const n = Bs(+/* @__PURE__ */ new Date());
  return n !== $s ? (js = 0, $s = n) : n + "." + Bs(js++);
}
for (; $n < fi; $n++)
  jf[ao[$n]] = $n;
function uo(n) {
  let e = "";
  for (let t in n)
    n.hasOwnProperty(t) && (e.length && (e += "&"), e += encodeURIComponent(t) + "=" + encodeURIComponent(n[t]));
  return e;
}
function $f(n) {
  let e = {}, t = n.split("&");
  for (let r = 0, i = t.length; r < i; r++) {
    let s = t[r].split("=");
    e[decodeURIComponent(s[0])] = decodeURIComponent(s[1]);
  }
  return e;
}
let co = !1;
try {
  co = typeof XMLHttpRequest < "u" && "withCredentials" in new XMLHttpRequest();
} catch {
}
const Bf = co;
function lo(n) {
  const e = n.xdomain;
  try {
    if (typeof XMLHttpRequest < "u" && (!e || Bf))
      return new XMLHttpRequest();
  } catch {
  }
  if (!e)
    try {
      return new Me[["Active"].concat("Object").join("X")]("Microsoft.XMLHTTP");
    } catch {
    }
}
function Hf() {
}
const Uf = function() {
  return new lo({
    xdomain: !1
  }).responseType != null;
}();
class Zf extends so {
  /**
   * XHR Polling constructor.
   *
   * @param {Object} opts
   * @package
   */
  constructor(e) {
    if (super(e), this.polling = !1, typeof location < "u") {
      const r = location.protocol === "https:";
      let i = location.port;
      i || (i = r ? "443" : "80"), this.xd = typeof location < "u" && e.hostname !== location.hostname || i !== e.port, this.xs = e.secure !== r;
    }
    const t = e && e.forceBase64;
    this.supportsBinary = Uf && !t;
  }
  get name() {
    return "polling";
  }
  /**
   * Opens the socket (triggers polling). We write a PING message to determine
   * when the transport is open.
   *
   * @protected
   */
  doOpen() {
    this.poll();
  }
  /**
   * Pauses polling.
   *
   * @param {Function} onPause - callback upon buffers are flushed and transport is paused
   * @package
   */
  pause(e) {
    this.readyState = "pausing";
    const t = () => {
      this.readyState = "paused", e();
    };
    if (this.polling || !this.writable) {
      let r = 0;
      this.polling && (r++, this.once("pollComplete", function() {
        --r || t();
      })), this.writable || (r++, this.once("drain", function() {
        --r || t();
      }));
    } else
      t();
  }
  /**
   * Starts polling cycle.
   *
   * @private
   */
  poll() {
    this.polling = !0, this.doPoll(), this.emitReserved("poll");
  }
  /**
   * Overloads onData to detect payloads.
   *
   * @protected
   */
  onData(e) {
    const t = (r) => {
      if (this.readyState === "opening" && r.type === "open" && this.onOpen(), r.type === "close")
        return this.onClose({ description: "transport closed by the server" }), !1;
      this.onPacket(r);
    };
    If(e, this.socket.binaryType).forEach(t), this.readyState !== "closed" && (this.polling = !1, this.emitReserved("pollComplete"), this.readyState === "open" && this.poll());
  }
  /**
   * For polling, send a close packet.
   *
   * @protected
   */
  doClose() {
    const e = () => {
      this.write([{ type: "close" }]);
    };
    this.readyState === "open" ? e() : this.once("open", e);
  }
  /**
   * Writes a packets payload.
   *
   * @param {Array} packets - data packets
   * @protected
   */
  write(e) {
    this.writable = !1, Mf(e, (t) => {
      this.doWrite(t, () => {
        this.writable = !0, this.emitReserved("drain");
      });
    });
  }
  /**
   * Generates uri for connection.
   *
   * @private
   */
  uri() {
    let e = this.query || {};
    const t = this.opts.secure ? "https" : "http";
    let r = "";
    this.opts.timestampRequests !== !1 && (e[this.opts.timestampParam] = oo()), !this.supportsBinary && !e.sid && (e.b64 = 1), this.opts.port && (t === "https" && Number(this.opts.port) !== 443 || t === "http" && Number(this.opts.port) !== 80) && (r = ":" + this.opts.port);
    const i = uo(e), s = this.opts.hostname.indexOf(":") !== -1;
    return t + "://" + (s ? "[" + this.opts.hostname + "]" : this.opts.hostname) + r + this.opts.path + (i.length ? "?" + i : "");
  }
  /**
   * Creates a request.
   *
   * @param {String} method
   * @private
   */
  request(e = {}) {
    return Object.assign(e, { xd: this.xd, xs: this.xs }, this.opts), new We(this.uri(), e);
  }
  /**
   * Sends data.
   *
   * @param {String} data to send.
   * @param {Function} called upon flush.
   * @private
   */
  doWrite(e, t) {
    const r = this.request({
      method: "POST",
      data: e
    });
    r.on("success", t), r.on("error", (i, s) => {
      this.onError("xhr post error", i, s);
    });
  }
  /**
   * Starts a poll cycle.
   *
   * @private
   */
  doPoll() {
    const e = this.request();
    e.on("data", this.onData.bind(this)), e.on("error", (t, r) => {
      this.onError("xhr poll error", t, r);
    }), this.pollXhr = e;
  }
}
class We extends le {
  /**
   * Request constructor
   *
   * @param {Object} options
   * @package
   */
  constructor(e, t) {
    super(), Pr(this, t), this.opts = t, this.method = t.method || "GET", this.uri = e, this.async = t.async !== !1, this.data = t.data !== void 0 ? t.data : null, this.create();
  }
  /**
   * Creates the XHR object and sends the request.
   *
   * @private
   */
  create() {
    const e = io(this.opts, "agent", "pfx", "key", "passphrase", "cert", "ca", "ciphers", "rejectUnauthorized", "autoUnref");
    e.xdomain = !!this.opts.xd, e.xscheme = !!this.opts.xs;
    const t = this.xhr = new lo(e);
    try {
      t.open(this.method, this.uri, this.async);
      try {
        if (this.opts.extraHeaders) {
          t.setDisableHeaderCheck && t.setDisableHeaderCheck(!0);
          for (let r in this.opts.extraHeaders)
            this.opts.extraHeaders.hasOwnProperty(r) && t.setRequestHeader(r, this.opts.extraHeaders[r]);
        }
      } catch {
      }
      if (this.method === "POST")
        try {
          t.setRequestHeader("Content-type", "text/plain;charset=UTF-8");
        } catch {
        }
      try {
        t.setRequestHeader("Accept", "*/*");
      } catch {
      }
      "withCredentials" in t && (t.withCredentials = this.opts.withCredentials), this.opts.requestTimeout && (t.timeout = this.opts.requestTimeout), t.onreadystatechange = () => {
        t.readyState === 4 && (t.status === 200 || t.status === 1223 ? this.onLoad() : this.setTimeoutFn(() => {
          this.onError(typeof t.status == "number" ? t.status : 0);
        }, 0));
      }, t.send(this.data);
    } catch (r) {
      this.setTimeoutFn(() => {
        this.onError(r);
      }, 0);
      return;
    }
    typeof document < "u" && (this.index = We.requestsCount++, We.requests[this.index] = this);
  }
  /**
   * Called upon error.
   *
   * @private
   */
  onError(e) {
    this.emitReserved("error", e, this.xhr), this.cleanup(!0);
  }
  /**
   * Cleans up house.
   *
   * @private
   */
  cleanup(e) {
    if (!(typeof this.xhr > "u" || this.xhr === null)) {
      if (this.xhr.onreadystatechange = Hf, e)
        try {
          this.xhr.abort();
        } catch {
        }
      typeof document < "u" && delete We.requests[this.index], this.xhr = null;
    }
  }
  /**
   * Called upon load.
   *
   * @private
   */
  onLoad() {
    const e = this.xhr.responseText;
    e !== null && (this.emitReserved("data", e), this.emitReserved("success"), this.cleanup());
  }
  /**
   * Aborts the request.
   *
   * @package
   */
  abort() {
    this.cleanup();
  }
}
We.requestsCount = 0;
We.requests = {};
if (typeof document < "u") {
  if (typeof attachEvent == "function")
    attachEvent("onunload", Hs);
  else if (typeof addEventListener == "function") {
    const n = "onpagehide" in Me ? "pagehide" : "unload";
    addEventListener(n, Hs, !1);
  }
}
function Hs() {
  for (let n in We.requests)
    We.requests.hasOwnProperty(n) && We.requests[n].abort();
}
const fo = (() => typeof Promise == "function" && typeof Promise.resolve == "function" ? (e) => Promise.resolve().then(e) : (e, t) => t(e, 0))(), Bn = Me.WebSocket || Me.MozWebSocket, Us = !0, Ff = "arraybuffer", Zs = typeof navigator < "u" && typeof navigator.product == "string" && navigator.product.toLowerCase() === "reactnative";
class Vf extends so {
  /**
   * WebSocket transport constructor.
   *
   * @param {Object} opts - connection options
   * @protected
   */
  constructor(e) {
    super(e), this.supportsBinary = !e.forceBase64;
  }
  get name() {
    return "websocket";
  }
  doOpen() {
    if (!this.check())
      return;
    const e = this.uri(), t = this.opts.protocols, r = Zs ? {} : io(this.opts, "agent", "perMessageDeflate", "pfx", "key", "passphrase", "cert", "ca", "ciphers", "rejectUnauthorized", "localAddress", "protocolVersion", "origin", "maxPayload", "family", "checkServerIdentity");
    this.opts.extraHeaders && (r.headers = this.opts.extraHeaders);
    try {
      this.ws = Us && !Zs ? t ? new Bn(e, t) : new Bn(e) : new Bn(e, t, r);
    } catch (i) {
      return this.emitReserved("error", i);
    }
    this.ws.binaryType = this.socket.binaryType || Ff, this.addEventListeners();
  }
  /**
   * Adds event listeners to the socket
   *
   * @private
   */
  addEventListeners() {
    this.ws.onopen = () => {
      this.opts.autoUnref && this.ws._socket.unref(), this.onOpen();
    }, this.ws.onclose = (e) => this.onClose({
      description: "websocket connection closed",
      context: e
    }), this.ws.onmessage = (e) => this.onData(e.data), this.ws.onerror = (e) => this.onError("websocket error", e);
  }
  write(e) {
    this.writable = !1;
    for (let t = 0; t < e.length; t++) {
      const r = e[t], i = t === e.length - 1;
      Qa(r, this.supportsBinary, (s) => {
        const a = {};
        try {
          Us && this.ws.send(s);
        } catch {
        }
        i && fo(() => {
          this.writable = !0, this.emitReserved("drain");
        }, this.setTimeoutFn);
      });
    }
  }
  doClose() {
    typeof this.ws < "u" && (this.ws.close(), this.ws = null);
  }
  /**
   * Generates uri for connection.
   *
   * @private
   */
  uri() {
    let e = this.query || {};
    const t = this.opts.secure ? "wss" : "ws";
    let r = "";
    this.opts.port && (t === "wss" && Number(this.opts.port) !== 443 || t === "ws" && Number(this.opts.port) !== 80) && (r = ":" + this.opts.port), this.opts.timestampRequests && (e[this.opts.timestampParam] = oo()), this.supportsBinary || (e.b64 = 1);
    const i = uo(e), s = this.opts.hostname.indexOf(":") !== -1;
    return t + "://" + (s ? "[" + this.opts.hostname + "]" : this.opts.hostname) + r + this.opts.path + (i.length ? "?" + i : "");
  }
  /**
   * Feature detection for WebSocket.
   *
   * @return {Boolean} whether this transport is available.
   * @private
   */
  check() {
    return !!Bn;
  }
}
const Gf = {
  websocket: Vf,
  polling: Zf
}, Yf = /^(?:(?![^:@\/?#]+:[^:@\/]*@)(http|https|ws|wss):\/\/)?((?:(([^:@\/?#]*)(?::([^:@\/?#]*))?)?@)?((?:[a-f0-9]{0,4}:){2,7}[a-f0-9]{0,4}|[^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/, Kf = [
  "source",
  "protocol",
  "authority",
  "userInfo",
  "user",
  "password",
  "host",
  "port",
  "relative",
  "path",
  "directory",
  "file",
  "query",
  "anchor"
];
function di(n) {
  const e = n, t = n.indexOf("["), r = n.indexOf("]");
  t != -1 && r != -1 && (n = n.substring(0, t) + n.substring(t, r).replace(/:/g, ";") + n.substring(r, n.length));
  let i = Yf.exec(n || ""), s = {}, a = 14;
  for (; a--; )
    s[Kf[a]] = i[a] || "";
  return t != -1 && r != -1 && (s.source = e, s.host = s.host.substring(1, s.host.length - 1).replace(/;/g, ":"), s.authority = s.authority.replace("[", "").replace("]", "").replace(/;/g, ":"), s.ipv6uri = !0), s.pathNames = qf(s, s.path), s.queryKey = Wf(s, s.query), s;
}
function qf(n, e) {
  const t = /\/{2,9}/g, r = e.replace(t, "/").split("/");
  return (e.slice(0, 1) == "/" || e.length === 0) && r.splice(0, 1), e.slice(-1) == "/" && r.splice(r.length - 1, 1), r;
}
function Wf(n, e) {
  const t = {};
  return e.replace(/(?:^|&)([^&=]*)=?([^&]*)/g, function(r, i, s) {
    i && (t[i] = s);
  }), t;
}
let ho = class Ct extends le {
  /**
   * Socket constructor.
   *
   * @param {String|Object} uri - uri or options
   * @param {Object} opts - options
   */
  constructor(e, t = {}) {
    super(), this.writeBuffer = [], e && typeof e == "object" && (t = e, e = null), e ? (e = di(e), t.hostname = e.host, t.secure = e.protocol === "https" || e.protocol === "wss", t.port = e.port, e.query && (t.query = e.query)) : t.host && (t.hostname = di(t.host).host), Pr(this, t), this.secure = t.secure != null ? t.secure : typeof location < "u" && location.protocol === "https:", t.hostname && !t.port && (t.port = this.secure ? "443" : "80"), this.hostname = t.hostname || (typeof location < "u" ? location.hostname : "localhost"), this.port = t.port || (typeof location < "u" && location.port ? location.port : this.secure ? "443" : "80"), this.transports = t.transports || ["polling", "websocket"], this.writeBuffer = [], this.prevBufferLen = 0, this.opts = Object.assign({
      path: "/engine.io",
      agent: !1,
      withCredentials: !1,
      upgrade: !0,
      timestampParam: "t",
      rememberUpgrade: !1,
      addTrailingSlash: !0,
      rejectUnauthorized: !0,
      perMessageDeflate: {
        threshold: 1024
      },
      transportOptions: {},
      closeOnBeforeunload: !0
    }, t), this.opts.path = this.opts.path.replace(/\/$/, "") + (this.opts.addTrailingSlash ? "/" : ""), typeof this.opts.query == "string" && (this.opts.query = $f(this.opts.query)), this.id = null, this.upgrades = null, this.pingInterval = null, this.pingTimeout = null, this.pingTimeoutTimer = null, typeof addEventListener == "function" && (this.opts.closeOnBeforeunload && (this.beforeunloadEventListener = () => {
      this.transport && (this.transport.removeAllListeners(), this.transport.close());
    }, addEventListener("beforeunload", this.beforeunloadEventListener, !1)), this.hostname !== "localhost" && (this.offlineEventListener = () => {
      this.onClose("transport close", {
        description: "network connection lost"
      });
    }, addEventListener("offline", this.offlineEventListener, !1))), this.open();
  }
  /**
   * Creates transport of the given type.
   *
   * @param {String} name - transport name
   * @return {Transport}
   * @private
   */
  createTransport(e) {
    const t = Object.assign({}, this.opts.query);
    t.EIO = ro, t.transport = e, this.id && (t.sid = this.id);
    const r = Object.assign({}, this.opts.transportOptions[e], this.opts, {
      query: t,
      socket: this,
      hostname: this.hostname,
      secure: this.secure,
      port: this.port
    });
    return new Gf[e](r);
  }
  /**
   * Initializes transport to use and starts probe.
   *
   * @private
   */
  open() {
    let e;
    if (this.opts.rememberUpgrade && Ct.priorWebsocketSuccess && this.transports.indexOf("websocket") !== -1)
      e = "websocket";
    else if (this.transports.length === 0) {
      this.setTimeoutFn(() => {
        this.emitReserved("error", "No transports available");
      }, 0);
      return;
    } else
      e = this.transports[0];
    this.readyState = "opening";
    try {
      e = this.createTransport(e);
    } catch {
      this.transports.shift(), this.open();
      return;
    }
    e.open(), this.setTransport(e);
  }
  /**
   * Sets the current transport. Disables the existing one (if any).
   *
   * @private
   */
  setTransport(e) {
    this.transport && this.transport.removeAllListeners(), this.transport = e, e.on("drain", this.onDrain.bind(this)).on("packet", this.onPacket.bind(this)).on("error", this.onError.bind(this)).on("close", (t) => this.onClose("transport close", t));
  }
  /**
   * Probes a transport.
   *
   * @param {String} name - transport name
   * @private
   */
  probe(e) {
    let t = this.createTransport(e), r = !1;
    Ct.priorWebsocketSuccess = !1;
    const i = () => {
      r || (t.send([{ type: "ping", data: "probe" }]), t.once("packet", (f) => {
        if (!r)
          if (f.type === "pong" && f.data === "probe") {
            if (this.upgrading = !0, this.emitReserved("upgrading", t), !t)
              return;
            Ct.priorWebsocketSuccess = t.name === "websocket", this.transport.pause(() => {
              r || this.readyState !== "closed" && (d(), this.setTransport(t), t.send([{ type: "upgrade" }]), this.emitReserved("upgrade", t), t = null, this.upgrading = !1, this.flush());
            });
          } else {
            const h = new Error("probe error");
            h.transport = t.name, this.emitReserved("upgradeError", h);
          }
      }));
    };
    function s() {
      r || (r = !0, d(), t.close(), t = null);
    }
    const a = (f) => {
      const h = new Error("probe error: " + f);
      h.transport = t.name, s(), this.emitReserved("upgradeError", h);
    };
    function o() {
      a("transport closed");
    }
    function u() {
      a("socket closed");
    }
    function c(f) {
      t && f.name !== t.name && s();
    }
    const d = () => {
      t.removeListener("open", i), t.removeListener("error", a), t.removeListener("close", o), this.off("close", u), this.off("upgrading", c);
    };
    t.once("open", i), t.once("error", a), t.once("close", o), this.once("close", u), this.once("upgrading", c), t.open();
  }
  /**
   * Called when connection is deemed open.
   *
   * @private
   */
  onOpen() {
    if (this.readyState = "open", Ct.priorWebsocketSuccess = this.transport.name === "websocket", this.emitReserved("open"), this.flush(), this.readyState === "open" && this.opts.upgrade) {
      let e = 0;
      const t = this.upgrades.length;
      for (; e < t; e++)
        this.probe(this.upgrades[e]);
    }
  }
  /**
   * Handles a packet.
   *
   * @private
   */
  onPacket(e) {
    if (this.readyState === "opening" || this.readyState === "open" || this.readyState === "closing")
      switch (this.emitReserved("packet", e), this.emitReserved("heartbeat"), e.type) {
        case "open":
          this.onHandshake(JSON.parse(e.data));
          break;
        case "ping":
          this.resetPingTimeout(), this.sendPacket("pong"), this.emitReserved("ping"), this.emitReserved("pong");
          break;
        case "error":
          const t = new Error("server error");
          t.code = e.data, this.onError(t);
          break;
        case "message":
          this.emitReserved("data", e.data), this.emitReserved("message", e.data);
          break;
      }
  }
  /**
   * Called upon handshake completion.
   *
   * @param {Object} data - handshake obj
   * @private
   */
  onHandshake(e) {
    this.emitReserved("handshake", e), this.id = e.sid, this.transport.query.sid = e.sid, this.upgrades = this.filterUpgrades(e.upgrades), this.pingInterval = e.pingInterval, this.pingTimeout = e.pingTimeout, this.maxPayload = e.maxPayload, this.onOpen(), this.readyState !== "closed" && this.resetPingTimeout();
  }
  /**
   * Sets and resets ping timeout timer based on server pings.
   *
   * @private
   */
  resetPingTimeout() {
    this.clearTimeoutFn(this.pingTimeoutTimer), this.pingTimeoutTimer = this.setTimeoutFn(() => {
      this.onClose("ping timeout");
    }, this.pingInterval + this.pingTimeout), this.opts.autoUnref && this.pingTimeoutTimer.unref();
  }
  /**
   * Called on `drain` event
   *
   * @private
   */
  onDrain() {
    this.writeBuffer.splice(0, this.prevBufferLen), this.prevBufferLen = 0, this.writeBuffer.length === 0 ? this.emitReserved("drain") : this.flush();
  }
  /**
   * Flush write buffers.
   *
   * @private
   */
  flush() {
    if (this.readyState !== "closed" && this.transport.writable && !this.upgrading && this.writeBuffer.length) {
      const e = this.getWritablePackets();
      this.transport.send(e), this.prevBufferLen = e.length, this.emitReserved("flush");
    }
  }
  /**
   * Ensure the encoded size of the writeBuffer is below the maxPayload value sent by the server (only for HTTP
   * long-polling)
   *
   * @private
   */
  getWritablePackets() {
    if (!(this.maxPayload && this.transport.name === "polling" && this.writeBuffer.length > 1))
      return this.writeBuffer;
    let t = 1;
    for (let r = 0; r < this.writeBuffer.length; r++) {
      const i = this.writeBuffer[r].data;
      if (i && (t += Cf(i)), r > 0 && t > this.maxPayload)
        return this.writeBuffer.slice(0, r);
      t += 2;
    }
    return this.writeBuffer;
  }
  /**
   * Sends a message.
   *
   * @param {String} msg - message.
   * @param {Object} options.
   * @param {Function} callback function.
   * @return {Socket} for chaining.
   */
  write(e, t, r) {
    return this.sendPacket("message", e, t, r), this;
  }
  send(e, t, r) {
    return this.sendPacket("message", e, t, r), this;
  }
  /**
   * Sends a packet.
   *
   * @param {String} type: packet type.
   * @param {String} data.
   * @param {Object} options.
   * @param {Function} fn - callback function.
   * @private
   */
  sendPacket(e, t, r, i) {
    if (typeof t == "function" && (i = t, t = void 0), typeof r == "function" && (i = r, r = null), this.readyState === "closing" || this.readyState === "closed")
      return;
    r = r || {}, r.compress = r.compress !== !1;
    const s = {
      type: e,
      data: t,
      options: r
    };
    this.emitReserved("packetCreate", s), this.writeBuffer.push(s), i && this.once("flush", i), this.flush();
  }
  /**
   * Closes the connection.
   */
  close() {
    const e = () => {
      this.onClose("forced close"), this.transport.close();
    }, t = () => {
      this.off("upgrade", t), this.off("upgradeError", t), e();
    }, r = () => {
      this.once("upgrade", t), this.once("upgradeError", t);
    };
    return (this.readyState === "opening" || this.readyState === "open") && (this.readyState = "closing", this.writeBuffer.length ? this.once("drain", () => {
      this.upgrading ? r() : e();
    }) : this.upgrading ? r() : e()), this;
  }
  /**
   * Called upon transport error
   *
   * @private
   */
  onError(e) {
    Ct.priorWebsocketSuccess = !1, this.emitReserved("error", e), this.onClose("transport error", e);
  }
  /**
   * Called upon transport close.
   *
   * @private
   */
  onClose(e, t) {
    (this.readyState === "opening" || this.readyState === "open" || this.readyState === "closing") && (this.clearTimeoutFn(this.pingTimeoutTimer), this.transport.removeAllListeners("close"), this.transport.close(), this.transport.removeAllListeners(), typeof removeEventListener == "function" && (removeEventListener("beforeunload", this.beforeunloadEventListener, !1), removeEventListener("offline", this.offlineEventListener, !1)), this.readyState = "closed", this.id = null, this.emitReserved("close", e, t), this.writeBuffer = [], this.prevBufferLen = 0);
  }
  /**
   * Filters upgrades, returning only those matching client transports.
   *
   * @param {Array} upgrades - server upgrades
   * @private
   */
  filterUpgrades(e) {
    const t = [];
    let r = 0;
    const i = e.length;
    for (; r < i; r++)
      ~this.transports.indexOf(e[r]) && t.push(e[r]);
    return t;
  }
};
ho.protocol = ro;
function Jf(n, e = "", t) {
  let r = n;
  t = t || typeof location < "u" && location, n == null && (n = t.protocol + "//" + t.host), typeof n == "string" && (n.charAt(0) === "/" && (n.charAt(1) === "/" ? n = t.protocol + n : n = t.host + n), /^(https?|wss?):\/\//.test(n) || (typeof t < "u" ? n = t.protocol + "//" + n : n = "https://" + n), r = di(n)), r.port || (/^(http|ws)$/.test(r.protocol) ? r.port = "80" : /^(http|ws)s$/.test(r.protocol) && (r.port = "443")), r.path = r.path || "/";
  const s = r.host.indexOf(":") !== -1 ? "[" + r.host + "]" : r.host;
  return r.id = r.protocol + "://" + s + ":" + r.port + e, r.href = r.protocol + "://" + s + (t && t.port === r.port ? "" : ":" + r.port), r;
}
const zf = typeof ArrayBuffer == "function", Xf = (n) => typeof ArrayBuffer.isView == "function" ? ArrayBuffer.isView(n) : n.buffer instanceof ArrayBuffer, po = Object.prototype.toString, Qf = typeof Blob == "function" || typeof Blob < "u" && po.call(Blob) === "[object BlobConstructor]", ed = typeof File == "function" || typeof File < "u" && po.call(File) === "[object FileConstructor]";
function Ri(n) {
  return zf && (n instanceof ArrayBuffer || Xf(n)) || Qf && n instanceof Blob || ed && n instanceof File;
}
function Vn(n, e) {
  if (!n || typeof n != "object")
    return !1;
  if (Array.isArray(n)) {
    for (let t = 0, r = n.length; t < r; t++)
      if (Vn(n[t]))
        return !0;
    return !1;
  }
  if (Ri(n))
    return !0;
  if (n.toJSON && typeof n.toJSON == "function" && arguments.length === 1)
    return Vn(n.toJSON(), !0);
  for (const t in n)
    if (Object.prototype.hasOwnProperty.call(n, t) && Vn(n[t]))
      return !0;
  return !1;
}
function td(n) {
  const e = [], t = n.data, r = n;
  return r.data = hi(t, e), r.attachments = e.length, { packet: r, buffers: e };
}
function hi(n, e) {
  if (!n)
    return n;
  if (Ri(n)) {
    const t = { _placeholder: !0, num: e.length };
    return e.push(n), t;
  } else if (Array.isArray(n)) {
    const t = new Array(n.length);
    for (let r = 0; r < n.length; r++)
      t[r] = hi(n[r], e);
    return t;
  } else if (typeof n == "object" && !(n instanceof Date)) {
    const t = {};
    for (const r in n)
      Object.prototype.hasOwnProperty.call(n, r) && (t[r] = hi(n[r], e));
    return t;
  }
  return n;
}
function nd(n, e) {
  return n.data = pi(n.data, e), delete n.attachments, n;
}
function pi(n, e) {
  if (!n)
    return n;
  if (n && n._placeholder === !0) {
    if (typeof n.num == "number" && n.num >= 0 && n.num < e.length)
      return e[n.num];
    throw new Error("illegal attachments");
  } else if (Array.isArray(n))
    for (let t = 0; t < n.length; t++)
      n[t] = pi(n[t], e);
  else if (typeof n == "object")
    for (const t in n)
      Object.prototype.hasOwnProperty.call(n, t) && (n[t] = pi(n[t], e));
  return n;
}
const rd = 5;
var q;
(function(n) {
  n[n.CONNECT = 0] = "CONNECT", n[n.DISCONNECT = 1] = "DISCONNECT", n[n.EVENT = 2] = "EVENT", n[n.ACK = 3] = "ACK", n[n.CONNECT_ERROR = 4] = "CONNECT_ERROR", n[n.BINARY_EVENT = 5] = "BINARY_EVENT", n[n.BINARY_ACK = 6] = "BINARY_ACK";
})(q || (q = {}));
class id {
  /**
   * Encoder constructor
   *
   * @param {function} replacer - custom replacer to pass down to JSON.parse
   */
  constructor(e) {
    this.replacer = e;
  }
  /**
   * Encode a packet as a single string if non-binary, or as a
   * buffer sequence, depending on packet type.
   *
   * @param {Object} obj - packet object
   */
  encode(e) {
    return (e.type === q.EVENT || e.type === q.ACK) && Vn(e) ? this.encodeAsBinary({
      type: e.type === q.EVENT ? q.BINARY_EVENT : q.BINARY_ACK,
      nsp: e.nsp,
      data: e.data,
      id: e.id
    }) : [this.encodeAsString(e)];
  }
  /**
   * Encode packet as string.
   */
  encodeAsString(e) {
    let t = "" + e.type;
    return (e.type === q.BINARY_EVENT || e.type === q.BINARY_ACK) && (t += e.attachments + "-"), e.nsp && e.nsp !== "/" && (t += e.nsp + ","), e.id != null && (t += e.id), e.data != null && (t += JSON.stringify(e.data, this.replacer)), t;
  }
  /**
   * Encode packet as 'buffer sequence' by removing blobs, and
   * deconstructing packet into object with placeholders and
   * a list of buffers.
   */
  encodeAsBinary(e) {
    const t = td(e), r = this.encodeAsString(t.packet), i = t.buffers;
    return i.unshift(r), i;
  }
}
class xi extends le {
  /**
   * Decoder constructor
   *
   * @param {function} reviver - custom reviver to pass down to JSON.stringify
   */
  constructor(e) {
    super(), this.reviver = e;
  }
  /**
   * Decodes an encoded packet string into packet JSON.
   *
   * @param {String} obj - encoded packet
   */
  add(e) {
    let t;
    if (typeof e == "string") {
      if (this.reconstructor)
        throw new Error("got plaintext data when reconstructing a packet");
      t = this.decodeString(e);
      const r = t.type === q.BINARY_EVENT;
      r || t.type === q.BINARY_ACK ? (t.type = r ? q.EVENT : q.ACK, this.reconstructor = new sd(t), t.attachments === 0 && super.emitReserved("decoded", t)) : super.emitReserved("decoded", t);
    } else if (Ri(e) || e.base64)
      if (this.reconstructor)
        t = this.reconstructor.takeBinaryData(e), t && (this.reconstructor = null, super.emitReserved("decoded", t));
      else
        throw new Error("got binary data when not reconstructing a packet");
    else
      throw new Error("Unknown type: " + e);
  }
  /**
   * Decode a packet String (JSON data)
   *
   * @param {String} str
   * @return {Object} packet
   */
  decodeString(e) {
    let t = 0;
    const r = {
      type: Number(e.charAt(0))
    };
    if (q[r.type] === void 0)
      throw new Error("unknown packet type " + r.type);
    if (r.type === q.BINARY_EVENT || r.type === q.BINARY_ACK) {
      const s = t + 1;
      for (; e.charAt(++t) !== "-" && t != e.length; )
        ;
      const a = e.substring(s, t);
      if (a != Number(a) || e.charAt(t) !== "-")
        throw new Error("Illegal attachments");
      r.attachments = Number(a);
    }
    if (e.charAt(t + 1) === "/") {
      const s = t + 1;
      for (; ++t && !(e.charAt(t) === "," || t === e.length); )
        ;
      r.nsp = e.substring(s, t);
    } else
      r.nsp = "/";
    const i = e.charAt(t + 1);
    if (i !== "" && Number(i) == i) {
      const s = t + 1;
      for (; ++t; ) {
        const a = e.charAt(t);
        if (a == null || Number(a) != a) {
          --t;
          break;
        }
        if (t === e.length)
          break;
      }
      r.id = Number(e.substring(s, t + 1));
    }
    if (e.charAt(++t)) {
      const s = this.tryParse(e.substr(t));
      if (xi.isPayloadValid(r.type, s))
        r.data = s;
      else
        throw new Error("invalid payload");
    }
    return r;
  }
  tryParse(e) {
    try {
      return JSON.parse(e, this.reviver);
    } catch {
      return !1;
    }
  }
  static isPayloadValid(e, t) {
    switch (e) {
      case q.CONNECT:
        return typeof t == "object";
      case q.DISCONNECT:
        return t === void 0;
      case q.CONNECT_ERROR:
        return typeof t == "string" || typeof t == "object";
      case q.EVENT:
      case q.BINARY_EVENT:
        return Array.isArray(t) && t.length > 0;
      case q.ACK:
      case q.BINARY_ACK:
        return Array.isArray(t);
    }
  }
  /**
   * Deallocates a parser's resources
   */
  destroy() {
    this.reconstructor && (this.reconstructor.finishedReconstruction(), this.reconstructor = null);
  }
}
class sd {
  constructor(e) {
    this.packet = e, this.buffers = [], this.reconPack = e;
  }
  /**
   * Method to be called when binary data received from connection
   * after a BINARY_EVENT packet.
   *
   * @param {Buffer | ArrayBuffer} binData - the raw binary data received
   * @return {null | Object} returns null if more binary data is expected or
   *   a reconstructed packet object if all buffers have been received.
   */
  takeBinaryData(e) {
    if (this.buffers.push(e), this.buffers.length === this.reconPack.attachments) {
      const t = nd(this.reconPack, this.buffers);
      return this.finishedReconstruction(), t;
    }
    return null;
  }
  /**
   * Cleans up binary packet reconstruction variables.
   */
  finishedReconstruction() {
    this.reconPack = null, this.buffers = [];
  }
}
const ad = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  Decoder: xi,
  Encoder: id,
  get PacketType() {
    return q;
  },
  protocol: rd
}, Symbol.toStringTag, { value: "Module" }));
function Ce(n, e, t) {
  return n.on(e, t), function() {
    n.off(e, t);
  };
}
const od = Object.freeze({
  connect: 1,
  connect_error: 1,
  disconnect: 1,
  disconnecting: 1,
  // EventEmitter reserved events: https://nodejs.org/api/events.html#events_event_newlistener
  newListener: 1,
  removeListener: 1
});
class mo extends le {
  /**
   * `Socket` constructor.
   */
  constructor(e, t, r) {
    super(), this.connected = !1, this.recovered = !1, this.receiveBuffer = [], this.sendBuffer = [], this._queue = [], this._queueSeq = 0, this.ids = 0, this.acks = {}, this.flags = {}, this.io = e, this.nsp = t, r && r.auth && (this.auth = r.auth), this._opts = Object.assign({}, r), this.io._autoConnect && this.open();
  }
  /**
   * Whether the socket is currently disconnected
   *
   * @example
   * const socket = io();
   *
   * socket.on("connect", () => {
   *   console.log(socket.disconnected); // false
   * });
   *
   * socket.on("disconnect", () => {
   *   console.log(socket.disconnected); // true
   * });
   */
  get disconnected() {
    return !this.connected;
  }
  /**
   * Subscribe to open, close and packet events
   *
   * @private
   */
  subEvents() {
    if (this.subs)
      return;
    const e = this.io;
    this.subs = [
      Ce(e, "open", this.onopen.bind(this)),
      Ce(e, "packet", this.onpacket.bind(this)),
      Ce(e, "error", this.onerror.bind(this)),
      Ce(e, "close", this.onclose.bind(this))
    ];
  }
  /**
   * Whether the Socket will try to reconnect when its Manager connects or reconnects.
   *
   * @example
   * const socket = io();
   *
   * console.log(socket.active); // true
   *
   * socket.on("disconnect", (reason) => {
   *   if (reason === "io server disconnect") {
   *     // the disconnection was initiated by the server, you need to manually reconnect
   *     console.log(socket.active); // false
   *   }
   *   // else the socket will automatically try to reconnect
   *   console.log(socket.active); // true
   * });
   */
  get active() {
    return !!this.subs;
  }
  /**
   * "Opens" the socket.
   *
   * @example
   * const socket = io({
   *   autoConnect: false
   * });
   *
   * socket.connect();
   */
  connect() {
    return this.connected ? this : (this.subEvents(), this.io._reconnecting || this.io.open(), this.io._readyState === "open" && this.onopen(), this);
  }
  /**
   * Alias for {@link connect()}.
   */
  open() {
    return this.connect();
  }
  /**
   * Sends a `message` event.
   *
   * This method mimics the WebSocket.send() method.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/send
   *
   * @example
   * socket.send("hello");
   *
   * // this is equivalent to
   * socket.emit("message", "hello");
   *
   * @return self
   */
  send(...e) {
    return e.unshift("message"), this.emit.apply(this, e), this;
  }
  /**
   * Override `emit`.
   * If the event is in `events`, it's emitted normally.
   *
   * @example
   * socket.emit("hello", "world");
   *
   * // all serializable datastructures are supported (no need to call JSON.stringify)
   * socket.emit("hello", 1, "2", { 3: ["4"], 5: Uint8Array.from([6]) });
   *
   * // with an acknowledgement from the server
   * socket.emit("hello", "world", (val) => {
   *   // ...
   * });
   *
   * @return self
   */
  emit(e, ...t) {
    if (od.hasOwnProperty(e))
      throw new Error('"' + e.toString() + '" is a reserved event name');
    if (t.unshift(e), this._opts.retries && !this.flags.fromQueue && !this.flags.volatile)
      return this._addToQueue(t), this;
    const r = {
      type: q.EVENT,
      data: t
    };
    if (r.options = {}, r.options.compress = this.flags.compress !== !1, typeof t[t.length - 1] == "function") {
      const a = this.ids++, o = t.pop();
      this._registerAckCallback(a, o), r.id = a;
    }
    const i = this.io.engine && this.io.engine.transport && this.io.engine.transport.writable;
    return this.flags.volatile && (!i || !this.connected) || (this.connected ? (this.notifyOutgoingListeners(r), this.packet(r)) : this.sendBuffer.push(r)), this.flags = {}, this;
  }
  /**
   * @private
   */
  _registerAckCallback(e, t) {
    var r;
    const i = (r = this.flags.timeout) !== null && r !== void 0 ? r : this._opts.ackTimeout;
    if (i === void 0) {
      this.acks[e] = t;
      return;
    }
    const s = this.io.setTimeoutFn(() => {
      delete this.acks[e];
      for (let a = 0; a < this.sendBuffer.length; a++)
        this.sendBuffer[a].id === e && this.sendBuffer.splice(a, 1);
      t.call(this, new Error("operation has timed out"));
    }, i);
    this.acks[e] = (...a) => {
      this.io.clearTimeoutFn(s), t.apply(this, [null, ...a]);
    };
  }
  /**
   * Emits an event and waits for an acknowledgement
   *
   * @example
   * // without timeout
   * const response = await socket.emitWithAck("hello", "world");
   *
   * // with a specific timeout
   * try {
   *   const response = await socket.timeout(1000).emitWithAck("hello", "world");
   * } catch (err) {
   *   // the server did not acknowledge the event in the given delay
   * }
   *
   * @return a Promise that will be fulfilled when the server acknowledges the event
   */
  emitWithAck(e, ...t) {
    const r = this.flags.timeout !== void 0 || this._opts.ackTimeout !== void 0;
    return new Promise((i, s) => {
      t.push((a, o) => r ? a ? s(a) : i(o) : i(a)), this.emit(e, ...t);
    });
  }
  /**
   * Add the packet to the queue.
   * @param args
   * @private
   */
  _addToQueue(e) {
    let t;
    typeof e[e.length - 1] == "function" && (t = e.pop());
    const r = {
      id: this._queueSeq++,
      tryCount: 0,
      pending: !1,
      args: e,
      flags: Object.assign({ fromQueue: !0 }, this.flags)
    };
    e.push((i, ...s) => r !== this._queue[0] ? void 0 : (i !== null ? r.tryCount > this._opts.retries && (this._queue.shift(), t && t(i)) : (this._queue.shift(), t && t(null, ...s)), r.pending = !1, this._drainQueue())), this._queue.push(r), this._drainQueue();
  }
  /**
   * Send the first packet of the queue, and wait for an acknowledgement from the server.
   * @param force - whether to resend a packet that has not been acknowledged yet
   *
   * @private
   */
  _drainQueue(e = !1) {
    if (!this.connected || this._queue.length === 0)
      return;
    const t = this._queue[0];
    t.pending && !e || (t.pending = !0, t.tryCount++, this.flags = t.flags, this.emit.apply(this, t.args));
  }
  /**
   * Sends a packet.
   *
   * @param packet
   * @private
   */
  packet(e) {
    e.nsp = this.nsp, this.io._packet(e);
  }
  /**
   * Called upon engine `open`.
   *
   * @private
   */
  onopen() {
    typeof this.auth == "function" ? this.auth((e) => {
      this._sendConnectPacket(e);
    }) : this._sendConnectPacket(this.auth);
  }
  /**
   * Sends a CONNECT packet to initiate the Socket.IO session.
   *
   * @param data
   * @private
   */
  _sendConnectPacket(e) {
    this.packet({
      type: q.CONNECT,
      data: this._pid ? Object.assign({ pid: this._pid, offset: this._lastOffset }, e) : e
    });
  }
  /**
   * Called upon engine or manager `error`.
   *
   * @param err
   * @private
   */
  onerror(e) {
    this.connected || this.emitReserved("connect_error", e);
  }
  /**
   * Called upon engine `close`.
   *
   * @param reason
   * @param description
   * @private
   */
  onclose(e, t) {
    this.connected = !1, delete this.id, this.emitReserved("disconnect", e, t);
  }
  /**
   * Called with socket packet.
   *
   * @param packet
   * @private
   */
  onpacket(e) {
    if (e.nsp === this.nsp)
      switch (e.type) {
        case q.CONNECT:
          e.data && e.data.sid ? this.onconnect(e.data.sid, e.data.pid) : this.emitReserved("connect_error", new Error("It seems you are trying to reach a Socket.IO server in v2.x with a v3.x client, but they are not compatible (more information here: https://socket.io/docs/v3/migrating-from-2-x-to-3-0/)"));
          break;
        case q.EVENT:
        case q.BINARY_EVENT:
          this.onevent(e);
          break;
        case q.ACK:
        case q.BINARY_ACK:
          this.onack(e);
          break;
        case q.DISCONNECT:
          this.ondisconnect();
          break;
        case q.CONNECT_ERROR:
          this.destroy();
          const r = new Error(e.data.message);
          r.data = e.data.data, this.emitReserved("connect_error", r);
          break;
      }
  }
  /**
   * Called upon a server event.
   *
   * @param packet
   * @private
   */
  onevent(e) {
    const t = e.data || [];
    e.id != null && t.push(this.ack(e.id)), this.connected ? this.emitEvent(t) : this.receiveBuffer.push(Object.freeze(t));
  }
  emitEvent(e) {
    if (this._anyListeners && this._anyListeners.length) {
      const t = this._anyListeners.slice();
      for (const r of t)
        r.apply(this, e);
    }
    super.emit.apply(this, e), this._pid && e.length && typeof e[e.length - 1] == "string" && (this._lastOffset = e[e.length - 1]);
  }
  /**
   * Produces an ack callback to emit with an event.
   *
   * @private
   */
  ack(e) {
    const t = this;
    let r = !1;
    return function(...i) {
      r || (r = !0, t.packet({
        type: q.ACK,
        id: e,
        data: i
      }));
    };
  }
  /**
   * Called upon a server acknowlegement.
   *
   * @param packet
   * @private
   */
  onack(e) {
    const t = this.acks[e.id];
    typeof t == "function" && (t.apply(this, e.data), delete this.acks[e.id]);
  }
  /**
   * Called upon server connect.
   *
   * @private
   */
  onconnect(e, t) {
    this.id = e, this.recovered = t && this._pid === t, this._pid = t, this.connected = !0, this.emitBuffered(), this.emitReserved("connect"), this._drainQueue(!0);
  }
  /**
   * Emit buffered events (received and emitted).
   *
   * @private
   */
  emitBuffered() {
    this.receiveBuffer.forEach((e) => this.emitEvent(e)), this.receiveBuffer = [], this.sendBuffer.forEach((e) => {
      this.notifyOutgoingListeners(e), this.packet(e);
    }), this.sendBuffer = [];
  }
  /**
   * Called upon server disconnect.
   *
   * @private
   */
  ondisconnect() {
    this.destroy(), this.onclose("io server disconnect");
  }
  /**
   * Called upon forced client/server side disconnections,
   * this method ensures the manager stops tracking us and
   * that reconnections don't get triggered for this.
   *
   * @private
   */
  destroy() {
    this.subs && (this.subs.forEach((e) => e()), this.subs = void 0), this.io._destroy(this);
  }
  /**
   * Disconnects the socket manually. In that case, the socket will not try to reconnect.
   *
   * If this is the last active Socket instance of the {@link Manager}, the low-level connection will be closed.
   *
   * @example
   * const socket = io();
   *
   * socket.on("disconnect", (reason) => {
   *   // console.log(reason); prints "io client disconnect"
   * });
   *
   * socket.disconnect();
   *
   * @return self
   */
  disconnect() {
    return this.connected && this.packet({ type: q.DISCONNECT }), this.destroy(), this.connected && this.onclose("io client disconnect"), this;
  }
  /**
   * Alias for {@link disconnect()}.
   *
   * @return self
   */
  close() {
    return this.disconnect();
  }
  /**
   * Sets the compress flag.
   *
   * @example
   * socket.compress(false).emit("hello");
   *
   * @param compress - if `true`, compresses the sending data
   * @return self
   */
  compress(e) {
    return this.flags.compress = e, this;
  }
  /**
   * Sets a modifier for a subsequent event emission that the event message will be dropped when this socket is not
   * ready to send messages.
   *
   * @example
   * socket.volatile.emit("hello"); // the server may or may not receive it
   *
   * @returns self
   */
  get volatile() {
    return this.flags.volatile = !0, this;
  }
  /**
   * Sets a modifier for a subsequent event emission that the callback will be called with an error when the
   * given number of milliseconds have elapsed without an acknowledgement from the server:
   *
   * @example
   * socket.timeout(5000).emit("my-event", (err) => {
   *   if (err) {
   *     // the server did not acknowledge the event in the given delay
   *   }
   * });
   *
   * @returns self
   */
  timeout(e) {
    return this.flags.timeout = e, this;
  }
  /**
   * Adds a listener that will be fired when any event is emitted. The event name is passed as the first argument to the
   * callback.
   *
   * @example
   * socket.onAny((event, ...args) => {
   *   console.log(`got ${event}`);
   * });
   *
   * @param listener
   */
  onAny(e) {
    return this._anyListeners = this._anyListeners || [], this._anyListeners.push(e), this;
  }
  /**
   * Adds a listener that will be fired when any event is emitted. The event name is passed as the first argument to the
   * callback. The listener is added to the beginning of the listeners array.
   *
   * @example
   * socket.prependAny((event, ...args) => {
   *   console.log(`got event ${event}`);
   * });
   *
   * @param listener
   */
  prependAny(e) {
    return this._anyListeners = this._anyListeners || [], this._anyListeners.unshift(e), this;
  }
  /**
   * Removes the listener that will be fired when any event is emitted.
   *
   * @example
   * const catchAllListener = (event, ...args) => {
   *   console.log(`got event ${event}`);
   * }
   *
   * socket.onAny(catchAllListener);
   *
   * // remove a specific listener
   * socket.offAny(catchAllListener);
   *
   * // or remove all listeners
   * socket.offAny();
   *
   * @param listener
   */
  offAny(e) {
    if (!this._anyListeners)
      return this;
    if (e) {
      const t = this._anyListeners;
      for (let r = 0; r < t.length; r++)
        if (e === t[r])
          return t.splice(r, 1), this;
    } else
      this._anyListeners = [];
    return this;
  }
  /**
   * Returns an array of listeners that are listening for any event that is specified. This array can be manipulated,
   * e.g. to remove listeners.
   */
  listenersAny() {
    return this._anyListeners || [];
  }
  /**
   * Adds a listener that will be fired when any event is emitted. The event name is passed as the first argument to the
   * callback.
   *
   * Note: acknowledgements sent to the server are not included.
   *
   * @example
   * socket.onAnyOutgoing((event, ...args) => {
   *   console.log(`sent event ${event}`);
   * });
   *
   * @param listener
   */
  onAnyOutgoing(e) {
    return this._anyOutgoingListeners = this._anyOutgoingListeners || [], this._anyOutgoingListeners.push(e), this;
  }
  /**
   * Adds a listener that will be fired when any event is emitted. The event name is passed as the first argument to the
   * callback. The listener is added to the beginning of the listeners array.
   *
   * Note: acknowledgements sent to the server are not included.
   *
   * @example
   * socket.prependAnyOutgoing((event, ...args) => {
   *   console.log(`sent event ${event}`);
   * });
   *
   * @param listener
   */
  prependAnyOutgoing(e) {
    return this._anyOutgoingListeners = this._anyOutgoingListeners || [], this._anyOutgoingListeners.unshift(e), this;
  }
  /**
   * Removes the listener that will be fired when any event is emitted.
   *
   * @example
   * const catchAllListener = (event, ...args) => {
   *   console.log(`sent event ${event}`);
   * }
   *
   * socket.onAnyOutgoing(catchAllListener);
   *
   * // remove a specific listener
   * socket.offAnyOutgoing(catchAllListener);
   *
   * // or remove all listeners
   * socket.offAnyOutgoing();
   *
   * @param [listener] - the catch-all listener (optional)
   */
  offAnyOutgoing(e) {
    if (!this._anyOutgoingListeners)
      return this;
    if (e) {
      const t = this._anyOutgoingListeners;
      for (let r = 0; r < t.length; r++)
        if (e === t[r])
          return t.splice(r, 1), this;
    } else
      this._anyOutgoingListeners = [];
    return this;
  }
  /**
   * Returns an array of listeners that are listening for any event that is specified. This array can be manipulated,
   * e.g. to remove listeners.
   */
  listenersAnyOutgoing() {
    return this._anyOutgoingListeners || [];
  }
  /**
   * Notify the listeners for each packet sent
   *
   * @param packet
   *
   * @private
   */
  notifyOutgoingListeners(e) {
    if (this._anyOutgoingListeners && this._anyOutgoingListeners.length) {
      const t = this._anyOutgoingListeners.slice();
      for (const r of t)
        r.apply(this, e.data);
    }
  }
}
function Wt(n) {
  n = n || {}, this.ms = n.min || 100, this.max = n.max || 1e4, this.factor = n.factor || 2, this.jitter = n.jitter > 0 && n.jitter <= 1 ? n.jitter : 0, this.attempts = 0;
}
Wt.prototype.duration = function() {
  var n = this.ms * Math.pow(this.factor, this.attempts++);
  if (this.jitter) {
    var e = Math.random(), t = Math.floor(e * this.jitter * n);
    n = Math.floor(e * 10) & 1 ? n + t : n - t;
  }
  return Math.min(n, this.max) | 0;
};
Wt.prototype.reset = function() {
  this.attempts = 0;
};
Wt.prototype.setMin = function(n) {
  this.ms = n;
};
Wt.prototype.setMax = function(n) {
  this.max = n;
};
Wt.prototype.setJitter = function(n) {
  this.jitter = n;
};
class mi extends le {
  constructor(e, t) {
    var r;
    super(), this.nsps = {}, this.subs = [], e && typeof e == "object" && (t = e, e = void 0), t = t || {}, t.path = t.path || "/socket.io", this.opts = t, Pr(this, t), this.reconnection(t.reconnection !== !1), this.reconnectionAttempts(t.reconnectionAttempts || 1 / 0), this.reconnectionDelay(t.reconnectionDelay || 1e3), this.reconnectionDelayMax(t.reconnectionDelayMax || 5e3), this.randomizationFactor((r = t.randomizationFactor) !== null && r !== void 0 ? r : 0.5), this.backoff = new Wt({
      min: this.reconnectionDelay(),
      max: this.reconnectionDelayMax(),
      jitter: this.randomizationFactor()
    }), this.timeout(t.timeout == null ? 2e4 : t.timeout), this._readyState = "closed", this.uri = e;
    const i = t.parser || ad;
    this.encoder = new i.Encoder(), this.decoder = new i.Decoder(), this._autoConnect = t.autoConnect !== !1, this._autoConnect && this.open();
  }
  reconnection(e) {
    return arguments.length ? (this._reconnection = !!e, this) : this._reconnection;
  }
  reconnectionAttempts(e) {
    return e === void 0 ? this._reconnectionAttempts : (this._reconnectionAttempts = e, this);
  }
  reconnectionDelay(e) {
    var t;
    return e === void 0 ? this._reconnectionDelay : (this._reconnectionDelay = e, (t = this.backoff) === null || t === void 0 || t.setMin(e), this);
  }
  randomizationFactor(e) {
    var t;
    return e === void 0 ? this._randomizationFactor : (this._randomizationFactor = e, (t = this.backoff) === null || t === void 0 || t.setJitter(e), this);
  }
  reconnectionDelayMax(e) {
    var t;
    return e === void 0 ? this._reconnectionDelayMax : (this._reconnectionDelayMax = e, (t = this.backoff) === null || t === void 0 || t.setMax(e), this);
  }
  timeout(e) {
    return arguments.length ? (this._timeout = e, this) : this._timeout;
  }
  /**
   * Starts trying to reconnect if reconnection is enabled and we have not
   * started reconnecting yet
   *
   * @private
   */
  maybeReconnectOnOpen() {
    !this._reconnecting && this._reconnection && this.backoff.attempts === 0 && this.reconnect();
  }
  /**
   * Sets the current transport `socket`.
   *
   * @param {Function} fn - optional, callback
   * @return self
   * @public
   */
  open(e) {
    if (~this._readyState.indexOf("open"))
      return this;
    this.engine = new ho(this.uri, this.opts);
    const t = this.engine, r = this;
    this._readyState = "opening", this.skipReconnect = !1;
    const i = Ce(t, "open", function() {
      r.onopen(), e && e();
    }), s = Ce(t, "error", (a) => {
      r.cleanup(), r._readyState = "closed", this.emitReserved("error", a), e ? e(a) : r.maybeReconnectOnOpen();
    });
    if (this._timeout !== !1) {
      const a = this._timeout;
      a === 0 && i();
      const o = this.setTimeoutFn(() => {
        i(), t.close(), t.emit("error", new Error("timeout"));
      }, a);
      this.opts.autoUnref && o.unref(), this.subs.push(function() {
        clearTimeout(o);
      });
    }
    return this.subs.push(i), this.subs.push(s), this;
  }
  /**
   * Alias for open()
   *
   * @return self
   * @public
   */
  connect(e) {
    return this.open(e);
  }
  /**
   * Called upon transport open.
   *
   * @private
   */
  onopen() {
    this.cleanup(), this._readyState = "open", this.emitReserved("open");
    const e = this.engine;
    this.subs.push(Ce(e, "ping", this.onping.bind(this)), Ce(e, "data", this.ondata.bind(this)), Ce(e, "error", this.onerror.bind(this)), Ce(e, "close", this.onclose.bind(this)), Ce(this.decoder, "decoded", this.ondecoded.bind(this)));
  }
  /**
   * Called upon a ping.
   *
   * @private
   */
  onping() {
    this.emitReserved("ping");
  }
  /**
   * Called with data.
   *
   * @private
   */
  ondata(e) {
    try {
      this.decoder.add(e);
    } catch (t) {
      this.onclose("parse error", t);
    }
  }
  /**
   * Called when parser fully decodes a packet.
   *
   * @private
   */
  ondecoded(e) {
    fo(() => {
      this.emitReserved("packet", e);
    }, this.setTimeoutFn);
  }
  /**
   * Called upon socket error.
   *
   * @private
   */
  onerror(e) {
    this.emitReserved("error", e);
  }
  /**
   * Creates a new socket for the given `nsp`.
   *
   * @return {Socket}
   * @public
   */
  socket(e, t) {
    let r = this.nsps[e];
    return r ? this._autoConnect && !r.active && r.connect() : (r = new mo(this, e, t), this.nsps[e] = r), r;
  }
  /**
   * Called upon a socket close.
   *
   * @param socket
   * @private
   */
  _destroy(e) {
    const t = Object.keys(this.nsps);
    for (const r of t)
      if (this.nsps[r].active)
        return;
    this._close();
  }
  /**
   * Writes a packet.
   *
   * @param packet
   * @private
   */
  _packet(e) {
    const t = this.encoder.encode(e);
    for (let r = 0; r < t.length; r++)
      this.engine.write(t[r], e.options);
  }
  /**
   * Clean up transport subscriptions and packet buffer.
   *
   * @private
   */
  cleanup() {
    this.subs.forEach((e) => e()), this.subs.length = 0, this.decoder.destroy();
  }
  /**
   * Close the current socket.
   *
   * @private
   */
  _close() {
    this.skipReconnect = !0, this._reconnecting = !1, this.onclose("forced close"), this.engine && this.engine.close();
  }
  /**
   * Alias for close()
   *
   * @private
   */
  disconnect() {
    return this._close();
  }
  /**
   * Called upon engine close.
   *
   * @private
   */
  onclose(e, t) {
    this.cleanup(), this.backoff.reset(), this._readyState = "closed", this.emitReserved("close", e, t), this._reconnection && !this.skipReconnect && this.reconnect();
  }
  /**
   * Attempt a reconnection.
   *
   * @private
   */
  reconnect() {
    if (this._reconnecting || this.skipReconnect)
      return this;
    const e = this;
    if (this.backoff.attempts >= this._reconnectionAttempts)
      this.backoff.reset(), this.emitReserved("reconnect_failed"), this._reconnecting = !1;
    else {
      const t = this.backoff.duration();
      this._reconnecting = !0;
      const r = this.setTimeoutFn(() => {
        e.skipReconnect || (this.emitReserved("reconnect_attempt", e.backoff.attempts), !e.skipReconnect && e.open((i) => {
          i ? (e._reconnecting = !1, e.reconnect(), this.emitReserved("reconnect_error", i)) : e.onreconnect();
        }));
      }, t);
      this.opts.autoUnref && r.unref(), this.subs.push(function() {
        clearTimeout(r);
      });
    }
  }
  /**
   * Called upon successful reconnect.
   *
   * @private
   */
  onreconnect() {
    const e = this.backoff.attempts;
    this._reconnecting = !1, this.backoff.reset(), this.emitReserved("reconnect", e);
  }
}
const zt = {};
function Gn(n, e) {
  typeof n == "object" && (e = n, n = void 0), e = e || {};
  const t = Jf(n, e.path || "/socket.io"), r = t.source, i = t.id, s = t.path, a = zt[i] && s in zt[i].nsps, o = e.forceNew || e["force new connection"] || e.multiplex === !1 || a;
  let u;
  return o ? u = new mi(r, e) : (zt[i] || (zt[i] = new mi(r, e)), u = zt[i]), t.query && !e.query && (e.query = t.queryKey), u.socket(t.path, e);
}
Object.assign(Gn, {
  Manager: mi,
  Socket: mo,
  io: Gn,
  connect: Gn
});
const ud = ({ url: n, userToken: e, namespace: t = "/private" }) => {
  let r;
  const i = {}, s = {}, a = (u) => {
    const { subscriptionData: c } = u, { target: d, topic: f } = c, h = Cs({ topic: f, target: d }), p = Math.random().toString();
    i[h] || (i[h] = []);
    const g = (_) => c.onNewEvent({ subscriptionId: p, payload: _ });
    return i[h].push({ id: p, subscriptionData: c, listener: g }), s[p] = h, { listener: g, subscriptionId: p };
  }, o = (u) => {
    const { id: c } = u, d = s[c];
    if (!d)
      return null;
    const f = i[d].find(({ id: h }) => h === c);
    return f ? (i[d] = i[d].filter(({ id: h }) => h !== c), i[d].length === 0 && delete i[d], delete s[c], { subscription: f, channel: d, wasLastSubscription: !i[d] }) : null;
  };
  return {
    connect: async () => new Promise((u, c) => {
      const d = n.replace(/\/+$/, "") + t;
      r = Gn(d, { transports: ["websocket"], auth: { userToken: e }, reconnectionAttempts: 3 }), r.once("connect", u), r.on("connect_error", c);
    }),
    subscribe: async (u) => {
      const { subscriptionsData: c } = u;
      return await new Promise((d, f) => {
        if (!r)
          throw new Error("Socket is not connected");
        r.emit("subscribe", c, (h) => {
          h.success ? d() : f(h.reason);
        });
      }), c.map((d) => {
        if (!r)
          throw new Error("Socket is not connected");
        const f = Cs(d), { subscriptionId: h, listener: p } = a({ subscriptionData: d });
        return r.on(f, p), h;
      });
    },
    unsubscribe: async (u) => {
      const { subscriptionIds: c } = u, d = c.reduce((f, h) => {
        if (!r)
          throw new Error("Socket is not connected");
        const p = o({ id: h });
        if (!p)
          throw new Error(`Subscription with id ${h} not found`);
        const { subscription: g, channel: _, wasLastSubscription: A } = p;
        return r.off(_, g.listener), A && f.push(g.subscriptionData), f;
      }, []);
      d.length !== 0 && await new Promise((f, h) => {
        if (r === void 0)
          throw new Error("Socket is not connected");
        r.emit("unsubscribe", d, (p) => {
          p.success ? f() : h(p.reason);
        });
      });
    },
    close: () => {
      r == null || r.disconnect(), r == null || r.close();
    },
    getSocket: () => {
      if (r === void 0)
        throw new Error("Socket is not connected");
      return r;
    },
    isConnected: () => (r == null ? void 0 : r.connected) === !0,
    getSubscriptions: () => i
  };
};
S.object({
  topic: S.string(),
  records: S.array(vf)
});
const Sd = ({
  userToken: n,
  url: e,
  disabled: t = !1
}) => {
  const [r, i] = ft(void 0);
  return mt(() => {
    if (t)
      return () => {
      };
    const s = ud({ url: e, userToken: n });
    return s.connect().then(() => {
      r == null || r.close(), i(s);
    }).catch(console.error), () => {
      r == null || r.close();
    };
  }, [e, n, t]), { ioClient: r, loading: !r };
}, Ed = () => Fs(tc);
var $r;
(function(n) {
  n.CLOUD = "cloud", n.EXTENSION = "extension";
})($r = $r || ($r = {}));
var Br;
(function(n) {
  n.FROM_USER_CREDENTIALS = "cloud: token-user-credentials", n.FROM_EXTENSION = "cloud: token-extension", n.UNKNOWN = "cloud: token-unknown-provider";
})(Br = Br || (Br = {}));
var Yn;
(function(n) {
  n.FIRST_WAALAXY_OPEN = "firstWaalaxyOpen", n.LAUNCHED_CAMPAIGN = "launchedCampaign", n.USED_PREFILLED_SEQUENCE = "usedPrefilledSequence", n.EXPORT_CSV_FINISHED = "exportCSVFinished", n.FIRST_LAUNCHED_CAMPAIGN = "firstLaunchedCampaign", n.FIRST_SEND_EMAIL = "firstSendEmail", n.FIRST_IMPORT_PROSPECT = "firstImportProspect", n.IMPORT_PROSPECTS = "importProspects", n.REACHED_QUOTA = "reachedQuota", n.RESTRICTED = "restricted", n.FINISHED_ONBOARDING = "finishedOnboarding", n.ENDED_EXTENDED_FREE_TRIAL = "endedExtendedFreeTrial", n.ENDED_FREE_TRIAL = "endedFreeTrial", n.EXTENDED_FREE_TRIAL = "extendedFreeTrial", n.DOWNLOAD_APP = "downloadApp", n.ONBOARDING_ARRIVAL_TEST = "onboardingArrivalTest", n.ACTIVATE_BETA_ACESS = "activateBetaAccess", n.NOTIFICATION_SENT = "notificationSent", n.HUBSPOT_USED = "hubspotUsed";
})(Yn = Yn || (Yn = {}));
var Kn;
(function(n) {
  n.DEFAULT = "default", n.TECH = "tech";
})(Kn = Kn || (Kn = {}));
const cd = S.object({
  value: S.string(),
  Domain: S.string().optional(),
  Expires: S.string().optional(),
  HttpOnly: S.boolean().optional(),
  Path: S.string().optional(),
  SameSite: S.union([S.literal("Strict"), S.literal("Lax"), S.literal("None")]).optional(),
  Secure: S.boolean().optional(),
  "Max-Age": S.number().optional()
});
S.record(S.string(), cd);
S.object({
  totalKeysScanned: S.number(),
  deletedKeys: S.number(),
  dryRun: S.boolean().optional(),
  error: S.string().optional(),
  keyPattern: S.string(),
  queueName: S.string()
});
var Hr;
(function(n) {
  n.isLinkedinPremiumV2 = "custom-invitation-compose-view", n.jobSeeker = "open-to-cards-by-top-card", n.getGroup = "com.linkedin.voyager.dash.deco.groups.GroupDetailPage", n.getGroups = "fetch-group-listing-page", n.invitations = "invitation-views-by-received", n.creatorExperienceDashboard = "get-creator-dashboard", n.personalInfo = "web-top-card-core-query", n.regularProfile = "web-top-card-core-query", n.featurePremiumAccess = "feature-access", n.fetchPendingInvitation = "invitation-views-by-received", n.filtersRegularSearch = "search-filter-cluster-collection", n.getRegularProfileTopCard = "top-card-supplementary-query", n.getIdentityDashProfile = "profile-cards-by-initial-cards", n.getMyEvents = "search-cluster-collection", n.searchGif = "search-third-party-media", n.searchPeople = "messaging-typeahead-by-keyword-and-types", n.getLinkedinPost = "single-update-by-backend-urn-or-nss", n.getProfessionnalEvent = "com.linkedin.voyager.dash.deco.events.ProfessionalEventDetailPage", n.getConnections = "com.linkedin.voyager.dash.deco.web.mynetwork.ConnectionListWithProfile", n.getCountryRegionGeoUrn = "search-reusable-typeahead-collection-finder-type-query", n.getIndustryId = "search-reusable-typeahead-collection-finder-type-query", n.viewersAsRegularFullView = "get-premium-dash-analytics-card", n.viewersAsPremium = "analytics-object-union-collection-finder", n.advancedRegularSearchV2 = "search-lazy-loaded-actions", n.getCompany = "member-company-by-company-urns", n.getCompanyFeedPosts = "organizational-page-relevance-feed", n.conversationData = "get-conversation-by-id", n.conversationEvents = "get-messages-by-timestamp", n.conversationsLastEvent = "find-conversations-by-search-criteria", n.conversationIdsByMemberIds = "find-conversations-by-recipients", n.postDataFromActivityId = "single-update-by-backend-urn-or-nss", n.postComments = "comments-by-social-detail", n.getExperiencesAndEducations = "profile-components";
})(Hr = Hr || (Hr = {}));
S.union([
  S.object({
    executionSuccess: S.literal(!0)
  }),
  S.object({ executionFailure: S.literal(!0) }),
  S.object({ parsingFailure: S.literal(!0) })
]);
var on;
(function(n) {
  n.FR = "fr", n.EN = "en", n.ES = "es", n.JA = "ja", n.RU = "ru", n.DE = "de", n.ID = "id", n.IT = "it", n.NL = "nl", n.PL = "pl", n.PT = "pt";
})(on = on || (on = {}));
Object.values(on);
var un;
(function(n) {
  n.LANGUAGE_UPDATED = "LANGUAGE_UPDATED";
})(un = un || (un = {}));
const ld = S.object({
  action: S.literal(un.LANGUAGE_UPDATED),
  data: S.object({ newLanguage: S.nativeEnum(on) })
}), fd = za("action", [ld]);
S.object({
  language: S.object({
    selected: S.string()
  })
});
const dd = S.object({
  mixpanel: S.object({
    project: S.nativeEnum(Kn),
    userIdOverride: S.string().optional()
  }).optional(),
  segment: S.object({
    userIdOverride: S.string().optional()
  }).optional()
});
S.object({
  userId: S.string(),
  eventName: S.nativeEnum(Yn),
  eventDate: S.string().optional(),
  eventProperties: S.record(S.string(), S.union([S.string(), S.boolean(), S.null(), S.number(), S.array(S.string())])),
  trackers: dd
});
vi.extendZodWithOpenApi(S);
var Ur;
(function(n) {
  n.CSV_IMPORT_WITH_EMAIL = "csv_import_with_email", n.SENTIMENT_FILTER = "sentiment_filter";
})(Ur = Ur || (Ur = {}));
var Zr;
(function(n) {
  n.INTERESTED = "interested", n.NOT_INTERESTED = "not_interested";
})(Zr = Zr || (Zr = {}));
var cn;
(function(n) {
  n.EMAIL = "email", n.CAPTCHA = "captcha", n.PHONE = "phone", n.MOBILE_APP = "mobileApp", n.UNKNOWN = "unknown", n.AUTH_APP = "authApp";
})(cn = cn || (cn = {}));
const hd = Object.values(cn);
hd.filter((n) => n !== cn.CAPTCHA);
var Fr;
(function(n) {
  n.SUCCESS = "success", n.FAILED = "failed", n.VERIFICATION = "verification", n.PROCESSING_CAPTCHA = "processingCaptcha";
})(Fr = Fr || (Fr = {}));
var Vr;
(function(n) {
  n.WRONG_EMAIL_OR_PASSWORD = "wrong_email_or_password", n.MAIL_CHALLENGE_FAILED = "mail_challenge_failed", n.PHONE_CHALLENGE_FAILED = "phone_challenge_failed", n.MOBILE_APP_CHALLENGE_FAILED = "mobile_app_challenge_failed", n.REMOTE_LOGIN_REQUEST_FAILED = "remote_login_request_failed", n.CAPTCHA_ANSWER_REJECTED = "captcha_answer_rejected", n.TOO_MANY_REQUESTS = "too_many_requests", n.PROXY_NOT_WORKING = "proxy_not_working", n.NON_WORKING_PROXY = "non_working_proxy", n.PROXY_AUTHENTICATION_REQUIRED = "proxy_authentication_required", n.TLS_DISCONNECTED_ERROR = "tls_disconnected_error", n.USER_IS_RESTRICTED = "user_is_restricted", n.CAPTCHA_PUBLIC_KEY_UNKNOWN = "captcha_public_key_unknown", n.NO_REDIRECTION_NO_COOKIES = "no_redirection_no_cookies", n.UNKNOWN = "unknown";
})(Vr = Vr || (Vr = {}));
var Gr;
(function(n) {
  n.EXPIRED_TOKEN = "EXPIRED_TOKEN", n.USER_ACTION = "USER_ACTION", n.SUPPORT_ACTION = "SUPPORT_ACTION", n.RELOGIN_JOB = "RELOGIN_JOB";
})(Gr = Gr || (Gr = {}));
var Yr;
(function(n) {
  n.USER_CANCELED_THE_CHALLENGE = "user_canceled_the_challenge", n.CAPTCHA_FAILED = "captcha_failed", n.LOGIN_PROCESS_FAILED = "login_process_failed", n.TOO_MANY_AUTO_RELOGIN = "too_many_auto_relogin";
})(Yr = Yr || (Yr = {}));
var Ut;
(function(n) {
  n.CAMPAIGN = "campaign", n.EXPORT = "export";
})(Ut = Ut || (Ut = {}));
var qn;
(function(n) {
  n.ID = "_id", n.IMPORT_DATE = "importDate", n.FIRST_NAME = "firstName", n.LAST_NAME = "lastName", n.OCCUPATION = "occupation", n.LOCATION = "location", n.EMAIL = "linkedinEmail", n.DROPCONTACT_EMAIL = "proEmail", n.SALES_NAVIGATOR_ID = "salesNavigatorId", n.PHONE_NUMBERS = "phoneNumbers", n.PROSPECT_LIST = "prospectList", n.LINKEDIN_URL = "linkedinUrl", n.PREMIUM = "premium", n.JOB_SEEKER = "jobSeeker", n.HEADLINE = "job_title", n.PROFILE_STATUS = "profileStatus", n.CONNECTION_REQUEST_DATE = "connectionRequestDate", n.MESSAGE_SENT = "messageSent", n.LAST_LINKEDIN_MESSAGE_SENT_DATE = "lastLinkedinMessageSentDate", n.MESSAGE_REPLIED = "messageReplied", n.LAST_LINKEDIN_REPLY_DATE = "lastLinkedinReplyDate", n.EMAIL_SENT = "emailSent", n.LAST_EMAIL_SENT_DATE = "lastEmailSentDate", n.EMAIL_REPLIED = "emailReplied", n.LAST_EMAIL_REPLY_DATE = "lastEmailReplyDate", n.CONNECTED_AT = "connectedAt", n.FIRST_MESSAGE_AT = "firstMessageAt", n.TAGS = "tags", n.COMPANY_NAME = "company_name", n.COMPANY_LINKEDIN_URL = "company_linkedinUrl", n.COMPANY_WEBSITE = "company_website", n.LINKEDIN_CONVERSATION_LINK = "linkedInConversationLink";
})(qn = qn || (qn = {}));
var Kr;
(function(n) {
  n.HUBSPOT = "hubspot", n.SALESFORCE = "salesforce", n.ZOHO = "zoho", n.NOCRMIO = "nocrmio", n.PIPEDRIVE = "pipedrive", n.SHEET = "sheet", n.OTHER = "other";
})(Kr = Kr || (Kr = {}));
const ji = {
  WEBHOOK_SUCCEEDED: "webhook_succeeded",
  HUBSPOT_WEBHOOK_SUCCEEDED: "hubspot_webhook_succeeded",
  NOCRM_WEBHOOK_SUCCEEDED: "nocrm_webhook_succeeded"
}, pd = S.object({
  name: S.literal(ji.NOCRM_WEBHOOK_SUCCEEDED),
  executionDate: S.string(),
  params: S.object({
    context: S.nativeEnum(Ut),
    user: S.string(),
    prospects: S.array(S.string()),
    webhookId: S.string().optional()
  })
}), md = S.object({
  name: S.literal(ji.WEBHOOK_SUCCEEDED),
  executionDate: S.string(),
  params: S.object({
    context: S.nativeEnum(Ut),
    user: S.string(),
    prospects: S.array(S.string()),
    webhookId: S.string().optional()
  })
}), gd = S.object({
  name: S.literal(ji.HUBSPOT_WEBHOOK_SUCCEEDED),
  executionDate: S.string(),
  params: S.object({
    context: S.nativeEnum(Ut),
    user: S.string(),
    list: S.object({
      id: S.string(),
      name: S.string()
    }).optional(),
    properties: S.array(S.nativeEnum(qn)),
    prospects: S.array(S.string())
  })
});
S.discriminatedUnion("name", [
  md,
  gd,
  pd
]);
let Ot;
const kd = ({ connectToExtension: n, canConnectWithExtension: e, onLanguageUpdated: t }) => {
  const r = async (s) => {
    const a = fd.safeParse(s);
    if (a.success === !1)
      return;
    const o = a.data;
    o.action === un.LANGUAGE_UPDATED && (t == null || t({ language: o.data.newLanguage }));
  }, i = async () => {
    (!e || e()) && (Ot = (await n({ portName: "language", messageListener: r })).port, Ot.onMessage.addListener(r));
  };
  mt(() => (Ot === void 0 && i(), () => {
    Ot && (Ot.onMessage.removeListener(r), Ot.disconnect(), Ot = void 0);
  }), []);
};
export {
  tc as IOClientContext,
  Re as Languages,
  vd as NO_EXTENSION_VERSION,
  _d as getApiClientsContext,
  bd as getEasyToReadNumber,
  yo as getNumberOfDigitsOfCurrency,
  Sd as initIOClient,
  zu as isLanguage,
  wd as useFormatAmountWithCurrency,
  Ed as useIOClient,
  Od as useInitApiClients,
  Ad as useInitTranslation,
  kd as useLanguageListener,
  ec as useTranslation
};
