import { BasePasswordInput } from "@/app/components/Inputs/BasePasswordInput/index.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Flex, ITheme, Icon, Text, TextSize } from "@waapi/waap-ui";
import { Field } from "formik";
import { useTheme } from "styled-components";
import { PasswordInputProps } from "./types.js";
import { passwordInputErrorMap } from "./validation.js";

export const PasswordInput = ({ formState }: PasswordInputProps): JSX.Element => {
    const theme = useTheme() as ITheme;
    const { t, translations } = useTranslation();

    const passwordInputErrors = passwordInputErrorMap({ t, translations });

    return (
        <Field name="password">
            {({ field, meta }) => (
                <Flex direction="column" gap="4">
                    <BasePasswordInput
                        {...field}
                        labelInfos={{ text: t(translations.forms.auth.password.label()), color: theme.grayscale.white }}
                        feedback={
                            meta.touched && meta.error
                                ? { type: "error", textColor: theme.grayscale.white, children: passwordInputErrors[meta.error] }
                                : undefined
                        }
                        value={formState.values.password}
                        disabled={formState.isSubmitting}
                        placeholder={t(translations.forms.auth.password.placeholder())}
                        status={meta.error ? "error" : undefined}
                    />
                    {!meta.touched && !meta.error && (
                        <Flex gap="4" align="center">
                            <Icon size="small" icon="essential/info" iconColor={theme.colors.primary[100]} />
                            <Text size={TextSize["XX-SMALL"]} color={theme.grayscale.white}>
                                {t(translations.forms.auth.password.feedback.help.requirements())}
                            </Text>
                        </Flex>
                    )}
                </Flex>
            )}
        </Field>
    );
};
