import { CopyMyAffiliationCodeProps } from "@/app/components/CopyMyAffiliationCode/types.js";
import { useCopyToClipboard } from "@/customHooks/useCopyToClipboard.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Button, Tooltip } from "@waapi/waap-ui";

export const CopyMyAffiliationCode = ({ affiliationCode }: CopyMyAffiliationCodeProps) => {
    const { t, translations } = useTranslation();
    const { copyToClipboard } = useCopyToClipboard();

    const copyDefaultLink = () => {
        copyToClipboard({
            textToCopy: affiliationCode,
            textFailed: t(translations.header.shareTracking.failed()),
            textSuccess: t(translations.header.shareTracking.success()),
        });
    };

    return (
        <Tooltip containerStyle={{ textAlign: "center" }} content={t(translations.header.shareTracking.tooltip())}>
            <Button iconLeft="essential/copy-bold" size="small" variant="outline" color="primary" onClick={copyDefaultLink}>
                {affiliationCode}
            </Button>
        </Tooltip>
    );
};
