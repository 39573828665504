import { Avatar as InnerAvatar } from "@waapi/waap-ui";
import styled from "styled-components";
import { MyAvatarProps } from "./types.js";

export const Avatar = styled(InnerAvatar)<MyAvatarProps>`
    ${({ customSize }) =>
        customSize &&
        `
        height: ${customSize.height}px;
        width: ${customSize.width}px;
    `}
`;
