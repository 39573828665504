import casualLife from "@/assets/casual-life-3d-glasses-with-notes.png";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useGoldenRules } from "@/entities/User/hooks/useGoldenRules/index.js";
import { getUser } from "@/entities/User/hooks/useUserState/selectors.js";
import { OTHER_COUNTRIES_CODE } from "@waapi/palpatine-client";
import { Button, Checkbox, Heading, HeadingSize, HeadingWeight, Icon, Image, Modal, Text, TextSize, TextWeight, useModal } from "@waapi/waap-ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Container, ContainerRules, Content, FooterButtons, ItemRule, ListRules } from "./style.js";

export const GoldenRules = (): JSX.Element => {
    const { goldenRules, handleAcceptRules, handleRefuseRules, checkedRules, toggleRules } = useGoldenRules();
    const { t, translations } = useTranslation();
    const user = useSelector(getUser);

    const [isOpen, toggle] = useModal({
        name: "golden-rules-modal",
        defaultIsOpen: true,
        useUrl: false,
    });

    const onAcceptRules = () => {
        handleAcceptRules();
        toggle(false);
    };

    const isOtherCountry = useMemo(() => user?.country === OTHER_COUNTRIES_CODE, [user?.country]);

    const renderLabelCheckbox = () => (
        <>
            {t(translations.goldenRules.checkbox.text())}
            <a
                href={isOtherCountry ? t(translations.goldenRules.checkbox.linkOtherCountry()) : t(translations.goldenRules.checkbox.link())}
                target="_blank"
                rel="noopener noreferrer"
            >
                {t(translations.goldenRules.checkbox.linkText())}
            </a>
            .
        </>
    );

    return (
        <Modal isOpen={isOpen} hideCloseButton onClose={() => ""} fluid={false}>
            <Container>
                <Heading size={HeadingSize.SMALL} weight={HeadingWeight.BOLD}>
                    {t(translations.goldenRules.title())}
                </Heading>
                <Content>
                    <Image src={casualLife} alt="golden rules" dimensions={{ height: "13rem" }} />
                    <ContainerRules>
                        <Text size={TextSize.SMALL} weight={TextWeight.BOLD}>
                            {t(translations.goldenRules.subtitle())}
                        </Text>
                        <ListRules>
                            {goldenRules.map((rule) => (
                                <ItemRule key={rule.name}>
                                    <Icon icon="essential/circle-bold" size="small" />
                                    <Text size={TextSize.SMALL}>{rule.text}</Text>
                                </ItemRule>
                            ))}
                        </ListRules>
                    </ContainerRules>
                </Content>
                <Checkbox checked={checkedRules} onChange={toggleRules} label={renderLabelCheckbox()} />
                <FooterButtons>
                    <Button color="neutral" variant="outline" onClick={handleRefuseRules}>
                        {t(translations.goldenRules.cta.refuse())}
                    </Button>
                    <Button disabled={!checkedRules} onClick={onAcceptRules}>
                        {t(translations.goldenRules.cta.accept())}
                    </Button>
                </FooterButtons>
            </Container>
        </Modal>
    );
};
