import { Card, Text } from "@waapi/waap-ui";
import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledCardBody = styled(Card)`
    box-shadow: ${(props) => props.theme.shadows.card.default};
    padding: ${({ theme }) => theme.spacing["12"]};
    border-radius: ${(props) => props.theme.radius.m};
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing["16"]};
`;

export const StyledText = styled(Text)``;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledHeaderBody = styled.div`
    display: flex;
    gap: ${(props) => props.theme.spacing[12]};
    align-items: center;
`;

export const SvgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.theme.spacing[4]};
`;
