import { MyAvatar } from "@/app/components/MyAvatar/index.js";
import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import { Icon } from "@waapi/waap-ui";
import { SettingsSecondaryMenuItemProperties, SettingsSecondaryMenuPage } from "./types.js";

// @TODO: Remove the Partial<> and add my documents page
export const getSettingsSecondayMenuItems = ({
    t,
    translations,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
}): Partial<Record<SettingsSecondaryMenuPage, SettingsSecondaryMenuItemProperties>> => ({
    [SettingsSecondaryMenuPage.MY_ACCOUNT]: {
        icon: <MyAvatar customSize={{ width: 36, height: 36 }} />,
        label: t(translations.sidebar.route.my_account()),
    },
    [SettingsSecondaryMenuPage.BILLS]: {
        icon: <Icon icon="money/credit-card" size="small" color="tertiary" />,
        label: t(translations.sidebar.route.bills()),
    },
});
